const state = {
  top: 0,
  pages: 0,
  threshold: 100,
  mouse: [0, 0],
  imu: [0, 0, 0, 0],
  homeIndex: 0,
  homeCounter: 1,
}

export default state
