const content = {
  home: {
    skin: {
      foreground: '#d6d6d6',
      background: '#101010',
      perimeter: '#aaaaaa',
      hoverColor: '#808080',
    },
    section: {
      main: {
        model: '/models/moebius.glb',
        modelNear: '/models/moebius_straight.glb',
        title: 'what if',
        subtitle: '',
      },
      fresh: {
        intro1: `Hi, I'm Andrei, a hands-on multifaceted designer.`,
        intro2: `I work with emerging tech & innovation.`,
        title: ``,
      },
    },
  },
  works: {
    skin: {
      foreground: '#d6d6d6',
      background: '#101010',
      perimeter: '#aaaaaa',
      hoverColor: '#808080',
    },
    tagStrings: [
      'immersive',
      'sensorial',
      'collaborative',
      'hardware',
      'service',
      'mechatronics',
      'connected',
      'simulation',
      'experimental',
      'venue',
      'XR',
      'retail',
      'tool',
      'city',
      'phygital',
      '3D',
      'narrative',
      'spatial',
      'game',
      'IoT',
      'app',
      'scenography',
      'marketing',
      'vision',
      'device',
    ],
    projects: [
      // chromnos BESIDES
      {
        caption: 'chromnos',
        subcaption: 'live WebArt piece',
        url: '/chromnos',

        coverImage: '/images/chromnos/chromnos_watch_1.jpg', //
        stripImage: '/images/chromnos/chromnos_cover.jpg', //
        thumbImage: '/images/chromnos/chromnos_thumb.jpg', //
        type: 'besides', //

        foreground: '#b7b7b7',
        background: '#111111',
        model: '/models/chromnos.glb',

        //color bar
        imagesColorbar: [
          '/images/chromnos/chromnos_1.jpg',
          '/images/chromnos/chromnos_2.jpg',
          '/images/chromnos/chromnos_3.jpg',
          '/images/chromnos/chromnos_simplification.png',
          '/images/chromnos/chromnos_pcb.jpg',
          '/images/chromnos/chromnos_portfolio_a.jpg',
          '/images/chromnos/chromnos_portfolio_b.jpg',
          '/images/chromnos/chromnos_thumb.jpg',
          '/images/chromnos/chromnos_logo.png',
          '/images/chromnos/chromnos_structure.jpg',
          '/images/chromnos/chromnos_battery.jpg',
          '/images/chromnos/chromnos_assembly.jpg',
          '/images/chromnos/chromnos_watch_1.jpg',
          '/images/chromnos/chromnos_sensorcolors.jpg',
          '/images/chromnos/chromnos_capture_1.png',
          '/images/chromnos/chromnos_colors.png',
          '/images/chromnos/chromnos_tech.png',
          '/images/chromnos/chromnos_watch.jpg',
          '/images/chromnos/chromnos_life.jpg',
          '/images/chromnos/chromnos_intro.jpg',
        ],
        textsColorbar: [
`The project started in 2016, when I got inspired by a tumblr called "moviebarcode" and thought about the idea of viewing my ordinary routine in the same way.

The original idea was based on compressing takes from movies temporally so there is a colorful visual progression in a compact timeline.

A single RGB color sensor was connected to a portable Seeduino with a lithium battery and a SD card. This way, the device woke up from time to time and logged the color of that moment.`,
`Instead of using rich 2D image captures (camera photos), I choose a very simple RGB light sensor that can be considered a one pixel CCD, implying a lossy and reductionist interpretation of the world, starting at a low hardware level.

By using a sensor as simple as that, I knew I could guarantee more fidelity on my routine capture, as I could bring it anywhere without worries about that would be depicted.`,
`Later I put all the data into a processing sketch to visualize the signature of my daily routine.

I've notticed that interesting patterns emerged: rhythms, contrasts, deviations. But the dataset wasn't big enough for me to conclude anything and soon I paused the project to focus on other matters.`,
`After years of backburning, recently I realized that the synthesis of this research was on the relationship between color and time.

I have then invented word "chromnos" from the fusion of two Greek words for color and time, respectively.

It becomes a unit for the specific combination of both components.`,
`By aggregating new techniques learned recently, I've decided to create a wearable device to ease the color capture.

The form factor is of a watch, that in fact watches around from inside out.

An I²C 16 bit-per-channel RGBCIR color sensor sends information to an ESP32-S3. The power comes from a battery, with integrated charging capability.

The device will link to a smartphone App via Bluetooth Low Energy, that will then stream the data to a cloud server.`,
`In this long-term experimental live piece on the Web, I continually wear a custom watch that captures color from my surroundings every minute.

By binding color with time, each chromnos entry represents a behavioral gene of my own existence.

Instead of showing the actual time, the wearable device seamlessly measures the sensorial now and subverts the wristwatch archetype while purposely retaining most of its symbolic relationship with time.`,
`Its single-pixel fuzzy sensing allows endless use without privacy concerns and portrays the author's daily routine with just enough fidelity.

While the time perception capability is detached from the physical device, it is openly accessible on the Web. The selected time zone for data capture is the Coordinated Universal Time, transposed to the viewer's locality to keep its synchronized principle.`,
`In real-time, the captured colors act as pigments for life painting on a poetic data canvas, each minute filling a new chromnatic entry to the inner ring that represents the ongoing day. 

Every 24 hours, the tree of life unfolds a new inner ring.`,
`The canvas acts as a dynamic music score for a generative soundscape, created by my friend Fabio Doná.

Its composition follows the color changes by shifting the music tonal palette using different scales and modes.

Daylight is uplifting, while dimmer colors give lower notes and darker chords. Tones towards sunset bring a more contemplative feeling to the music, and night colors into more mystical and mysterious scales.

The song's rhythm is guided by sets of patterns that evolve during the day. Larger and similar color areas can have a different rhythmic and melodic structure so the music never repeats, telling a singular story for each day.

Every day has a different music, built minute by minute.`,
``,
``,
``,
``,
``,
``,
``,
``,
        ],timeline: [
          {
            title: 'behavioral\ngenome\nsequences',
            text: ``,
            image: '/images/chromnos/genome.jpg',
          },
          {
            title: 'expressive\nmusical\nnotations',
            text: ``,
            image: '/images/chromnos/artikulation.jpg',
          },
          {
            title: 'visual\nmemento\nartifacts',
            text: ``,
            image: '/images/chromnos/memento.jpg',
          },
        ],
      },
      // etkt BESIDES
      {
        caption: 'e-tkt',
        subcaption: 'anachronic label maker\nopen source',
        url: '/e-tkt',

        coverImage: '/images/e-tkt/e-tkt_cover.jpg', //
        stripImage: '/images/e-tkt/e-tkt_cover.jpg', //
        thumbImage: '/images/e-tkt/e-tkt_thumb.jpg', //
        type: 'besides', //
        
        foreground: '#e6dac8',
        background: '#0c111a',        
        model: '/models/e-tkt.glb',

        mosaicDevice: [
          '/images/e-tkt/e-tkt_device_1.jpg',
          '/images/e-tkt/e-tkt_device_2.jpg',
          '/images/e-tkt/e-tkt_device_3.jpg',
          '/images/e-tkt/e-tkt_device_4.jpg',
        ],

        imagesEtkt: [
          '/images/e-tkt/e-tkt_title.jpg',
          '/images/e-tkt/e-tkt_device.jpg',
          '/images/e-tkt/e-tkt_open.png',
          '/images/e-tkt/e-tkt_full.jpg',
          '/images/e-tkt/e-tkt_parts.jpg',
          '/images/e-tkt/e-tkt_documentation.png',
          '/images/e-tkt/e-tkt_top.jpg',
          '/images/e-tkt/e-tkt_end.jpg',
        ],
        textsEtkt: [
`E-TKT is an open source label maker that fuses together both old and contemporary technology to create something as ubiquitous as…

EMBOSSED LABELS!

Besides its own utility, it served as a laboratory on design, technology and experimenting with different types of collaboration.

Also, it's got positive feedback, check it out:`,
`If you want to know more about how the project started and how it was developed, please check the dedicated documentation page.

There you will find:
» news
» about
» how does it work
» features
» gallery
» do it yourself
» iterations
» license
» credits
» media

Link below:`,
`I have decided to experiment with something different for this project: opening its source.

So, everyone is more than invited to make their own and collaborate with ideas and improvements ;)`,
        ],
      },
      // collab
      {
        type: 'work',
        caption: 'collab',
        subcaption: 'collective VR storytelling tool',
        info: '2020, XR tool',
        year: '2020',
        company: 'ARVORE',
        related: 'beleaf',
        role: 'UX & design direction\n(detailed credits at the bottom)',
        extras: `. project management\n. UI design\n. software development`,
        url: '/works/collab',
        foreground: '#ff90f1',
        background: '#170338',
        model: '/models/works/collab.glb',
        subtitle: 'we simultaneously crafted stories together, at distance',
        stripImage: '/images/works/collab/collab_thumb.jpg',
        coverImage: '/images/works/collab/collab_thumb.jpg',
        thumbImage: '/images/works/collab/collab_thumb.jpg',
        peekImage: '/images/works/collab/peek_1.jpg',
        tags: [1, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
        mosaicImages1: [
          '/images/works/collab/_DSC3231_s.jpg',
          '/images/works/collab/_DSC9774_s.jpg',
          '/images/works/collab/_DSC9812_s.jpg',
          '/images/works/collab/_DSC3220_s.jpg',
        ],
        images: [
          '/images/works/collab/collab_1.jpg',
          '/images/works/collab/collab_2.png',
          '/images/works/collab/_DSC9757_s.jpg',
          '/images/works/collab/Imagem4.jpg',
          '/images/works/collab/Imagem5.jpg',
          '/images/works/collab/collab_3.jpg',
          '/images/works/collab/collab_4.jpg',
          '/images/works/collab/collab_5.jpg',
          '/images/works/collab/benchmark.png',
          '/images/works/collab/import.png',
          '/images/works/collab/userinterface.jpg',
          '/images/works/collab/overview.jpg',
        ],
        texts: [
          `ARVORE is an immersive experiences company, and in the past years has been fully relying on physical working spaces and dynamics to create its products: games and stories.

At the same time the content developed and the company's extended reality (XR) knowledge had lots of potential for "telepresence" to be naturally taken advantage of.
          
So, while already quarantined, as a R&D Director, I have led the creation of a tool that consisted of a virtual place where teammates could join to sculpt, annotate, talk, walk and play while creating replayable stories in imaginary worlds.          `,
          `XR creations must be done in space and taking advantage of our own physical bodies.
          
We have always been using “cheap” materials to test ideas as early as possible together with successive digital iterations.
          
By using virtual spatial objects we can collaborate anywhere and with much more flexibility, making it even cheaper and easier to iterate, as we are already using the same medium as our final product from the beginning.`,
          `» enable remote collaboration, even when the “in-person world” comes back as a choice.
          
» bridge creativity and logic, at the same time consolidate processes on ever growing diverse teams.

» create a fruitful and human environment for structured collaboration and team building.`,
          `We began collab by exploring “raster” voxel octrees as minimal “building units” similar to LEGO bricks. They were interesting for creating the first iteration, but lacked flexibility for further modification and collaboration. Collab was already used by several people at the same time remotely, which helped us gather these insights.

The explorations then evolved into a “raster” + “vector” combination by grouping voxels into entities that could be freely moved, modified and replicated. Now every voxel sculpture could be treated as a singular object, but at the same time allowing editing.

We adopted this core logic consisting on entities+content that would guide the tool's interaction system from that point on.`,
          `In late 2020, we had the opportunity to test and aprimorate collab in a real scenario as ARVORE was conducting its “inception” co-creation dynamics to kickstart new products.

We gave our colleagues basic directions and conducted constant observations and tests to gather suggestions and issues, feeding our backlog and at the same time developing new features and fixing bugs.
          
At the time, the tool's creativity capabilities relied only on sculpting, but in this cycle it became very clear that we needed to incorporate dynamics to those scenes by bringing animations and interactions.`,
          `We closed the development cycle by adding features that focused on dynamism and automation of scenes and improving several interface usability aspects.

The animation system works together with the interactions. It was inspired by a Rube Goldberg Machine, where actions from either users or objects trigger animations, in cascade.

An internal wiki was created for reference, and collab is being adopted not only for creation, but ocasionally meetings too.`,
          `After creating the first tool (sculpt), we needed to create an interaction system that allowed new ones to be incorporated modularly. This is where we began exploring the tools + slots concept. 

By wearing “gems” in a generic tool, the user adopts contextual capabilities such as “sculpt”, “animate” or “navigate”, but keepint the basic interaction pattern.

This way, we could keep the base controllers and add different command sets for each tool, following their contextual needs ambidextrously.`,
          `Collab comes to solve a major issue at project beginnings: the blank canvas panic.

We designed it intentionally for quickly scraping ideas. Whenever an idea takes a good path, we made sure it flows for other appropriate software for further evolving.
           
So, there is an Unity importer that brings the models into a scene. They are saved in FBX format to be imported to 3D modelling software.`,
          `Before deciding to create a tool from scratch, we tested eight leading VR creation tools existent at the time.

We ranked them according to features such as modelling, animation, real time collaboration, layers and exporting. That helped to make it clear what their proposal was.
          
We concluded no tool at the time had “remote mise-en-scène” as a core value, so that became the value proposition for Collab.`,
          `The main advantage of creating a proprietary application is to develop it tailored for the company's own needs.

Collab started as a tool, but there are many possibilities including also serving as a living space for teams to meet in virtual corridors, or even having virtual parties.`,
          ``,
        ],        
        credits:
`STUDIO
ARVORE Immersive Experiences

UX & DESIGN DIRECTION
Andrei Speridião

UI DESIGN
Andrei Speridião, Fábio Doná, Carlo Caputo

VISUAL DESIGN
Jairo Rodrigues, Andrei Speridião

SOFTWARE DEVELOPMENT
Carlo Caputo, Fabio Doná, Luna Olmewe, Fábio Marques, Andrei Speridião

PROJECT MANAGEMENT
Andrei Speridião`,
        interaction: [
          {
            title: 'Embodied\nuser interface',
            text: `Users may wear objects in different parts of the body such as arms, head and torso. There are tools on the hip, HUD and arms.`,
            image: '/images/works/collab/body.png',
          },
          {
            title: 'No complicated windows',
            text: `By using the joystick and carrousel, users can adjust a parameter only by using their thumb. This avoids windows with lots of small buttons.`,
            image: '/images/works/collab/noscreen.jpg',
          },
          {
            title: 'Grab and drag objects around',
            text: `This is a basic functionality to hold, move, rotate and scale all objects in the scene. What you see is what you grab.`,
            image: '/images/works/collab/grab.jpg',
          },
          {
            title: 'Slots for objects and tools',
            text: `There are slots over the user's arms, hips and head, allowing for tools to be stored into them. Or even objects, that apart from being stored can also be worn.`,
            image: '/images/works/collab/slots.jpg',
          },
        ],
        timeline: [
          {
            title: 'communication',
            text: `. realtime collaboration
. spatialized voice
. avatar and gestures
. text notes`,
            image: '/images/works/collab/communication.jpg',
            // icons: ['/images/who/andrei.png'],
          },
          {
            title: 'usability',
            text: `. 100% ambidextrous
. HUD & body slots for tools
. spatial navigation
. tooltips
. 2D interface/viewer
. unity importer
. undo/redo
              `,
            image: '/images/works/collab/usability.jpg',
            // icons: ['/images/who/cubocc.png', '/images/who/fau.png'],
          },
          {
            title: 'creativity',
            text: `. voxel sculpting
. object cloning & instancing
. object collections
. import images as objects
. wearable objects
. cameras
. animation (key & realtime)
. interaction
            `,
            image: '/images/works/collab/collab_1.jpg',
            // icons: ['/images/who/flag.png'],
          },
        ],
        hovered: false,
      },
      // arvore lab
      {
        type: 'work',
        caption: 'arvore lab',
        subcaption: 'VR & sensorial prototypes',
        info: '2017-19, XR + phygital',
        year: '2017-19',
        company: 'ARVORE',
        related: '3fx',
        role: 'research direction',
        extras: `. design\n. project management\n. software development\n. hardware development`,
        url: '/works/lab',
        foreground: '#af6278',
        background: '#00172e',
        model: '/models/works/lab.glb',
        subtitle: 'immersive experiences went beyond the VR headsets',
        stripImage: '/images/works/lab/lab_thumb.jpg',
        coverImage: '/images/works/lab/lab_thumb.jpg',
        thumbImage: '/images/works/lab/lab_thumb.jpg',
        peekImage: '/images/works/lab/peek_1.jpg',
        tags: [1, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1, 0],
        mosaicImages: [
          '/images/works/lab/lab_general_01.jpg',
          '/images/works/lab/lab_general_02.jpg',
          '/images/works/lab/lab_general_03.jpg',
          '/images/works/lab/lab_general_04.jpg',
          '/images/works/lab/lab_general_05.jpg',
          '/images/works/lab/lab_general_06.jpg',
          '/images/works/lab/lab_general_07.jpg',
          '/images/works/lab/lab_general_08.jpg',
        ],
        mosaicImagesSmell: [
          '/images/works/lab/iff_1.png',
          '/images/works/lab/iff_2.png',
          '/images/works/lab/iff_3.png',
          '/images/works/lab/iff_4.png',
        ],
        mosaicImagesSmellCredits: ['', '', '', 'Rodrigo Terra'],
        mosaicImagesStructure: [
          '/images/works/lab/structure.jpg',
          '/images/works/lab/structure_2.jpg',
          '/images/works/lab/structure_3.jpg',
          '/images/works/lab/structure_4.jpg',
        ],
        images: [
          '/images/works/lab/lab_1.jpg',
          '/images/works/lab/intro.jpg',
          '/images/works/lab/touch.jpg',
          '/images/works/lab/lab_2.jpg',
          '/images/works/lab/wind.jpg',
          '/images/works/lab/heat.jpg',
          '/images/works/lab/humidity.jpg',
          '/images/works/lab/haptics.jpg',
          '/images/works/lab/ultrasound.jpg',
          '/images/works/lab/diadem.jpg',
          '/images/works/lab/device.jpg',
          '/images/works/lab/floor.jpg',
          '/images/works/lab/seat.jpg',
          '/images/works/lab/spaces.jpg',
          '/images/works/lab/phygitrack.jpg',
          '/images/works/lab/octo.jpg',
          '/images/works/lab/smell.jpg',
          '/images/works/lab/biometrics.jpg',
          '/images/works/lab/extended.jpg',
          '/images/works/lab/lighting.jpg',
          '/images/works/lab/mixed.jpg',
          '/images/works/lab/photogrammetry.jpg',
          '/images/works/lab/tools.jpg',
          '/images/works/lab/mocapface.jpg',
          '/images/works/lab/mocapbody.jpg',
          '/images/works/lab/collab.jpg',
          '/images/works/lab/wiki.jpg',
          '/images/works/lab/structure.jpg',
          '/images/works/lab/wind_1.jpg',
          '/images/works/lab/device_1.jpg',
          '/images/works/lab/vision.jpg',
          '/images/works/lab/floor_1.jpg',
          '/images/works/lab/structure.jpg',
          '/images/works/lab/vrena.jpg',
        ],
        timeline: [
          {
            title: 'touch',
            text: `. wind
. heat
. humidity
. haptics`,
            image: '/images/works/lab/haptics.jpg',
            // icons: ['/images/who/andrei.png'],
          },
          {
            title: 'dynamic\nspaces',
            text: `. physical-digital
. robots in scene`,
            image: '/images/works/lab/spaces.jpg',
            // icons: ['/images/who/cubocc.png', '/images/who/fau.png'],
          },
          {
            title: 'smell',
            text: `. fragrances
. scents`,
            image: '/images/works/lab/smell.jpg',
            // icons: ['/images/who/flag.png'],
          },
          {
            title: 'bio\nmetrics',
            text: `. heartbeat
. muscle tension`,
            image: '/images/works/lab/biometrics.jpg',
            // icons: ['/images/who/flag.png'],
          },
          {
            title: 'extended\nvision',
            text: `. dynamic lighting
. mixed reality
. depth cams
            `,
            image: '/images/works/lab/extended.jpg',
            // icons: ['/images/who/flag.png'],
          },
          {
            title: 'tools &\nculture',
            text: `. motion capture
. collab
. wiki
            `,
            image: '/images/works/lab/tools.jpg',
            // icons: ['/images/who/flag.png'],
          },
        ],
        imagesCaptions: ['some very long caption is what you expect from this image', 'all work and no play: makes jack a kinda dull boy'],
        texts: [
          //intro
          `In 2017 I was invited to be a R&D Director and part of the founding team of ARVORE, a brand new immersive experiences studio.

The challenge given to me was to create and spearhead the LAB, a unit focused at exploratory speculations over territories that potentialize immersion and emotional storytelling in extended reality experiences.
          
We started physical-digital from scratch, mixing Virtual Reality with prototypes that bridged different technologies for provoking reflections, creating tools and improving products.`,
          //motivation
          `Why explore more senses? Today, we are culturally oriented for assuming audiovisual as the main way for consuming content. It is not hard to see the storytelling evolution through ages: word-of-mouth stories, books, radio, television […] and of course computers, smartphones and streaming of today.`,
          //
          `Each media has its own sensorial basket mix, not exclusively relying on audio and or visual, but as computers are coming more and more towards our own bodies, we may refresh our sensibilities and question the state-of-the-art to see if we can be more porous for experiencing or even “living” stories.`,
          //touch
          `Our body and especially its skin are the definitive boundaries between us and the world “outside”. In a very simplified way: the touch is a complex and diverse sense that has nervous terminals specialized in feeling temperature, pressure, stretch, vibration, pain and also encompasses the very important “what and where is myself?”, which is called proprioception. The latter differentiates 6DOF VR, which enables the tracking of our bodies and limbs inside virtual spaces, over 3DOF VR that is a static non-bodily contraption.

Touch is not classified as a “special sense” such as vision, hearing, smell or taste, meaning that it doesn't rely on a unique specialized organ but it is rather distributed throughout our body, unevenly.
          
Thinking about that, we can see the huge potential to explore these sensorial channels by using technological apparatus that enriches the experience stack.
          `,
          //wind
          `Brings dramaticity and creates sense of direction (or resistance), especially when synced with dynamic visual particles to reinforce this directionality.\n\nWe used this resource on beleaf at the climax to intensify the user's emotion just before the soothing narrative resolution.`,
          //heat
          `Mild warmth can be used to attract the user.\n\nWe created a proof of concept in a hackathon where the user in a cold (air conditioned) and darkened room (in VR) is eventually attracted by the cozy sunlight coming from the window.`,
          //humidity
          `In a static environment, humidity is neither too noticeable nor “healthy” for VR HMDs and general electronics.\n\nWhen briefly carried by the wind, it can potentialize its "cold feel" sensation in the skin.`,
          //haptics
          `In a more specific way for understanding touch, an haptic system is used by a person actively touching for connecting to and exploring its immediate surroundings.
          
Several layers of interaction between the skin and the object's characteristics determine if it is soft or hard, rough or smooth, pulsating or stable.

There are complimentary approaches for trying to emulate this complexity: creating digitally matched physical props or vibrating surfaces/air.

And of course we may also create new sensations by exploring these same principles.
`,
          //ultrasound
          `We partnered with a company (confidential) to adapt an ARVORE existing narrative for using their proprietary solution of tactile ultrasound actuation.\n\nUsers could touch and feel the interface at specific points during the complete immersive narrative.`,
          //diadem
          `That idea came by pressing transducers around my head: by using multiple transducers on the skull we can hear sounds inside our head with spatialized effect.\n\nIn the end, hearing is similar to touch and both their responses coexist around the 20-80hz frequency range.`,
          //device
          `VR controllers are amazing for their flexibility, but sometimes we may need to feel the shape of the digital object in a physical way.\n\nThese phygital objects may go further, having as simple as a “magnetic click” or even interactive vibration.\n\nWe created this hourglass for an experience, it vibrates when tilting and is attracted to snap on the platform, together with visual effects when that happens.`,
          //floor
          `This began as a simple static wood plank on the ground for beleaf. People crossed it while virtually feeling they were in a high passage.\n\nThis idea was later evolved into another prototype using transducers mounted on an elevated platform to give spatial seismic sensations according to the user position.`,
          //car
          `Vehicles get touched by a lot of our body parts in a very diverse way, thus the relevance of feeling the many vibrations that are caused either by a mechanical part or an environmental context.\n\nThis improves the experience and lets the user be aware of the suspension, steering system and even the ground below.\n\nWe used this knowledge on our joint venture on motion simulators (see the 3fx project on works).`,
          //space
          `As screen matrices change pixel light levels to create virtual images, this idea is based on moving devices around physically to “fool” the user as if the environment has dramatically changed or virtual actors are real.`,
          //phygitrack
          `This was the first experiment made to connect custom mechatronic hardware to Unity (using UDP for better latency) with an HTC Vive Tracker for positioning the device in virtual space. The laser always pointed to the other Vive controller.\n\nSo, we could evolve this idea for moving lights, sensorial apparatus or even physical bafflers to change one's perception of space.`,
          //octo
          `In a more elaborated approach, we created this tracked bot that uses omni wheels to rotate and translate in space.\n\nThis way we could put objects in or out of the scene.`,
          //smell
          `Scents (food) and fragrances (perfumes) are part of our life from the day we are born. Each sense has its strengths and this particular one is all about its pervasiveness and speed for accessing deep memories. The smell sense is developed during the third month of gestation.

To understand that better, I have developed relations with IFF, a major global leader in the area, to organize a knowledge exchange, as they had great interest in knowing XR (our company core) better.
          
We had immersion sessions on both sides: first we invited them to VOYAGER (ARVORE's arcades) and their team experienced lots of state-of-the-art games and immersive experiences, followed by technical and conceptual explanations on how these were actually made.
          
The IFF team received us in their R&D center in São Paulo and gave us an amazing afternoon with a tour in their facilities followed by theoretical and practical experimentation on scents and fragrances.
          
I've later conducted a collaborative presentation with all of our team participants to propagate knowledge and connections to our world to the rest of the ARVORE team.`,
          //biometrics
          `We usually take Human-Computer Interfaces for granted as conscient commands: buttons, dials and levers. But what if we exposed a more visceral part of ourselves to experiences? There are lots of organic functions that are controlled by the parasympathetic nervous system, such as breathing, heart beating and many others.

This was a very short experiment in a hackathon, where we did a game controlled by the player's heart beat count. We also did other short experimentations with myoware muscle electrodes.
          `,
          //extended vision
          `Take an usual VR scene: for each one using a headset there is always a few around observing and a demo operator looking like he or she is a kind magician.

When creating experiences focused only on the user “inside” VR, we began to see that this actual person was an important part of the performance that is happening from the “outside” perspective.
          
Thinking about user journeys: whoever is watching may be the next to “enter”, so a story may start to be told at that moment, before putting on the headset.
          `,
          //dynamic lighting
          `Building over the Phigitrack idea and now working with DMX512/Artnet standards to use stage lights to create illumination synced to what's happening in the experience.\n\nThis is useful for either scenographic reasons or also for mixed reality capture integrating the user in the virtual scenario.`,
          //mixed
          `The fetish for living fantasies comes to a point where we want to record these moments for ourselves or showing others. To match these apparently different realities, we explored chroma key, depth cameras and dynamic lighting, putting the user inside the virtual world in a video output.`,
          //depth cam
          `More focused on the “inner” experience, I've experimented with depth cams such as Microsoft Kinect and Intel Depthsense for trying to incorporate the adjacent “outside” world into the narrative.`,
          //tools
          `Besides exploratory paths, I've also led and been involved in more objective tools to help create better immersive experiences.`,
          //face mocap
          `ARVORE needed to accelerate its animation production processes, so I've built a physical helmet to use Cory Strassburger's iPhone Face ID mocap solution.`,
          //body mocap
          `Also for the same animation efforts, we developed a solution to capture full body animation directly from inside targeted virtual scenes by using several HTC Vive Trackers.\n\nThis way, the actors could interact with the exact scenario, as the exact embodied character.`,
          //collab
          `A tool for simultaneous remote story creation.
    
Check this project in the link below:`,
          //wiki
          `I've also created the ARVORE's internal wiki (which I've called wikiLEAFS — I love puns) for the whole company so we could improve it's culture, process and share knowledge with current and new team members.`,
          //structure
          `To accomplish all these experiments, I've also led the design and construction of infrastructure for the lab.

» mobile stations: pc rack and trolleys for tools and experiments;

» electronics station: solder, rework;

» 3D printer: loved the fact we used it more than 2D printers;

» component and tools organization: physical inventory + digital catalogue;

» vrena: using it as a platform for experimentation, making sure future solutions would be modularly compatible with the units already installed on VOYAGER LBEs.
          `,
          //
          `Besides being a company unit by itself, the LAB always had its doors open as an inviting place where people from all ARVORE would come to interact in a different way, bring ideas or demands, take a peek for curiosity or just sit for a coffee.         
          `,
        ],
        hovered: false,
      },
      // 3fx
      {
        type: 'work',
        caption: '3fx',
        subcaption: '3-axis motion simulator',
        info: '2019, XR + simulation',
        year: '2019',
        company: 'ARVORE',
        related: 'voyager',
        role: 'UX & design direction\n(detailed credits at the bottom)',
        extras: `. industrial design\n. mechanical design`,
        url: '/works/threefx',
        foreground: '#b20000',
        background: '#130707',
        model: '/models/works/threefx.glb',
        subtitle: 'we floated around, freely detaching our feet from the ground below',
        stripImage: '/images/works/threefx/threefx_thumb.jpg',
        coverImage: '/images/works/threefx/threefx_thumb.jpg',
        thumbImage: '/images/works/threefx/threefx_thumb.jpg',
        peekImage: '/images/works/threefx/peek_1.jpg',
        tags: [0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1],
        mosaicImagesBs: [
          '/images/works/threefx/bs_1.jpg',
          '/images/works/threefx/bs_2.jpg',
          '/images/works/threefx/bs_3.jpg',
          '/images/works/threefx/bs_4.jpg',
        ],
        mosaicImages: [
          '/images/works/threefx/proto_2.jpg',
          '/images/works/threefx/proto_3.jpg',
          '/images/works/threefx/proto_4.jpg',
          '/images/works/threefx/proto_5.jpg',
        ],
        mosaicImages2: [
          '/images/works/threefx/proto_6.jpg',
          '/images/works/threefx/proto_7.jpg',
          '/images/works/threefx/proto_8.jpg',
          '/images/works/threefx/proto_9.jpg',
        ],
        images: [
          '/images/works/threefx/render_051_.jpg',
          '/images/works/threefx/render_052_.jpg',
          '/images/works/threefx/render_066.jpg',
          '/images/works/threefx/render_070.jpg',
          '/images/works/threefx/render_073.jpg',
          '/images/works/threefx/render_074.jpg',
          '/images/works/threefx/render_076.jpg',
          '/images/works/threefx/render_077.jpg',
          '/images/works/threefx/2fx.jpg',
          '/images/works/threefx/2fx_2.jpg',
          '/images/works/threefx/captures_1.jpg',
          '/images/works/threefx/captures_2.jpg',
          '/images/works/threefx/captures_3.jpg',
          '/images/works/threefx/proto_1.jpg',
        ],
        texts: [
          `In 2018 ARVORE began relations with BS Motion, a motion simulator manufacturer in the south of Brazil, founded by engineers passionate for racing and flying.

A joint venture was formed to develop a compact simulator that would enable advanced and unique immersive experiences. Several units would be produced to be deployed on VOYAGER stores throughout the country. 
          
I participated from the very early relationship development and was appointed to be the design director for the project, complementing the BS Motion engineering team.`,
          `BS Motion was already experienced in manufacturing simulators with a short range of actuation (pivot + two motors) intended for racing. I've had the opportunity to facelift these models to be implemented on VOYAGER.

The 2FX was a different and more complex 2 axis (full roll + pitch) simulator prototype that even had a few units sold to the Colombian national army for training purposes. Even though it worked well, It wasn't projected with XR in mind because it happened before the VR HMDs popularization.
          
So, the 3FX was a total redesign inspired by 2FX.`,
          `» embed dangerous moving parts to eliminate risk to users inside and outside;

» create a safe, comfortable and accessible cabin for users;

» incorporate the 3rd axis seamlessly;

» allow for seamless VR positional tracking;

» create an attractive device that captivates users from far away to inside;

» propose the smallest size possible that comprehend all needs, including assembly and transport;
          `,
          `While analyzing the engineering hardpoints, I've explored radial/polar symmetry from “inside-out”.`,
          `There are many natural structures that are based on polar/radial equilibrium such as crystals and molecules.

By experimenting with a few solid geometric shapes I found a variation of the octahedron to be super interesting for the internal user cabin needs.

The outer shell shape came by modifying a tetrahedron, creating a hull to protect external users from moving parts.`,
          `Using these two geometries meant that the whole structure could be based on three kinds of equilateral surfaces: triangles, hexagons and squares.
          
That would allow optimization for sheet and tube cutting, improve structural rigidity, aswell bring aesthetic unity.`,
          `Cabin size allows for any vehicle simulation: car, plane, ship, motorcycle or even a flying rug.

It also provided internal rails for fixation and enough space to accommodate a seat, steering wheel, pedals, sidesticks or even just plain hand tracking.

Have a look at my drive and fly simulator be-sides project to see how it works.`,
          `The two most critical aspects are: entering/exiting the cabin between sessions and the safety for internal and external users during the operation.

The simulator has a neutral zone isolated by two levels of doors, so neither internal and external users have access to the rotating axes while operating.

As the external doors are static, they are made out of plexiglass and steel sheets over steel laser cut tubes.`,
          `The internal capsule is also made of a tubular steel hull, but the faces are filled with a strong nylon mesh, so there is plenty ventilation.
          
This material gives a nice soft touch, also allowing the HTC Vive lighthouse tracking beam to pass through.
          
In case of an extreme emergency or fault, a simple cut with a knife can easily open space for the user to come out.`,
          `We conducted a few tests to check:
» if the cabin would fit tall and short users;
» if there would be space for accessories;
» if there would be space wide enough for arm movement;
» if the entering and exiting of the cabin was safe and easy;
          
The overall results were good, but we have elongated the cabin a little bit to increase the space.`,
          `
          `,
        ],        
        credits:
`STUDIO
ARVORE Immersive Experiences

UX & DESIGN DIRECTION
Andrei Speridião

INDUSTRIAL DESIGN
Andrei Speridião

ENGINEERING
BS Motion (Gabriel Sffair, Pedro Boessio, Evandro Souza Lima, Alexandre Stedile & team)

PROJECT MANAGEMENT
BS Motion (Kento Kojima, Caroline Guerreiro & team)`,
        hovered: false,
      },
      // beleaf
      {        
        type: 'work',
        caption: 'beleaf',
        subcaption: 'room-scale immersive VR experience',
        info: '2017-19, XR + phygital',
        year: '2017-19',
        company: 'ARVORE',
        related: 'arvore lab',
        role: 'creative direction\n(detailed credits at the bottom)',
        extras: `. visual development\n. 3D modelling\n. 3D printing\n. wood/steel crafting\n. hardware development`,
        url: '/works/beleaf',
        foreground: '#ff6c4f',
        background: '#12041f',
        model: '/models/works/beleaf.glb',
        subtitle: 'we wandered through portals to visit magical worlds',
        stripImage: '/images/works/beleaf/beleaf_strip.jpg',
        coverImage: '/images/works/beleaf/beleaf_cover.jpg',
        thumbImage: '/images/works/beleaf/beleaf_thumb.jpg',
        peekImage: '/images/works/beleaf/peek_1.jpg',
        tags: [1, 1, 0, 1, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0],
        timeline: [
          {
            title: 'soul',
            text: ``,
            image: '/images/works/beleaf/leaf.jpg',
          },
          {
            title: 'senses',
            text: ``,
            image: '/images/works/beleaf/torch.jpg',
          },
          {
            title: 'space',
            text: ``,
            image: '/images/works/beleaf/space.jpg',
          },
        ],
        mosaicTorch1: [
          '/images/works/beleaf/torch_1.jpg',
          '/images/works/beleaf/torch_2.jpg',
          '/images/works/beleaf/torch_3.jpg',
          '/images/works/beleaf/torch_4.jpg',
        ],
        mosaicTorch2: [
          '/images/works/beleaf/torch_5.jpg',
          '/images/works/beleaf/torch_6.jpg',
          '/images/works/beleaf/torch_7.jpg',
          '/images/works/beleaf/torch_8.jpg',
          '/images/works/beleaf/torch_big_1.jpg',
          '/images/works/beleaf/torch_big_2.jpg',
          '/images/works/beleaf/torch_big_3.jpg',
          '/images/works/beleaf/torch_big_4.jpg',
        ],
        mosaicTorch2Credits: ['', '', '', 'Hugo Chinaglia', '', '', '', ''],
        mosaicWow: [
          '/images/works/beleaf/wow_1.jpg',
          '/images/works/beleaf/wow_2.jpg',
          '/images/works/beleaf/wow_3.jpg',
          '/images/works/beleaf/wow_4.jpg',
          '/images/works/beleaf/wow_5.jpg',
          '/images/works/beleaf/wow_6.jpg',
          '/images/works/beleaf/wow_7.jpg',
          '/images/works/beleaf/wow_8.jpg',
        ],
        images: [
          '/images/works/beleaf/beleaf_1.jpg',
          '/images/works/beleaf/beleaf_2.jpg',
          '/images/works/beleaf/beleaf_3.jpg',
          '/images/works/beleaf/beleaf_4.jpg',
          '/images/works/beleaf/beleaf_5.jpg',
          '/images/works/beleaf/beleaf_6.jpg',
          '/images/works/beleaf/purpose.jpg',
          '/images/works/beleaf/process.jpg',
          '/images/works/beleaf/leaf.jpg',
          '/images/works/beleaf/torch.jpg',
          '/images/works/beleaf/space.jpg',
          '/images/works/beleaf/torch_big.jpg',
          '/images/works/beleaf/beleaf_intro.jpg',
        ],
        texts: [
          `In the very early stages of ARVORE, I have idealized and worked with the founding team to create an experience that explores different immersion possibilities in VR by using our body and proprioception in peculiar spaces.

It served as a way for us to bond and achieve our first delivery together, which happened in three weeks. The première was at the Red Bull Basement festival in 2018.
          
From that point on, the company set an internal standard for what kind of qualities its immersive products must have.`,
          `We started by brainstorming possibilities for a room-scale VR experience and came out with a few promising:

» physical-digital props for embodiment;
» sentient character interaction;
» walking through portals;
» growing/shrinking;
» walking in non euclidean spaces;
» fearing imaginary heights.`,
          `In the first place, we wanted to create this because we wanted to experience it by ourselves.
          
We started by sketching in space using a technique called mise-en-scène, which we borrowed from theater/cinema.

By using portals as connectors for the different worlds we could fool users as if they were travelling in a path much different than in tangible reality.`,
          `A kindle companion you meet in the very beginning and bond together by sealing the journey kickoff, sometimes it leads and sometimes is led by you.

It reveals the braziers in the way, but needs you to light them. In this way you both develop a symbiotic relationship, creating connection through the way.`,
          `Represents your body inside the experience, keeping you aware of your physical and digital presence as a strong link in-between. 

Not using a common VR controller keeps the user away from breaking the magic. Furthermore, all interactions are done by gesture and context, with no buttons, a very universal approach that proved itself fruitful for very diverse audiences.`,
          `The possibility for travelling through multiple worlds was enabled by using portals that allow the users to feel like jumping from and to anywhere, but in a coherent way.

This way, we created contrasting worlds with core concepts that explore new VR possibilities, utilizing the user sensations as a bread crumble to subjectively provoke meaning.

A powerful way for understanding spaces is using our body as measurement in relation to the surroundings. Thus, walking contributes majorly to the presence sensation, together with the user's own body proprioception.`,
          `In a dark room the user sees and picks the torch, connecting the physical to digital. Also, meets the leaf and starts interacting with it.`,
          `By passing through the portal, the user lands on the peak of a mountain with many others around. By following the leaf in a spiral-like path, the user shrinks.`,
          `Now in a tight, dark and confusing cave, the user actually walks in circles. The leaf must be followed closely, making use of the torch light to see where this path will lead to.`,
          `The climax of the journey presents itself as a tall and narrow passage, where the user's belief is questioned. At this moment, there is a wood plank on the ground and wind to reinforce the tension.`,
          `Soothing end, where a whole tree is summoned by the leaf. It's color has been morphing from fire-orange to fresh-green throughout the journey, helped by the user.`,
          `We have been constantly using Beleaf as a platform for other experiments/possibilities: wind integration, home version standing multiplayer (not developed), and an unreleased home version with small footprint.

It is an experience that has been iterated through several years, serving many purposes. Starting from a first project for a new company, it was used on pitches and demos to investors. Later, it was brought to VOYAGER, ARVORE's location-based entertainment stores, where it was experienced by thousands of people of all ages, from children to elder.`,
          `The torch mixes both virtual and real complimentary characteristics: triangles, touch, texture, volume and weight.

It all began with fallen tree branches from the my graduation university campus nearby, where I've made a trip to collect. I've chosen this approach to create this fuzzy familiarity with the Leaf.

It causes strangeness because it is rustic, but at the same time powerful, something the user gets to understand in the very beginning.`,
          `After the first prototype I've created, we needed to replicate the torch so more people could enjoy Beleaf.

We worked with Madeira Design, a Brazilian company with an absolute and refined knowledge on wood craftsmanship. They gave an excelent idea to utilize their amazing wood scraps from other projects to build the torches with CNC machined marquetry.

This way, all torches have the same volume, but different visual signatures, like fingerprint digitals.`,
        ],        
        credits:
`STUDIO
ARVORE Immersive Experiences

ORIGINAL IDEA
Andrei Speridião

CREATIVE DIRECTION
Andrei Speridião, Ricardo Justus, Ricardo Laganaro

CONCEPT ART
Galdino Sa, Rodrigo Blanco

3D MODELLING & ANIMATION
Fábio Doná, Julia Lemos, Galdino Sa, Rodrigo Vargas, Eduardo Nicolau, Isaias Junior, Andrei Speridião

SOFTWARE DEVELOPMENT
Rômulo Silva, Fábio Doná, Carlo Caputo, Ivan Garcia Filho

HARDWARE DEVELOPMENT
Andrei Speridião, Julio Silva

SOUNDTRACK
Fabio Doná

SOUND DESIGN
Estúdio JLS (Daniel Sasso, Toco Cerqueira)

INDUSTRIAL DESIGN
Andrei Speridião

PHYSICAL TORCH
Andrei Speridião, Madeira Design (Hugo Chinaglia, Antonio Vespoli & team)

NARRATIVE DESIGN
Mariana Brecht, Eduardo Nicolau

QA & TESTING
Rodrigo Vargas, Isaias Junior

PROJECT MANAGEMENT
Mônica Ravaioli, Julia Lemos`,
        hovered: false,
      },
      // drivesim BESIDES
      {
        caption: 'hull',
        subcaption: 'multi-purpose sensorial vehicle simulator',
        url: '/hull',

        coverImage: '/images/drivesim/drivesim_cover.jpg', //
        stripImage: '/images/drivesim/fly.jpg', //
        thumbImage: '/images/drivesim/drivesim_thumb.jpg', //
        type: 'besides', //
  
        background: '#0f0004',
        foreground: '#ffd8b3',        
        model: '/models/drivesim.glb',

        mosaicImagesDrive: [
          '/images/drivesim/drive_wheel.jpg',
          '/images/drivesim/drive_paddle.jpg',
          '/images/drivesim/drive_shifter.jpg',
          '/images/drivesim/drive_keyboard.jpg',
        ],
        mosaicImagesFly: [
          '/images/drivesim/fly_yokestore.jpg',
          '/images/drivesim/fly_yoke.jpg',
          '/images/drivesim/fly_sidestick.jpg',
          '/images/drivesim/fly_rudders.jpg',
        ],
        mosaicImagesSenses: [
          '/images/drivesim/senses_1.jpg',
          '/images/drivesim/senses_2.jpg',
          '/images/drivesim/senses_3.jpg',
          '/images/drivesim/senses_4.jpg',
        ],
        mosaicImagesProcess: [
          '/images/drivesim/process_1.jpg',
          '/images/drivesim/process_2.jpg',
          '/images/drivesim/process_3.jpg',
          '/images/drivesim/process_4.jpg',
          '/images/drivesim/process_5.jpg',
          '/images/drivesim/process_6.jpg',
          '/images/drivesim/process_7.jpg',
          '/images/drivesim/process_8.jpg',
        ],
        images: [
          '/images/drivesim/drivesim_main.jpg',
          '/images/drivesim/drivesim_pov.jpg',
          '/images/drivesim/mode_drive.jpg',
          '/images/drivesim/mode_fly.jpg',
          '/images/drivesim/senses_vibration.jpg',
          '/images/drivesim/senses_wind.jpg',
          '/images/drivesim/drive.jpg',
          '/images/drivesim/fly.jpg',
          '/images/drivesim/interface.jpg',
          '/images/drivesim/end.jpg',
        ],
        texts: [
          `I love driving (and fantasizing piloting), so while quarantined I've decided to build this simulator.
          
I was already tinkering in this idea for more than a year and upgrading it slowly. As I had more time at home, it seemed a good idea to dive in.

It ended up being an opportunity to explore senses, developing hardware and having fun.`,
          `Whenever operating a machine in space it is important to receive feedback from both it's own mechanical parts and also it's interaction with surroundings. This way, other senses may be focused on their best.

There are three "Buttkicker" actuators distributed in the simulator chassis, which vibrate according to spatially simulated events such as engine RPMs, tyres traction on the ground or even other moving parts.

Two dedicated sound amplifiers move these transducers, which act like subwoofers but having a small weight instead of a cone.`,
          `The core purpose of a simulator is to reduce the costs and risks of doing the real stuff.

At the same time, the main pursuit becomes the fidelity of simulation. The weakest point of a stationary simulator like this is the motion.

By bringing proportionally calculated wind to the experience with a pair of PWM high capacity fans, it is possible to feel a small part of a whole speed sensation and a cool breeze.`,
          `The main advantage of VR simulation is the possibility to fake everything around. But, the caveat is that complex menus and navigation gets compromised.

Thinking of that, I've developed a button box with a main directional stick to navigate directionaly, with several satellite buttons around.

There are different types of formats to improve usability even not having a VR visual representation.`,
          `Driving a car is a pretty straightforward activity: press pedals, turn driving wheel and handle the shift.

I've decided to have dedicated hardware for all of these, so regardless of the car driven I can train my muscular memory.

There are several buttons on the real custom driving wheel (I've modded a Logitech G29), a custom handbrake (using a real car part) and a real racing car bucket seat.`,
          `X-Plane allows VR spatial control operation for buttons in the cockpit, inspiring me to create hybrid operational controls instead of relying on dedicated controls.

By placing Oculus Rift CV1 controllers on standardized mounts, I can hold them in the air to operate the virtual commands or pick and place them on the yoke or sidesticks (both provides restrain and fake force feedback).

The rudder pedals are from DIY Beyond 3D with several modifications from me.`,
          `The whole process started with a tubular out-of-the-shelf rig I bought. I have quickly realized that it wasn't neither stable nor ergonomic as I was using it with my office chair, with a simple adapter.

The next step was projecting a frame to attach this rig onto. I choose aluminum profiles for further expansions and modularity. At that time I have also have put the real racing seat and the overall comfort increased a lot.

Then came the first Buttkicker transducer attached to the base frame, helping propagate vibrations. But the overall rig wasn't still too visually interesting or flexible to driving wheel and body posture adjustments.`,
          `I started to project the rest of rig over the base by studying the ergonomics, finding an interesting setup. With all the parts ordered, it was time to 3D print custom parts and assemble it.

To avoid problems with neighbors I've added industrial dampeners to keep vibrations isolated from ground, which also served to improve the shaking eficiency. 

For the interactive controls (handbrake, wheel buttons, rudder pedals and button box) I've chosen the Arduino 32u4 (as custom HID device) together with prototype PCB electronics.`,
        ],
      },
      // voyager
      {
        type: 'work',
        caption: 'voyager',
        subcaption: 'VR entertainment framework',
        info: '2018-19, XR + phygital + service',
        year: '2018-19',
        company: 'ARVORE',
        related: 'oasis',
        role: 'UX & design direction\n(detailed credits at the bottom)',
        extras: `. project management\n. industrial design\n. UI design\n. hardware development\n. software development`,
        url: '/works/voyager',
        foreground: '#e8e8e8',
        background: '#000037',
        model: '/models/works/voyager.glb',
        subtitle: 'we enjoyed the entertainment spaces of the future, right now',
        stripImage: '/images/works/voyager/voyager_strip.jpg',
        coverImage: '/images/works/voyager/voyager_cover.jpg',
        thumbImage: '/images/works/voyager/voyager_thumb.jpg',
        peekImage: '/images/works/voyager/peek_1.jpg',
        tags: [1, 0, 0, 1, 1, 0, 1, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 1, 0, 0, 1, 1, 0, 0, 1],
        timeline: [
          {
            // title: 'fixed\nstations',
            title: 'modular VR\nstations',
            text: ``,
            image: '/images/works/voyager/vrena.jpg',
          },
          // {
          //   title: 'mobile\nstations',
          //   text: ``,
          //   image: '/images/works/voyager/vrolley.jpg',
          // },
          {
            title: 'session\nmanagement',
            text: ``,
            image: '/images/works/voyager/status.jpg',
          },
        ],
        mosaicResult: [
          '/images/works/voyager/vrena_1.jpg',
          '/images/works/voyager/vrena_3.jpg',
          '/images/works/voyager/vrena_2.jpg',
          '/images/works/voyager/intro.jpg',
        ],
        mosaicProcess: [
          '/images/works/voyager/process_1.jpg',
          '/images/works/voyager/process_2.jpg',
          '/images/works/voyager/process_3.jpg',
          '/images/works/voyager/process_4.jpg',
          '/images/works/voyager/process_5.jpg',
          '/images/works/voyager/process_6.jpg',
          '/images/works/voyager/process_7.jpg',
          '/images/works/voyager/process_8.jpg',
        ],
        mosaicStores: [
          '/images/works/voyager/loja_1.jpg',
          '/images/works/voyager/loja_2.jpg',
          '/images/works/voyager/MatheusCarpenedo_01.jpg',
          '/images/works/voyager/MatheusCarpenedo_03.jpg',
        ],
        mosaicStoresCredits: ['', '', 'Matheus Carpenedo', 'Matheus Carpenedo'],
        mosaicVrolleyProcess: [
          '/images/works/voyager/vrolley_process_1.jpg',
          '/images/works/voyager/vrolley_process_2.jpg',
          '/images/works/voyager/vrolley_process_3.jpg',
          '/images/works/voyager/vrolley_process_4.jpg',
        ],
        mosaicVrolley: [
          '/images/works/voyager/vrolley_2.jpg',
          '/images/works/voyager/vrolley_4.jpg',
          '/images/works/voyager/vrolley_3.jpg',
          '/images/works/voyager/vrolley_1.jpg',
        ],
        mosaicStatus: [
          '/images/works/voyager/device_1.jpg',
          '/images/works/voyager/device_2.jpg',
          '/images/works/voyager/device_3.jpg',
          '/images/works/voyager/device_4.jpg',
        ],
        mosaicStatusApp: [
          '/images/works/voyager/status_app_1.png',
          '/images/works/voyager/status_app_2.png',
          '/images/works/voyager/status_app_3.png',
          '/images/works/voyager/status_app_4.png',
        ],
        images: [
          '/images/works/voyager/intro.jpg',
          '/images/works/voyager/vrena_intro.jpg',
          '/images/works/voyager/vrena_main_1.jpg',
          '/images/works/voyager/vrena_main_2.jpg',
          '/images/works/voyager/vrena_safety.jpg',
          '/images/works/voyager/vrena_materials.jpg',
          '/images/works/voyager/vrena_aesthetics.jpg',
          '/images/works/voyager/vrena_process.jpg',
          '/images/works/voyager/vrena_speed.jpg',
          '/images/works/voyager/vrolley.jpg',
          '/images/works/voyager/status.jpg',
          '/images/works/voyager/vrolley_problem.jpg',
          '/images/works/voyager/vrena_tech.jpg',
          '/images/works/voyager/ManualDoUsuario_01.jpg',
          '/images/works/voyager/status_device.jpg',
          '/images/works/voyager/status_app.jpg',
          '/images/works/voyager/vrena_x.jpg',
          '/images/works/voyager/mk2.jpg',
        ],
        texts: [
          `VR has always been an universe explored mostly in universities and hi-end research centers. But, since the mid 2010s, with popularization of smartphones and subsequently its components, VR has been in a strong revival and having greater popularity amongst end-consumers.

Although it's getting cheaper to have a headset and there is plenty of content available, it's still very expensive, especially in Brazil. This is the main motivation for the creation of VOYAGER, a LBE (location-based entertainment) that existed in Brazil from 2018 until 2020. 
          
At its peak, VOYAGER had 4 stores in separate parts of Brazil. I had the opportunity to help envision, develop and implement solutions that made it come true.`,
          `To ease the deployment of new LBE stores and cut costs, I've led the design and development of modular self contained VR stations that have everything needed for the operation:

» technical functionality;
» physical safety;
» visual appeal;
» flexibility of use.`,
          `Always the priority, especially in public venues where there is big diversity and peaks of utilization.

The overall size was defined to be bigger than the average lateral span of an adult: 2,25m. Of course, there was also a virtual “chaperone” to warn more active users if they were approaching the boundaries.

The floor is made of a damp anti-skid material and there are no dangerous points or corners, as they were covered with rubber edge tape.`,
          `The intention was to create this “spaceship sci-fi” mood, as VOYAGER's name implies.  That creates attractiveness from outside, something very different from any other neighbor store.

The modules are equipped with animated RGB LEDs that could be easily programmed with different motion and color behaviors, allowing great flexibility.

The overall environmental experience is unique, not achievable at home. People love taking selfies and recording others while playing.`,
          `Taking modularity as a prerequisite since the beginning as VR and the business were evolving fast, I have found modular aluminum profiles a good approach.
          
We started prototyping for structural stability, ease of assembly, user ergonomy and technological restraints.

Also, by creating the VRENA standard, we could have an unit at the ARVORE LAB to develop experiences and devices that would be automatically compatible with the ones at VOYAGER.`,
          `VR tech evolves very fast. The modules support the use of either VR Backpacks (as in images) or Desktop PCs. Each module can support two of them.

For using Desktop PCs, there are 19" rack mounts in the lateral doors. The middle door holds the LED controllers and power supplies that also serve the Vive Lighthouses up in the columns.

There is also a small hidden superior tray that stores batteries and USB and chargers. All the cables for the above cited are embedded, improving visuals, durability and safety.`,
          `Lights and blacks.

The smooth lighting streaks were achieved by using indirect refraction, making advantage of the internal dispersion through acrylic beams. They were covered in black vinyl to hide the LED spots underneath.

The ground is made out of recycled rubber from tires, making it anti-skid, slightly soft to touch, but very sturdy and stable. It also hugely improves the acoustics of the environment.

Other surfaces were made out of plain and perforated wood panels.`,
          `The project was officially kickstarted in early October 2018. In January 2019 the store was fully operational.

During this period, I've led the development by dealing with architects, site managers, and all providers that made VRENA happen: electricians, engineers, aluminum profile specialists and wood workers.

There were over 30 people directly and indirectly involved, based in three different states of Brazil.`,
          `The first 8 units were assembled in a brand new store in a couple of weeks, cutting costs from the previous mK2 imported solution alone up to three times. It also improved aesthetics, space usage and allowed for a new type of VR escape-room model to be explored.

In mid-2019 the second iteration was implemented in a bigger store in another state of Brazil, with 15 units in individual and room-scale conversible setups. The electric harnesses and illumination were improved, along with new custom structural components.`,
          `It started as a simple trolley for moving tools and components boxes inside the office. It was inspired by airplane trolleys, which are light, compact and specialized, but at the same time modular and able to fit in several places, also serving as a working bench.

In the beginning of ARVORE there was constant demand for showing VR demos to investors. Many of these sessions were in external offices and event venues, so we quickly realized that we needed a solution to bring the equipment all around.`,
          `As already seen on VRENA, VR gear can be bulky, complex and it changes very fast. That gets more extreme when in a mobile context of use. This is why VRENA was conceived as a plug-n-play solution, where all these features are embedded:

» gaming-grade PC (19" rack)
» adjustable forced ventilation;
» monitor and keyboard;
» standalone WLAN;
» wireless HTC Vive Pro;
» experience props (Beleaf torch);
» I/O: USB 3.0, hdmi, RJ45 (WAN), power inlet, fuse;
» universal power outlets (19" rack);
» one rack slot for expansion.`,
          `With Oculus Quest and other competitors offering untethered VR devices, one may wonder: what is the use for a mobile VR station?

The answer is: performance. These devices simply cannot render high-end graphics and still rely on more powerful PCs to achieve that. Even more, they now are supporting streaming over wireless, which makes the VROLLEY approach totally complimentary as it offers a local wireless network.

Apart from roadshows, VROLLEY has been used on VOYAGER to support experiences that needed mobility inside the venue.`,
          `The VOYAGER monetization was based on time, so users ended up competing for games and experiences while the clock was running.

By conducting research we understood that it was a major friction on value perception, as it created a “losing money every second” sensation.

STATUS is a session management solution and means that users pay only for “net time” having fun. It is composed of a device, a tablet app and a VR app (optional).`,
          `By wearing RFID wristbands, users are able to check into VR stations using their STATUS devices. The station becomes locked and dedicated until they log out or check into another station.

The device is built by using an ESP32 as a platform. There are two modes of usage:

1. Entrance/exit (counter): associated with an android tablet (bluetooth), for registering and validating the wristbands;

2. Inside (stations): with the PCs (USB cable), starting each game/experience attributed to that station.`,
          `Two android tablets work in tandem to serve the operation: one in the entrance and another inside the store.

1. Entrance/exit (counter): attendants can manage queue, log users on waitlists, select modalities to register and unregister user bracelets (by using the device);

2. Inside (stations): staff can manage station availability and follow the time left for each person.`,
          `For its first VOYAGER store, mk2 VR pods have been imported from France. It didn't take too long to realize the issues:

» expensiveness: intensified by shipping + importing costs;
          
» odd footprint: waste of real estate and lack of flexibility;
          
» bad ergonomics: people constantly hitting the shelf;
          
» cheap furniture aspect: dull and unappealing;
          
» struggles with spare parts.`,
          ``,
        ],        
        credits:
`STUDIO
ARVORE Immersive Experiences

UX & DESIGN DIRECTION
Andrei Speridião

INDUSTRIAL DESIGN (VRENA)
Andrei Speridião

ENGINEERING & LIGHTING HARDWARE DEVELOPMENT (VRENA)
BS Motion (Gabriel Sffair, Pedro Boessio, Alexandre Stedile, Diego Machado & team)

MANUFACTURING (VRENA)
M2 Móveis (Marcio Shoji, Márcio Melo & team), Forseti Soluções (Renan Orlatei, Ivan Teixeira & team)

PROJECT MANAGEMENT (VRENA)
Andrei Speridião, Mauricio Rocha, Emerson Morais, BS Motion (Kento Kojima, Caroline Guerreiro & team)

UI DESIGN (STATUS prototype)
Andrei Speridião

HARDWARE DEVELOPMENT (STATUS prototype)
Julio Silva, Andrei Speridião

SOFTWARE DEVELOPMENT (STATUS prototype)
Evandro Romeiro, Michel Hanoch, André Elimenek, André V., Andrei Speridião

PROJECT MANAGEMENT (STATUS prototype)
Andrei Speridião`,
        hovered: false,
      },
      // connected drains
      {
        type: 'work',
        caption: 'connected drains',
        subcaption: 'IoT on the city-scale to avoid floods',
        info: '2013, IoT + service',
        year: '2013',
        company: 'FAU-USP',
        related: 'science box',
        role: 'final undergraduate project',
        extras: `. research\n. UX design\n. UI design\n. hardware development`,
        url: '/works/connecteddrains',
        foreground: '#e9e9e9',
        background: '#151515',
        model: '/models/works/connecteddrains.glb',
        subtitle: 'floods were avoided in cities by using emerging technologies',
        stripImage: '/images/works/connecteddrains/connecteddrains_strip.jpg',
        coverImage: '/images/works/connecteddrains/connecteddrains_cover.jpg',
        thumbImage: '/images/works/connecteddrains/connecteddrains_thumb.jpg',
        peekImage: '/images/works/connecteddrains/peek_1.jpg',
        tags: [0, 0, 0, 1, 1, 0, 1, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 0, 1, 1],
        mosaicImages: [
          '/images/works/connecteddrains/flood_andre_penner_ap.jpg',
          '/images/works/connecteddrains/flood_amanda_perobelli_reuters.jpg',
          '/images/works/connecteddrains/flood_felipe_rau_estadao_conteudo.jpg',
          '/images/works/connecteddrains/flood_reproducao_rede_globo.jpg',
        ],
        mosaicImagesCredits: ['Andre Penner, AP', 'Amanda Perobelli, Reuters', 'Felipe Rau, Estadão Conteúdo', 'Reprodução, Rede Globo'],
        mosaicApp: [
          '/images/works/connecteddrains/app_1.png',
          '/images/works/connecteddrains/app_2.png',
          '/images/works/connecteddrains/app_3.png',
          '/images/works/connecteddrains/app_4.png',
        ],
        mosaicObjectProcess: [
          '/images/works/connecteddrains/prototype_1.jpg',
          '/images/works/connecteddrains/prototype_2.jpg',
          '/images/works/connecteddrains/prototype_3.jpg',
          '/images/works/connecteddrains/prototype_4.jpg',
          '/images/works/connecteddrains/prototype_5.jpg',
          '/images/works/connecteddrains/prototype_6.jpg',
          '/images/works/connecteddrains/prototype_7.jpg',
          '/images/works/connecteddrains/prototype_8.jpg',
        ],
        mosaicObject: [
          '/images/works/connecteddrains/produto_apagado.jpg',
          '/images/works/connecteddrains/connecteddrains_3.jpg',
          '/images/works/connecteddrains/qr_code.jpg',
          '/images/works/connecteddrains/chao.jpg',
        ],
        mosaicPrizes: [
          '/images/works/connecteddrains/prize_1.jpg',
          '/images/works/connecteddrains/prize_2.jpg',
          '/images/works/connecteddrains/exhibit_1.jpg',
          '/images/works/connecteddrains/exhibit_2.jpg',
        ],
        mosaicMedia: [
          '/images/works/connecteddrains/media_startupi.jpg',
          '/images/works/connecteddrains/media_uoltab.jpg',
          '/images/works/connecteddrains/media_exame.jpg',
          '/images/works/connecteddrains/media_select.jpg',
        ],
        images: [
          '/images/works/connecteddrains/intro.jpg',
          '/images/works/connecteddrains/prob_1.jpg',
          '/images/works/connecteddrains/prob_2.jpg',
          '/images/works/connecteddrains/ecosystem.png',
          '/images/works/connecteddrains/xray.png',
          '/images/works/connecteddrains/connecteddrains_3.jpg',
          '/images/works/connecteddrains/webapp.png',
          '/images/works/connecteddrains/identity.png',
          '/images/works/connecteddrains/prize.jpg',
          '/images/works/connecteddrains/exhibit.jpg',
          '/images/works/connecteddrains/media_info.jpg',
          '/images/works/connecteddrains/prob_3.jpg',
          '/images/works/connecteddrains/scheme.png',
          '/images/works/connecteddrains/reflection.jpg',
          '/images/works/connecteddrains/connecteddrains_2.jpg',
          '/images/works/connecteddrains/connecteddrains_square.jpg',
        ],
        timeline: [
          {
            title: 'floods',
            text: ``,
            image: '/images/works/connecteddrains/prob_1.jpg',
          },
          {
            title: 'accidents',
            text: ``,
            image: '/images/works/connecteddrains/prob_2.jpg',
          },
        ],
        texts: [
          `To conclude my 5 year formal design studies in 2013, I've decided to research the relations between design, emerging technologies and communication in 6 months of theoretical work.

In the following 6 months, I crossed these learnings with urban problems. As a result, I have conceived a solution that aims at flood prevention.
          
This project was important for me as a way to crystalize my design learning experience. It also gave me awards and media exposure.`,
          `As a passage for water, drains capture lots of residues that eventually get stuck. If enough accumulate, there may be blocks preventing the water from flowing through and subsequently overflows may occur.

Of course this is not the single reason for floods: bad city planning and ground impermeabilization are the underlying reasons, but preventing misuse of already implemented infrastructure is prioritary.`,
          `Drain covers in São Paulo, Brazil, are usually made of concrete and embedded into the sidewalk and curb. It serves as a pedestrian passage.

Is not uncommon to see those covers cracked, loose or even absent. I talked to a person who had a brother severely injured after falling into a drain pit, causing life-long issues.`,
          `After researching and learning a lot about the Internet of Things, I was amazed by the breakthrough potential. But, the common question was: do we really need Twitter access on refrigerators?

I was looking for a real challenge when I started observing the university campus refurbishment works. I've noticed that they were replacing the curbs and drain covers.`,
          `The drain is the first interaction between pluvial water and the draining system. In a city, there are thousands of drains like that, forming a water network.

Something as straightforward as it seems, ends up strongly affecting mobility, health and safety for all citizens. That was it, I needed to join these “distant” technologies.\n`,
          `The vision comprehended two objects and two actors:

» object to capture physical drain data;
» app to expose data publicly with reporting instruments;

» citizens to pressure government;
» government to take actions.

The idea was to propose the system as a coherent vision, but the App itself would be gradually transitioned into a data API to be incorporated by a bigger citizenship platform.`,
          `Below the concrete cover on each drain, there is a pit that is connected by pipes to other equivalent units, eventually leading to an underground gallery that flows to a river or channel.

So, by putting the device to monitor this first stage, residues can be detected before they accumulate or go further into the drainage system.
          
I've proposed embedding the device into the concrete cover. That meant that it would be very hard for it to be stealed, and at the same time sensoring and power feeding could be easier.`,
          `» drain capacity: ultrasound sensor checks for obstruction;
» cover integrity: embedded wire gets interrupted if the cover is broken;
» maintenance: accelerometer indicates cover remotion, (also serves the integrity measurement)

The above solutions were validated in the proof-of-concept, but these below were only propositions:
» communication: GPRS, but I've also studied other options such as Lora-wan to minimize power consumption;
» power: solar panel + battery.`,
          `The physical device had an unique QR code that pointed to a webApp, directly to that specific drain's page.

I chose that lighter and casual approach to avoid the hurdle of downloading an App, meaning that anyone with a simple smartphone could participate.

The main user flow included:
» check for drains geographically
» report problems to authorities
» add specific drains to a list to follow up.

The navigable prototype was made with Axure.`,
          `The Design education I had was very abrangent, meaning that I had both graphic/industrial design classes with projects throughout all the 5 years.

So, from the beginning I knew the importance of creating a visual identity for the project. It helped create unity in a system of multiple components.

Following the smiley-drain logo, all icons are made with simple lines. All the colors are used to represent data: red, yellow and green. Just like traffic lights, universal.

The chosen font was the Open Sans.`,
          `» Gold (Student) - Idea Brasil (IDSA) 2014

» Highlight (Student) - Idea Brasil (IDSA) 2014

» Finalist at Desafio CISCO at Porto Maravilha, Rio de Janeiro, RJ - 2015

» V Mostra Jovens Designers - 2015

» FAUFORMA - 2016

» Mostra Acierno Design Week - 2016`,
          `» Revista Info: Conheça o projeto que planeja conectar os bueiros da cidade
                    
» Startupi: Tecnologia em prol da cidadania: conheça o projeto high-tec premiado que quer acabar com as enchentes
`,
          `» UOL Tab: Design na Cidade

» Revista Exame: Conheça o projeto que planeja conectar os bueiros da cidade

» Revista Select: Da cidade interativa à cidade participativa`,
          `A service, product and digital application project that connects the town drains with citizens, creating a mobilization and decision taking environment around preventive and corrective actions for the social well-being.`,
          `Looking back, this was a very important milestone for my career because I learned:

» seeing problems and solutions as parts of bigger systems (also containing smaller ones too);

» how to meet, present to and articulate with diverse stakeholders;

» that is essential to be hands-on to make crazy ideas born;

» the huge step between envisioning and delivering an MVP;

» new tech launches every day, but legacy tech problems persist.

And most important: how valuable are speculative prototypes to set the tone for productive discussions.`,
          `Bueiros conectados. 2013.

Final graduation project, Design.

Faculdade de Arquitetura e Urbanismo

Universidade de São Paulo.`,
          `Advisor: Profa. Dra. Giselle Beiguelman.

Examining board:
- Profa. Dra. Giselle Beiguelman;
- Profa. Dra. Daniela Kutschat Hanns;
- Prof. Dr. Ricardo Nakamura;
- Prof. Bruno Canato.`,
          ``,
        ],
        hovered: false,
      },
      // science box
      {
        type: 'work',
        caption: 'science box',
        subcaption: 'IoT smart science-kit',
        info: '2014, IoT + marketing',
        year: '2014',
        company: 'FLAGCX (Google)',
        related: 'play tags',
        role: 'business & creative direction\n(detailed credits at the bottom)',
        extras: `. creative direction\n. UX & design direction\n. sourcing`,
        url: '/works/sciencebox',
        foreground: '#e9e9e9',
        background: '#2e0002',
        model: '/models/works/sciencebox.glb',
        subtitle: 'a science kit with its own will opened itself inviting us to experiment',
        stripImage: '/images/works/sciencebox/sciencebox_strip.jpg',
        coverImage: '/images/works/sciencebox/sciencebox_cover.jpg',
        thumbImage: '/images/works/sciencebox/sciencebox_thumb.jpg',
        peekImage: '/images/works/sciencebox/peek_1.jpg',
        tags: [0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1, 0, 1],
        timeline: [
          {
            title: 'experiments',
            text: `. diverse, fun and easy to do\n. fits inside the box`,
            image: '/images/works/sciencebox/experiments.jpg',
          },
          {
            title: 'science box',
            text: `. custom-made\n. fully wireless (power & data)\n. lets users watch videos`,
            image: '/images/works/sciencebox/box.jpg',
          },
          {
            title: 'cloud service',
            text: `. checks for uploaded videos\n. redirects to the current video\n. makes boxes open on time`,
            image: '/images/works/sciencebox/cloud.jpg',
          },
        ],
        mosaicImages: [
          '/images/works/sciencebox/mosaic_1.jpg',
          '/images/works/sciencebox/mosaic_2.jpg',
          '/images/works/sciencebox/mosaic_3.jpg',
          '/images/works/sciencebox/mosaic_4.jpg',
          '/images/works/sciencebox/mosaic_5.jpg',
          '/images/works/sciencebox/mosaic_6.jpg',
          '/images/works/sciencebox/mosaic_7.jpg',
          '/images/works/sciencebox/mosaic_8.jpg',
        ],
        mosaicImagesCredits: ['', '', 'Wesley Lee', 'Wesley Lee', 'Wesley Lee', 'Wesley Lee', '', 'Wesley Lee'],
        mosaicExperiments: [
          '/images/works/sciencebox/sciencebox_1.jpg',
          '/images/works/sciencebox/sciencebox_2.jpg',
          '/images/works/sciencebox/sciencebox_2.jpg',
          '/images/works/sciencebox/sciencebox_2.jpg',
        ],
        images: [
          '/images/works/sciencebox/box.jpg',
          '/images/works/sciencebox/personality.jpg',
          '/images/works/sciencebox/connected.jpg',
          '/images/works/sciencebox/safety.jpg',
          '/images/works/sciencebox/users.jpg',
          '/images/works/sciencebox/makingof.jpg',
          '/images/works/sciencebox/agency.jpg',
          '/images/works/sciencebox/electronics.jpg',
          '/images/works/sciencebox/woodwork.jpg',
        ],
        texts: [
          `I've led the creation and development of an interactive science kit together with Google and Manual do Mundo.

It was part of a huge YouTube campaign in Brazil to make advertising leaders aware of its powerful segmented public performance. 

As of July 2021, Manual do Mundo has almost 16 million subscribers, positioned between the thirty biggest YouTube channels in Brazil. 

It is focused on making science, experiments and inventions fun and accessible to everyone.`,
          `A box that opens only by itself seems weird because it goes against its primary purpose: “store what I want, while I want”. 

One could say that the box is malfunctional, but at the same time it creates expectations and buzz around, the exact reaction we wanted to provoke. 
          
This idea came in a routine brainstorm with the team, even before the briefing. Later, we pitched the idea to Google's marketing team and they loved it.`,
          `1. open the box whenever fresh videos are uploaded.

2. have everything needed for the experiment.

3. repeat four times, each with a different challenge.`,
          `That good-old-goo that went viral not so long ago.`,
          `An oversaturated solution of sodium acetate that creates “fake ice” when disturbed.`,
          `A syringe with coiled wire and a moving magnet that lights up LEDs when shaken.`,
          `Using a cylinder, reflective film and warped image to form an undistorted reflection.`,
          `All instructions for both the operation and experiments can be seen by using a smartphone.

The first touchpoint is an external tag that greets the user giving a brief introduction.

The other is a call to action inside the box cover, that allows the user to access the current experiment video to be made.

Both can be viewed by using NFC, QR code or a simple URL.`,
          `To offer safety and at the same time “role playing” props, we also provided personal protection equipment inside:
» gloves;
» apron customized with branding;
» safety glass.

All liquids and small parts were stored in Schott-Duran chemical-grade bottles with branded informative labels.`,
          `The end-users were digital influencers and also creative leaders in the biggest advertising companies in Brazil.

18 boxes were delivered all around and opened at the right time, inviting decision makers to experiment with science and feel that each Youtube channel was a world by itself to be explored as new ways to talk to consumers.

Few years later, media investment in online media has already surpassed offline and YouTube channels are a growing market.`,
          `Working with hardware is not easy. But doing it in Brazil inside a digital ad-agency is a next-level challenge.

All the electronics were designed, built and assembled in-house by the team. And then came the logistics, something the company wasn't prepared at all to deal with.

The result? I made a day trip to NYC to pick components, because they were blocked in Brazil customs (a classic). In the end, everything went alright!`,
          `For its specific nature, the whole project was custom built from scratch. The opening was enabled by using a strong solenoid lock, but that demanded a specific power supply for its peak current, fed by lithium cells.

We used an Arduino chip to control everything and the communication happened via GPRS, meaning the end-users would not need to worry about wifi configuration.

Just in case: we put a hall sensor to detect if the box cover opened when demanded. If not, the solenoid would flick until it did.`,
          `The boxes were done by true craftsmen from Madeira Design. Both upper and lower parts were cut out of a hollow box, so the wood veins on the sides would be continuous. Instead of simple paint, the red varnish built over the grain.

The dovetail fittings were made with a CNC machine. The YouTube logo was also milled and later filled with white resin. The internal separators were made with PVC, and some bays had soft black wood chips to accommodate glasses.

A small slit below the logo reveals the “breathing” light.`,
          ``,
          ``,
          ``,
          ``,
        ],
        credits:
`CLIENT
Google Brasil (Daniel Xavier, Marcos Valeta, Thiago Avancini & team)

STUDIO
Iceland 2nd Nation (FLAGCX)

ORIGINAL IDEA
Andrei Speridião, Vitaliano Palmieri, Wesley Lee

UX & DESIGN DIRECTION
Andrei Speridião

PROJECT MANAGEMENT
Francisca Limberger

INDUSTRIAL DESIGN
Andrei Speridião

GRAPHIC DESIGN
Wesley Lee

SOFTWARE DEVELOPMENT
Vitaliano Palmieri, Wesley Lee, Bruno Martin

HARDWARE DEVELOPMENT
Wesley Lee, Rafael Guayer

WOODWORKING
Madeira Design (Antonio Vespoli & team)

VIDEO
The Kumite`,
        hovered: false,
      },
      // play tags
      {
        type: 'work',
        caption: 'play tags',
        subcaption: 'smart-tag interactive ad',
        info: '2014, IoT + marketing',
        year: '2014',
        company: 'FLAGCX (Google)',
        related: 'early works',
        role: 'business & creative direction\n(detailed credits at the bottom)',
        extras: `. creative direction\n. UX & design direction\n. sourcing`,
        url: '/works/playtags',
        foreground: '#ffb401',
        background: '#000722',
        model: '/models/works/playtags.glb',
        subtitle: 'we picked physical pixels from a video to watch it later',
        stripImage: '/images/works/playtags/playtags_strip.jpg',
        coverImage: '/images/works/playtags/playtags_cover.jpg',
        thumbImage: '/images/works/playtags/playtags_thumb.jpg',
        peekImage: '/images/works/playtags/peek_1.jpg',
        tags: [0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0],
        mosaicPixels: [
          '/images/works/playtags/pixels_1.jpg',
          '/images/works/playtags/pixels_2.jpg',
          '/images/works/playtags/pixels_3.jpg',
          '/images/works/playtags/pixels_4.jpg',
        ],
        mosaicPixelsCredits: ['Wesley Lee', '', 'Wesley Lee', 'Wesley Lee'],
        mosaicProcess: [
          '/images/works/playtags/process_1.jpg',
          '/images/works/playtags/process_2.jpg',
          '/images/works/playtags/process_3.jpg',
          '/images/works/playtags/process_4.jpg',
        ],
        mosaicProcessCredits: ['Wesley Lee', 'Wesley Lee', 'Wesley Lee', 'Wesley Lee'],
        images: [
          '/images/works/playtags/playtags_1.jpg',
          '/images/works/playtags/playtags_2.jpg',
          '/images/works/playtags/esquema.png',
          '/images/works/playtags/pixel.jpg',
          '/images/works/playtags/pick.jpg',
          '/images/works/playtags/megapixels.jpg',
          '/images/works/playtags/mural_borrou_1.png',
          '/images/works/playtags/mural_borrou_2.png',
          '/images/works/playtags/mural_fabio_1.png',
          '/images/works/playtags/mural_fabio_2.png',
          '/images/works/playtags/mural_indio_1.png',
          '/images/works/playtags/mural_indio_2.png',
          '/images/works/playtags/mural_looks_1.png',
          '/images/works/playtags/mural_looks_2.png',
          '/images/works/playtags/mural_manual_1.png',
          '/images/works/playtags/mural_manual_2.png',
          '/images/works/playtags/nfc.jpg',
          '/images/works/playtags/makingof.jpg',
          '/images/works/playtags/pixel_full.jpg',
        ],
        imagesCaptions: ['some very long caption is what you expect from this image', 'all work and no play: makes jack a kinda dull boy'],
        texts: [
          `Simultaneously with Science Box, I've also led the creation and production of an interactive physical ad with Google as a part of a campaign in Brazil to make advertising leaders aware of Youtube's powerful segmented public performance. 

This part of the campaign wasn't focused on a specific channel, but encompassed three big YouTube channels: Porta dos Fundos (comedy), Manual do Mundo (science) and Camila Coelho (make-up). As of July 2021, they have 17, 16 and 3 million subscribers respectively.`,
          `The embryo for this idea (smart tags that could be picked) came from the team in a free internal brainstorm, then was later evolved when we were asked for cool ideas in a briefing from the Youtube marketing team in Brazil.

As Science Box was already very demanding, we partnered with Estudio Margem, a Brazilian design studio. They brought amazing creative ideas, were responsible for the graphic design and also produced the final printed material.`,
          `One of their propositions was to make a wall of tags that represented pixels, and when picked revealed a higher resolution image behind, in a loading effect. We loved it, and so did Google!

Even though the tags were made of paper, we wanted to create something durable that people would like to keep. We used premium thick matte laminated paper with the YouTube logo in relief varnish.
          
Every pixel has a unique ID corresponding to their position in the image.`,
          `Inside each pixel there is a NFC tag, but also QR code and a plain URL in the back. The Google team had the great idea of implementing analytics and as a result we learned that 70% of conversions happened via NFC.

When interacting, the user would watch the most recent video from the respective Youtube channel. A Google App engine service handled that redirection.`,
          `5 panels were made, each one with 1400 tags that only lasted for a few hours, as they were put in the busiest metro station in São Paulo, Paulista.`,
          `The experience was made possible by building over an already traditional way of advertising and leveraging it with digital capabilities.

The NFC tags were bought with separate shortened links for each channel. The same shortening technique was applied to the QR code and the simple URL.

A cloud system using Google App Engine was then responsible for redirecting each link to the most recent video of the respective channel, at the same time capturing analytics.`,
        ],
        credits:
`CLIENT
Google Brasil (Daniel Xavier, Marcos Valeta, Thiago Avancini & team)

STUDIO
Iceland 2nd Nation (FLAGCX)

ORIGINAL IDEA
Andrei Speridião, Vitaliano Palmieri, Wesley Lee

UX & DESIGN DIRECTION
Andrei Speridião

PROJECT MANAGEMENT
Francisca Limberger

GRAPHIC DESIGN
Estúdio Margem (Nathalia Cury, Alexandre Lindemberg, Luana Graciano), Wesley Lee

PRINT PRODUCTION
Signorini Produção Gráfica (Márcia Signorini & team)

SOFTWARE DEVELOPMENT
Vitaliano Palmieri, Bruno Martin

VIDEO
The Kumite`,
        hovered: false,
      },
      // prototypes BESIDES
      {
        caption: 'prototypes',
        subcaption: 'interaction sketchbook',
        url: '/prototypes',
        
        coverImage: '/images/prototypes/prototypes_cover.jpg', //
        stripImage: '/images/prototypes/decorato_process_6.jpg', //
        thumbImage: '/images/prototypes/prototypes_thumb.jpg', //
        type: 'besides', //
        
        background: '#001516',
        foreground: '#d1d1d1',
        model: '/models/prototypes.glb',


        images: ['/images/prototypes/prototypes_1.jpg', '/images/prototypes/prototypes_2.jpg'],
        //lebraile
        mosaicLebraile: [
          '/images/prototypes/lebraile_geral.jpg',
          '/images/prototypes/lebraile_cad.jpg',
          '/images/prototypes/lebraile_inside.jpg',
          '/images/prototypes/lebraile_detail.jpg',
        ],
        imagesLebraile: ['/images/prototypes/lebraile_intro.jpg', '/images/prototypes/lebraile_close.jpg'],
        textsLebraile: [
          `Even though I was a mentor at Red Bull Basement, I have decided to go hands-on in this project for the value of the problem tacked together with the vision of it's creators.

Lebraile is intended to be a low cost handheld wireless braile reader and came from the minds of Cristiano Moraes and Paulo Porto.

When they arrived at the Residence in 2018, they had already iterated on several previous versions of the device, so I contributed on technological problem solving and industrial design.`,
          `We brainstormed together an idea that substituted the current magnetical pistons (not enough torque) with small but strong linear RC helicopter servos.

I have then designed a device that uses solid filaments attached to six servos, passing through guides to form braile characters on the finger tips uppon activation.

There was also place for a chargeable lithium battery cell and a board with an ESP8266 that listened to a web platform.`,
        ],

        //coiso
        mosaicCoiso: [
          '/images/prototypes/coiso_process_1.jpg',
          '/images/prototypes/coiso_process_2.jpg',
          '/images/prototypes/coiso_process_3.jpg',
          '/images/prototypes/coiso_process_4.jpg',
        ],
        imagesCoiso: [
          '/images/prototypes/coiso_intro.jpg',
          '/images/prototypes/coiso_before.jpg',
          '/images/prototypes/coiso_after.jpg',
          '/images/prototypes/coiso_exploded.jpg',
        ],
        textsCoiso: [
          `I helped my wife on her final work at the Design Graduation by making the hardware component of COISO, a tool for children and teachers to create and live stories together.
  
Her investigation raised questions on the limits between real and imaginary on children education, so her core idea was to propose a character to be made by little kids to be their friend.`,
          `By using a physical-digital interactive core, any box could serve as a body for imaginary creatures. Depending on children interactions, the it would respond differently.

To add a magical layer, we added capacitive (touch) and magnetic sensors (for attachments such as a tail), an accelerometer (to check the body orientation), RGB LEDs (for eyes and body) and a sound speaker.

The final enclosure was made with lasercut acrylic and several 3D printed parts.`,
        ],

        //decorato
        mosaicDecorato: [
          '/images/prototypes/decorato_process_1.jpg',
          '/images/prototypes/decorato_process_2.jpg',
          '/images/prototypes/decorato_process_3.jpg',
          '/images/prototypes/decorato_process_4.jpg',
          '/images/prototypes/decorato_process_5.jpg',
          '/images/prototypes/decorato_process_6.jpg',
          '/images/prototypes/decorato_process_7.jpg',
          '/images/prototypes/decorato_process_8.jpg',
        ],
        imagesDecorato: ['/images/prototypes/decorato_intro.jpg', '/images/prototypes/decorato_production.jpg'],
        textsDecorato: [
          `Mueller is a Brazilian home appliance industry that asked QUESTTO|NO to redesign its electrical oven product line.

Decorato Gourmet was a new model, targeted at a higher-end market segment than they were used to sell for.

I prototyped a solution that used a dial to command with auxiliary buttons useful for setting mdoes such as “keep heated“, instead of very specific segregated functions.`,
          `Apart from other contributions, I was responsible for the ideation of several features through this early stage interactable prototype.

The project continued after I left the company for further product development and its now selling on the market.`,
        ],

        //tem gente
        mosaicTemgente: [
          '/images/prototypes/temgente_process_1.jpg',
          '/images/prototypes/temgente_process_2.jpg',
          '/images/prototypes/temgente_process_3.jpg',
          '/images/prototypes/temgente_process_4.jpg',
        ],
        imagesTemgente: [
          '/images/prototypes/temgente.jpg',
          '/images/prototypes/temgente_light.jpg',
          '/images/prototypes/temgente_graph.jpg',
          '/images/prototypes/temgente_app.jpg',
          '/images/prototypes/temgente_dark.jpg',
          '/images/prototypes/temgente_prototypes.jpg',
        ],
        textsTemgente: [
          `At the time I worked at Questto|Nó, the team grew and the office wasn't keeping up with it anymore.

I mean, especially the toilets as there were only 3 for more than 30 people. Usage peaks made it very upsetting to walk to a bathroom just to find it occupied.

Beyond that, there was another consequence of people using the restrooms heavily: the smell. Restrooms had exhaustors, but they were combined with the light switch, so whenever people leaved, the fans halted and the odor persisted.`,
          `It is responsible for reading presence and the smell "levels" and controlling the exhaustor with a relay.

I've used the Electric Imp platform to ease the deploy as an IoT device natively connected to the cloud.

There were two basic development iterations: on the first I tried using a PIR sensor to detect presence, but there were interferences with the alarm system. So, as the bathrooms had no natural light, I chose to "tap" the light switch by using a non-invasive current detector to know whether there was anyone inside.

For the smell I've chosen an MQ4 methane sensor. It worked perfectly, we are biodigestors.`,
          `Data was promptly sent to freeboard.io, a platform that was fully compatible with Electric Imp at the time.

Even though all the physical actuation was made locally at the device, the cloud graphs helped me to fine tune the behaviors embedded. That included the bug fixings and threshold tweaks.`,
          `The idea was to people only move to unnocupied bathrooms, so I created a webApp that provided views for the three bathrooms in the office:

» whether any of these were busy or not;
» how the "smell situation" was, so people could choose to postpone their session;
» for fun, there was a quantification for smell amount based on the sensor readings.`,
          ``,
        ],

        //telebot

      },
      // material BESIDES
      {
        caption: 'material',
        subcaption: 'digital-to-analog experiments',
        url: '/material',
        
        coverImage: '/images/material/material_cover.jpg', //
        stripImage: '/images/material/polargraph_close.jpg', //
        thumbImage: '/images/material/material_thumb.jpg', //
        type: 'besides', //


        foreground: '#efc9ff',
        background: '#03000d',
        model: '/models/material.glb',

        mosaicImages: ['/images/material/mylab_1.jpg', '/images/material/mylab_2.jpg'],
        images: ['/images/material/mylab_1.jpg', '/images/material/mylab_2.jpg'],

        // polargraph
        mosaicPolargraph: [
          '/images/material/polargraph_parts.jpg',
          '/images/material/polargraph_electronics.jpg',
          '/images/material/polargraph_motor.jpg',
          '/images/material/polargraph_close.jpg',
        ],
        mosaicPolargraphTests: [
          '/images/material/polargraph_tests_1.jpg',
          '/images/material/polargraph_tests_2.jpg',
          '/images/material/polargraph_tests_3.jpg',
          '/images/material/polargraph_tests_4.jpg',
        ],
        imagesPolargraph: [
          '/images/material/polargraph_wide.jpg',
          '/images/material/polargraph_escort.jpg',
          '/images/material/polargraph_furniture.jpg',
        ],
        textsPolargraph: [
          `In 2016 I became very interested into drawing machines and got to know Polargraph, an open source project for a drawing plotter that sketched vertically, made by Sandy Noble.

Apart from drawing on standing position, the overall feeling of the machine was very ingenious as it used simply two motors with a hanging pen.

I built one for Questto|Nó that was used in many events and then I decided to do ony for myself.`,
          `From the original project, I created a version that acted as a hybrid furniture piece, an indirect lamp and the drawing machinery itself.

There is a paper roll on top and everything is supported by a plywood body, with a teak horizontal bar that holds the stepper motors in its endings.

Instead of using curtain beads and ballasts, Ive chosen very thin nylon strings (floating effect) that are self winded on a reel, together with strong stepper motors to hold the weight.`,
        ],

        // holi
        mosaicHoli: [
          '/images/material/polargraph_parts.jpg',
          '/images/material/polargraph_electronics.jpg',
          '/images/material/polargraph_motor.jpg',
          '/images/material/polargraph_close.jpg',
        ],
        imagesHoli: [
          '/images/material/holi_cover.jpg',
          '/images/material/holi_os.jpg',
          '/images/material/holi_accessible.jpg',
          '/images/material/holi_format.jpg',
          '/images/material/holi_workshop.jpg',
          '/images/material/holi_basement.jpg',
        ],
        textsHoli: [
          `I've known Tiago Moraes from Ovni Studios for a long time and he invited me to help him with an idea that demanded a physical artifact.

Based on his vision, we worked together to create a low-cost AR head-mounted display that uses a smartphone.`,
          `VR is expensive but AR is even more restrict.

Thinking of that, Ovni Studios asked for a device that could be very cheap, analog to Google Cardboard. They had already done an early prototype to test the overall reflection and geometry. 

So I proposed using as few materials as possible: lasercut tinted acrylic, transparent PVC sheet, velcro and elastic bands.

Of course, the digital part would be provided by the ubiquitous smartphones, that could easily fit into place.`,
          `After many iterations and structural tests, I was able to achieve a satisfactory volume by using a pattern that allowed the acrylic to bend safely.

So it was possible to pack all the needed parts into a single A4-sized sheet of acrylic. There were spare lens.

Apart from material economy, that format choice opened oportunities for standardized packaging to ease distribution.`,
          `To promote this first version of Holi Glass, I joined Tiago Moraes for a workshop at Red Bull Basement.

Together with people from very different backgrounds, we discussed extended reality, presented the project and together built Holi glasses for everyone present.`,
        ],

        // mri
        mosaicMri: [
          '/images/material/mri_capture.jpg',
          '/images/material/mri_wire.jpg',
          '/images/material/mri_print.jpg',
          '/images/material/mri_1.jpg',
        ],
        imagesMri: [
          '/images/material/mri.jpg',
          '/images/material/mri_2.jpg',
          '/images/material/mri_3.jpg',
          '/images/material/mri_4.jpg',
        ],
        textsMri: [
          `It started with an idea from Roberto Martini at FLAGCX: what if we could replicate skulls from real interesting people?

We already had a 3D printer operating, so we only needed a 3D file from a real head...`,
          `CT scans are stored in DICOM format, where several "tomes" (constantantly offsetted slices) with 2D raster images can form voxels (3D).

By using 3D Slicer, an open source image computing platform, we could build volumes according to the bones tissues density.

After mesh tesselation, cleanup and simplification it was 3D printed.`,
        ],
      },
      // lamps BESIDES
      {
        caption: 'lamps',
        subcaption: 'photon machines',
        url: '/lamps',
        
        coverImage: '/images/lamps/pyr_1.jpg', //
        stripImage: '/images/lamps/pyr_1.jpg', //
        thumbImage: '/images/lamps/lamps_thumb.jpg', //
        type: 'besides', //

        foreground: '#ffa200',
        background: '#180600',
        model: '/models/lamps.glb',      

        mosaicPyrImages: [
          '/images/lamps/pyr_process_3.jpg',
          '/images/lamps/pyr_process_1.jpg',
          '/images/lamps/pyr_process_2.jpg',
          '/images/lamps/pyr_process_4.jpg',
        ],
        mosaicClampImages: [
          '/images/lamps/clamp_3.jpg',
          '/images/lamps/clamp_4.jpg',
          '/images/lamps/clamp_5.jpg',
          '/images/lamps/clamp_6.jpg',
        ],
        mosaicIgnezImages: [
          '/images/lamps/ignez_process_1.jpg',
          '/images/lamps/ignez_process_2.jpg',
          '/images/lamps/ignez_process_3.jpg',
          '/images/lamps/ignez_process_4.jpg',
        ],
        images: [
          '/images/lamps/main.jpg',
          '/images/lamps/pyr.jpg',
          '/images/lamps/pyr_1.jpg',
          '/images/lamps/pyr_2.jpg',
          '/images/lamps/clamp.jpg',
          '/images/lamps/clamp_1.jpg',
          '/images/lamps/clamp_2.jpg',
          '/images/lamps/ignez_1.jpg',
          '/images/lamps/ignez_2.jpg',
          '/images/lamps/ignez_detail_1.jpg',
          '/images/lamps/ignez_detail_2.jpg',
          '/images/lamps/toninha.jpg',
          '/images/lamps/toninha_1.jpg',
          '/images/lamps/toninha_2.jpg',
          '/images/lamps/pyr_3.jpg',
        ],
        imagesCaptions: ['some very long caption is what you expect from this image', 'all work and no play: makes jack a kinda dull boy'],
        texts: [
          `Through time I've discovered that I'm very sensitive to lighting moods, so I started making objects to reshape the environment not only by their format but also their light emission. Some of them were made for myself and others were gifts for people I love.`,
          // toninha
          `Toninha was the name of a little dim lamp that was my company at my bedroom until I slept when I was a child.

My father "created" her to tell me stories about product development and life cycle. Pretty usual?

Anyways, almost 20 years after, I recreated her with an appended little body, as a gift made specially for my girlfriend Ana for her birthday. We are now married.

It is made of several pinewood plank cross sections piled and finished with fine manual sanding and a thin sealing varnish layer. The cord has a nice fabric weave. You can control the nice decorative lamp intensity by using the dimmer.`,
          // clamp
          `This is the second lamp I made, using teak wood, concrete and 3d printed parts. I messed up and fractured the concrete, but It turned out to be nicer that way, revealing its guts but keeping it solid.
          
A simple dimmer allows for fine tuning the light emission, something very interesting when using incandescent bulbs.

Everything is tinted with black pigmented and covered with shellac instead of simply being painted, to keep their texture and characteristics alive. The knob and plug are 3D printed in ABS and sanded/brushed giving it an interesting anisotropic aspect.`,
          // ignez
          `I got my gramma in Secret Santa in 2016, which was a very special opportunity for me to make her a present myself.

I created this lamp out of teak slices in a format that allowed for different ways for using: standing on every side or put on a wall.

When she passed away, my sister kept her light.`,
          //pyr
          `I began creating this lamp by using t5 fluorescent tubes, before there was a big availability of LED equivalents. That meant embedding a bulky ballast, hearing noise buzz and waiting for it to warm. 

The core idea was to create a volume out of light beams, so I created a pyramid out of wooden bars, kept together by 3d printed connections.

By adopting LEDs, I could remove the balast and make the silhouette cleaner. That happened several years after, in another house.`,
          `Even though the light temperature of these new tubes was decent, I was puzzled at the idea of making light pass through the same wood of the structure.

So I used thin pine-tree wood sheets conformed to a cylindrical shape. The otherwise pale light became filtered by passing through the veins and fills the surroundings with warmth.`,
        ],
      },
      // oasis
      {
        // type: 'work',
        caption: 'oasis',
        subcaption: 'IoT beer service',
        info: '2016, IoT + service',
        // year: '2016',
        company: 'Questto|Nó (AB-InBev)',
        related: 'connected drains',
        role: 'UX & Product Lead\n(detailed credits at the bottom)',
        extras: `. project management\n. implementation\n. stakeholder relations\n. UI design\n. hardware development`,
        url: '/works/oasis',
        foreground: '#ffcc00',
        background: '#100900',
        model: '/models/works/oasis.glb',
        // margin: 0,
        subtitle: 'we took a beer in a crowded pub with no queues or bills on exit',
        stripImage: '/images/works/oasis/oasis_strip.jpg',
        coverImage: '/images/works/oasis/oasis_cover.jpg',
        thumbImage: '/images/works/oasis/oasis_thumb.jpg',
        // peekImage: '/images/works/oasis/peek_1.jpg',
        // tags: [0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 1],
        mosaicImages: [
          '/images/works/oasis/oasis_1.jpg',
          '/images/works/oasis/ABI_OASIS_PILOTANALYSIS-68.jpg',
          '/images/works/oasis/ABI_OASIS_PILOTANALYSIS-71.jpg',
          '/images/works/oasis/oasis_2.jpg',
        ],
        images: [
          '/images/works/oasis/oasis_1.jpg',
          '/images/works/oasis/oasis_2.jpg',
          '/images/works/oasis/ABI_OASIS_PILOTANALYSIS-68.jpg',
          '/images/works/oasis/ABI_OAS_ST04_CASE_V01-1536x864.jpg',
          '/images/works/oasis/ABI_OAS_ST06_CASE_V01-1536x864.jpg',
          '/images/works/oasis/ABI_OAS_ST07_CASE_V01-1536x864.jpg',
          '/images/works/oasis/img_3212.jpg',
          '/images/works/oasis/ABI_OASIS_PILOTANALYSIS-36.jpg',
          '/images/works/oasis/IMG_1756.jpg',
        ],
        imagesCaptions: ['some very long caption is what you expect from this image', 'all work and no play: makes jack a kinda dull boy'],
        texts: [
          `Skol OASIS is an innovative product aimed at automating draft beer consumption to avoid queues and ease payment on very crowded points-of-sale.

The project began before I entered Questto|Nó, in an innovation summit organized by AB-InBev, the biggest brewery in the world. 

When I joined the team, my role was to lead the UX to transform the service that began to be implemented as a prepaid RFID card system into a full mobile digital payment one.

I have managed multiple stakeholders, implemented the pilot operation and helped guide the proposal for a new improved version based on evidence.
          `,
          `Oasis is a service that integrates a machine + webApp + web remote control panel for pub owners to monitor the beer keg consumption.

The machine itself is connected to the internet and has specialized hardware to control the beer flow, so users only pay for what they serve.

We chose to develop a lightweight webApp to remove the barrier of downloading an App, so users just need to read a QR core or write the url, signup and start serving after registering their credit card info.`,
          `When the user has already registered, there is a form for typing a short session number that is shown in the machine. 
          
The machine greets the users by their names and teaches how to serve the perfect draft in an interactive game.

They have fun while knowing exactly how they are paying for it and also at the same time automating the work that is usually bottlenecked to a few pub waiters.
`,
          `The pilot operated in 15 pubs of São Paulo, Brazil. All consumption was fed into custom-made data visualization panels for the client to follow-up on the business KPIs.`,
          `» increase sales throughput via barrier elimination: remove queue for both serving and paying after

» eliminate bill splitting pain: individual consumption in collective environments, each person pays exactly what they serve;

» evolve beer beyond a good through automation: testing different service business models and relationships with pubs and stakeholders.
`,
        ],        
        credits:
`CLIENT
Anheuser-Busch InBev (Cristiano Fonseca, Jonathan Cavallaro, Fábio Chamis & team)

STUDIO
Questto Nó

DESIGN DIRECTION
Levi Girardi, Leonardo Massarelli

PROJECT & PRODUCT MANAGEMENT
Andrei Speridião, Lorênna Matos, Silas Warren

INDUSTRIAL DESIGN
Daniel Turiano, Walmor de Paula & Questto Nó team

UX/UI DESIGN
Andrei Speridião, Lucas Costa, Maria Julia Brito & Questto Nó team

UX RESEARCH
Gustavo Rosa Silva, Rafael Oliveira & Questto Nó team

AUTOMATION
myTapp (João Paulo Bodanese & team)

MANUFACTURING
Chopeiras Memo (Eduardo Arjona & team)

SOFTWARE DEVELOPMENT
Alice Wonders (Eric Winck & team)

PAYMENT SYSTEM
Moip (Mario Dias & team)

ACTIVATION
The Marketing Store (Ricardo Vansan & team)`,
        hovered: false,
      },
      // early works
      {
        type: 'work',
        caption: 'early works',
        subcaption: 'interactions, games and CGI',
        info: '2006-2014, miscellaneous',
        year: '2006-2014',
        company: '',
        related: 'collab',
        role: 'varies',
        extras: `. UX design\n. industrial design\n. project management\n. 3D modelling`,
        url: '/works/early',
        foreground: '#aaaaaa',
        background: '#0f0f0f',
        model: '/models/works/old.glb',
        subtitle: 'physical and digital worlds could blend together',
        stripImage: '/images/works/old/old_strip.jpg',
        coverImage: '/images/works/old/old_cover.jpg',
        thumbImage: '/images/works/old/old_thumb.jpg',
        peekImage: '/images/works/old/peek_1.jpg',
        tags: [1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 1, 0, 0, 1, 1, 1, 0, 1, 1, 0, 1, 1, 0, 1],
        mosaicGalaxyMe: [
          '/images/works/old/galaxyme_1.jpg',
          '/images/works/old/galaxyme_2.jpg',
          '/images/works/old/galaxyme_3.jpg',
          '/images/works/old/galaxyme_4.jpg',
        ],
        mosaicGalaxyMeCredits: ['', 'Wesley Lee', 'Wesley Lee', ''],
        mosaicDoritosCapsule: [
          '/images/works/old/doritoscapsule_1.jpg',
          '/images/works/old/doritoscapsule_2.jpg',
          '/images/works/old/doritoscapsule_3.jpg',
          '/images/works/old/doritoscapsule_4.jpg',
        ],
        mosaicDoritosCapsuleCredits: ['', '', '', 'Wesley Lee'],
        mosaicMotionsense: [
          '/images/works/old/motionsense_1.jpg',
          '/images/works/old/motionsense_2.jpg',
          '/images/works/old/motionsense_3.jpg',
          '/images/works/old/motionsense_4.jpg',
        ],
        mosaicMotionsenseCredits: ['Wesley Lee', 'Wesley Lee', 'Wesley Lee', 'Wesley Lee'],
        mosaicRecBottle: [
          '/images/works/old/recbottle_1.jpg',
          '/images/works/old/recbottle_2.jpg',
          '/images/works/old/recbottle_3.jpg',
          '/images/works/old/recbottle_4.jpg',
        ],
        mosaicRecBottleCredits: ['', 'Wesley Lee', 'Wesley Lee', ''],
        mosaicGalaxyX: [
          '/images/works/old/galaxyx_1.jpg',
          '/images/works/old/galaxyx_2.jpg',
          '/images/works/old/galaxyx_3.jpg',
          '/images/works/old/galaxyx_4.jpg',
        ],
        mosaicKde: ['/images/works/old/kde_1.png', '/images/works/old/kde_2.png', '/images/works/old/kde_3.png', '/images/works/old/kde_4.png'],
        mosaicLovers: [
          '/images/works/old/lovers_2.png',
          '/images/works/old/lovers_1.png',
          '/images/works/old/lovers_3.jpg',
          '/images/works/old/lovers_4.jpg',
        ],
        mosaicXrace: [
          '/images/works/old/xrace_1.jpg',
          '/images/works/old/xrace_2.jpg',
          '/images/works/old/xrace_3.jpg',
          '/images/works/old/xrace_4.jpg',
          '/images/works/old/xrace_5.jpg',
          '/images/works/old/xrace_6.jpg',
          '/images/works/old/xrace_7.jpg',
          '/images/works/old/xrace_8.jpg',
        ],
        mosaicXraceCredits: ['', '', '', '', 'Drop', 'Drop', 'Drop', 'Drop'],
        mosaicPeugeot: ['/images/works/old/207_1.png', '/images/works/old/207_2.png', '/images/works/old/207_3.png', '/images/works/old/207_4.png'],
        mosaicRenders: [
          '/images/works/old/renders_5.jpg',
          '/images/works/old/renders_1.jpg',
          '/images/works/old/renders_3.jpg',
          '/images/works/old/renders_2.jpg',
          '/images/works/old/renders_6.jpg',
          '/images/works/old/renders_4.jpg',
          '/images/works/old/renders_7.jpg',
          '/images/works/old/renders_8.jpg',
        ],
        images: [
          '/images/works/old/old_1.jpg',
          '/images/works/old/old_2.jpg',
          '/images/works/old/phygital.jpg',
          '/images/works/old/galaxyme.jpg',
          '/images/works/old/doritoscapsule.jpg',
          '/images/works/old/motionsense.jpg',
          '/images/works/old/recbottle.jpg',
          '/images/works/old/recipecart.jpg',
          '/images/works/old/binoculars.jpg',
          '/images/works/old/galaxyx.jpg',
          '/images/works/old/webstore.jpg',
          '/images/works/old/kde.jpg',
          '/images/works/old/realtime.jpg',
          '/images/works/old/stock.jpg',
          '/images/works/old/footzombie.jpg',
          '/images/works/old/fangio.jpg',
          '/images/works/old/xrace.jpg',
          '/images/works/old/lovers.jpg',
          '/images/works/old/207.png',
          '/images/works/old/static.jpg',
          '/images/works/old/307.jpg',
          '/images/works/old/renders.jpg',
        ],
        texts: [
          `In the first 8 years of my professional career I've used complimentary techniques that ranged from the fully analogical to the digitally connected.

As time passed, I started to blend them together to create my own toolsets to approach challenges, solve problems and envision possibilities.

If I could synthesize this in three phases:`,
          `From 2011 I began to mix the physical and digital mediums in my work. This was a blend between my very tech-oriented daily work routine and a very spatial, layered and abrangent university design course (heavily influenced by modernist architecture and Bauhaus values).`,
          `Samsung, 2014
Role: Business unit direction, UX, industrial design

An interactive totem for Samsung that suggested exclusive apps.

After putting the smartphone into the appropriate dock, the user answered a short quiz. These apps were then presented and could be “dragged” to be installed.

It was placed into Samsung concept stores in São Paulo, Brazil.`,
          `Pepsico, 2014
Role: Business unit direction, UX, industrial design

An immersive experience in which people ate Doritos while skydiving in virtual reality.

Customers of a pub entered a special capsule for a short trip, and as they catched a ride on a free wingsuit flight they could feel real wind blowing.`,
          `Unilever, 2013
Role: Business unit direction, UX, industrial design

To promote an innovative new fragrance technology from Unilever, I've led the creation of a prototype sensing t-shirt that measured body movement, humidity and temperature.

It featured on an advertising video worn by Sandro Dias (Mineirinho). He is a pro vert skateboarder and engaged in crazy tricks to show extreme body movements on data visualization panels amidst his halfpipe track.`,
          `Coca-Cola, 2013
Role: UX and design direction, hardware prototyping

A special bottle created for influencer campaigns during the 2014 World Cup.

Its shape may look ordinary, but instead of coke it holds cheerful moments that are transformed into short musical videoclips.`,
          `Unilever, 2013
Role: Interaction and industrial design

Smart shopping carts that suggest users how to cook dishes with Hellmann's Mayo, depending on their route inside the supermarket.

For that, each cart has a tablet app and a RFID tag. Embedded on the store's ceiling there are RFID antennas on strategic places (meats, fish, salads, etc) that trigger the video tutorials on the screen.`,
          `Google, 2013
Role: Creation, UX and design direction

These binoculars were created to advertise Google Nexus phone's new capability to create panoramic photos.

Users panned the binoculars around like on tourist attractions, peeking into nice landmarks such as the Corcovado through a digital representation of the actual Nexus smartphone.`,
          `Samsung, 2012
Role: UX and industrial design

These Daft Punk inspired helmets were used at a stunt activation when the three strange “bots” wandered around partying with people at the Sónar Festival 2012 in São Paulo, Brazil.

Each unit held two Galaxy X smartphones: one for filming everything and the other to mirror anything happening in front. And of course, blinking lights.`,
          `Google, 2011
Role: Industrial design

This shelf is a physical interpretation of how a virtual Google Chrome Web Store would look like.

It was made for a video series where actors would interact with it and pick fluffy pillows that represented Chrome Extensions.`,
          `Diageo, 2011
Role: Interaction design, 3D modelling and Unity

K.D.E. stands for Knowledge, Development and Execution. It  was conceived to be a marketing war room inside big companies, proposing the ideal environment for exchanging marketing insights and creating campaigns.

It consisted of a modular and very flexible structure for different setups such as presenting, meeting, workshopping, ideating and analyzing data.`,
          `Interactive 3D for the web (way before WebGL...), using mostly Unity.

At this period I've migrated from the pre-renders to realtime engines, learning to deal with performance and extract the best visuals even with technical limitations.`,
          `Globo, 2012
Role: Project management, game design, visual direction, 3D models (car and tracks)

Official online game for the Brazilian Stock Car Franchise, allowing simple car adjusts and three tracks.`,
          `Unilever, 2010
Role: 3D models ("gun" and level design + modelling)

A first person shooter that puts the players on duty to use a Rexona gun, deodorizing zombies back as normal human soccer fans. Made for the 2010 World Cup.

...Nonsense.`,
          `Unilever, 2009
Role: 3D models (car and acessories design + modelling)

A multiplayer game that lets users customize their cars (spoilers, wheels, hoods, colors, etc), and then race on streets using “V12 Rexona” pods as boost packs.`,
          `Unilever, 2009
Role: 3D models (cart design + modelling)

Tuned shopping carts to endure frenzy daily routines feature in this street racing game, which also had a global rank for players to compete.

A real version of that took place at a shopping mall in 2009`,
          `Pepsico, 2009
Role: 3D models

An innovative Augmented Reality campaign with tags printed on snack packages that allowed users to give birth to virtual monsters on a hot site.

Each monster was created modularly so they were unique (up to trillions of possibilities).`,
          `Peugeot, 2008
Role: 3D models

An interactive showroom for the new Peugeot 207 line. Users could test available paint colors, tilt wheels and open doors for the hatchback and station wagon models while panning around and inside the car.`,
          `By learning computer graphics on the internet and community forums with colleagues from all around the world, I have realized my first in-depth studies and professional works.`,
          `Peugeot, 2006
Role: 3D renders (animated sequence)

My first freelance job for Peugeot Brasil when I was 16 years old.

I've taken the pictures and modelled the 307 hatchback, station wagon and sedan that featured on a hot site where users could see the car with the available colors from different angles.`,
          `from 2006 to 2008
Role: 3D renders

As a car lover, these are early studies that I've made on 3D modelling and rendering.

The tools used were 3dsmax, Vray, Rhinoceros and/or Alias Studio Tools.`,
          `To make the clips, users open the cap (“TSSS” sound), look inside (tiny screen) and film through (camera/mic at the bottom).

Then, repeat that until the luminous ribbon is filled and wait for the video to be stitched together.
          
Later, pull the pendrive on the bottom of the bottle and plug in a computer, open the app and share the creations on social media.`,
          ``,
          ``,
        ],
      },
    ],
  },
  besides: {
    skin: {
      foreground: '#202020',
      altForeground: 'white',
      altBackground: 'white',
      background: '#101010',
      perimeter: '#aaaaaa',
      hoverColor: '#808080',
    },
    projects: [
      // simulator
      {
        caption: 'vr hull',
        subcaption: 'still travelling',
        url: '#',
        position: [-9.98, 2.69, 8.52],
        related: 'collab',
        background: '#0f0004',
        foreground: '#ffd8b3',
        model: '/models/besides/drivesim.glb',
        cloud: '/models/besides/drivesim.xyz',
        proxy: '/models/besides/drivesim_proxy.glb',
      },
      // intangible
      {
        caption: 'imaterial\ninterfaces',
        subcaption: 'pixel rehersals',
        subtitle: 'etc',
        url: '#',
        position: [-7.9, -18.27, 10.87],
        related: 'collab',
        foreground: '#ffffff',
        background: '#000000',
        cloud: '/models/besides/web.xyz',
        proxy: '/models/besides/web_proxy.glb',
        model: '/models/besides/imaterial.glb',
      },
      // lamps
      {
        caption: 'lamps',
        subcaption: 'photonic\nmachines',
        url: '#',
        position: [13.69, -15.59, 8.79],
        related: 'collab',
        foreground: '#ffa200',
        background: '#180600',
        model: '/models/besides/lamps.glb',
        cloud: '/models/besides/lamps.xyz',
        proxy: '/models/besides/lamps_proxy.glb',
      },
      // prototypes
      {
        caption: 'phygital prototypes',
        subcaption: 'interactive sketchbook',
        url: '#',
        position: [8.39, 3.56, 11.08],
        related: 'collab',
        background: '#001516',
        foreground: '#d1d1d1',
        model: '/models/besides/prototypes.glb',
        cloud: '/models/besides/prototypes.xyz',
        proxy: '/models/besides/prototypes_proxy.glb',
        texts: [`intro text.`],
        images: ['/images/besides/prototypes/prototypes_1.jpg', '/images/besides/prototypes/prototypes_2.jpg'],
      },
      // material
      {
        caption: 'hybrid\nmaterials',
        subcaption: 'tangible exercises',
        subtitle: 'etc',
        url: '#',
        position: [15.5, -8.58, 9.79],
        related: 'collab',
        foreground: '#efc9ff',
        background: '#03000d',
        cloud: '/models/besides/material.xyz',
        proxy: '/models/besides/material_proxy.glb',
        model: '/models/besides/material.glb',
      },
      // XXguitars
      {
        caption: 'strings & waves',
        subcaption: 'bodily music',
        subtitle: 'etc',
        url: '#',
        position: [-13.6, 5.1, 16.42],
        cloud: '/models/besides/guitars.xyz',
        proxy: '/models/besides/guitars_proxy.glb',
      },
      // XXsynth
      {
        caption: 'synth',
        subcaption: 'sonic\nscapes',
        subtitle: 'etc',
        url: '#',
        position: [-1.28, 7.01, 5.96],
        cloud: '/models/besides/synth.xyz',
        proxy: '/models/besides/synth_proxy.glb',
      },
      // XXvr
      {
        caption: 'xr',
        subcaption: 'YOU-I\ninstruments',
        subtitle: 'etc',
        url: '#',
        position: [-16.3, -4.62, 9.78],
        cloud: '/models/besides/vr.xyz',
        proxy: '/models/besides/vr_proxy.glb',
      },
      // photo *external
      {
        caption: 'photo',
        subcaption: 'geo bits',
        externalUrl: true,
        url: 'https://www.flickr.com/photos/andreisperid/albums',
        position: [-12.81, -13.5, 9.81],
        cloud: '/models/besides/photography.xyz',
        proxy: '/models/besides/photography_proxy.glb',
      },
      // books
      {
        caption: 'books',
        subcaption: 'memorable\nreflections',
        url: '#',
        position: [-2.77, -15.07, 8.49],
        cloud: '/models/besides/books.xyz',
        proxy: '/models/besides/books_proxy.glb',
        model: '/models/besides/books.glb',
      },
    ],
  },
  who: {
    skin: {
      foreground: 'black',
      background: '#d6d6d6',
      perimeter: '#aaaaaa',
      hoverColor: '#808080',
    },
    cover: {
      title: `hi,\nI'm Andrei`,
      text:
`I'm a Brazilian designer and technologist born in São Paulo, the most populous city in the western hemisphere.

It's no wonder that space, people, and technology recurrently emerge as patterns of my expressions.

I craft interactions by sketching both physically and digitally. My design process involves hardware, software, CGI, virtual reality, and even better: new skills and knowledge to be cultivated while experimenting.

In my latest endeavor, I've been leading an immersive studio laboratory, but before that, I was in makerspaces, design studios, and agencies.

Currently, I'm honing my curiosity by exploring the "around", "between", and "within" dimensions that associate things, relationships, and senses to form unique interactive experiences.

As hobbies, I like to make puns unpromptedly, play guitar & bass, and reflect through the lenses of my camera on trips.`,
      image: '/images/who/holo.png',
    },
    mosaicPhotos: ['/images/who/photos_1.jpg', '/images/who/photos_2.jpg', '/images/who/photos_3.jpg', '/images/who/photos_4.jpg'],
    mosaicMentoring1: ['/images/who/photos_1.jpg', '/images/who/photos_2.jpg', '/images/who/classes.jpg', '/images/who/photos_4.jpg'],
    mosaicMentoring2: ['/images/who/basement_1.png', '/images/who/basement_2.png', '/images/who/basement_3.png', '/images/who/basement_4.png'],
    mosaicMentoringCredits1: [
      'Associaçao Brasileira de Cinematografia',
      'Willian Soares Alves, Campus Party',
      'Conexão Maker, Canal Futura',
      'Victor Nomoto, I Hate Flash',
    ],
    mosaicMentoringCredits2: ['Felipe Gabriel, Red Bull', 'Felipe Gabriel, Red Bull', 'Felipe Gabriel, Red Bull', 'Marcelo Maragni, Red Bull'],
    mentoring: {
      text: 
`Red Bull Basement was a social innovation residence and focused on technology and citizenship. It took place yearly from July to September in a dedicated hackerspace, in São Paulo, Brazil.

In a collective effort with other specialists, which included a selection board for candidates, I was the mentor responsible to help teams better understand their challenges, and solve them more efficiently with a hybrid design and technology approach.
      
In five years I’ve helped 26 mentee groups from different parts of Brazil. They brought solutions to urban issues in categories such as accessibility, education, ethics, climate, clean energy and natural disasters.
      
Beyond the project outcomes, I've had the opportunity to create a diverse network made of scientists, activists, artists, engineers and designers.`,
      image: '/images/who/basement.jpg',
    },
    speaking:{
      text:
`My latest experience teaching was at Hyper Island (remote to Sweden) in March 2022, giving a class on Exponential Technologies.

Yet, since 2014 I’ve given several classes on design, technology and creativity in Brazilian schools such as Echos Escola de Design Thinking, TERA, GNED CESAR School, FLAGCX, Sandbox Escola de Estratégia and Cuca Escola de Criatividade. The students were professionals of different seniorities, including executives, from both creative and corporate contexts.

And to that day, I have traveled to seven different states in Brazil to present 34 lectures and talks in companies, universities, hackathons and big festivals, with a wide variety of audiences. 

I’ve also been featured in several Brazilian magazines such as INFO (Editora Abril) and Select (Editora Três), podcasts (Future Hacker) and one tv show (Conexão Maker, from Canal Futura).`,
      image: '/images/who/photos_3.jpg'
    },
    texts: [
`Dec/21 (podcast) - Future Hacker (Global) #65

Nov/21 (fish bowl) - 19th SIGP - Intl. Seminar on Project Management (PMI) - São Paulo
      
Sep/20 (podcast) - Future Hacker (Brazil) #07

Mar/20 (talk) - FEU2020 - São Paulo, SP

Dec/19 (talk) - Besides The Screen - Vitória, ES

Oct/19 (talk) - Pecha Kucha Nights São Paulo - São Paulo, SP

Oct/19 (tv show) - Conexão Maker with Lebraile and Labeye - Canal Futura

Oct/19 (talk) - WHEXT Festival - São Paulo, SP

Oct/19 (talk) - Festival Audiovisual Mercosul 2019 - Florianópolis, SC

Jun/19 (talk) - Festival Path 2019 - São Paulo, SP

Feb/19 (talk) - Campus Party 12nd Edition - São Paulo, SP

Feb/19 (in company) - ATECH Embraer UX Café - São Paulo, SP

Nov/18 (talk) - Fórum BDA - São Paulo, SP

Aug/18 (talk) - Fashion Tech - São Paulo, SP

Aug/18 (in company) - Ponto de Criação - São Paulo, SP

Jun/17 (talk) - Pós Graduação Design FAU USP - São Paulo, SP

Mai/17 (talk) - Startup Weekend USP - São Paulo, SP`,
`Mar/17 (talk) - Arduino Day 2017 - São Paulo, SP

Dec/16 (undergrad academic examining board)
Guilherme Queiroz: “The relationship between citizens and urban furniture”

Nov/16 (talk) - Round N Around - São Paulo, SP

Aug/16 (talk) - Design em Pauta FAU USP - São Paulo, SP

Aug/16 (talk) - Semana de Design São Judas - São Paulo, SP

Jul/16 (talk) - N Design Parahyba 2016 - João Pessoa, PB

Jul/16 (talk) - Q|N LAB 20X20 - São Paulo, SP

Jul/16 (talk) - Ágora Fab Lab Livre SP - São Paulo, SP

Mai/16 (in company) - Try Talks - São Paulo, SP

Jan/16 (talk) - Campus Party 9th Edition - São Paulo, SP

Oct/15 (talk) - Minas Startup Week - Belo Horizonte, MG

Sep/15 (talk) - ModaCamp 2015: Moda & Tecnologia - São Paulo, SP

Aug/15 (in company) - New Digital Day @NewStyle Agency - São Paulo, SP

Aug/15 (talk) - FAU USP PV IV - São Paulo, SP

Jul/15 (talk) - N Design São Paulo - São Paulo, SP

Nov/14 (talk) - Semana de Design Rio - Rio de Janeiro, RJ`,
    ],
    timelineTitle: {
      text: `my experience milestones`,
    },
    timelineExperience: [
      {
        title: '2004\n3D',
        text: 'Began learning computer graphics as self taught and accomplished my first freelance jobs.',
        image: '/images/who/2004.jpg',
        strip: '/images/who/2004_strip.jpg',
        icons: ['/images/who/andrei.png'],
      },
      {
        title: '2008\ninteractive',
        text:
          'Entered Design University and at the same time started working full-time with interactive real-time applications and games on an advertising hotshop.',
        image: '/images/who/2008.jpg',
        strip: '/images/who/2008_strip.jpg',
        icons: ['/images/who/fau.png', '/images/who/cubocc.png'],
      },
      {
        title: '2012\nphygital',
        text: 'Grown together with the company and created a team that designed and produced special phygital projects for marketing.',
        image: '/images/who/2012.jpg',
        strip: '/images/who/2012_strip.jpg',
        icons: ['/images/who/flag.png'],
      },
      {
        title: '2015\ninnovation',
        text:
          'Already graduated, joined a design and innovation company, worked with complex products, services and conceptual experiences.\n\nStarted to teach and mentor on design and technology.',
        image: '/images/who/2015.jpg',
        strip: '/images/who/2015_strip.jpg',
        icons: ['/images/who/redbullbasement.png', '/images/who/questtono.png'],
      },
      {
        title: '2017\nimmersive',
        text:
          'Dove into VR as founding team in a startup studio.\n\nCreated experiences, tools and exploratory prototypes to expand immersion through other senses besides audio and vision.',
        image: '/images/who/2017.jpg',
        strip: '/images/who/2017_strip.jpg',
        icons: ['/images/who/arvore.png'],
      },
    ],
    timelineVision: [
      {
        title: 'around\nbuilt artifacts',
        text: '... to understand the impact of technology on the DNA of things and its reflection on the everyday interactions.',
        image: '/images/who/aroundbetweenwithin-01.png',
      },
      {
        title: 'between\nhuman relations',
        text: '... to reach further spatially and culturally while technology becomes more visceral.',
        image: '/images/who/aroundbetweenwithin-02.png',
      },
      {
        title: 'within\nperceptual senses',
        text: '... to experiment with the boundaries of the unbodily: what is the "me" in between atoms, cells, and the cosmos?',
        image: '/images/who/aroundbetweenwithin-03.png',
      },
    ],
    timelineCompanies: [
      {
        image: '/images/who/_google.png',
      },
      {
        image: '/images/who/_samsung.png',
      },
      {
        image: '/images/who/_motorola.png',
      },
      {
        image: '/images/who/_unilever.png',
      },
      {
        image: '/images/who/_pepsico.png',
      },
      {
        image: '/images/who/_cocacola.png',
      },
      {
        image: '/images/who/_abi.png',
      },
      {
        image: '/images/who/_diageo.png',
      },
      {
        image: '/images/who/_redbull.png',
      },
      {
        image: '/images/who/_peugeot.png',
      },
      {
        image: '/images/who/_ford.png',
      },
      {
        image: '/images/who/_chevrolet.png',
      },
      {
        image: '/images/who/_volkswagen.png',
      },
      {
        image: '/images/who/_globo.png',
      },
      {
        image: '/images/who/_mueller.png',
      },
    ],
    timelineClasses: [
      {
        image: '/images/who/_hyperisland.png',
      },
      {
        image: '/images/who/_redbullbasement.png',
      },
      {
        image: '/images/who/flag.png',
      },
      {
        image: '/images/who/_sandbox.png',
      },
      {
        image: '/images/who/_cuca.png',
      },
      {
        image: '/images/who/_echos.png',
      },
      {
        image: '/images/who/_tera.png',
      },
      {
        image: '/images/who/_cesar.png',
      },
    ],
    skillsHeader: {
      labels: ['design', '3D/2D', 'prototyping', 'code', 'hardware', 'leadership'],
      content: [
        ['direction', 'UX', 'interaction', 'UI', 'visual', 'industrial'],
        [
          '3dsmax',
          'Rhinoceros',
          'Alias Design',
          'hi&low poly\nmodelling',
          'NURBS\nmodelling',
          'Corona/Vray',
          'photography',
          'Adobe CC',
          'photogrammetry',
        ],
        ['sketching', 'wireframing', 'physical\nprototyping', 'high/low\nfidelity', 'digital\nfabrication'],
        ['Unity', 'C#', 'javascript', 'three.js', 'React'],
        ['sensors & actuators', 'Arduino', 'Espressif', 'Raspberry Pi', 'PCB\nprototyping'],
        ['team/project\nmanagement', 'sourcing', 'R&D', 'business\ndevelopment', 'mentoring', 'teaching'],
      ],
    },
  },
}

export default content
