/* eslint eqeqeq: "off", no-array-constructor: "off", jsx-a11y/anchor-is-valid: "off", no-sequences: "off", no-unused-vars:"off" */

import * as THREE from 'three'
import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { useFrame, useThree, useLoader, extend } from '@react-three/fiber'
import { useGLTF, Sphere, QuadraticBezierLine, shaderMaterial, Html } from '@react-three/drei'
import Text from './Text'
import state from '../state'
import { useLocation } from 'wouter'
import glsl from 'babel-plugin-glsl/macro'
import { AdditiveBlending, DoubleSide } from 'three'
import { XYZLoader } from '../utils/XYZLoader.js'

// intersection observer
import { useInView } from 'react-intersection-observer'

const hoverRadius = 0.4

const WireMaterial = shaderMaterial(
  {
    thickness: 1,
    // side: THREE.DoubleSide,
    depthWrite: THREE.NeverDepth,
    depthTest: false,
    // alphaToCoverage: true,
    // blending: THREE.NormalBlending,
    color: new THREE.Color(0.5, 0.5, 0.5),
    // opacity: 0.5
  },
  // vertex shader
  glsl`
  attribute vec3 center;
  varying vec3 vCenter;
  void main() {
    vCenter = center;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 ) * 0.1;
  }
  `,
  // fragment shader
  glsl`  
  uniform float thickness;
  uniform vec3 color;
  uniform float opacity;
  varying vec3 vCenter;
  void main() {
    vec3 afwidth = fwidth( vCenter.xyz );
    vec3 edge3 = smoothstep( ( thickness - 1.0 ) * afwidth, thickness * afwidth, vCenter.xyz );
    float edge = 1.0 - min( min( edge3.x, edge3.y ), edge3.z );
    gl_FragColor.rgb = gl_FrontFacing ? color : color * vec3( 0.0, 0.0, 0.0 );
    gl_FragColor.a = gl_FrontFacing ? edge : 0.0 ;
    //vec3( 0.9, 0.9, 1.0 ) 
    
  }
  `
)
extend({ WireMaterial })

const FresnelMaterial = shaderMaterial(
  {
    u_opacity: 1,
  },
  // vertex shader
  glsl`
  varying vec3 vPositionW;
  varying vec3 vNormalW;
  void main() {
    vPositionW = vec3( vec4( position, 1.0 ) * modelMatrix);
    vNormalW = normalize( vec3( vec4( normal, 0.0 ) * modelMatrix ));
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  } 
  `,
  // fragment shader
  glsl`  
  varying vec3 vPositionW;
  varying vec3 vNormalW;
  uniform float u_opacity;
  void main() {		
    vec3 color = vec3(.58, .74, 1.);
    vec3 viewDirectionW = normalize(cameraPosition - vPositionW);
    float fresnelTerm = dot(viewDirectionW, vNormalW) * (1. - u_opacity/2.);
    fresnelTerm = clamp(1.0 - fresnelTerm, 0., 1.);
    gl_FragColor = vec4( color * fresnelTerm, 1.) * u_opacity;
  }
  `
)
extend({ FresnelMaterial })

export function Thumbnail(
  {
    model,
    caption,
    subcaption,
    subtitle,
    role,
    year,
    url,
    index = 0,
    fgColor,
    scale = 1,
    position = [0, 0, 0],
    textureImage,
    textureThumb,
    touch = false,
    freshHelper = false,
    left = false,
  },
  props
) {
  const textureAlpha = useLoader(THREE.TextureLoader, '/images/background_vignette.jpg')

  const group = useRef()
  const text = useRef()
  const backgroundImage = useRef()
  const textPos = new THREE.Vector3()
  const cursorText = useRef()

  const [start, setStart] = useState(position)
  const [mid, setMid] = useState(position)
  const [end, setEnd] = useState(position)
  const vertices = useMemo(() => [start, mid, end].map((v) => new THREE.Vector3(...v)), [start, mid, end])
  const update = useCallback((self) => (self.verticesNeedUpdate = true), [])

  const { viewport } = useThree()
  const [location, setLocation] = useLocation()

  const { nodes } = useGLTF(model, true)

  let cloneSolid = nodes.geo
  const [hovered, setHover] = useState(false)
  const [hidden, setHidden] = useState(false)
  const [intersectRef, inView] = useInView({
    threshold: 0.75,
  })

  const ratio = viewport.width / viewport.height

  useFrame(({ clock }) => {
    if (group.current != null || group.current != undefined) {
      const t = (1 + Math.sin(clock.getElapsedTime() * 0.2)) / 2
      // group.current.position.y = Math.sin(((t + index) * 10) / 2) * scale
      group.current.position.y = touch ? -0.75 : 0

      setHidden(state.mouse[1] < -0.8)

      const x = position[0] * 0.8 + (state.mouse[0] * viewport.width) / 2 / 5
      const y = position[1] * 0.8 + (state.mouse[1] * -viewport.height) / 2 / 5
      group.current.position.x = ratio > 1 && touch && !freshHelper ? -viewport.width / 4.5 : 0
      setEnd([
        freshHelper ? x : group.current.position.x,
        freshHelper ? y : group.current.position.y,
        (state.mouse[0] == 0 && state.mouse[1] == 0) || (Math.abs(state.mouse[0]) < 0.2 && Math.abs(state.mouse[1]) < 0.2 && !hovered && !freshHelper)
          ? 1000
          : 0,
      ])
      group.current.rotation.x = Math.sin(((t + index + 10) * 20) / 3) / 10
      group.current.rotation.z = Math.sin(((t + index) * 20) / 3) / 10

      const z =
        (state.mouse[0] == 0 && state.mouse[1] == 0) || (Math.abs(state.mouse[0]) < 0.2 && Math.abs(state.mouse[1]) < 0.2 && !hovered && !freshHelper)
          ? 100
          : 0 + 100 * (!inView && !hovered)

      if (text.current != null || text.current != undefined) {
        !touch
          ? text.current.position.lerp(
              textPos.set(
                -position[0] * 0.2 + (state.mouse[0] * viewport.width) / 2,
                -position[1] * 0.2 + (state.mouse[1] * -viewport.height) / 2,
                touch ? 0 : z
              ),
              0.2
            )
          : text.current.position.lerp(textPos.set(freshHelper ? (left ? -0.5 : 0.5) : ratio > 1 ? -1 : 0, position[1] - 1, touch ? 0 : z), 0.1)

        setStart([(state.mouse[0] * viewport.width) / 2, (state.mouse[1] * -viewport.height) / 2 + 1 - scale, touch ? 0 : text.current.position.z])
        setMid([(start[0] + end[0]) / 2, (start[1] + end[1]) / 2, z])
      }

      if (cursorText.current != null || cursorText.current != undefined) {
        cursorText.current.position.lerp(
          textPos.set((state.mouse[0] * viewport.width) / 2, (state.mouse[1] * -viewport.height) / 2 + 1 - scale, z),
          0.2
        )
      }

      if (backgroundImage.current != null || backgroundImage.current != undefined) {
        // console.log(backgroundImage.current.material.opacity)
        backgroundImage.current.material.opacity = THREE.MathUtils.lerp(backgroundImage.current.material.opacity, hovered && inView ? 1 : 0, 0.15)
      }
    }
  })

  const scaleFactor = 10 * scale

  useEffect(() => void (document.body.style.cursor = hovered ? 'none' : 'auto'), [hovered])

  return (
    <>
      <QuadraticBezierLine
        start={vertices[2]}
        mid={vertices[1]}
        end={vertices[0]}
        color={'#555555'}
        vertexColors={[
          [0, 0, 0],
          [0, 0, 0],
          [0.5, 0.5, 0.5],
          [1, 1, 1],
          [0.5, 0.5, 0.5],
          [0, 0, 0],
          [0, 0, 0],
        ]}
        lineWidth={0.25}
        transparent={true}
        visible={!hovered && !touch && inView && !hidden}
        onUpdate={update}
        blending={AdditiveBlending}
        depthTest={false}
        depthWrite={false}
        segments={6}
        renderOrder={15}
      />

      <group position-x={0} position-y={viewport.height / 2}>
        <Html zIndexRange={[-99, -98]}>
          <div
            ref={intersectRef}
            style={{
              // background: inView? "green" : "red",
              pointerEvents: 'none',
              opacity: 0.5,
              width: 1,
              height: '100vh',
            }}
          />
        </Html>
      </group>

      <group ref={cursorText}>
        <Text
          bold
          fontSize={freshHelper ? 0.5 : 1}
          letterSpacing={-0.05}
          color={'white'}
          textAlign={'center'}
          anchorY={'bottom'}
          anchorX={'center'}
          fillOpacity={(freshHelper ? 0.1 : 0.25) * inView}
          visible={!hovered && !touch && !hidden}
          renderOrder={15}>
          {freshHelper ? `fresh!` : `what if?`}
          <meshBasicMaterial blending={AdditiveBlending} depthTest={false} depthWrite={false} transparent side={DoubleSide} />
        </Text>
      </group>

      <group ref={text}>
        <group
          position={[
            (ratio > 1 && touch && !freshHelper ? viewport.width / 6 : 0) * (left ? -1 : 1),
            ratio > 1 || (touch && freshHelper) ? 0 : -viewport.height / 6,
            0,
          ]}>
          <Text
            bold
            anchorX={touch && freshHelper ? (!left ? 'left' : 'right') : 'center'}
            anchorY="bottom"
            textAlign="center"
            position={[(touch && freshHelper ? 0 : 0) * (left ? 1 : -1), 1, touch && freshHelper ? 0 : 7]}
            fontSize={(touch && freshHelper ? 2 : 0.8) * scale}
            lineHeight={1 * scale}
            letterSpacing={0}
            color={fgColor}
            fillOpacity={hovered || touch ? 0.05 : 0}
            visible={touch && freshHelper}>
            {'fresh!'}
          </Text>
          <Text
            bold
            anchorX={touch && freshHelper ? (!left ? 'left' : 'right') : 'center'}
            anchorY="bottom"
            textAlign="center"
            position={[
              (touch && freshHelper ? 0 : 0) * (left ? 1 : -1),
              ratio > 1 && touch && !freshHelper ? -2.5 : -0.25 * scale,
              touch && freshHelper ? 0 : 7,
            ]}
            fontSize={(touch && freshHelper ? 1.5 : 1) * scale}
            letterSpacing={0}
            maxWidth={Math.max((viewport.width / 5) * 2, 5)}
            color={fgColor}
            fillOpacity={hovered || touch ? 0.5 : 0}
            visible={hovered || touch}>
            {caption.toUpperCase()}
          </Text>
          <Text
            anchorX={touch && freshHelper ? (!left ? 'left' : 'right') : 'center'}
            textAlign="center"
            anchorY="top"
            position={[
              (touch && freshHelper ? 0 : 0) * (left ? 1 : -1),
              ratio > 1 && touch && !freshHelper ? -2.75 : -1 * scale,
              touch && freshHelper ? 0 : 7,
            ]}
            fontSize={(touch && freshHelper ? 1 : 0.6) * scale}
            lineHeight={1}
            color={fgColor}
            maxWidth={Math.max((viewport.width / 5) * 3, 5)}
            fillOpacity={hovered || touch ? 0.5 : 0}
            visible={hovered || touch}>
            {subcaption}
          </Text>
          <Text
            anchorX={touch && freshHelper ? (!left ? 'left' : 'right') : 'center'}
            textAlign="center"
            anchorY="top"
            position={[
              (touch && freshHelper ? 0 : 0) * (left ? 1 : -1),
              ratio > 1 && touch && !freshHelper ? -3.75 : -2.25 * scale,
              touch && freshHelper ? 0 : 7,
            ]}
            fontSize={(touch && freshHelper ? 0.75 : 0.4) * scale}
            lineHeight={1}
            color={fgColor}
            maxWidth={Math.max((viewport.width / 5) * 3, 5)}
            fillOpacity={hovered || touch ? 0.25 : 0}
            visible={(hovered || touch) && !freshHelper}>
            {role + ' / ' + year}
          </Text>
        </group>
        <group
          position={[(ratio > 1 && touch ? -viewport.width / 6 : 0) * (left ? 1 : -1), ratio > 1 ? (touch ? 0.25 : 1.5) : viewport.height / 8, 8]}
          centerAnchor>
          <Text
            bold
            anchorX="center"
            anchorY="bottom"
            textAlign="center"
            maxWidth={ratio > 1 ? Math.max(viewport.width / 3, 5) * scale : (viewport.width / 5) * 3}
            fontSize={0.75 * scale}
            lineHeight={1}
            letterSpacing={0}
            color={fgColor}
            fillOpacity={hovered || touch ? 0.95 : 0}
            visible={hovered || (touch && !freshHelper)}>
            {`...what if ` + subtitle + `?`}
          </Text>
        </group>
        <mesh position-z={-1000} ref={backgroundImage}>
          <planeBufferGeometry args={[1100, 1100]} />
          <meshBasicMaterial
            attach="material"
            color={'#707070'}
            visible={inView && !touch}
            transparent
            opacity={1}
            map={textureThumb}
            alphaMap={textureAlpha}
            depthTest={false}
            depthWrite={false}
          />
        </mesh>
      </group>

      <group {...props} position={position} dispose={null} onClick={() => (touch ? (setLocation(url), (document.body.style.cursor = 'auto')) : null)}>
        {/* <Sphere scale={[0.5, 0.5, 0.5]} visible={false} /> */}
        <group ref={group} scale={[scaleFactor, scaleFactor, scaleFactor]}>
          <group
            onPointerOver={() => !touch && setHover(true)}
            onPointerOut={() => !touch && setHover(false)}
            onClick={() => (setLocation(url), (document.body.style.cursor = 'auto'))}
            onPointerMissed={() => !touch && setHover(false)}>
            <Sphere scale={[hoverRadius, hoverRadius, hoverRadius]} visible={false} />
          </group>

          <primitive object={cloneSolid}>
            <meshPhongMaterial
              vertexColors={true}
              blending={hovered ? THREE.SubtractiveBlending : THREE.NormalBlending}
              map={textureImage}
              flatShading={true}
              color={hovered ? '#d6d6d6' : '#404040'}
              opacity={0.85}
              transparent={true}
            />
          </primitive>
        </group>
      </group>
    </>
  )
}

export function Moebius(
  {
    model,
    textureImage,
    fade = true,
    hovered = false,
    floatModel = false,
    showroom = false,
    rotateX = false,
    rotateY = false,
    rotateZ = false,
    rotationOffset = [0, 0, 0],
    scale = 1,
    position = [0, 0, 0],
    opacity = 0,
    targetOpacity = 1,
    additive = true,
    shaded = false,
    speed = 0.2,
    order = 2,
    touch = false,
    browserName = '',
  },
  props
) {
  const group = useRef()
  const mesh = useRef()
  const planet = useRef()

  if (textureImage != null) {
    textureImage.rotation = Math.PI / 0.5 // fix distortion

    textureImage.repeat.set(0, 0.75)
    textureImage.wrapS = THREE.RepeatWrapping
    textureImage.wrapT = THREE.RepeatWrapping
  }
  const { nodes } = useGLTF(model, true)
  let cloneSolid = nodes.geo.clone()

  const textureAlpha = useLoader(THREE.TextureLoader, '/images/moebius_gradient.jpg')
  textureAlpha.repeat.set(1, 1)
  textureAlpha.wrapS = THREE.RepeatWrapping
  textureAlpha.wrapT = THREE.RepeatWrapping

  cloneSolid.material = shaded
    ? new THREE.MeshPhongMaterial({
        side: THREE.FrontSide,
        color: 'white',
        alphaMap: fade && textureImage != null ? textureAlpha : null,
        map: textureImage != null ? textureImage : null,
        bumpMap: textureImage != null ? textureImage : null,
        bumpScale: 0.3,
        depthWrite: textureImage != null ? THREE.NeverDepth : THREE.EqualDepth,
        opacity: textureImage != null ? (hovered ? opacity : opacity / 1.25) : opacity,
        blending: additive ? THREE.AdditiveBlending : THREE.NormalBlending,
        transparent: true,
      })
    : new THREE.MeshBasicMaterial({
        side: THREE.FrontSide,
        color: 'white',
        alphaMap: fade && textureImage != null ? textureAlpha : null,
        map: textureImage != null ? textureImage : null,
        depthWrite: textureImage != null ? THREE.NeverDepth : THREE.EqualDepth,
        opacity: textureImage != null ? (hovered ? opacity : opacity / 1.25) : opacity,
        blending: additive ? THREE.AdditiveBlending : THREE.MultiplyBlending,
        transparent: true,
      })

  const vecQuat = new THREE.Quaternion()

  // if (touch && browserName != 'safari' && browserName != 'firefox') { // throwing error (firefox) "TypeError: window.AbsoluteOrientationSensor is not a constructor"
  if (touch && browserName == 'chrome') {
    const options = { frequency: 60, referenceFrame: 'device' }
    const sensor = new window.AbsoluteOrientationSensor(options)

    // if (touch) {
      sensor.addEventListener('reading', () => {
        state.imu = sensor.quaternion
      })
      sensor.start()
    // }
  } else {
    //TODO iOS, Safari & Firefox compatibility
  }

  useFrame(({ clock }) => {
    if (group.current != null || group.current != undefined) {
      const t = (1 + Math.sin(clock.getElapsedTime() * 0.95)) / 2
      if (textureImage != null) {
        textureImage.offset.set((clock.getElapsedTime() / 50) * speed, (-clock.getElapsedTime() / (hovered ? 15 : 15)) * speed)
        textureAlpha.offset.set(0, (-clock.getElapsedTime() / 10) * speed)
      }
      if (floatModel) {
        group.current.position.y = t / 3
      }
      group.current.rotation.y += showroom ? 0.0025 : 0

      if (touch) {
        group.current.quaternion.slerp(vecQuat.set(state.imu[0], state.imu[1], state.imu[2], state.imu[3]).invert(), 0.01)
      } else {
        group.current.rotation.x += rotateX ? THREE.MathUtils.lerp(group.current.position.x, state.mouse[1] / 5, 0.015) : 0
        group.current.rotation.y += rotateY ? THREE.MathUtils.lerp(group.current.position.x, state.mouse[0] / 5, 0.02) : 0
      }
      group.current.rotation.z -= rotateZ ? 0.005 : 0
    }
    if (planet.current != null || planet.current != undefined) {
      planet.current.rotation.z += rotateZ ? 0.018 : 0
    }
    if (mesh.current != null || mesh.current != undefined) {
      mesh.current.children[0].material.opacity = THREE.MathUtils.lerp(mesh.current.children[0].material.opacity, targetOpacity, 0.005)
    }
  })
  return (
    <>
      <group {...props} dispose={null}>
        <group ref={group} scale={[scale, scale, scale]} position={position}>
          {/* <group ref={planet}>
            <group position-y={-0.9}>
              <Sphere scale={[0.002, 0.002, 0.002]} visible={false}>
                <meshBasicMaterial
                  attach="material"
                  color="white"
                  transparent={true}
                  opacity={1}
                  blending={AdditiveBlending}
                  depthTest={false}
                  depthWrite={false}
                />
              </Sphere>
            </group>
          </group> */}

          {/* <group ref={mobileRef}>         */}
          <mesh ref={mesh} rotation={rotationOffset}>
            <primitive object={cloneSolid} renderOrder={order} />
          </mesh>
          {/* </group> */}
        </group>
      </group>
    </>
  )
}

export function ModelSimple(
  {
    model,
    fgColor,
    steadyPos = false,
    steadyRot = false,
    scale = 1,
    position = [0, 0, 0],
    opacity = 1.0,
    hullVisible = false,
    flat = true,
    rotationOffset,
    doubleSide = true,
    index = 0,
    shaded = true,
    light = false,
    wireframe = false,
    order = 15,
  },
  props
) {
  const group = useRef()
  const mesh = useRef()

  const { nodes } = useGLTF(model, true)
  const cloneSolid = nodes.geo.clone() // disabling clone makes lab icon to disappear

  cloneSolid.material = shaded
    ? new THREE.MeshPhongMaterial({
        vertexColors: true,
        flatShading: flat,
        color: '#909090',
        opacity: opacity,
        side: doubleSide ? THREE.DoubleSide : THREE.FrontSide,
        transparent: opacity === 1 ? false : true,
      })
    : new THREE.MeshBasicMaterial({
        wireframe: wireframe,
        vertexColors: true,
        flatShading: flat,
        color: fgColor,
        opacity: opacity,
        side: THREE.FrontSide,
        transparent: opacity === 1 ? false : true,
      })

  let randomTime = 0

  useFrame(({ clock }) => {
    if (group.current != null || group.current != undefined) {
      const t = (1 + Math.sin(clock.getElapsedTime() * 0.7)) / 2

      if (!steadyPos) {
        group.current.position.y = Math.sin(((t + index + randomTime) * 10) / 2) / 10 + position[1]
      }
      if (!steadyRot) {
        group.current.rotation.x = Math.sin(((t + index) * 2) / 3) / 10
        group.current.rotation.z = Math.sin(((t + index) * 2) / 3) / 10

        group.current.rotation.y += THREE.MathUtils.lerp(group.current.position.x, state.mouse[0] / 5, 0.015)
      }
    }
  })
  return (
    <>
      {light ? <pointLight position={[0, 3, 3]} distance={10} decay={1} intensity={2} color={fgColor} /> : null}
      <group {...props} dispose={null} rotation={rotationOffset}>
        <group ref={group} scale={[scale, scale, scale]} position={position}>
          <Sphere scale={[0.7, 0.7, 0.7]} visible={hullVisible}>
            <meshBasicMaterial opacity={0.1} color={fgColor} wireframe transparent />
          </Sphere>
          <mesh ref={mesh}>
            <primitive object={cloneSolid} renderOrder={order} />
          </mesh>
        </group>
      </group>
    </>
  )
}

export function ModelSimpleShaded(
  {
    model,
    fgColor,
    steadyPos = false,
    steadyRot = false,
    scale = 1,
    position = [0, 0, 0],
    opacity = 1.0,
    hullVisible = false,
    flat = true,
    rotationOffset,
    index = 0,
    textureImage,
    renderOrder = 15,
  },
  props
) {
  const group = useRef()
  const mesh = useRef()

  const { nodes } = useGLTF(model, true)
  const cloneSolid = nodes.geo.clone() // disabling clone makes lab icon to disappear

  cloneSolid.material = new THREE.MeshPhongMaterial({
    vertexColors: true,
    flatShading: flat,
    map: textureImage,
    color: 'white',
    shininess: 75,
    opacity: 0,
    blending: THREE.AdditiveBlending,
    transparent: opacity === 1 ? false : true,
  })

  let randomTime = 0

  useFrame(({ clock }) => {
    if (group.current != null || group.current != undefined) {
      const t = (1 + Math.sin(clock.getElapsedTime() * 0.7)) / 2

      if (!steadyPos) {
        group.current.position.y = Math.sin(((t + index + randomTime) * 10) / 2) / 10 + position[1]
      }
      if (!steadyRot) {
        group.current.rotation.x = Math.sin(((t + index) * 2) / 3) / 10
        group.current.rotation.z = Math.sin(((t + index) * 2) / 3) / 10

        group.current.rotation.y += THREE.MathUtils.lerp(group.current.position.x, state.mouse[0] / 5, 0.015)
      }
    }

    if (mesh.current != null || mesh.current != undefined) {
      mesh.current.children[0].material.opacity = THREE.MathUtils.lerp(mesh.current.children[0].material.opacity, opacity, 0.05)
    }
  })
  return (
    <>
      <group {...props} dispose={null} rotation={rotationOffset}>
        <group ref={group} scale={[scale, scale, scale]} position={position}>
          <Sphere scale={[0.7, 0.7, 0.7]} visible={hullVisible}>
            <meshBasicMaterial opacity={0.1} color={fgColor} wireframe transparent />
          </Sphere>
          <mesh ref={mesh} renderOrder={20}>
            <primitive object={cloneSolid} renderOrder={renderOrder} />
          </mesh>
        </group>
      </group>
    </>
  )
}

export function ModelRotate({ model, fgColor, steadyPos = false, steadyRot = false, scale = 1, position = [0, 0, 0] }, props) {
  const group = useRef()
  const mesh = useRef()

  const { nodes } = useGLTF(model, true)
  let cloneWire = nodes.geo.clone()
  let cloneSolid = nodes.geo.clone()

  cloneWire.material = new THREE.MeshPhongMaterial({
    color: fgColor,
    wireframe: true,
    opacity: 1,
    transparent: true,
  })

  useFrame(({ clock }) => {
    if (group.current != null || group.current != undefined) {
      const t = (1 + Math.sin(clock.getElapsedTime() * 0.95)) / 2

      if (!steadyPos) {
        group.current.position.y = t / 5
      }
      if (!steadyRot) {
        group.current.rotation.x += 0.00025
        group.current.rotation.y += THREE.MathUtils.lerp(group.current.position.x, state.mouse[0] / 5, 0.005)
        group.current.rotation.z -= THREE.MathUtils.lerp(group.current.position.x, state.mouse[1] / 5, 0.005)
      }
    }
  })
  return (
    <>
      <group {...props} dispose={null}>
        <group ref={group} scale={[scale, scale, scale]} position={position}>
          <mesh ref={mesh}>
            <primitive object={cloneSolid} renderOrder={1}>
              <meshPhongMaterial vertexColors={true} flatShading={true} color={'#808080'} side={THREE.DoubleSide} />
            </primitive>
          </mesh>
        </group>
      </group>
    </>
  )
}

export function CloudThumb(
  {
    cloud,
    proxy,
    caption,
    subcaption,
    url = '',
    fgColor,
    index = 0,
    scale = 1,
    position = [0, 0, 0],
    touch = false,
    externalUrl = false,
    titleVisible = true,
    size = 1.0,
    inside = false,
  },
  props
) {
  const text = useRef()
  const textPos = new THREE.Vector3()
  const cursorText = useRef()

  const { viewport } = useThree()
  const [location, setLocation] = useLocation()

  const [dotSize, setDotSize] = useState(1)
  const [opacityFactor, setOpacityFactor] = useState(0.015)
  const [sizeMult, setSizeMult] = useState(1)

  const { nodes } = useGLTF(proxy, true)
  let proxyModel = nodes.geo

  const model = useLoader(XYZLoader, cloud)
  const textureAlpha = useLoader(THREE.TextureLoader, '/images/bokeh.jpg')
  textureAlpha.center = new THREE.Vector2(0.5, 0.5)

  const { camera } = useThree()

  const proxyCollider = useRef()
  const raycaster = new THREE.Raycaster()
  var vector = new THREE.Vector3()

  raycaster.set(camera.position, vector.sub(camera.position).normalize())

  const [hovered, setHover] = useState(false)

  useFrame(({ clock }) => {
    if (touch) {
      const intersects = raycaster.intersectObject(proxyModel)
      if (inside && intersects.length != 0 && url != '') {
        console.log(intersects[0] + '  ' + url)
        setHover(true)
      } else {
        setHover(false)
      }
    }

    const t = (1 + Math.sin(clock.getElapsedTime() * 0.2)) / 2

    setSizeMult(Math.sin(clock.getElapsedTime() + index) / 5)

    const x = position[0]
    const y = position[1]

    if (cursorText.current != null || cursorText.current != undefined) {
      cursorText.current.position.lerp(
        textPos.set((state.mouse[0] * viewport.width) / 2, (state.mouse[1] * -viewport.height) / 2 + 1 - scale, 0),
        0.25
      )
      cursorText.current.lookAt(camera.position)
    }

    if (text.current != null || text.current != undefined) {
      text.current.lookAt(camera.position)
      text.current.position.lerp(textPos.set(x, y, position[2]), 1)
    }

    setDotSize(THREE.MathUtils.lerp(dotSize, (hovered ? 0.25 : 2) * size, 0.1)) // check performance
    setOpacityFactor(THREE.MathUtils.lerp(opacityFactor, hovered ? 1 : 0.02, 0.1)) // check performance

    textureAlpha.rotation = THREE.MathUtils.lerp(textureAlpha.rotation, (state.mouse[0] + state.mouse[1]) * 5 + t * 5, 0.01)
  })

  useEffect(() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'), [hovered])

  return (
    <>
      <group ref={text}>
        <Text
          bold
          anchorX={'center'}
          anchorY="bottom"
          textAlign="center"
          fontSize={0.75 * scale}
          lineHeight={1 * scale}
          letterSpacing={0}
          visible={url != ''}
          renderOrder={1}>
          {caption.toUpperCase()}
          <meshBasicMaterial
            color={fgColor}
            opacity={titleVisible ? (hovered || touch ? 0.75 : 0.1) : 0}
            transparent={true}
            blending={THREE.AdditiveBlending}
            depthWrite={false} depthTest={false} 
          />
        </Text>
        <Text
          anchorX={'center'}
          textAlign="center"
          anchorY="top"
          fontSize={0.5 * scale}
          lineHeight={1}
          maxWidth={Math.max(viewport.width / 3, 5)}
          visible={url != ''}
          renderOrder={1}>
          {subcaption}
          <meshBasicMaterial
          depthWrite={false} depthTest={false} 
            color={fgColor}
            opacity={titleVisible ? (hovered || touch ? 0.75 : 0.1) : 0}
            transparent={true}
            blending={THREE.AdditiveBlending}
          />
        </Text>
      </group>

      <group>
        <group
          onPointerOver={(e) => (setHover((touch ? false : true) && url != ''), e.stopPropagation())}
          onPointerOut={(e) => (setHover(false && url != ''), e.stopPropagation())}
          onClick={(e) => (externalUrl ? window.open(url) : setLocation(url), (document.body.style.cursor = 'auto'), e.stopPropagation())}
          rotation={[Math.PI / 2, 0, 0]}
          position={[-0.5, -0.75, 0]}
          ref={proxyCollider}>
          <primitive object={proxyModel}>
            <meshBasicMaterial color="white" wireframe opacity={0} transparent visible={false} />
          </primitive>
        </group>

        <points geometry={model} renderOrder={2}>
          <pointsMaterial
            sizeAttenuation={true}
            attach="material"
            vertexColors={true}
            color={'#fff1df'}
            alphaMap={textureAlpha}
            depthWrite={false}
            depthTest={false}
            blending={THREE.AdditiveBlending}
            size={(dotSize + (!hovered ? sizeMult : null)) * (touch ? 0.5 : 0.75)}
            transparent={true}
            opacity={opacityFactor}
          />
        </points>
      </group>
    </>
  )
}

export function Constellation({ cloud, scale = 1, position = [0, 0, -200], intensity = 1, background = false }, props) {
  const group = useRef()
  const mesh = useRef()

  const model = useLoader(XYZLoader, cloud)
  const textureAlpha = useLoader(THREE.TextureLoader, background ? '/images/moebius_gradient_radial.jpg' : '/images/flare.jpg')

  const [sizeMult, setSizeMult] = useState(1)

  useFrame(({ clock }) => {
    setSizeMult(Math.sin(clock.getElapsedTime()) / 1.75)
  })
  return (
    <>
      <group {...props} dispose={null}>
        <group ref={group} scale={[scale, scale, scale]} position={position} rotation={[Math.PI / 2, 0, 0]}>
          <mesh ref={mesh}>
            <points geometry={model} renderOrder={2}>
              <pointsMaterial
                sizeAttenuation={!background}
                attach="material"
                color={background ? '#98b3ff' : '#ffecd4'}
                alphaMap={textureAlpha}
                depthWrite={false}
                depthTest={false}
                blending={THREE.AdditiveBlending}
                size={background ? (2000 + 1000 * sizeMult) * intensity : (250 + 200 * sizeMult) * intensity}
                transparent={true}
                opacity={background ? 0.02 : 1}
              />
            </points>
          </mesh>
        </group>
      </group>
    </>
  )
}

export function Nebula({ model, scale = 1, position = [0, 0, -200] }, props) {
  const group = useRef()
  const mesh = useRef()

  const { nodes } = useGLTF(model, true)
  const cloneSolid = nodes.geo.clone() // disabling clone makes lab icon to disappear

  const textureImage = useLoader(THREE.TextureLoader, '/images/besides/highlights.png')
  textureImage.rotation = Math.PI / 0.5 // fix distortion
  textureImage.repeat.set(0, 3)
  textureImage.wrapS = THREE.RepeatWrapping
  textureImage.wrapT = THREE.RepeatWrapping

  cloneSolid.material = new THREE.MeshBasicMaterial({
    map: textureImage,
    opacity: 0.125,
    depthWrite: false,
    depthTest: false,
    blending: THREE.AdditiveBlending,
    side: THREE.FrontSide,
    transparent: false,
    wireframe: true,
  })

  useFrame(({ clock }) => {
    if (group.current != null || group.current != undefined) {
      if (textureImage != null) {
        textureImage.offset.set((clock.getElapsedTime() / 50) * 1, (-clock.getElapsedTime() / 20) * 1)
      }
    }
  })
  return (
    <>
      <group {...props} dispose={null}>
        <group ref={group} scale={[scale, scale, scale]} position={position} rotation={[Math.PI / 2, 0, 0]}>
          <mesh ref={mesh}>
            <primitive object={cloneSolid} renderOrder={1} />
          </mesh>
        </group>
      </group>
    </>
  )
}
