/* eslint eqeqeq: "off", no-array-constructor: "off", jsx-a11y/anchor-is-valid: "off", no-sequences: "off", no-unused-vars:"off" */

// libraries
import * as THREE from 'three'
import { Uniform, Vector3 } from 'three'
import React, { Suspense, useEffect, useRef, useState, useCallback, forwardRef, useMemo } from 'react'
import { Canvas, useThree, useFrame, useLoader, extend } from '@react-three/fiber'
import { Flex, Box } from '@react-three/flex'

import {
  Loader,
  Html,
  Sphere,
  Preload,
  shaderMaterial,
  Stars,
  Line,
  Box as BoxGeo,
  // Stats,
  OrbitControls,
} from '@react-three/drei'

import { useRoute, Switch, Route, Link, useLocation } from 'wouter'
import { Perf } from 'r3f-perf'
import glsl from 'babel-plugin-glsl/macro'
import { XYZLoader } from './utils/XYZLoader.js'

// effects
import {
  EffectComposer,
  // Noise,
  Vignette,
} from '@react-three/postprocessing'
import { BlendFunction, Effect } from 'postprocessing'

// components
import Text from './components/Text'
import { Thumbnail, Moebius, ModelSimple, ModelSimpleShaded, ModelRotate, CloudThumb, Constellation, Nebula } from './components/Geo'

// content
import state from './state'
import content from './content'

// iconify
import { InlineIcon } from '@iconify/react'
import emailIcon from '@iconify-icons/ic/outline-email'
import linkedinIcon from '@iconify-icons/akar-icons/linkedin-fill'
// import mediumIcon from '@iconify/icons-simple-icons/medium'
import instagramIcon from '@iconify/icons-simple-icons/instagram'
import flickrIcon from '@iconify/icons-simple-icons/flickr'
import githubIcon from '@iconify/icons-simple-icons/github'
import hackadayIcon from '@iconify/icons-simple-icons/hackaday'
import hacksterIcon from '@iconify/icons-cib/hackster'
import makezineIcon from '@iconify/icons-emojione-monotone/letter-m'
import menuIcon from '@iconify-icons/akar-icons/text-align-justified'
import closeIcon from '@iconify-icons/akar-icons/cross'
// import threeIcon from '@iconify/icons-simple-icons/threedotjs'
import downloadIcon from '@iconify/icons-ci/download'
import externalIcon from '@iconify/icons-ci/external-link'
import fullscreenIcon from '@iconify/icons-mdi/fullscreen'
import fullscreenExitIcon from '@iconify/icons-mdi/fullscreen-exit'

// intersection observer
import { useInView } from 'react-intersection-observer'

// analytics
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDU3TFvCZU9URvcJM4ahA5Ld-2t_o99qX4',
  authDomain: 'portfolio-5c190.firebaseapp.com',
  projectId: 'portfolio-5c190',
  storageBucket: 'portfolio-5c190.appspot.com',
  messagingSenderId: '623001955024',
  appId: '1:623001955024:web:e83e151a00b78bb3714cd5',
  measurementId: 'G-BWF89JBSK6',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

let foregroundColor
let backgroundColor
let invertScreen
let currentUrl
let colorA = '#000000'
let colorB = '#000000'
let touchDevice = false
let dpr = (window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio) || 1.0
let targetScroll = 0
let previousPages = 0
let besidesExternal = false
let worksExternal = false
let besidesScroll = 0
let worksScroll = 0
let besidesRotation = [0, 2 * Math.random() * Math.PI, 0]
let browserName

console.log(`♫ I'm the operator, with my pocket calculator ♪`)
// components ------------------------------------------------------------------------------

const invertFragmentShader = `
uniform float opacity;
void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor)
{
  vec4 colorNormal = vec4(inputColor.r, inputColor.g, inputColor.b, 1.0);
  vec4 colorInverted = vec4(1.0 - inputColor.r, 1.0 -inputColor.g, 1.0 -inputColor.b, 1.0);

  outputColor = mix( colorNormal, colorInverted, opacity);
}
`
let _opacityParam

// Effect implementation
class InvertEffectImpl extends Effect {
  constructor({ opacity = 0.1 } = {}) {
    super('InvertEffect', invertFragmentShader, {
      uniforms: new Map([['opacity', new Uniform(opacity)]]),
    })
    _opacityParam = opacity
  }
  update(renderer, inputBuffer, deltaTime) {
    this.uniforms.get('opacity').value = _opacityParam
  }
}

// Effect component
export const InvertEffect = forwardRef((opacity, ref) => {
  const effect = useMemo(() => new InvertEffectImpl(opacity), [opacity])
  return <primitive ref={ref} object={effect} dispose={null} />
})

// custom materials
const DoubleChannelMaterial = shaderMaterial(
  { opacity: 1.0, repeat: [1, 1], offset: [0, 0], map: new THREE.Texture(), alphaMap: new THREE.Texture() },
  // vertex shader
  glsl`
  varying vec2 vUv1;
  varying vec2 vUv2;
  uniform vec2 repeat;

  void main() {
    // vUv1 = uv;
    vUv1 = repeat * uv;
    
    vUv2 = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
  `,
  // fragment shader
  glsl`  
  uniform sampler2D map;
  uniform sampler2D alphaMap;
  uniform float opacity;
  varying vec2 vUv1;
  varying vec2 vUv2;
  uniform vec2 offset;

  void main() {

    vec4 textureSource = texture2D(map, vUv1 + offset); 
    vec4 alphaSource = texture2D(alphaMap, vUv2) * opacity;

    gl_FragColor = textureSource*alphaSource;
    gl_FragColor.a = mix(gl_FragColor.rgb, vec3(1.0), alphaSource.r).r;

  }
  `
)
extend({ DoubleChannelMaterial })
const DoubleChannelMaterialMask = shaderMaterial(
  { opacity: 1.0, repeat: [1, 1], offset: [0, 0], map: new THREE.Texture(), alphaMap: new THREE.Texture() },
  // vertex shader
  glsl`
  varying vec2 vUv1;
  varying vec2 vUv2;
  uniform vec2 repeat;

  void main() {
    // vUv1 = uv;
    vUv1 = uv;
    
    vUv2 = repeat * uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
  `,
  // fragment shader
  glsl`  
  uniform sampler2D map;
  uniform sampler2D alphaMap;
  uniform float opacity;
  varying vec2 vUv1;
  varying vec2 vUv2;
  uniform vec2 offset;

  void main() {

    vec4 textureSource = texture2D(map, vUv1); 
    vec4 alphaSource = texture2D(alphaMap, vUv2 + offset) * opacity;

    gl_FragColor = textureSource*alphaSource;
    gl_FragColor.a = mix(gl_FragColor.rgb, vec3(1.0), alphaSource.r).r;

  }
  `
)
extend({ DoubleChannelMaterialMask })

// --------------------------------------------------------------------------------------
// DOM simple functions
// --------------------------------------------------------------------------------------
function setNav(target = '') {
  if (target != '') {
    if (document.getElementById('home')) {
      document.getElementById('home').childNodes[0].className = currentUrl == '/' ? 'activeNav' : ''
    }
    if (document.getElementById('works')) {
      document.getElementById('works').childNodes[0].className = currentUrl.startsWith('/works')
        ? currentUrl == '/works'
          ? 'activeNav'
          : 'activeNavDashed'
        : ''
    }
    if (document.getElementById('who')) {
      document.getElementById('who').childNodes[0].className = currentUrl.startsWith('/who') ? 'activeNav' : ''
    }
    if (document.getElementById('besides')) {
      document.getElementById('besides').childNodes[0].className = currentUrl.startsWith('/besides')
        ? currentUrl == '/besides'
          ? 'activeNav'
          : 'activeNavDashed'
        : ''
    }
  }
}
function topFunction() {
  if (document.getElementById('scrollDiv')) {
    previousPages = state.pages
    document.getElementById('scrollDiv').scrollTop = targetScroll
    state.top = targetScroll
  }
}
function Header() {
  fnBrowserDetect()

  const [click, setClick] = useState(false)
  const [fullscreen, setFullscreen] = useState(false)

  const handleClick = () => setClick(!click)
  const handleFullscreen = () => setFullscreen(!fullscreen)
  const closeMobileMenu = () => setClick(false)

  // console.log(fullscreen)

  useEffect(() => {
    let key = document.querySelectorAll('a')
    // console.log(key)
    key.forEach((k) => {
      k.style.color = 'foregroundColor'
      // k.style.textShadow = '0 0 20px rgb(255, 255, 255), 0 0 20px rgb(255, 255, 255), 0 0 20px rgb(255, 255, 255), 0 0 20px rgb(255, 255, 255), 0 0 20px rgb(255, 255, 255)'
      // k.style.color = 'white'
    })
  })
  // console.log('backgroundColor', backgroundColor)

  return (
    <>
      <header id="headerRoot" style={{ background: 'linear-gradient(' + backgroundColor + '66 0%, transparent 50%)' }}>
        <div className={click ? 'header-inner active' : 'header-inner'} style={{ backgroundColor: click ? backgroundColor : null }} id="header">
          {/* <div className="nav">
            <span className="item" id="besides" onClick={closeMobileMenu} onMouseUp={setNav('besides')}>
              <ActiveLink href="/besides">
                {(touchDevice && (browserName == 'ios' || browserName == 'safari' || browserName == 'firefox')) ? null : '& be-sides'}
              </ActiveLink>
            </span>
          </div> */}

          {/* <div id='headerBackground'></div> */}

          <div className="logo" id="home" onClick={closeMobileMenu} onMouseUp={setNav('home')}>
            <ActiveLink href="/">andrei speridião</ActiveLink>
          </div>

          <span className="socialBar" onClick={closeMobileMenu}>
            <a
              href="mailto:hi@andrei.cc"
              onMouseDown={() => {
                analyticsTracker('header click email')
              }}
              className="icon-bar"
              target="_blank"
              rel="noopener noreferrer">
              <InlineIcon icon={emailIcon} />
            </a>
            <a
              href="https://www.linkedin.com/in/andreisperid/"
              onMouseDown={() => {
                analyticsTracker('header click linkedin')
              }}
              className="icon-bar"
              target="_blank"
              rel="noopener noreferrer">
              <InlineIcon icon={linkedinIcon} />
            </a>
            {/* <a href="https://medium.com/@andreisperid" onMouseDown={()=>{analyticsTracker('header click medium')}}  className="icon-bar" target="_blank" rel="noopener noreferrer">
              <InlineIcon icon={mediumIcon} />
            </a> */}
            <a
              href="https://www.instagram.com/andreisperid/"
              onMouseDown={() => {
                analyticsTracker('header click instagram')
              }}
              className="icon-bar"
              target="_blank"
              rel="noopener noreferrer">
              <InlineIcon icon={instagramIcon} />
            </a>
            <a
              href="https://www.flickr.com/photos/andreisperid/albums"
              onMouseDown={() => {
                analyticsTracker('header click flickr')
              }}
              className="icon-bar"
              target="_blank"
              rel="noopener noreferrer">
              <InlineIcon icon={flickrIcon} />
            </a>
            <a
              href="https://github.com/andreisperid"
              onMouseDown={() => {
                analyticsTracker('header click github')
              }}
              className="icon-bar"
              target="_blank"
              rel="noopener noreferrer">
              <InlineIcon icon={githubIcon} />
            </a>
          </span>
        </div>

        <span className="mobileBar" onClick={handleClick}>
          {click ? (
            <span className="icon-bar">
              <InlineIcon icon={closeIcon} />
            </span>
          ) : (
            <span className="icon-bar">
              <InlineIcon icon={menuIcon} />
            </span>
          )}
        </span>

        {/* <span className="threeCredit">
          <div style={{ marginRight: '10px', alignSelf: 'center' }}>
            <InlineIcon icon={threeIcon} />
          </div>
          <div className="threeText" style={{ fontSize: 12 }}>
            made with
            <br />
            three.js
          </div>
        </span> */}

        <span className="fullscreenButton" id="fullscreen" onClick={handleFullscreen} onMouseUp={toggleFullscreen}>
          {fullscreen ? (
            <span className="icon-bar">
              <InlineIcon icon={fullscreenExitIcon} />
            </span>
          ) : (
            <span className="icon-bar">
              <InlineIcon icon={fullscreenIcon} />
            </span>
          )}
        </span>

        <div className="headerBackground"></div>
      </header>
    </>
  )
}
function toggleFullscreen() {
  if (!document.fullscreenElement) {
    analyticsTracker('clicked fullscreen')
    document.documentElement.requestFullscreen()
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    }
  }
}
function fnBrowserDetect() {
  let userAgent = navigator.userAgent
  if (userAgent.match(/ios/i)) {
    browserName = 'ios'
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari'
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera'
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge'
  } else {
    browserName = 'No browser detection'
  }

  // console.log(browserName)

  //  document.querySelector("h1").innerText="You are using "+ browserName +" browser";
}

// --------------------------------------------------------------------------------------
// DOM jsx functions
// --------------------------------------------------------------------------------------
function ScrollContainer({ scroll, children }) {
  const { viewport } = useThree()
  const group = useRef()
  const vec = new THREE.Vector3()

  useFrame(() => {
    if (group.current != null && group.current != undefined) {
      group.current.position.lerp(vec.set(0, viewport.height * scroll.current, 0), 1)
    }
  })
  return <group ref={group}>{children}</group>
}
const ActiveLink = (props) => {
  const [isActive] = useRoute(props.href)

  return (
    <Link {...props}>
      <a className={isActive ? 'activeNav' : 'a'}>{props.children}</a>
    </Link>
  )
}
function DeviceDetect() {
  if ('ontouchstart' in document.documentElement) {
    touchDevice = true
  }
  return null
}
function SnapSections({ number = 1 }) {
  return (
    <>
      {
        {
          // '/': (
          //   <div
          //     style={{
          //       width: '50px',
          //       height: '100%',
          //       bottom: 0,
          //       position: 'relative',
          //       // borderWidth: '1px',
          //       // border: 'solid',
          //       // borderColor: "red",
          //     }}>
          //     <div
          //       style={{
          //         width: '25px',
          //         height: '50%',
          //         position: 'absolute',
          //         top: 0,
          //         // borderWidth: '1px', border: 'solid' ,
          //         scrollSnapAlign: 'start',
          //       }}>
          //       {/* {index} */}
          //     </div>
          //     <div
          //       style={{
          //         width: '25px',
          //         height: '50%',
          //         position: 'absolute',
          //         bottom: 0,
          //         // borderWidth: '1px', border: 'solid' ,
          //         scrollSnapAlign: 'end',
          //       }}>
          //       {/* {index} */}
          //     </div>
          //   </div>
          // ),
          // '/works': content.works.projects.map((props, index) => (
          //   <div
          //     key={index}
          //     style={{
          //       width: '50px',
          //       height: '100vh',
          //       //  borderWidth: '1px', border: 'solid' ,
          //       scrollSnapAlign: 'center',
          //     }}>
          //     {/* {index} */}
          //   </div>
          // )),
          '/besides': (
            <>
              <div
                style={{
                  width: '50vw',
                  height: '100vh',
                  // borderWidth: '1px',
                  // border: 'solid',
                  scrollSnapAlign: 'start',
                  color: 'white',
                }}>
                {/* {'besides header'} */}
              </div>
              <div
                id="start"
                style={{
                  width: '50vw',
                  height: '100vh',
                  // borderWidth: '1px',
                  // border: 'solid',
                  scrollSnapAlign: 'end',
                }}></div>
              {/* {content.besides.projects.map((props, index) => (
              <div
                key={index}
                style={{
                  width: '50px',
                  height: '100vh',
                  // borderWidth: '1px',
                  // border: 'solid',
                  scrollSnapAlign: 'center',
                }}>
              </div>
            ))} */}
            </>
          ),
        }[currentUrl]
      }
    </>
  )
}

// --------------------------------------------------------------------------------------
// threejs functions --------------------------------------------------------------------
// --------------------------------------------------------------------------------------
function MouseLight() {
  const { viewport } = useThree()

  const lightRef = useRef()
  const lightPos = new THREE.Vector3()

  useFrame(() => {
    if (lightRef.current != null || lightRef.current != undefined) {
      lightRef.current.position.lerp(lightPos.set((state.mouse[0] * viewport.width) / 2, (state.mouse[1] * -viewport.height) / 2, 10), 0.1)
    }
  })

  return touchDevice ? null : (
    <>
      <group ref={lightRef}>
        {/* <Sphere scale={[0.02, 0.02, 0.02]} position-z={-10}/> */}
        <pointLight intensity={1.5} decay={0.75} distance={20} color={foregroundColor} />
      </group>
    </>
  )
}
function StaticLights() {
  const backgroundRef = useRef()

  const colorBg = new THREE.Color(backgroundColor)

  useFrame(() => {
    if (backgroundRef.current != null || backgroundRef.current != undefined) {
      backgroundRef.current.children[0].material.color.lerp(colorBg, 0.3)
    }
  })

  return (
    <>
      {/*ambient light */}
      <ambientLight color={backgroundColor} intensity={touchDevice ? 1 : 2} />

      {/* fill light */}
      <pointLight position={[0, 0, 15]} intensity={touchDevice ? 2 : 1} distance={1000} color={foregroundColor} />

      <group ref={backgroundRef}>
        <Sphere scale={[5000, 5000, 5000]}>
          <meshBasicMaterial color={'black'} side={THREE.BackSide} /> :
        </Sphere>
      </group>
    </>
  )
}
function ProjectColorLights({ lightIntensity = 10 }) {
  const lightRef = useRef()
  const sphereRef = useRef()

  const colorBg = new THREE.Color(colorA)
  const colorFg = new THREE.Color(colorB)

  let colors = [colorFg, colorBg, colorBg]

  useFrame(() => {
    if (lightRef.current != null || lightRef.current != undefined) {
      lightRef.current.rotation.y -= 0.01
      lightRef.current.rotation.x -= 0.005
      for (let i = 0; i < lightRef.current.children.length; i++) {
        lightRef.current.children[i].color.lerp(colors[i], 0.025)
      }
    }
    if (sphereRef.current != null || sphereRef.current != undefined) {
      sphereRef.current.children[0].material.color.lerp(colors[1], 0.025)
    }
  })

  return (
    <>
      <group ref={lightRef}>
        <pointLight position={[15, 5, 1]} intensity={lightIntensity} distance={2000} color={'black'} />
        <pointLight position={[-15, -5, -1]} intensity={lightIntensity} distance={3000} color={'black'} />
      </group>

      <group ref={sphereRef}>
        <Sphere scale={[3990, 3990, 3990]}>
          <meshBasicMaterial
            color={'black'}
            side={THREE.BackSide}
            opacity={0.35}
            transparent={true}
            depthTest={true}
            depthWrite={true}
            depth={THREE.NeverDepth}
          />
        </Sphere>
      </group>
    </>
  )
}
function MouseLaser() {
  const { viewport } = useThree()

  const lineRef = useRef()
  const linePos = new THREE.Vector3()

  useFrame(() => {
    if (lineRef.current != null || lineRef.current != undefined) {
      lineRef.current.position.lerp(linePos.set((state.mouse[0] * viewport.width) / 2, (state.mouse[1] * -viewport.height) / 2, 0), 1)
    }
  })

  return touchDevice ? null : (
    <>
      <group ref={lineRef}>
        <Line
          points={[
            [0, 0, 5],
            [0, 0, 15],
            [0, 0, 100],
          ]} // Array of points
          color="white" // Default
          lineWidth={0.5} // In pixels (default)
          opacity={1}
          vertexColors={[
            [0, 0, 0],
            [1, 1, 1],
            [1, 1, 1],
          ]}
          // blending={THREE.AdditiveBlending}
          transparent={true}
          dashed={false} // Default
        />
      </group>
    </>
  )
}

// --------------------------------------------------------------------------------------
// react three flex modules -------------------------------------------------------------
// --------------------------------------------------------------------------------------

// utilities
function HeaderStrip() {
  const { viewport } = useThree()
  const textureBackgroundAlphaHeader = useLoader(THREE.TextureLoader, '/images/halfinverted_linear_gradient.jpg')

  const backgroundRef = useRef()

  useFrame(() => {
    // if (backgroundRef.current != null && backgroundRef.current != undefined) {
    //   backgroundRef.current.position.y = THREE.MathUtils.lerp(
    //     backgroundRef.current.position.y,
    //     touchDevice ? viewport.height / 2 - 3.4 : viewport.height / 2 - 2.5 + Math.min(state.mouse[1] * 0.5, 0),
    //     0.1
    //   )
    //   backgroundRef.current.material.opacity = THREE.MathUtils.lerp(
    //     backgroundRef.current.material.opacity,
    //     (touchDevice || state.mouse[1]) < -0.1 ? 1 : 0.9,
    //     0.1
    //   )
    // }
  })

  return (
    <>
      <mesh position-x={viewport.width / 2} position-y={viewport.height / 2 - 2.75} position-z={10} centerAnchor renderOrder={15} ref={backgroundRef}>
        <planeBufferGeometry args={[viewport.width * 4, 2.5]} />
        <meshBasicMaterial
          color={backgroundColor}
          alphaMap={textureBackgroundAlphaHeader}
          opacity={touchDevice ? 1 : 0.9}
          transparent
          depthTest={false}
          depthWrite={false}
        />
      </mesh>
    </>
  )
}
function ProgressionBar({ pages }) {
  const { viewport, size } = useThree()
  const progressHoriz = useRef()
  const pageLerp = useRef(state.top / size.height)

  const vec = new THREE.Vector3()

  let visible = currentUrl != '/' && currentUrl != '/besides'

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 1))

    let progressionRatio = page / (pages - 1)

    if (progressHoriz.current != null && progressHoriz.current != undefined) {
      progressHoriz.current.position.lerp(vec.set(viewport.width * (pages > 0 ? progressionRatio : 0), 0, 0), 0.05)
    }
  })

  return (
    <>
      <mesh position={[0, viewport.height / 2, 0]} renderOrder={16}>
        <planeBufferGeometry args={[viewport.width, 0.05]} />
        <meshBasicMaterial color={backgroundColor} depthTest={false} depthWrite={false} opacity={1} transparent />
      </mesh>
      <group ref={progressHoriz}>
        <mesh position={[-viewport.width, viewport.height / 2, 0]} renderOrder={16}>
          <planeBufferGeometry args={[viewport.width, 0.05]} />
          <meshBasicMaterial color={foregroundColor} depthTest={false} depthWrite={false} opacity={visible ? 0.75 : 0} transparent />
        </mesh>
      </group>
    </>
  )
}
function SectionTitle({ text = '' }) {
  const { viewport } = useThree()
  const proportionalScale = Math.min(1, viewport.width / 16)

  return (
    <Box dir="row" alignSelf="center" alignContent="center" width="100%" mt={2} mb={0.5} centerAnchor>
      <Text
        position-z={-2.5}
        color={foregroundColor}
        textAlign="center"
        anchorX="center"
        anchorY="middle"
        fontSize={Math.max(2 * proportionalScale, 1.2)}
        maxWidth={(viewport.width / 6) * 5}
        lineHeight={1}
        letterSpacing={-0.05}>
        {text}
      </Text>
    </Box>
  )
}
function Spacer({ mt = 1, mb = 1 }) {
  return (
    <>
      <Box
        dir="row"
        align="center"
        justify="center"
        mb={touchDevice ? 0 : mb}
        mt={touchDevice ? 0 : mt}
        width="100%"
        height="0"
        flexWrap="wrap"></Box>
    </>
  )
}
function HtmlLink({ url, text, icon, self = false }) {
  return (
    <>
      <Box dir="row" mt={0.6} align="center">
        <Box>
          <Html zIndexRange={[1, 0]} center transform>
            <div style={{ whiteSpace: 'nowrap', fontSize: 15, width: '0px' }}>
              <a href={url} className="icon-bar" target={self ? '_self' : '_blank'} rel="noopener noreferrer">
                <InlineIcon icon={icon} style={{ color: foregroundColor, marginRight: '5px' }} />
                {' ' + text}
              </a>
            </div>
          </Html>
        </Box>
      </Box>
    </>
  )
}

// analytics
function analyticsTracker(eventName) {
  let treatedEvent = eventName.replaceAll(' ', '_') // substitutes spaces with underscores

  logEvent(analytics, treatedEvent)
  // console.log(treatedEvent)
}
function ScrollTracker({ eventName }) {
  const [intersectRef, inView] = useInView()
  const [mounted, setMounted] = useState(false)
  const [triggered, setTriggered] = useState(false)

  useEffect(() => {
    if (!mounted) {
      setTimeout(() => {
        setMounted(true)
        // console.log("mounted")
      }, 1000)
    }
    if (!triggered && mounted && eventName != null && eventName != undefined && inView) {
      setTriggered(true)
      analyticsTracker(eventName)
    }
  }, [mounted, triggered, eventName, inView])

  return (
    <>
      <Box width="0" height="0">
        <Html zIndexRange={[1, 0]}>
          <div
            ref={intersectRef}
            style={{
              // background: inView? "green" : "red",
              opacity: 0.5,
              width: 1,
              height: 1,
            }}
          />
        </Html>
      </Box>
    </>
  )
}

// cover
function Cover({ projectKey, category }) {
  const { viewport } = useThree()
  const project = content[category].projects[projectKey]
  const [textureCoverImage, textureAlpha] = useLoader(THREE.TextureLoader, [project.coverImage, '/images/background_gradient.jpg'])
  const backgroundRef = useRef()
  const icon = useRef()
  const iconPos = new Vector3()

  const proportionalScale = Math.min(1, viewport.width / 16)

  textureCoverImage.repeat.y = 0
  textureCoverImage.wrapS = THREE.MirroredRepeatWrapping
  textureCoverImage.wrapT = THREE.MirroredRepeatWrapping

  const ratio = viewport.width / viewport.height

  useFrame(({ clock }) => {
    if (textureCoverImage != null) {
      textureCoverImage.offset.set(0, 0.5 + (clock.getElapsedTime() * 0.3) / 50)
    }
    if (backgroundRef.current != null && backgroundRef.current != undefined) {
      backgroundRef.current.material.repeat = [1, 0]
      backgroundRef.current.material.offset = [0, 0.5 + (clock.getElapsedTime() * 0.3) / 50]
    }

    if (icon.current != null && icon.current != undefined) {
      icon.current.position.lerp(iconPos.set(0, touchDevice ? 3.5 : 0, 0), 0.1)
    }
  })

  return (
    <>
      <group position={[viewport.width / 2, -viewport.height / 2, 0]}>
        <group ref={icon} position-z={-10}>
          <ModelSimpleShaded
            model={project.model}
            hullVisible={false}
            scale={25 * proportionalScale}
            opacity={0.5}
            position={[0, ratio > 1 ? 0 : -viewport.height, -20]}
            bgColor={project.background}
            fgColor={project.foreground}
            textureImage={textureCoverImage}
          />
        </group>
        <Text
          bold
          position={[ratio > 1 ? -2.75 : 0, 2.25, -50]}
          textAlign={'center'}
          anchorX={ratio > 1 ? 'right' : 'center'}
          anchorY="bottom"
          fontSize={Math.max(7 * proportionalScale, 5)}
          letterSpacing={-0.05}
          fillOpacity={0.15}
          color={project.foreground}>
          {'...what iƒ'}
        </Text>
      </group>

      <Box dir="row" align={'flex-start'} height="auto" width="auto" margin={0} justifyContent="center">
        <mesh position={[viewport.width / 2, -viewport.height, -50]} ref={backgroundRef}>
          <planeBufferGeometry args={[viewport.width * 2.75, viewport.height * (ratio > 1 ? 3 : 5)]} />
          <doubleChannelMaterial opacity={0.4} attach="material" map={textureCoverImage} alphaMap={textureAlpha} transparent={true} />
        </mesh>
      </Box>

      <Box dir="row" align={'flex-start'} height="auto" minHeight={viewport.height} width="100%" marginTop={2} justifyContent="center" wrap="wrap">
        {/* QUESTION COLUMN */}
        <Box dir="column" minHeight={viewport.height} m={1} mt={5} mb={0} minWidth="40%" align="flex-end">
          <Box dir="column">
            <Box dir="row" m={0} mt={0} mb={0} alignContent={ratio > 1 ? 'flex-start' : 'center'}>
              <Text
                bold
                position-y={ratio > 1 ? 0 : -0.5}
                textAlign={ratio > 1 ? 'right' : 'center'}
                anchorY={'top'}
                fontSize={Math.max(1.3 * proportionalScale, 0.8)}
                maxWidth={ratio > 1 ? viewport.width / 3 : viewport.width * 0.9}
                color={project.foreground}>
                {project.subtitle + '?'}
              </Text>
            </Box>
          </Box>
        </Box>

        {/* INFO COLUMN */}
        <Box dir="column" height="auto" m={1} mt={5} mb={0} justifyContent="center" alignContent="center" minWidth="40%">
          <Box dir="row" marginTop={0.25}>
            <Text bold anchorX={'left'} anchorY="top" fontSize={1} maxWidth={(viewport.width / 4) * 3} color={project.foreground}>
              {project.caption.toUpperCase()}
            </Text>
          </Box>
          <Box dir="row" marginTop={0.3}>
            <Text
              anchorX="left"
              anchorY="top"
              fontSize={Math.max(1 * 1 - proportionalScale, 0.5)}
              lineHeight={1}
              maxWidth={(viewport.width / 4) * 3}
              color={project.foreground}>
              {project.subcaption}
            </Text>
          </Box>
          <Box dir="row" marginTop={0.25}>
            <Text
              textAlign="left"
              anchorX="left"
              anchorY="top"
              maxWidth={(viewport.width / 4) * 3}
              fontSize={Math.max(0.75 * 1 - proportionalScale, 0.4)}
              color={project.foreground}>
              {project.info + (project.company != '' ? ' @' + project.company : '')}
            </Text>
          </Box>
          <Box dir="row" marginTop={0.5}>
            <Text
              textAlign="left"
              anchorX="left"
              anchorY="top"
              fontSize={Math.max(0.75 * 1 - proportionalScale, 0.4)}
              maxWidth={(viewport.width / 4) * 3}
              fillOpacity={1}
              lineHeight={1.5}
              color={project.foreground}>
              {/* {'main role:\n. ' + project.role} */}
              {'main role: ' + project.role}
            </Text>
          </Box>
          <Box dir="row" marginTop={0.2}>
            <Text
              textAlign="left"
              anchorX="left"
              anchorY="top"
              fontSize={Math.max(0.75 * 1 - proportionalScale, 0.4)}
              maxWidth={(viewport.width / 4) * 3}
              fillOpacity={0.5}
              color={project.foreground}>
              {/* {'also\n' + project.extras} */}
              {/* {'+ detailed credits at the bottom'} */}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  )
}
function BesidesCover({ projectKey, category }) {
  const { viewport } = useThree()
  const project = content[category].projects[projectKey]

  const proportionalScale = Math.min(1, viewport.width / 16)
  const group = useRef()
  const pos = new Vector3()

  const [textureCoverImage, textureAlpha] = useLoader(THREE.TextureLoader, [project.coverImage, '/images/background_gradient.jpg'])
  const backgroundRef = useRef()

  textureCoverImage.repeat.y = 0
  textureCoverImage.wrapS = THREE.MirroredRepeatWrapping
  textureCoverImage.wrapT = THREE.MirroredRepeatWrapping

  const ratio = viewport.width / viewport.height

  useFrame(({ clock }) => {
    if (textureCoverImage != null) {
      textureCoverImage.offset.set(0, 0.5 + (clock.getElapsedTime() * 0.3) / 50)
    }
    if (backgroundRef.current != null && backgroundRef.current != undefined) {
      backgroundRef.current.material.repeat = [1, 0]
      backgroundRef.current.material.offset = [0, 0.5 + (clock.getElapsedTime() * 0.3) / 50]
    }

    if (group.current != null && group.current != undefined) {
      group.current.position.lerp(pos.set(0, 0, -5), 0.1)
    }
  })

  return (
    <>
      <group position={[viewport.width / 2, -viewport.height / 2, 0]}>
        <group position-z={-10} ref={group}>
          <ModelSimpleShaded
            model={project.model}
            hullVisible={false}
            scale={25 * proportionalScale}
            opacity={0.25}
            position={[0, 0, -20]}
            bgColor={project.background}
            fgColor={project.foreground}
            textureImage={textureCoverImage}
            renderOrder={1}
          />
        </group>
      </group>

      <Box dir="row" align={'flex-start'} height="auto" width="auto" margin={0} justifyContent="center">
        <mesh position={[viewport.width / 2, -viewport.height / 2, -100]} ref={backgroundRef}>
          <planeBufferGeometry args={[viewport.width * 4, viewport.height * (ratio > 1 ? 3 : 5)]} />
          <doubleChannelMaterial opacity={0.4} attach="material" map={textureCoverImage} alphaMap={textureAlpha} transparent={true} />
        </mesh>
      </Box>

      <Box dir="row" align={'center'} height="auto" minHeight={viewport.height} width="100%" justifyContent="center" wrap="wrap">
        <Box dir="column" align="center">
          <Box dir="row" justify="center" alignContent={'center'} mb={2}>
            <Text bold position-z={-15} textAlign={'center'} fontSize={2 * proportionalScale} fillOpacity={0.2} color={project.foreground}>
              {'be-sides'}
            </Text>
          </Box>
          <Box dir="row" justify="center" alignContent={'center'}>
            <Text bold position-z={-10} textAlign={'center'} fontSize={3 * proportionalScale} color={project.foreground}>
              {project.caption.toUpperCase()}
            </Text>
          </Box>
          <Box dir="row" justify="center" alignContent="center" mt={0.5}>
            <Text textAlign={'center'} fontSize={1 * proportionalScale} color={project.foreground}>
              {project.subcaption}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  )
}

// footer
function Footer({ kind, related }) {
  const { viewport } = useThree()
  const proportionalScale = Math.min(1, viewport.width / 16)

  const textureBackgroundAlpha = useLoader(THREE.TextureLoader, '/images/half_linear_gradient.jpg')

  const [hovered, setHover] = useState(false)
  const [location, setLocation] = useLocation()

  let relatedKey = 0
  let relatedCategory = ''

  const ratio = viewport.width / viewport.height

  useEffect(() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'), [hovered])

  for (var i in content) {
    if (content[i].projects != null || content[i].projects != undefined) {
      for (var key in content[i].projects) {
        if (content[i].projects[key].caption == related) {
          relatedKey = key
          relatedCategory = i
          break
        }
      }
    }
  }

  return (
    <>
      {/* CONTACT HOOK */}
      <Box dir="row" align="center" justify="center" width="100%" marginTop={2}>
        <Box dir="column" height="auto" width="auto" align="center">
          <Box align="center" width="auto" justify="center" marginBottom={0}>
            <Text
              textAlign="center"
              anchorX="center"
              anchorY="bottom"
              fontSize={Math.max(1 * proportionalScale, 0.4)}
              color={foregroundColor}
              maxWidth={(viewport.width / 10) * 8}>
              {`let's talk about ` + kind + '?'}
            </Text>
          </Box>
          <Box dir="row" align="center" width="auto">
            <Box marginRight={1} marginLeft={1}>
              <Html center zIndexRange={[1, 0]}>
                <a href="https://www.linkedin.com/in/andreisperid/" className="icon-bar" target="_blank" rel="noopener noreferrer">
                  <InlineIcon icon={linkedinIcon} style={{ color: foregroundColor, fontSize: 50 }} />
                </a>
              </Html>
            </Box>
            <Box marginRight={1} marginLeft={1}>
              <Html center zIndexRange={[1, 0]}>
                <a href="mailto:hi@andrei.cc" className="icon-bar" target="_blank" rel="noopener noreferrer">
                  <InlineIcon icon={emailIcon} style={{ color: foregroundColor, fontSize: 50 }} />
                </a>
              </Html>
            </Box>
          </Box>
          <mesh position-x={viewport.width / 2} position-z={-0.1} renderOrder={15}>
            <planeBufferGeometry args={[viewport.width * 2, (viewport.height / 10) * 5]} />
            <meshBasicMaterial color={backgroundColor} alphaMap={textureBackgroundAlpha} transparent={true} />
          </mesh>
        </Box>
      </Box>

      {/* RELATED PROJECT */}
      <group
        position={[0, -0.5, -5]}
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
        onClick={() => (setLocation(content[relatedCategory].projects[relatedKey].url), (document.body.style.cursor = 'auto'))}>
        <Box dir="row" align="center" justify="center" width="100%" flexWrap="wrap" height={(viewport.height / 10) * 1.5}>
          <Box dir="column" height="auto" width="auto" align="center" justify="center">
            <Box>
              <Text
                bold
                textAlign="center"
                fontSize={Math.max(0.5 * proportionalScale, 0.3)}
                color={content[relatedCategory].projects[relatedKey].foreground}
                fillOpacity={hovered ? 1.0 : 0.5}>
                {'...what iƒ'}
              </Text>
            </Box>

            <Box marginTop={0.2}>
              <Text
                bold
                textAlign="center"
                fontSize={Math.max(0.8 * proportionalScale, 0.6)}
                color={content[relatedCategory].projects[relatedKey].foreground}
                maxWidth={ratio > 1 ? Math.max(viewport.width / 2, 7) : viewport.width * 0.9}
                fillOpacity={hovered ? 1.0 : 0.5}>
                {content[relatedCategory].projects[relatedKey].subtitle + '?'}
              </Text>
            </Box>
          </Box>
          <group position-y={-1} position-x={(viewport.width / 6) * 5}>
            {ratio > 1 ? (
              <ModelSimple
                model={content[relatedCategory].projects[relatedKey].model}
                hullVisible={false}
                scale={5}
                steadyPos={false}
                steadyRot={true}
                doubleSide={false}
                opacity={hovered ? 0.99 : 0.4}
                bgColor={content[relatedCategory].projects[relatedKey].background}
                fgColor={content[relatedCategory].projects[relatedKey].foreground}
              />
            ) : null}
          </group>
        </Box>

        <Box height="auto">
          <mesh position={[viewport.width / 2, 0, -5]} renderOrder={15} rotation-z={Math.PI}>
            <planeBufferGeometry args={[viewport.width * 2, (viewport.height / 8) * 3]} />
            <meshBasicMaterial
              color={content[relatedCategory].projects[relatedKey].background}
              alphaMap={textureBackgroundAlpha}
              // transparent={!hovered}
              transparent={true}
              opacity={hovered ? 1.0 : 0.5}
            />
          </mesh>
        </Box>
      </group>
    </>
  )
}
function CopyrightFooter({ background = true }) {
  const { viewport } = useThree()
  const proportionalScale = Math.min(1, viewport.width / 16)

  var today = new Date()

  return (
    <>
      {/* COPYRIGHT */}
      <Box dir="row" align="center" justify="center" width="100%" flexWrap="wrap">
        {/* <group position={[1, -0.3, 0]}>
          <Html centerAnchor>
            <div className="threeCredit">
              <div style={{ marginRight: '10px', alignSelf: 'center' }}>
                <InlineIcon icon={threeIcon} style={{ fontSize: Math.max(26 * proportionalScale, 3) }} />
              </div>
              <div className="threeText" style={{ fontSize: Math.max(12 * proportionalScale, 4) }}>
                made with
                <br />
                three.js
              </div>
            </div>
          </Html>
        </group> */}

        <Box dir="row" align={'flex-start'} height="auto" width="auto" justifyContent="center">
          <Text
            textAlign="center"
            anchorX="center"
            anchorY={'middle'}
            fontSize={Math.max(0.25 * 1 - proportionalScale, 0.225)}
            lineHeight={1.5}
            color={foregroundColor}
            fillOpacity={0.5}>
            {'© andrei speridião 2006 - ' + today.getFullYear()}
          </Text>
          <mesh position-x={viewport.width / 2} position-z={-0.01} visible={background}>
            <planeBufferGeometry args={[viewport.width * 2, 1]} />
            <meshBasicMaterial color={backgroundColor} visible={false} transparent />
          </mesh>
        </Box>
      </Box>
    </>
  )
}
function BesidesFooter() {
  const { viewport } = useThree()
  const proportionalScale = Math.min(1, viewport.width / 16)

  const [hovered, setHover] = useState(false)
  const [location, setLocation] = useLocation()

  useEffect(() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'), [hovered])

  return (
    <>
      {/* STOP MAKING SENSE, MAKING  */}
      <group
        position={[0, -0.5, -5]}
        onPointerOver={() => setHover(true)}
        onPointerOut={() => setHover(false)}
        onClick={() => (setLocation('/'), (document.body.style.cursor = 'auto'))}>
        <Box dir="row" align="center" justify="center" width="100%" flexWrap="wrap" height={viewport.height}>
          <Box dir="column" height="auto" width="auto" align="center" justify="center">
            <Box>
              <Text
                textAlign="center"
                anchorY="top"
                position-y={-1.25}
                fontSize={Math.max(0.9 * proportionalScale, 0.6)}
                maxWidth={viewport.width * 0.8}
                lineHeight={1}
                color={foregroundColor}
                letterSpacing={0.15}
                fillOpacity={hovered ? 1 : 0.5}>
                {'STOP MAKING SENSE, MAKING'}
              </Text>
            </Box>
          </Box>
        </Box>
      </group>
    </>
  )
}

// highlights
function HighlightText({ text, textScaleFactor = 1, color = foregroundColor }) {
  const { viewport } = useThree()

  const proportionalScale = Math.min(1, viewport.width / 8)

  return (
    <>
      <Box marginTop={1} marginBottom={1} justifyContent="center" width="100%">
        {/* HIGHLIGHT ROW */}
        <Box dir="row" align={'center'} height="auto" width="100%" justifyContent="center" wrap="wrap">
          <Box dir="row" marginLeft={1} marginRight={1}>
            <Text
              bold
              textAlign={'center'}
              fontSize={textScaleFactor * proportionalScale}
              lineHeight={1.25}
              maxWidth={(viewport.width / 5) * 4}
              color={color}>
              {text}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  )
}
function HighlightTextPortal({ text }) {
  const { viewport } = useThree()
  const ratio = viewport.width / viewport.height

  const proportionalScale = Math.min(1, viewport.width / 16)
  const [textureBackgroundAlpha, texturePortalAlpha] = useLoader(THREE.TextureLoader, [
    '/images/background_gradient.jpg',
    '/images/linear_gradient.jpg',
  ])

  return (
    <>
      <Box marginTop={3} marginBottom={1} justifyContent="center" width="100%" minHeight={viewport.height}>
        <Box dir="row" align={'flex-start'} height="auto" width="100%" margin={0} justifyContent="center" rotation-z={0.1}>
          <group position-y={ratio > 1 ? -4 : -2}>
            <group rotation-x={-Math.PI / 2.5} position-x={viewport.width / 2} position-z={-10}>
              <mesh position-y={1.5}>
                <planeBufferGeometry args={[viewport.width * 1, 3]} />
                <meshBasicMaterial color={'white'} />
              </mesh>
            </group>
            <group rotation-x={Math.PI / 2.75} position-z={-10} position-x={viewport.width / 2}>
              <mesh position-y={7.5}>
                <planeBufferGeometry args={[viewport.width * 1, 15]} />
                <meshBasicMaterial color={'white'} alphaMap={texturePortalAlpha} transparent opacity={0.5} />
              </mesh>
            </group>
          </group>
        </Box>
        {/* HIGHLIGHT ROW */}
        <Box dir="row" align={'center'} height="auto" width="100%" justifyContent="center" wrap="wrap">
          <Box dir="row" marginLeft={1} marginRight={1}>
            <Text
              bold
              textAlign={'center'}
              fontSize={1 * proportionalScale}
              color={backgroundColor}
              position-z={1}
              position-y={ratio > 1 ? 0.5 : 1}
              maxWidth={(viewport.width / 3) * 2}
              rotation-z={0}>
              {text}
            </Text>
          </Box>
        </Box>
        <Box dir="row" align={'flex-start'} height="0" width="100%" justifyContent="center">
          <mesh position-x={viewport.width / 2} position-z={-25}>
            <planeBufferGeometry args={[viewport.width * 2, 15]} />
            <meshBasicMaterial color={backgroundColor} alphaMap={textureBackgroundAlpha} transparent />
          </mesh>
        </Box>
      </Box>
    </>
  )
}
function HighlightModel({ model, scale = 8 }) {
  const { viewport, size } = useThree()
  const proportionalScale = Math.min(1, viewport.width / 16)
  const group = useRef()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 1))
    const y = page * viewport.height

    if (group.current != null || group.current != undefined) {
      group.current.rotation.y = y * 0.2
    }
  })

  return (
    <>
      {/* big3dHighLight */}
      <Box dir="row" width="auto" justifyContent="center" mt={1} mb={1}>
        <Box centerAnchor>
          <group ref={group} position-z={5}>
            <ModelRotate
              steadyRot={true}
              position={[0, 0, 0]}
              model={model}
              hullVisible={true}
              scale={scale * proportionalScale}
              bgColor={backgroundColor}
              fgColor={foregroundColor}
              light
            />
          </group>
        </Box>
      </Box>
    </>
  )
}

// texts
function TextImage({
  title = '',
  text = '',
  image,
  color = foregroundColor,
  imageOpacity = 1,
  onReflow,
  left = true,
  titleColor = 'white',
  imagesCredit = '',
  ...props
}) {
  const textureImage = useLoader(THREE.TextureLoader, image)
  const { viewport } = useThree()

  const proportionalScale = Math.min(1, viewport.width / 16)

  const ratio = viewport.width / viewport.height

  const imageRatio = textureImage.image.width / textureImage.image.height

  if (imageRatio != 1) {
    const repeatX = imageRatio < 1 ? 1 : 1 / imageRatio
    const repeatY = imageRatio > 1 ? 1 : imageRatio
    const offsetX = imageRatio < 1 ? 0 : (1 - 1 / imageRatio) / 2
    const offsetY = imageRatio > 1 ? 0 : 1 - imageRatio

    textureImage.repeat.x = repeatX
    textureImage.repeat.y = repeatY
    textureImage.offset.x = offsetX
    textureImage.offset.y = offsetY
  }

  const smallestSize = Math.min(viewport.width, viewport.height)

  const [intersectRef, inView] = useInView()
  const imageRef = useRef()
  const textRef = useRef()
  const titleRef = useRef()
  useFrame(() => {
    if (imageRef.current != null || imageRef.current != undefined) {
      imageRef.current.material.opacity = THREE.MathUtils.lerp(
        imageRef.current.material.opacity,
        inView || touchDevice ? imageOpacity : imageOpacity / 10,
        inView ? 0.1 : 0.2
      )
      // imageRef.current.position.z = THREE.MathUtils.lerp(imageRef.current.position.z, inView || touchDevice ? (ratio > 1 ? -5 : 0) : -2,  inView ? 0.05 : 0.2 )
    }
    if (titleRef.current != null || titleRef.current != undefined) {
      titleRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        titleRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        inView ? 0.1 : 0.2
      )
    }
    if (textRef.current != null || textRef.current != undefined) {
      textRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        textRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        inView ? 0.01 : 0.2
      )
    }
  })

  return (
    <>
      <Box
        dir={left ? 'row' : 'row-reverse'}
        align="center"
        justify="center"
        width="100%"
        height="auto"
        mt={ratio > 1 ? 1.5 : 0.25}
        mb={ratio > 1 ? 1.5 : 0.25}
        flexWrap="wrap">
        <Box height="0" width="0" centerAnchor>
          <mesh
            position={[
              ratio > 1 ? ((left ? 1 : -1) * (smallestSize * (ratio > 1 ? 0.6 : 0.85))) / 2 : 0,
              ratio > 1 ? 0 : -1 - (smallestSize * (ratio > 1 ? 0.6 : 0.85)) / 2,
              ratio > 1 ? -5 : 0,
            ]}
            renderOrder={15}
            ref={imageRef}>
            <planeBufferGeometry args={[smallestSize * (ratio > 1 ? 0.7 : 0.85), smallestSize * (ratio > 1 ? 0.7 : 0.85)]} />
            <meshBasicMaterial map={textureImage} transparent={imageOpacity < 1} />
          </mesh>
        </Box>

        <Box height="0" width="0" centerAnchor>
          {/* CREDIT */}
          <Text
            position={[
              ratio > 1 ? (left ? -1 : 1) * 0.75 : (left ? -1 : 1) * ((smallestSize * (ratio > 1 ? 0.7 : 0.85)) / 2 - 0.1),
              ratio > 1 ? -((smallestSize * (ratio > 1 ? 0.7 : 0.85)) / 2 + 0.1) : -1 + 0.1,
              ratio > 1 ? -5 : 0,
            ]}
            fontSize={0.175}
            anchorY={ratio > 1 ? 'top' : 'bottom'}
            anchorX={left ? 'left' : 'right'}
            fillOpacity={inView || touchDevice ? 0.5 : 0.1}
            maxWidth={smallestSize * (ratio > 1 ? 0.7 : 0.85)}
            color={foregroundColor}
            visible={imagesCredit != ''}>
            {`Photo: ` + imagesCredit}
          </Text>
        </Box>

        {/* IMAGE COLUMN */}
        <Box dir="column" m={1} mb={1} mt={0} height="auto" minWidth="35%" align={left ? 'flex-end' : 'flex-start'}>
          <Box dir="column" centerAnchor>
            <group>
              <Html zIndexRange={[1, 0]}>
                <div
                  ref={intersectRef}
                  style={{
                    // background: inView? "green" : "red",
                    opacity: 0.5,
                    width: 1,
                    height: smallestSize * 50,
                  }}
                />
              </Html>
            </group>

            <mesh position-y={0} position-z={0}>
              <planeBufferGeometry args={[smallestSize * (ratio > 1 ? 0.6 : 0.85), smallestSize * (ratio > 1 ? 0.6 : 0.85)]} />
              <meshBasicMaterial opacity={imageOpacity} transparent={imageOpacity < 1} visible={false} />
            </mesh>
          </Box>
        </Box>
        {/* TEXT COLUMN */}
        <Box dir="column" m={1} mb={0} mt={ratio > 1 ? 0 : 1} height="auto" minWidth="35%" align={left ? 'flex-start' : 'flex-end'}>
          <Box>
            <Box mb={title != '' ? 0.5 : 0}>
              <group ref={titleRef}>
                <Text
                  bold
                  textAlign="left"
                  anchorX="left"
                  anchorY="top"
                  // fillOpacity={inView || touchDevice ? 1 : 0.1}
                  color={titleColor}
                  fontSize={title != '' ? 0.75 : 0}
                  letterSpacing={-0.05}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {title}
                </Text>
              </group>
            </Box>
            <Box>
              <group ref={textRef}>
                <Text
                  textAlign="left"
                  anchorX="left"
                  // fillOpacity={inView || touchDevice ? 1 : 0.1}
                  fontSize={Math.max(0.5 * 1 - proportionalScale, 0.3)}
                  lineHeight={1.25}
                  letterSpacing={0}
                  color={color}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {text}
                </Text>
              </group>
            </Box>
            <group {...props} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
function TextVideo({ title = '', text = '', videoSrc, videoOpacity = 1, onReflow, left = true, titleColor = 'white', imagesCredit = '', ...props }) {
  const { viewport } = useThree()
  const proportionalScale = Math.min(1, viewport.width / 16)
  const ratio = viewport.width / viewport.height
  const smallestSize = Math.min(viewport.width, viewport.height)

  const [intersectRef, inView] = useInView()
  const [intersectRefFade, inViewFade] = useInView({ threshold: 0.5 })

  const videoRef = useRef()

  const [isLoaded, setLoaded] = useState()

  const imageRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()

  useFrame(() => {
    setLoaded(videoRef.current.readyState == 4)

    if (imageRef.current != null || imageRef.current != undefined) {
      imageRef.current.material.opacity = THREE.MathUtils.lerp(
        imageRef.current.material.opacity,
        inViewFade || touchDevice ? videoOpacity : videoOpacity / 10,
        inViewFade ? 0.1 : 0.2
      )
    }

    if (videoRef.current != null || videoRef.current != undefined) {
      if (inView && isLoaded) {
        videoRef.current.play()
      } else if (isLoaded && videoRef.current.currentTime != 0) {
        videoRef.current.pause()
        videoRef.current.currentTime = 0
      }
    }
    if (titleRef.current != null || titleRef.current != undefined) {
      titleRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        titleRef.current.children[0].fillOpacity,
        inViewFade || touchDevice ? 1 : 0.1,
        inViewFade ? 0.1 : 0.2
      )
    }
    if (textRef.current != null || textRef.current != undefined) {
      textRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        textRef.current.children[0].fillOpacity,
        inViewFade || touchDevice ? 1 : 0.1,
        inViewFade ? 0.01 : 0.2
      )
    }
  })

  return (
    <>
      <Box
        dir={left ? 'row' : 'row-reverse'}
        align="center"
        justify="center"
        width="100%"
        height="auto"
        mt={ratio > 1 ? 1 : 0.25}
        mb={ratio > 1 ? 1 : 0.5}
        flexWrap="wrap">
        {/* VIDEO COLUMN */}
        <Box dir="column" m={1} mt={0} minHeight={viewport.height / 5} height="auto" minWidth="35%" align={left ? 'flex-end' : 'flex-start'}>
          <Box dir="column" centerAnchor>
            <mesh position-y={ratio > 1 ? -0.75 : 0} position-z={ratio > 1 ? -2.5 : 0} ref={imageRef} renderOrder={15}>
              <group>
                <Html center zIndexRange={[1, 0]}>
                  <div style={{ visibility: 'hidden' }}>
                    <video autoPlay muted={true} ref={videoRef} loop={true} width={1}>
                      <source src={videoSrc} type="video/mp4" />
                    </video>
                  </div>
                </Html>
              </group>

              <group>
                <Html center zIndexRange={[1, 0]}>
                  <div
                    ref={intersectRef}
                    style={{
                      // background: inView? "green" : "red",
                      opacity: 0.5,
                      width: 1,
                      height: smallestSize * (ratio > 1 ? 0.5 / 1.77 : 0.85 / 1.77) * 100,
                    }}
                  />
                </Html>
              </group>

              <group>
                <Html zIndexRange={[1, 0]}>
                  <div
                    ref={intersectRefFade}
                    style={{
                      // background: inViewFade? "green" : "red",
                      opacity: 0.5,
                      width: 5,
                      height: smallestSize * 20,
                    }}
                  />
                </Html>
              </group>

              <planeBufferGeometry args={[smallestSize * (ratio > 1 ? 0.6 : 0.85), smallestSize * (ratio > 1 ? 0.6 / 1.77 : 0.85 / 1.77)]} />
              <meshBasicMaterial transparent>
                {videoRef.current != undefined ? <videoTexture attach="map" args={[videoRef.current]} /> : null}
              </meshBasicMaterial>
            </mesh>

            {/* CREDIT */}
            <Text
              position={[
                ((left ? -1 : 1) * (smallestSize * (ratio > 1 ? 0.6 : 0.85))) / 2,
                -(smallestSize * (ratio > 1 ? 0.75 / 1.77 : 0.85 / 1.77)) / 2 - 0.1,
                ratio > 1 ? -2.5 : 0,
              ]}
              fontSize={0.175}
              anchorX={left ? 'left' : 'right'}
              fillOpacity={inViewFade || touchDevice ? 0.5 : 0.1}
              maxWidth={(smallestSize * (ratio > 1 ? 0.6 : 0.85)) / 2}
              color={foregroundColor}
              visible={imagesCredit != ''}>
              {`Video: ` + imagesCredit}
            </Text>
          </Box>
        </Box>

        {/* TEXT COLUMN */}
        <Box dir="column" m={1} mt={0} mb={0} height="auto" minWidth="35%" align={left ? 'flex-start' : 'flex-end'}>
          <Box dir="column">
            <group {...props} />
          </Box>
          <Box dir="column" align={left ? 'flex-start' : 'flex-end'}>
            <Box dir="row" marginBottom={title == '' ? 0 : 0.5}>
              <group ref={titleRef}>
                <Text
                  bold
                  textAlign={left ? 'left' : 'right'}
                  anchorY="top"
                  // fillOpacity={inViewFade || touchDevice ? 1 : 0.1}
                  color={titleColor}
                  fontSize={0.75}
                  letterSpacing={-0.05}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {title}
                </Text>
              </group>
            </Box>
            <Box dir="row">
              <group ref={textRef}>
                <Text
                  textAlign={left ? 'left' : 'right'}
                  // fillOpacity={inViewFade || touchDevice ? 1 : 0.1}
                  fontSize={Math.max(0.5 * 1 - proportionalScale, 0.3)}
                  lineHeight={1.25}
                  letterSpacing={0}
                  color={foregroundColor}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {text}
                </Text>
              </group>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
function TextText({ textFirst = '', textSecond = '', title = '', sizeMultiplier = 1, titleColor = 'white' }) {
  const { viewport } = useThree()

  const proportionalScale = Math.min(1, viewport.width / 16)
  const ratio = viewport.width / viewport.height
  const smallestSize = Math.min(viewport.width, viewport.height)

  const [intersectRef, inView] = useInView({ threshold: 0.2 })
  const textOneRef = useRef()
  const textTwoRef = useRef()
  const titleRef = useRef()

  useFrame(() => {
    if (titleRef.current != null || titleRef.current != undefined) {
      titleRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(titleRef.current.children[0].fillOpacity, inView || touchDevice ? 1 : 0.1, 0.1)
    }
    if (textOneRef.current != null || textOneRef.current != undefined) {
      textOneRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        textOneRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        0.01
      )
    }
    if (textTwoRef.current != null || textTwoRef.current != undefined) {
      textTwoRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        textTwoRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        0.005
      )
    }
  })

  return (
    <>
      <Box
        dir="row"
        align={'flex-start'}
        justify="center"
        width="100%"
        /*minHeight={viewport.height} */
        height="auto" // ?
        mt={ratio > 1 ? 1 : 0.25}
        mb={ratio > 1 ? 1 : 0.25}
        flexWrap="wrap">
        {/* TEXT COLUMN FIRST */}
        <Box
          dir="column"
          margin={1}
          mb={ratio > 1 ? 1 : -0.125}
          height="auto"
          minWidth="35%"
          justifyContent="flex-end"
          align="flex-end"
          marginBottom={1}>
          <Box>
            <group>
              <Html zIndexRange={[1, 0]}>
                <div
                  ref={intersectRef}
                  style={{
                    // background: inView? "green" : "red",
                    opacity: 0.5,
                    width: 1,
                    height: smallestSize * 50,
                  }}
                />
              </Html>
            </group>

            <Box mb={title != '' ? 0.5 : 0}>
              <group ref={titleRef}>
                <Text
                  bold
                  textAlign="left"
                  anchorX="left"
                  anchorY="top"
                  color={titleColor}
                  fontSize={title != '' ? 0.75 : 0}
                  letterSpacing={-0.05}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {title}
                </Text>
              </group>
            </Box>
            <Box>
              <group ref={textOneRef}>
                <Text
                  textAlign="left"
                  anchorX="left"
                  anchorY="top"
                  // fontSize={Math.max(0.5 * 1 - proportionalScale, 0.4) * sizeMultiplier}
                  fontSize={Math.max(0.5 * 1 - proportionalScale, 0.3)}
                  lineHeight={1.25}
                  color={foregroundColor}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {textFirst}
                </Text>
              </group>
            </Box>
          </Box>
        </Box>

        {/* TEXT COLUMN SECOND */}
        <Box dir="column" margin={1} mt={ratio > 1 ? 1 : 0} height="auto" minWidth="35%" justifyContent="flex-end" alignSelf="flex-start">
          <Box>
            <Box mb={title != '' ? 0.5 : 0}>
              <Text
                bold
                textAlign="left"
                anchorX="left"
                anchorY="top"
                color={titleColor}
                fontSize={title != '' ? (ratio > 1 ? 0.75 : 0) : 0}
                visible={false}
                letterSpacing={-0.05}
                maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                {title}
              </Text>
            </Box>
            <Box>
              <group ref={textTwoRef}>
                <Text
                  textAlign="left"
                  anchorX="left"
                  anchorY="top"
                  // fontSize={Math.max(0.5 * 1 - proportionalScale, 0.4) * sizeMultiplier}
                  fontSize={Math.max(0.5 * 1 - proportionalScale, 0.3)}
                  lineHeight={1.25}
                  color={foregroundColor}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {textSecond}
                </Text>
              </group>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
function TextTitle({
  title = '',
  subTitle = '',
  text = '',
  image = '/images/pixel.png',
  imageOpacity = 1,
  onReflow,
  left = true,
  fade = true,
  titleColor = 'white',
  imagesCredit = '',
  ...props
}) {
  const [textureImage, textureAlpha] = useLoader(THREE.TextureLoader, [
    image,
    left ? '/images/diagonal_gradient.jpg' : '/images/diagonal_flip_gradient.jpg',
  ])
  const { viewport } = useThree()

  const ratio = viewport.width / viewport.height
  const smallestSize = Math.min(viewport.width, viewport.height)
  const proportionalScale = Math.min(1, viewport.width / 16)

  const imageRatio = textureImage.image.width / textureImage.image.height

  if (imageRatio != 1) {
    const repeatX = imageRatio < 1 ? 1 : 1 / imageRatio
    const repeatY = imageRatio > 1 ? 1 : imageRatio
    const offsetX = imageRatio < 1 ? 0 : (1 - 1 / imageRatio) / 2
    const offsetY = imageRatio > 1 ? 0 : 1 - imageRatio

    textureImage.repeat.x = repeatX
    textureImage.repeat.y = repeatY
    textureImage.offset.x = offsetX
    textureImage.offset.y = offsetY
  }

  const [intersectRef, inView] = useInView({ threshold: 0.2 })
  const imageRef = useRef()
  const titleRef = useRef()
  const titleSecRef = useRef()
  const textRef = useRef()
  useFrame(() => {
    if (titleRef.current != null || titleRef.current != undefined) {
      titleRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        titleRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        inView ? 0.1 : 0.2
      )
    }

    if (imageRef.current != null || imageRef.current != undefined) {
      imageRef.current.material.opacity = THREE.MathUtils.lerp(
        imageRef.current.material.opacity,
        inView || touchDevice ? imageOpacity : imageOpacity / 5,
        inView ? 0.1 : 0.2
      )
      imageRef.current.position.z = THREE.MathUtils.lerp(imageRef.current.position.z, inView || touchDevice ? -6 : -4, 0.1)
    }
    if (titleSecRef.current != null || titleSecRef.current != undefined) {
      titleSecRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        titleRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        inView ? 0.1 : 0.2
      )
    }
    if (textRef.current != null || textRef.current != undefined) {
      textRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        textRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        inView ? 0.01 : 0.2
      )
    }
  })

  return (
    <>
      <Box
        dir={left ? 'row' : 'row-reverse'}
        justify="center"
        width="100%"
        height="auto"
        marginTop={4}
        marginBottom={ratio > 1 ? 1 : 0.25}
        flexWrap="wrap"
        alignItems="flex-start">
        <Box dir="column" height="0" width="0">
          <mesh position={[ratio > 1 ? (left ? 2 : -2) : 0, ratio > 1 ? -6.5 : -2, -5]} renderOrder={15} ref={imageRef}>
            <planeBufferGeometry args={[smallestSize * (ratio > 1 ? 1.2 : 1.5), smallestSize * (ratio > 1 ? 1.2 : 1.5)]} />
            <meshBasicMaterial map={textureImage} alphaMap={fade ? textureAlpha : null} opacity={imageOpacity} transparent={true} />
          </mesh>
        </Box>

        {/* CREDIT */}
        <Text
          position={[left ? 0 : viewport.width, (smallestSize * (ratio > 1 ? 1 : 1.5)) / 2 + (ratio > 1 ? -5 : -4) + 0.1, -6]}
          fontSize={0.2}
          anchorX={left ? 'left' : 'right'}
          anchorY="bottom"
          fillOpacity={0.5}
          maxWidth={smallestSize}
          color={foregroundColor}
          visible={imagesCredit != ''}>
          {`Photo: ` + imagesCredit}
        </Text>

        {/* TITLE COLUMN */}
        <Box
          dir="column"
          m={1}
          mt={0}
          minHeight={viewport.height / 5}
          height="auto"
          width={ratio > 1 ? undefined : '100%'}
          minWidth="35%"
          align={ratio > 1 ? (left ? 'flex-end' : 'flex-start') : 'center'}>
          <Box>
            <group>
              <Html zIndexRange={[1, 0]}>
                <div
                  ref={intersectRef}
                  style={{
                    // background: inView? "green" : "red",
                    opacity: 0.5,
                    width: 1,
                    height: smallestSize * 75,
                  }}
                />
              </Html>
            </group>

            <group ref={titleRef}>
              <Text
                textAlign={ratio > 1 ? (left ? 'right' : 'left') : 'center'}
                // anchorX={ratio > 1 ? 'left' : 'center'}
                anchorY="top"
                color={foregroundColor}
                fontSize={Math.max(2 * proportionalScale, 1.25)}
                letterSpacing={-0.05}
                lineHeight={0.9}
                maxWidth={smallestSize * (ratio > 1 ? 0.55 : 0.85)}>
                {title}
              </Text>
            </group>
          </Box>
        </Box>

        {/* TEXT COLUMN */}
        <Box dir="column" m={1} mt={0} height={'auto'} minWidth="35%" align={left ? 'flex-start' : 'flex-end'}>
          <Box>
            <Box mb={subTitle != '' ? 0.5 : 0}>
              <group ref={titleSecRef}>
                <Text
                  bold
                  textAlign="left"
                  anchorX="left"
                  anchorY="top"
                  // fillOpacity={inView || touchDevice ? 1 : 0.1}
                  color={titleColor}
                  fontSize={subTitle != '' ? 0.75 : 0}
                  letterSpacing={-0.05}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {subTitle}
                </Text>
              </group>
            </Box>
            <Box>
              <group ref={textRef}>
                <Text
                  textAlign="left"
                  anchorX="left"
                  // fillOpacity={inView || touchDevice ? 1 : 0.1}
                  fontSize={Math.max(0.5 * 1 - proportionalScale, 0.3)}
                  lineHeight={1.25}
                  letterSpacing={0}
                  color={foregroundColor}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {text}
                </Text>
              </group>
            </Box>
            <group {...props} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
function TextModel({
  title = '',
  text,
  model,
  modelScale = 10,
  modelOpacity = 1,
  onReflow,
  left = false,
  fixed = true,
  doubleSide,
  titleColor = 'white',
  ...props
}) {
  const { viewport } = useThree()

  const proportionalScale = Math.min(1, viewport.width / 16)

  const smallestSize = Math.min(viewport.width, viewport.height)
  const ratio = viewport.width / viewport.height

  const [intersectRef, inView] = useInView()
  const modelRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()
  useFrame(() => {
    if (modelRef.current != null || modelRef.current != undefined) {
      modelRef.current.position.z = THREE.MathUtils.lerp(modelRef.current.position.z, inView ? (ratio > 1 ? -5 : 0) : 20, 0.1)
      modelRef.current.rotation.y = THREE.MathUtils.lerp(modelRef.current.rotation.y, inView ? 0 : (Math.PI / 4) * (left ? -1 : 1), 0.01)
    }
    if (titleRef.current != null || titleRef.current != undefined) {
      titleRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        titleRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        inView ? 0.1 : 0.2
      )
    }
    if (textRef.current != null || textRef.current != undefined) {
      textRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        textRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        inView ? 0.01 : 0.2
      )
    }
  })

  return (
    <>
      <Box
        dir={left ? 'row' : 'row-reverse'}
        align="center"
        justify="center"
        width="100%"
        height="auto"
        marginTop={0}
        marginBottom={ratio > 1 ? 1 : 0.25}
        flexWrap="wrap">
        <Box height="0" width="0" centerAnchor>
          <mesh
            position={[
              ratio > 1 ? ((left ? 1 : -1) * (smallestSize * (ratio > 1 ? 0.6 : 0.85))) / 2 : 0,
              ratio > 1 ? 0 : -1 - (smallestSize * (ratio > 1 ? 0.6 : 0.85)) / 2,
              ratio > 1 ? -5 : 0,
            ]}
            ref={modelRef}>
            <ModelSimple
              steadyRot={true}
              steadyPos={fixed}
              model={model}
              hullVisible={false}
              scale={modelScale * proportionalScale}
              bgColor={backgroundColor}
              fgColor={foregroundColor}
              doubleSide={doubleSide}
              light
            />
          </mesh>
        </Box>

        {/* MODEL COLUMN */}
        <Box dir="column" m={1} mt={0} minHeight={viewport.height / 5} height="auto" minWidth="35%" align={left ? 'flex-end' : 'flex-start'}>
          <Box dir="column" centerAnchor>
            <group>
              <Html zIndexRange={[1, 0]}>
                <div
                  ref={intersectRef}
                  style={{
                    // background: inView? "green" : "red",
                    opacity: 0.5,
                    width: 1,
                    height: smallestSize * 50,
                  }}
                />
              </Html>
            </group>

            <planeBufferGeometry args={[smallestSize * (ratio > 1 ? 0.6 : 0.85), smallestSize * (ratio > 1 ? 0.6 : 0.85)]} />
            <Box dir="row" centerAnchor width="0" height="0"></Box>
          </Box>
        </Box>

        {/* TEXT COLUMN */}
        <Box dir="column" m={1} mt={0} height="auto" minWidth="35%" align={left ? 'flex-start' : 'flex-end'}>
          <Box>
            <Box mb={title != '' ? 0.5 : 0}>
              <group ref={titleRef}>
                <Text
                  bold
                  textAlign="left"
                  anchorX="left"
                  anchorY="top"
                  color={titleColor}
                  fontSize={title != '' ? 0.75 : 0}
                  letterSpacing={-0.05}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {title}
                </Text>
              </group>
            </Box>
            <Box>
              <group ref={textRef}>
                <Text
                  textAlign="left"
                  anchorX="left"
                  fontSize={Math.max(0.5 * 1 - proportionalScale, 0.3)}
                  lineHeight={1.25}
                  letterSpacing={0}
                  color={foregroundColor}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  {text}
                </Text>
              </group>
            </Box>
            <group {...props} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
function TextBook({
  title = '',
  text,
  image,
  modelScale = 6,
  modelOpacity = 1,
  onReflow,
  left = false,
  fixed = true,
  doubleSide,
  titleColor = 'white',
  ...props
}) {
  const { viewport } = useThree()
  const group = useRef()

  let randomOffset = Math.random() * 5

  const proportionalScale = Math.min(1, viewport.width / 16)

  const smallestSize = Math.min(viewport.width, viewport.height)
  const ratio = viewport.width / viewport.height

  const textureImage = useLoader(THREE.TextureLoader, image)

  useFrame(({ clock }) => {
    if (group.current != null || group.current != undefined) {
      const t = (1 + Math.sin(clock.getElapsedTime() * 0.2)) / 2
      group.current.position.y = ratio > 1 ? Math.sin(((t + randomOffset) * 10) / 5) : -3
      group.current.rotation.x = Math.sin(((t + randomOffset) * 10) / 3) / 10
    }
  })

  return (
    <>
      <Box
        dir={left ? 'row' : 'row-reverse'}
        align="center"
        justify="center"
        width="100%"
        height="auto"
        marginTop={1}
        marginBottom={1}
        flexWrap="wrap">
        <Box height="0" width="0" centerAnchor>
          <group
            ref={group}
            position={[ratio > 1 ? (left ? 8 : -8) : 0, 0, 0]}
            scale={[modelScale / (ratio > 1 ? 1 : 1.5), modelScale / (ratio > 1 ? 1 : 1.5), modelScale / (ratio > 1 ? 1 : 1.5)]}
            rotation={[0, (Math.PI / 4) * (left ? 1 : -1), (Math.PI / 6) * (left ? 1 : -1)]}>
            <BoxGeo args={[1, 1.65, 0.25]} renderOrder={1}>
              <meshPhongMaterial
                shininess={100}
                attach="material"
                specular="blue"
                color="gray"
                opacity={0.2}
                transparent={true}
                blendEquation={THREE.MultiplyBlending}
              />{' '}
            </BoxGeo>
            <mesh position-z={-0.25 / 2}>
              <planeBufferGeometry args={[1, 1.65]} />
              <meshBasicMaterial
                attach="material"
                map={textureImage}
                depthFunc={THREE.AlwaysDepth}
                opacity={0.75}
                transparent={true}
                renderOrder={1}
              />
            </mesh>
          </group>
        </Box>

        {/* MODEL COLUMN */}
        <Box dir="column" m={1} mt={0} minHeight={viewport.height / 5} height="auto" minWidth="35%" align={left ? 'flex-end' : 'flex-start'}>
          <Box dir="column" centerAnchor>
            <planeBufferGeometry args={[smallestSize * (ratio > 1 ? 0.6 : 0.85), smallestSize * (ratio > 1 ? 0.6 : 0.85)]} />
            <Box dir="row" centerAnchor width="0" height="0"></Box>
          </Box>
        </Box>

        {/* TEXT COLUMN */}
        <Box dir="column" m={1} mt={0} height="auto" minWidth="35%" align={left ? 'flex-start' : 'flex-end'}>
          <Box mb={0.5}>
            <Text
              bold
              textAlign={left ? 'left' : 'right'}
              color={titleColor}
              fontSize={title != '' ? 0.75 : 0}
              letterSpacing={-0.05}
              maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
              {title}
            </Text>
          </Box>
          <Box>
            <Text
              textAlign={left ? 'left' : 'right'}
              fontSize={Math.max(0.5 * 1 - proportionalScale, 0.3)}
              lineHeight={1.25}
              letterSpacing={0}
              color={foregroundColor}
              maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
              {text}
            </Text>
          </Box>
          <Box>
            <Box centerAnchor mt={0.2}>
              <group {...props} />
              <BoxGeo args={[1.9, 1]} visible={false} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
function Credits({ textFirst = '', titleColor = 'white' }) {
  const { viewport } = useThree()

  const proportionalScale = Math.min(1, viewport.width / 16)
  const ratio = viewport.width / viewport.height
  const smallestSize = Math.min(viewport.width, viewport.height)

  const [intersectRef, inView] = useInView({ threshold: 0.2 })
  const textOneRef = useRef()
  const textTwoRef = useRef()
  const titleRef = useRef()

  useFrame(() => {
    if (titleRef.current != null || titleRef.current != undefined) {
      titleRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(titleRef.current.children[0].fillOpacity, inView || touchDevice ? 1 : 0.1, 0.1)
    }
    if (textOneRef.current != null || textOneRef.current != undefined) {
      textOneRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        textOneRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        0.01
      )
    }
    if (textTwoRef.current != null || textTwoRef.current != undefined) {
      textTwoRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
        textTwoRef.current.children[0].fillOpacity,
        inView || touchDevice ? 1 : 0.1,
        0.005
      )
    }
  })

  return (
    <>
      <Box
        dir="row"
        align={'flex-start'}
        justify="center"
        width="100%"
        height="auto" // ?
        mt={ratio > 1 ? 1 : 0.25}
        mb={ratio > 1 ? 1 : 0.25}
        flexWrap="wrap">
        <Box>
          <group>
            <Html zIndexRange={[1, 0]}>
              <div
                ref={intersectRef}
                style={{
                  // background: inView? "green" : "red",
                  opacity: 0.5,
                  width: 1,
                  height: smallestSize * 50,
                }}
              />
            </Html>
          </group>

          <Box mb={0.5} justifyContent="center" alignItems="center" width="100%">
            <Box>
              <group ref={titleRef}>
                <Text
                  bold
                  textAlign="center"
                  // anchorX="center"
                  anchorY="top"
                  color={titleColor}
                  fontSize={0.75}
                  letterSpacing={-0.05}
                  // maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}
                >
                  {'Credits'}
                </Text>
              </group>
            </Box>
          </Box>
          <Box>
            <group ref={textOneRef}>
              <Text
                textAlign="center"
                // anchorX="center"
                anchorY="top"
                // fontSize={Math.max(0.5 * 1 - proportionalScale, 0.4) * sizeMultiplier}
                fontSize={Math.max(0.5 * 1 - proportionalScale, 0.3)}
                lineHeight={1.25}
                color={foregroundColor}
                maxWidth={ratio < 1 ? smallestSize * 0.6 : undefined}>
                {textFirst}
              </Text>
            </group>
          </Box>
        </Box>
      </Box>
    </>
  )
}

// columns
function Timeline({ title = '', text = '', image, strip, icons = '/images/pixel.png', item, index = 0, imageOpacity = 1 }) {
  const [textureImage, textureImageBackground, textureBackgroundAlpha] = useLoader(THREE.TextureLoader, [
    image,
    strip,
    '/images/background_gradient.jpg',
  ])

  const [intersectRef, inView] = useInView({ threshold: 0.2 })
  const imageRef = useRef()
  const backgroundRef = useRef()
  const textRef = useRef()
  const titleRef = useRef()

  useFrame(({ clock }) => {
    if (!touchDevice) {
      if (textureImageBackground != null) {
        backgroundRef.current.material.repeat = [1, 0]
        backgroundRef.current.material.offset = [0, 0.5 + Math.sin(clock.getElapsedTime() / 10) / 20]
        backgroundRef.current.material.opacity = THREE.MathUtils.lerp(backgroundRef.current.material.opacity, inView ? 1 : 0, 0.05)
        backgroundRef.current.position.x = THREE.MathUtils.lerp(backgroundRef.current.position.x, inView ? imageSize / 2 : -20, 0.05)
      }
      if (imageRef.current != null || imageRef.current != undefined) {
        imageRef.current.material.opacity = THREE.MathUtils.lerp(
          imageRef.current.material.opacity,
          inView || touchDevice ? imageOpacity : 0,
          inView ? Math.max(0.05 - 0.01 * index * index, 0.02) : 0.2
        )
      }
      if (titleRef.current != null || titleRef.current != undefined) {
        titleRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
          titleRef.current.children[0].fillOpacity,
          inView || touchDevice ? 1 : 0.1,
          inView ? Math.max(0.01 - 0.01 * index, 0.005) : 0.2
        )
      }
      if (textRef.current != null || textRef.current != undefined) {
        textRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
          textRef.current.children[0].fillOpacity,
          inView || touchDevice ? 1 : 0.1,
          inView ? Math.max(0.05 - 0.01 * index, 0.01) : 0.2
        )
      }
    }
  })

  const texturesIcon = useLoader(THREE.TextureLoader, icons)
  const { viewport } = useThree()
  const imageSize = 3
  const iconWidth = 1

  const ratio = viewport.width / viewport.height
  const proportionalScale = Math.min(1, viewport.width / 16)

  return (
    <>
      <Box dir="row" m={0.5} mt={0} mb={1} width={imageSize}>
        <Box dir="column">
          <Box dir="column" width="auto" mb={0.25}>
            <group ref={titleRef}>
              <Text
                fontSize={Math.max(1.25 * 1 - proportionalScale, 0.6)}
                letterSpacing={-0.05}
                lineHeight={0.95}
                color={'white'}
                transparent={true}
                maxWidth={imageSize * 1.25}>
                {title}
              </Text>
            </group>
          </Box>

          <Box height="0">
            <group>
              <Html zIndexRange={[1, 0]}>
                <div
                  ref={intersectRef}
                  style={{
                    // background: inView? "green" : "red",
                    opacity: 0.5,
                    width: 1,
                    height: 800,
                  }}
                />
              </Html>
            </group>
          </Box>

          <Box rotation-x={-0.0}>
            <mesh position={[imageSize / 2, -imageSize / 2, 0]} ref={imageRef}>
              <planeBufferGeometry args={[imageSize, imageSize]} />
              <meshBasicMaterial map={textureImage} opacity={1} transparent />
            </mesh>
          </Box>

          <mesh position={[imageSize / 2, -imageSize / 2, -2]} rotation-z={Math.PI / 2} ref={backgroundRef}>
            <planeBufferGeometry args={[imageSize * 1.3, imageSize * 8]} />
            <doubleChannelMaterial
              repeat={[1, 0]}
              offset={[0, 0.5]}
              opacity={1}
              attach="material"
              map={textureImageBackground}
              alphaMap={textureBackgroundAlpha}
              transparent={true}
            />
          </mesh>

          <Box dir="column" justifyContent="space-between" height={ratio > 1 ? 6.5 : null} mt={0.2}>
            <Box mb={0.5}>
              <group ref={textRef}>
                <Text
                  margin={0}
                  textAlign="left"
                  anchorX="left"
                  anchorY="top"
                  fontSize={Math.max(0.4 * 1 - proportionalScale, 0.25)}
                  lineHeight={1.25}
                  color={foregroundColor}
                  maxWidth={imageSize}>
                  {text}
                </Text>
              </group>
            </Box>

            <Box>
              {texturesIcon.map(
                (icons, index, imageRatio) => (
                  (imageRatio = icons.image.height / icons.image.width),
                  (
                    <Box key={index} width={iconWidth} height={iconWidth * imageRatio} marginLeft={0.5} mb={0.25}>
                      {(width, height) => (
                        <mesh>
                          <planeBufferGeometry args={[width, height]} />
                          <meshBasicMaterial map={icons} toneMapped={false} opacity={0.5} transparent />
                        </mesh>
                      )}
                    </Box>
                  )
                )
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
function ColumnItems({
  title = '',
  text = '',
  image = '/images/pixel.png',
  imageSize = 5,
  icons = '/images/pixel.png',
  gradient = true,
  imageOpacity = 0.3,
  textInside = true,
  titleColor = 'white',
  index = 0,
  centered = false,
}) {
  const [textureImage, textureAlpha] = useLoader(THREE.TextureLoader, [image, '/images/linear_inverted_gradient.jpg'])
  const texturesIcon = useLoader(THREE.TextureLoader, icons)
  const { viewport } = useThree()
  const iconWidth = 100

  const proportionalScale = Math.min(1, viewport.width / 16)

  const imageRatio = textureImage.image.width / textureImage.image.height

  if (imageRatio != 1) {
    const repeatX = imageRatio < 1 ? 1 : 1 / imageRatio
    const repeatY = imageRatio > 1 ? 1 : imageRatio
    const offsetX = imageRatio < 1 ? 0 : (1 - 1 / imageRatio) / 2
    const offsetY = imageRatio > 1 ? 0 : 1 - imageRatio

    textureImage.repeat.x = repeatX
    textureImage.repeat.y = repeatY
    textureImage.offset.x = offsetX
    textureImage.offset.y = offsetY
  }

  const [intersectRef, inView] = useInView({ threshold: 0.4 })
  const imageRef = useRef()
  const textRef = useRef()
  const titleRef = useRef()
  useFrame(() => {
    if (!touchDevice) {
      if (imageRef.current != null || imageRef.current != undefined) {
        imageRef.current.material.opacity = THREE.MathUtils.lerp(
          imageRef.current.material.opacity,
          inView || touchDevice ? imageOpacity : imageOpacity / 10,
          inView ? Math.min(0.02 * (index + 1)) : 0.2
        )
      }
      if (titleRef.current != null || titleRef.current != undefined) {
        titleRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
          titleRef.current.children[0].fillOpacity,
          inView || touchDevice ? 1 : 0.1,
          inView ? Math.min(0.02 * (index + 1)) : 0.2
        )
      }
      if (textRef.current != null || textRef.current != undefined) {
        textRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(
          textRef.current.children[0].fillOpacity,
          inView || touchDevice ? 1 : 0.1,
          inView ? Math.min(0.02 * (index + 1)) : 0.2
        )
      }
    }
  })

  return (
    <>
      <Box dir="row" m={0.5} mt={textInside ? 0 : 0.75} mb={1} minWidth={imageSize}>
        <Box dir="column" grow={0}>
          <Box dir="column" width="auto" centerAnchor={textInside || centered ? true : false} m={0.25} mb={0} mt={0}>
            <group ref={titleRef}>
              <Text
                bold
                textAlign={textInside || centered ? 'center' : 'left'}
                anchorX={textInside || centered ? 'center' : 'left'}
                position-y={textInside ? -0.5 - imageSize / 2 : -0.25}
                color={titleColor}
                fontSize={Math.max(1.25 * 1 - proportionalScale, 0.6)}
                letterSpacing={-0.05}
                maxWidth={imageSize}>
                {title}
              </Text>
            </group>
          </Box>

          <Box height="0">
            <group>
              <Html zIndexRange={[1, 0]}>
                <div
                  ref={intersectRef}
                  style={{
                    // background: inView? "green" : "red",
                    opacity: 0.5,
                    width: 1,
                    height: 800,
                  }}
                />
              </Html>
            </group>
          </Box>

          <Box dir="column" width={imageSize} height={imageSize} centerAnchor marginTop={0.5}>
            {(width, height) => (
              <mesh
                // ref={(element) => item.current.push(element)}
                renderOrder={15}
                ref={imageRef}>
                <planeBufferGeometry args={[width, height]} />
                <meshBasicMaterial map={textureImage} alphaMap={gradient ? textureAlpha : null} opacity={imageOpacity} transparent />
              </mesh>
            )}
          </Box>

          <Box dir="column" justifyContent="space-between" height={5} m={0.25}>
            <Box>
              <group ref={textRef}>
                <Text
                  margin={0}
                  textAlign={centered ? 'center' : 'left'}
                  anchorX="left"
                  anchorY="top"
                  position-y={textInside ? 0 : gradient ? 0.5 : 0}
                  fontSize={Math.max(0.5 * 1 - proportionalScale, 0.325)}
                  lineHeight={1.25}
                  color={foregroundColor}
                  maxWidth={imageSize * 0.9}>
                  {text}
                </Text>
              </group>
            </Box>

            <Box margin={0}>
              {icons != '/images/pixel.png'
                ? texturesIcon.map(
                    (icons, index, imageRatio) => (
                      (imageRatio = icons.image.height / icons.image.width),
                      (
                        <Box key={index} width={iconWidth + 'vw'} height={iconWidth * imageRatio + 'vh'} marginLeft={0.5} marginBottom={0.0}>
                          {(width, height) => (
                            <mesh>
                              <planeBufferGeometry args={[width, height]} />
                              <meshBasicMaterial map={icons} transparent opacity={0.5} />
                            </mesh>
                          )}
                        </Box>
                      )
                    )
                  )
                : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

// mosaic
function ImageMosaic({ images, opacity = 1, imagesCredit = '', creditOffset = 1 }) {
  const { viewport } = useThree()
  const imageWidth = viewport.width > viewport.height ? (viewport.width / 4) * 100 : (viewport.width / 2) * 100

  return (
    <>
      {/* MOSAIC */}
      <Box dir="row" width="100%" height="auto" align="flex-start" justify="center" marginTop={0.5} marginBottom={0.5} wrap="wrap">
        {images.map((imageUrl, index) => (
          <ImageMosaicItem
            image={imageUrl}
            key={index}
            imageCredit={imagesCredit[index]}
            creditOffset={creditOffset}
            imageWidth={imageWidth}
            index={index}
            opacity={opacity}
          />
        ))}
      </Box>
    </>
  )
}
function ImageMosaicItem({ image, opacity = 1, imageCredit = '', creditOffset = 1, index = 0, imageWidth = 10 }) {
  const texturesImage = useLoader(THREE.TextureLoader, image)

  const [intersectRef, inView] = useInView()
  const imageRef = useRef()
  const groupRef = useRef()

  // const position = touchDevice ? 0 : Math.random(0, 1) * -2
  const position = -1

  const imageRatio = texturesImage.image.width / texturesImage.image.height
  texturesImage.repeat.x = imageRatio < 1 ? 1 : 1 / imageRatio
  texturesImage.repeat.y = imageRatio > 1 ? 1 : imageRatio
  texturesImage.offset.x = imageRatio < 1 ? 0 : (1 - 1 / imageRatio) / 2
  texturesImage.offset.y = imageRatio > 1 ? 0 : 1 - imageRatio

  useFrame(() => {
    if (!touchDevice && (imageRef.current != null || imageRef.current != undefined)) {
      imageRef.current.material.opacity = THREE.MathUtils.lerp(
        imageRef.current.material.opacity,
        inView ? opacity : opacity / 10,
        inView ? Math.min(0.02 * (index + 1)) : 0.1
      )
      groupRef.current.position.z = THREE.MathUtils.lerp(groupRef.current.position.z, inView ? position : 4 + index * 3, Math.min(0.02 * (index + 1)))
    }
  })

  return (
    <>
      {/* MOSAIC ITEM */}
      <Box width={imageWidth * 0.9 + 'vw'} height={imageWidth * 0.9 + 'vh'} centerAnchor mb={0.5}>
        {(width, height) => (
          <group>
            <group>
              <Html zIndexRange={[1, 0]}>
                <div
                  ref={intersectRef}
                  style={{
                    // background: inView? "green" : "red",
                    opacity: 0.5,
                    width: 1,
                    height: 200,
                  }}
                />
              </Html>
            </group>

            <group ref={groupRef}>
              <mesh renderOrder={15} ref={imageRef}>
                <planeBufferGeometry args={[width * 0.9, height * 0.9]} />
                <meshBasicMaterial map={texturesImage} opacity={opacity} transparent={opacity < 1} />
              </mesh>

              <Text
                position={[(-width / 2) * 0.88, (-height / 2) * 0.92 * creditOffset, 0.1]}
                fontSize={0.175}
                fillOpacity={0.5}
                maxWidth={width * 0.8}
                color={foregroundColor}
                visible={imageCredit != '' && imageCredit != undefined && imageCredit != null}>
                {`Photo: ` + imageCredit}
              </Text>
            </group>
          </group>
        )}
      </Box>
    </>
  )
}

// image
function ImageFull({ image, imagesCaptions = '', imagesCredit = '', onReflow }) {
  const [textureImage, textureImageBackground, textureAlpha, textureBackgroundAlpha] = useLoader(THREE.TextureLoader, [
    image,
    image,
    '/images/linear_gradient.jpg',
    '/images/background_gradient.jpg',
  ])

  const { viewport, size } = useThree()
  const backgroundRef = useRef()

  const proportionalScale = Math.min(1, viewport.width / 16)

  let imageSize

  let imageRatio = [
    textureImage.image.width / Math.max(textureImage.image.width, textureImage.image.height),
    textureImage.image.height / Math.max(textureImage.image.width, textureImage.image.height),
  ]

  const ratio = viewport.width / viewport.height
  const imageScale = ratio > 1 ? 0.85 : 1

  const pageLerp = useRef(state.top / size.height)
  let backgroundOffset
  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.1))
    backgroundOffset = page * viewport.height

    if (backgroundRef.current != null && backgroundRef.current != undefined) {
      backgroundRef.current.material.repeat = [1, 0]
      backgroundRef.current.material.offset = [0, Math.sin(backgroundOffset / size.height)]
    }
  })

  // landscape
  if (viewport.width >= viewport.height) {
    imageSize =
      textureImage.image.width > textureImage.image.height
        ? [viewport.width * imageRatio[0], viewport.width * imageRatio[1]]
        : [viewport.height * imageRatio[0], viewport.height * imageRatio[1]]
  }
  // portrait
  else {
    imageSize =
      textureImage.image.width >= textureImage.image.height
        ? [viewport.width * imageRatio[0], viewport.width * imageRatio[1]]
        : [viewport.height * imageRatio[0], viewport.height * imageRatio[1]]
  }

  return (
    <>
      {/* IMAGE */}
      <Box
        dir="row"
        align={'flex-end'}
        height="auto"
        width="100%"
        margin={0}
        justifyContent="center"
        mt={ratio > 1 ? 2 : 0.5}
        mb={ratio > 1 ? 2 : imagesCaptions == '' ? 0.5 : 2}>
        <Box width={imageSize[0]} height={imageSize[1] * imageScale}>
          <group>
            <mesh position={[viewport.width / 2, (-imageSize[1] * imageScale) / 2, -20]} ref={backgroundRef} renderOrder={1}>
              <planeBufferGeometry args={[viewport.width * 2, viewport.height * (ratio > 1 ? 3 : 1.5)]} />
              <doubleChannelMaterial
                opacity={0.5}
                attach="material"
                map={textureImageBackground}
                alphaMap={textureBackgroundAlpha}
                transparent={true}
              />
            </mesh>
          </group>
          <mesh position={[viewport.width / 2, -(imageSize[1] * imageScale) / 2, ratio > 1 ? -5 : 0]}>
            <planeBufferGeometry args={[imageSize[0] * imageScale, imageSize[1] * imageScale]} />
            <meshBasicMaterial map={textureImage} />
          </mesh>

          {/* CAPTION */}
          <mesh
            position={[viewport.width / 2, -imageSize[1] * imageScale + (ratio > 1 ? 2.5 : -2.5), ratio > 1 ? -5 : 0]}
            rotation={[0, 0, ratio > 1 ? 0 : Math.PI]}
            renderOrder={15}>
            <planeBufferGeometry args={[imageSize[0] * imageScale, 5]} />
            <meshBasicMaterial alphaMap={textureAlpha} color={'black'} opacity={1} transparent visible={!(imagesCaptions == '')} />
          </mesh>
          <Text
            textAlign={'center'}
            anchorY={ratio > 1 ? 'middle' : 'top'}
            anchorX="center"
            position={[viewport.width / 2, -imageSize[1] * imageScale + (1 * ratio > 1 ? 1 : -0.25), ratio > 1 ? -5 : 0]}
            fontSize={Math.max(0.55 * 1 - proportionalScale, 0.35)}
            maxWidth={imageSize[0] * imageScale * 0.9}
            color={'white'}>
            {imagesCaptions}
          </Text>

          {/* CREDIT */}
          <Text
            position={[viewport.width / 2 - (imageSize[0] * imageScale) / 2 + 0.1, 0.3, ratio > 1 ? -5 : 0]}
            fontSize={0.175}
            fillOpacity={0.5}
            maxWidth={imageSize[0] * imageScale}
            color={foregroundColor}
            visible={imagesCredit != ''}>
            {`Photo: ` + imagesCredit}
          </Text>
        </Box>
      </Box>
    </>
  )
}
function ImageFullHover({ image1, image2, imagesCaptions = '', onReflow }) {
  const [
    textureImage1,
    textureImageBackground,
    textureImage2,
    textureAlpha,
    textureBackgroundAlpha,
    textureReveal,
    textureRevealAlpha,
  ] = useLoader(THREE.TextureLoader, [
    image1,
    image1,
    image2,
    '/images/linear_gradient.jpg',
    '/images/background_gradient.jpg',
    '/images/reveal.jpg',
    '/images/reveal_alpha.jpg',
  ])

  const [hovered, setHover] = useState(false)
  const [intersectRef, inView] = useInView({
    threshold: 0.6,
  })

  const { viewport, size } = useThree()
  const backgroundRef = useRef()
  const bar = useRef()
  const [reveal, setReveal] = useState()

  const proportionalScale = Math.min(1, viewport.width / 16)

  let imageRatio = [
    textureImage1.image.width / Math.max(textureImage1.image.width, textureImage1.image.height),
    textureImage1.image.height / Math.max(textureImage1.image.width, textureImage1.image.height),
  ]
  let imageSize

  const ratio = viewport.width / viewport.height
  const imageScale = ratio > 1 ? 0.85 : 1

  const pageLerp = useRef(state.top / size.height)
  let backgroundOffset

  useFrame(({ clock }) => {
    const t = Math.sin(clock.getElapsedTime() * 2) / 5
    const s = Math.sin(clock.getElapsedTime())

    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.1))
    backgroundOffset = page * viewport.height

    if (backgroundRef.current != null && backgroundRef.current != undefined) {
      backgroundRef.current.material.repeat = [1, 0]
      backgroundRef.current.material.offset = [0, Math.sin(backgroundOffset / size.height)]
    }

    if (bar.current != null && bar.current != undefined) {
      if (touchDevice) {
        bar.current.position.x = inView ? (s * viewport.width) / 2 : 1
        setReveal(inView ? -s * 0.5 : -1)
      } else {
        bar.current.position.x = (state.mouse[0] * viewport.width) / 2 + (hovered ? 0 : t)
        setReveal(inView ? -state.mouse[0] * (ratio > 1 ? 0.685 : 1) : -1)
      }
    }
  })

  // landscape
  if (viewport.width >= viewport.height) {
    imageSize =
      textureImage1.image.width > textureImage1.image.height
        ? [viewport.width * imageRatio[0], viewport.width * imageRatio[1]]
        : [viewport.height * imageRatio[0], viewport.height * imageRatio[1]]
  }
  // portrait
  else {
    imageSize =
      textureImage1.image.width >= textureImage1.image.height
        ? [viewport.width * imageRatio[0], viewport.width * imageRatio[1]]
        : [viewport.height * imageRatio[0], viewport.height * imageRatio[1]]
  }

  return (
    <>
      {/* IMAGE */}
      <Box
        dir="row"
        align={'flex-end'}
        height="auto"
        width="100%"
        margin={0}
        justifyContent="center"
        mt={ratio > 1 ? 2 : 0.5}
        mb={ratio > 1 ? 2 : imagesCaptions == '' ? 0.5 : 2}>
        {/* scan bar */}
        <group ref={bar}>
          <mesh position={[viewport.width / 2, (-imageSize[1] * imageScale) / 2, 0]} renderOrder={1}>
            <planeBufferGeometry args={[hovered ? 0.05 : 0.085, imageSize[1] * imageScale * (touchDevice ? 1.5 : 1)]} />
            <meshBasicMaterial color={'white'} alphaMap={textureRevealAlpha} transparent opacity={hovered ? 0.25 : 0.75} visible={inView} />
          </mesh>
        </group>

        <Box width={imageSize[0]} height={imageSize[1] * imageScale}>
          <group>
            <mesh position={[viewport.width / 2, (-imageSize[1] * imageScale) / 2, -20]} ref={backgroundRef} renderOrder={1}>
              <planeBufferGeometry args={[viewport.width * 2, viewport.height * (ratio > 1 ? 3 : 1.5)]} />
              <doubleChannelMaterial
                opacity={0.4}
                attach="material"
                map={textureImageBackground}
                alphaMap={textureBackgroundAlpha}
                transparent={true}
              />
            </mesh>
          </group>

          <mesh
            onPointerOut={() => setHover(false)}
            onPointerOver={() => setHover(true)}
            position={[viewport.width / 2, (-imageSize[1] * imageScale) / 2, ratio > 1 ? -5 : 0]}>
            <planeBufferGeometry args={[imageSize[0] * imageScale, imageSize[1] * imageScale]} />
            <meshBasicMaterial map={textureImage1} transparent={true} />
          </mesh>
          <mesh position={[viewport.width / 2, (-imageSize[1] * imageScale) / 2, ratio > 1 ? -5 : 0]}>
            <planeBufferGeometry args={[imageSize[0] * imageScale, imageSize[1] * imageScale]} />
            <doubleChannelMaterialMask
              offset={[reveal, 0]}
              opacity={1}
              attach="material"
              map={textureImage2}
              alphaMap={textureReveal}
              transparent={true}
            />
          </mesh>

          {/* CAPTION */}
          <mesh
            position={[viewport.width / 2, -imageSize[1] * imageScale + (ratio > 1 ? 2.5 : -2.5), ratio > 1 ? -5 : 0]}
            rotation={[0, 0, ratio > 1 ? 0 : Math.PI]}
            renderOrder={15}>
            <planeBufferGeometry args={[imageSize[0] * imageScale, 5]} />
            <meshBasicMaterial alphaMap={textureAlpha} color={'black'} opacity={1} transparent visible={!(imagesCaptions == '')} />
          </mesh>
          <Text
            textAlign={'center'}
            anchorY={ratio > 1 ? 'middle' : 'top'}
            anchorX="center"
            position={[viewport.width / 2, -imageSize[1] * imageScale + (1 * ratio > 1 ? 1 : -0.25), ratio > 1 ? -5 : 0]}
            fontSize={Math.max(0.55 * 1 - proportionalScale, 0.35)}
            maxWidth={imageSize[0] * imageScale * 0.9}
            color={'white'}>
            {imagesCaptions}
          </Text>

          <Html zIndexRange={[1, 0]}>
            <div
              ref={intersectRef}
              style={{
                // background: inView? "green" : "red",
                opacity: 0.5,
                width: 15,
                height: imageSize[1] * imageScale * (touchDevice ? 40 : 80),
              }}
            />
          </Html>
        </Box>
      </Box>
    </>
  )
}
function VideoFull({ image = '/images/16_9.png', videoSrc, bar = false, muted = true, imagesCaptions = '', imagesCredit = '' }) {
  const [textureImage, textureAlpha, textureBackgroundAlpha] = useLoader(THREE.TextureLoader, [
    image,
    '/images/linear_gradient.jpg',
    '/images/background_gradient.jpg',
  ])

  const [intersectRef, inView] = useInView()

  const videoRef = useRef()

  const [isLoaded, setLoaded] = useState()
  const [videoProgress, setProgress] = useState()

  const { viewport, size } = useThree()
  const backgroundRef = useRef()
  const proportionalScale = Math.min(1, viewport.width / 16)

  let imageRatio = [
    textureImage.image.width / Math.max(textureImage.image.width, textureImage.image.height),
    textureImage.image.height / Math.max(textureImage.image.width, textureImage.image.height),
  ]
  let imageSize
  const imageScale = viewport.width >= viewport.height ? 0.85 : 1

  const ratio = viewport.width / viewport.height

  const pageLerp = useRef(state.top / size.height)
  let backgroundOffset

  const [clicked, setClick] = useState(false)

  useFrame(() => {
    setLoaded(videoRef.current.readyState == 4)

    setProgress(videoRef.current.currentTime / videoRef.current.duration)

    if (videoRef.current != null || videoRef.current != undefined) {
      if (inView && isLoaded && muted) {
        videoRef.current.play()
        videoRef.current.muted = clicked ? muted : true
      } else if (inView && isLoaded && !muted && clicked) {
        videoRef.current.play()
        videoRef.current.muted = clicked ? muted : true
      } else if (isLoaded && videoRef.current.currentTime != 0) {
        videoRef.current.pause()
        videoRef.current.muted = true
        setClick(false)
        videoRef.current.currentTime = 0
      }
    }

    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.1))
    backgroundOffset = page * viewport.height

    if (backgroundRef.current != null && backgroundRef.current != undefined) {
      backgroundRef.current.material.repeat = [1, 0]
      backgroundRef.current.material.offset = [0, Math.sin(backgroundOffset / size.height)]
    }
  })

  // landscape
  if (viewport.width >= viewport.height) {
    imageSize =
      textureImage.image.width > textureImage.image.height
        ? [viewport.width * imageRatio[0], viewport.width * imageRatio[1]]
        : [viewport.height * imageRatio[0], viewport.height * imageRatio[1]]
  }
  // portrait
  else {
    imageSize =
      textureImage.image.width >= textureImage.image.height
        ? [viewport.width * imageRatio[0], viewport.width * imageRatio[1]]
        : [viewport.height * imageRatio[0], viewport.height * imageRatio[1]]
  }

  const [hovered, setHover] = useState(false)
  useEffect(() => void (document.body.style.cursor = hovered && !muted ? 'pointer' : 'auto'), [hovered, muted])

  return (
    <>
      {/* VIDEO */}
      <Box
        dir="row"
        align={'flex-end'}
        height="auto"
        width="100%"
        margin={0}
        justifyContent="center"
        mt={ratio > 1 ? 2 : 0.5}
        mb={ratio > 1 ? 2 : imagesCaptions == '' ? 0.5 : 2}>
        <Box width={imageSize[0]} height={imageSize[1] * imageScale}>
          <group>
            <mesh position={[viewport.width / 2, (-imageSize[1] * imageScale) / 2, -40]} ref={backgroundRef} renderOrder={1}>
              <planeBufferGeometry args={[viewport.width * 3, viewport.height * (ratio > 1 ? 4 : 2)]} />
              <meshBasicMaterial opacity={1} attach="material" color="black" alphaMap={textureBackgroundAlpha} transparent={true} />
            </mesh>
          </group>

          {/* PROGRESS BAR */}
          <group position={[viewport.width / 2 - (imageSize[0] * imageScale) / 2, 0.05 / 2, ratio > 1 ? -5 : 0]} scale={[videoProgress, 1, 1]}>
            <mesh position-x={(imageSize[0] * imageScale) / 2}>
              <planeBufferGeometry args={[imageSize[0] * imageScale, 0.05]} />
              <meshBasicMaterial opacity={1} attach="material" color={'white'} depthTest={false} depthWrite={false} transparent visible={bar} />
            </mesh>
          </group>

          <mesh position={[viewport.width / 2, 0.05 / 2, ratio > 1 ? -5 : 0]}>
            <planeBufferGeometry args={[imageSize[0] * imageScale, 0.05]} />
            <meshBasicMaterial attach="material" color={'white'} depthTest={false} depthWrite={false} transparent opacity={0.2} visible={bar} />
          </mesh>

          <Text
            bold
            textAlign={'center'}
            anchorY={'middle'}
            anchorX="center"
            position={[viewport.width / 2, (-imageSize[1] * imageScale) / 2, ratio > 1 ? -5 : 0]}
            fontSize={1.5}
            maxWidth={imageSize[0] * imageScale * 0.9}
            visible={!muted}
            color={'white'}>
            {!clicked && !muted ? 'PLAY\n(sound)' : ''}
          </Text>

          <mesh
            position={[viewport.width / 2, (-imageSize[1] * imageScale) / 2, ratio > 1 ? -5 : 0]}
            onClick={() => setClick(!clicked)}
            onPointerOver={() => setHover(true)}
            onPointerOut={() => setHover(false)}>
            {/* HTML5 PLAYER */}
            <Html center zIndexRange={[1, 0]}>
              <div style={{ visibility: 'hidden' }}>
                <video autoPlay={false} ref={videoRef} muted={true} loop={true} width={1}>
                  <source src={videoSrc} type="video/mp4" />
                </video>
              </div>
            </Html>
            {/* INTERSECTION OBS */}
            <Html center zIndexRange={[1, 0]}>
              <div
                ref={intersectRef}
                style={{
                  // background: inView? "green" : "red",
                  opacity: 0.5,
                  width: 1,
                  height: imageSize[1] * imageScale * 80,
                }}
              />
            </Html>

            <planeBufferGeometry args={[imageSize[0] * imageScale, imageSize[1] * imageScale]} />
            <meshBasicMaterial opacity={muted ? 1 : clicked ? 1 : 0.1} transparent={!touchDevice || !muted}>
              {videoRef.current != undefined ? <videoTexture attach="map" args={[videoRef.current]} /> : null}
            </meshBasicMaterial>
          </mesh>

          {/* CAPTION */}
          <mesh
            position={[viewport.width / 2, -imageSize[1] * imageScale + (ratio > 1 ? 2.5 : -2.5), ratio > 1 ? -5 : 0]}
            rotation={[0, 0, ratio > 1 ? 0 : Math.PI]}
            renderOrder={15}>
            <planeBufferGeometry args={[imageSize[0] * imageScale, 5]} />
            <meshBasicMaterial alphaMap={textureAlpha} color={'black'} opacity={1} transparent visible={!(imagesCaptions == '')} />
          </mesh>
          <Text
            textAlign={'center'}
            anchorY={ratio > 1 ? 'middle' : 'top'}
            anchorX="center"
            position={[viewport.width / 2, -imageSize[1] * imageScale + (1 * ratio > 1 ? 1 : -0.25), ratio > 1 ? -5 : 0]}
            fontSize={Math.max(0.55 * 1 - proportionalScale, 0.35)}
            maxWidth={imageSize[0] * imageScale * 0.9}
            color={'white'}>
            {imagesCaptions}
          </Text>
        </Box>
        {/* CREDIT */}
        <Text
          position={[viewport.width / 2 - (imageSize[0] * imageScale) / 2 + 0.1, -imageSize[1] * imageScale - 0.1, ratio > 1 ? -5 : 0]}
          fontSize={0.175}
          fillOpacity={0.5}
          maxWidth={imageSize[0] * imageScale}
          color={foregroundColor}
          visible={imagesCredit != ''}>
          {`Video: ` + imagesCredit}
        </Text>
      </Box>
    </>
  )
}

// thumbs
function ThumbSimple({
  caption = 'title',
  // year = `short description`,
  subcaption = `short description`,
  // role = `short description`,
  thumbImage,
  url,
  imageOpacity = 0.85,
  besides = false,
}) {
  const [textureImage, textureImageAlpha] = useLoader(THREE.TextureLoader, [
    thumbImage,
    touchDevice ? '/images/half_linear_gradient.jpg' : '/images/white.png',
  ])

  const { viewport } = useThree()
  const [location, setLocation] = useLocation()
  const proportionalScale = Math.min(1, viewport.width / 16)
  const ratio = viewport.width / viewport.height
  const imageRatio = textureImage.image.width / textureImage.image.height

  if (imageRatio != 1) {
    const repeatX = imageRatio < 1 ? 1 : 1 / imageRatio
    const repeatY = imageRatio > 1 ? 1 : imageRatio
    const offsetX = imageRatio < 1 ? 0 : (1 - 1 / imageRatio) / 2
    const offsetY = imageRatio > 1 ? 0 : 1 - imageRatio
    textureImage.repeat.x = repeatX
    textureImage.repeat.y = repeatY
    textureImage.offset.x = offsetX
    textureImage.offset.y = offsetY
  }
  // textureImage.wrapS = THREE.MirroredRepeatWrapping
  // textureImage.wrapT = THREE.MirroredRepeatWrapping

  const smallestSize = Math.min(viewport.width, viewport.height)
  const [hovered, setHover] = useState(false)
  const group = useRef()

  // const [intersectRef, inView] = useInView({ threshold: 1 })

  const [triggered, setTriggered] = useState(false)

  useEffect(() => {
    // let key = document.querySelectorAll('a')
    // key.forEach((k) => {
    //   k.style.color = inView ? 'white' : 'foregroundColor'
    // })

    if (!triggered && hovered) {
      setTriggered(true)
      analyticsTracker('thumb ' + caption.replaceAll(' ', ''))
    }
  }, [caption, hovered, triggered])

  useFrame(({ clock }) => {
    if (!touchDevice && (group.current != null || group.current != undefined)) {
      const t = Math.sin(clock.getElapsedTime() / 2)
      group.current.children[0].material.repeat[1] = THREE.MathUtils.lerp(group.current.children[0].material.repeat[1], hovered ? 0 : 1, 0.5)

      if (hovered) {
        group.current.children[0].material.offset[1] = THREE.MathUtils.lerp(group.current.children[0].material.offset[1], 0.5 + t / 20, 0.5)
      } else {
        group.current.children[0].material.offset[1] = THREE.MathUtils.lerp(group.current.children[0].material.offset[1], 0, 0.5)
      }

      group.current.children[0].material.opacity = THREE.MathUtils.lerp(
        group.current.children[0].material.opacity,
        hovered ? 0.5 : imageOpacity,
        0.25
      )
      group.current.children[1].material.opacity = THREE.MathUtils.lerp(group.current.children[1].material.opacity, hovered ? 0.85 : 0, 0.1)
      group.current.children[2].material.opacity = THREE.MathUtils.lerp(group.current.children[1].material.opacity, hovered ? 0.85 : 0, 0.1)
    } else {
      group.current.children[1].material.opacity = 1
      group.current.children[2].material.opacity = 1
    }
  })

  return (
    <>
      <group>
        <Box
          dir={'row'}
          align="center"
          justify="center"
          flexWrap="wrap"
          m={0}
          width={ratio > 1 ? viewport.width / 4 : viewport.width}
          height={ratio > 1 ? viewport.width / 4 : viewport.width}>
          {/* <Box height="0" width="100%">
            <group>
              <Html zIndexRange={[1, 0]}>
                <div
                  ref={intersectRef}
                  style={{
                    // background: inView? "green" : "red",
                    opacity: 0.5,
                    width: 1,
                    height: 500,
                  }}
                />
              </Html>
            </group>
          </Box> */}

          <Box width="100%" centerAnchor>
            <Box>
              <group ref={group}>
                <mesh
                  renderOrder={2}
                  onPointerOver={() => (!touchDevice ? setHover(true) : null)}
                  onPointerOut={() => (!touchDevice ? setHover(false) : null)}
                  onClick={() => (setLocation(url), (document.body.style.cursor = 'auto'))}
                  onPointerMissed={() => (!touchDevice ? setHover(false) : null)}>
                  <planeBufferGeometry args={[ratio > 1 ? viewport.width / 4 : viewport.width, ratio > 1 ? viewport.width / 4 : viewport.width]} />
                  <doubleChannelMaterial
                    attach="material"
                    repeat={[1, touchDevice ? 1 : -10000]}
                    offset={[0, touchDevice ? 0 : 10000]}
                    opacity={imageOpacity}
                    map={textureImage}
                    alphaMap={textureImageAlpha}
                    transparent={true}
                  />
                </mesh>
                <Text
                  bold
                  textAlign="left"
                  anchorX="center"
                  anchorY="bottom"
                  position={!touchDevice ? [0, 0.1, 0] : [0, 2.25, 0]}
                  fontSize={0.6}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  <meshBasicMaterial color={'white'} transparent opacity={0} blendingMode={THREE.SubtractiveBlending} />
                  {caption.toUpperCase()}
                </Text>
                <Text
                  textAlign="center"
                  anchorX="center"
                  anchorY="top"
                  position={!touchDevice ? [0, -0.1, 0] : [0, 2.15, 0]}
                  fontSize={Math.max(0.4 * 1 - proportionalScale, 0.3)}
                  lineHeight={1.35}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>
                  <meshBasicMaterial color={'white'} transparent opacity={0} blendingMode={THREE.SubtractiveBlending} />
                  {subcaption}
                </Text>
                {/* { besides ? <Text
                  bold
                  textAlign="center"
                  anchorX="center"
                  anchorY="top"
                  position={!touchDevice ? [2.7, -3, 0] : [0, 2.25, 0]}
                  fontSize={Math.max(0.4 * 1 - proportionalScale, 0.3)}
                  lineHeight={1.35}
                  maxWidth={smallestSize * (ratio > 1 ? 0.6 : 0.85)}>                  
                  <meshBasicMaterial color={'white'} transparent opacity={1} blendingMode={THREE.SubtractiveBlending} />
                  {'be-sides'}
                </Text> : null } */}

                <mesh renderOrder={1}>
                  <planeBufferGeometry args={[ratio > 1 ? viewport.width / 4 : viewport.width, ratio > 1 ? viewport.width / 4 : viewport.width]} />
                  <meshBasicMaterial color={'black'} transparent={true} opacity={hovered ? 1 : 0} />
                </mesh>
              </group>
            </Box>
          </Box>
          {/* <group>
            <mesh position={[0, -(ratio > 1 ? viewport.width/4 - 0.3 : viewport.width)/2, 0]}>
              <planeBufferGeometry args={[viewport.width *3, (ratio > 1 ? viewport.width/4 : viewport.width * 2) + 15]} />
              <meshBasicMaterial attach="material" color="black" transparent alphaMap={textureBackgroundAlpha} />
            </mesh>                             
          </group> */}
          <group>
            <mesh position={[0, -(ratio > 1 ? viewport.width / 4 : viewport.width) / 2, 0]}>
              <planeBufferGeometry args={[viewport.width * 3, ratio > 1 ? viewport.width / 4 + 0.025 : viewport.width + 1]} />
              <meshBasicMaterial attach="material" color="black" />
            </mesh>
          </group>
        </Box>
      </group>
    </>
  )
}

// --------------------------------------------------------------------------------------
// content ------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------

// main sections ------------------------------------------------------------------------

function Home({ onReflow }) {
  invertScreen = false
  let sidebar = document.getElementById('scrollDiv')

  if (currentUrl != '/' && sidebar != undefined) {
    topFunction()
    currentUrl = '/'
    analyticsTracker('home')
  }

  if (previousPages != state.pages) {
    if (worksScroll !== null) {
      sidebar.scrollTop = worksScroll
      state.top = worksScroll
      previousPages = state.pages

      if (worksScroll > 0) {
        worksExternal = true
      }
    }
  }

  window.addEventListener('mousedown', () => {
    if (currentUrl == '/') {
      worksScroll = sidebar.scrollTop
    }
  })

  foregroundColor = content.who.skin.foreground
  backgroundColor = content.who.skin.background

  // const singleTexture = useLoader(THREE.TextureLoader, '/images/strip.jpg')
  // const [textureBackgroundAlpha] = useLoader(THREE.TextureLoader, ['/images/background_gradient.jpg'])

  const totalQuestions = content.works.projects.length - 1
  const images = content.works.projects.map((x) => x.stripImage)
  const textureStrip = useLoader(THREE.TextureLoader, images)
  const [textureBackgroundAlpha, textureFadeAlpha, textureFadeStrip] = useLoader(THREE.TextureLoader, [
    '/images/thumb_gradient.png',
    '/images/half_linear_gradient.jpg',
    '/images/strip.jpg',
  ])

  textureFadeStrip.wrapS = THREE.MirroredRepeatWrapping
  textureFadeStrip.wrapT = THREE.MirroredRepeatWrapping

  const [newImage, setnewImage] = useState(textureFadeStrip)
  // const [newImage, setnewImage] = useState(textureStrip[state.homeIndex])

  // const shuffle = useCallback(() => {
  //   state.homeIndex++
  //   if (state.homeIndex > totalQuestions) {
  //     state.homeIndex = 0
  //   }
  //   state.homeCounter++

  //   setnewImage(textureStrip[state.homeIndex])
  // }, [textureStrip, totalQuestions])

  // const flip = useCallback(() => {
  //   setTimeout(() => {
  //     shuffle()
  //   }, 1000)
  // }, [shuffle])

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.backgroundColor = backgroundColor
    document.body.style.background = backgroundColor

    // const intervalFlip = setInterval(flip, 5000)
    // return () => clearInterval(intervalFlip)
  })

  const group = useRef()
  const triad = useRef()
  const { viewport, size } = useThree()
  const pageLerp = useRef(state.top / size.height)
  const ratio = viewport.width / viewport.height

  const moebius = useRef()
  const moebiusPos = new THREE.Vector3()

  const bgfade1 = useRef()
  const bgfade2 = useRef()
  const aletter1 = useRef()
  const aletter2 = useRef()

  // const [intersectRef, inView] = useInView({ threshold: 0.2 })

  useFrame(() => {
    let instant = false
    if (worksExternal) {
      instant = true
      worksExternal = false
    }
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, instant ? 1 : 0.15))
    const y = page * viewport.height

    if (bgfade1.current != null && bgfade1.current != undefined) {
      bgfade1.current.material.repeat = [1, 0]
      // bgfade1.current.material.offset = [0, Math.sin(y * 1.25 / size.height)]
      bgfade1.current.material.offset[1] += 0.0002
    }
    if (bgfade2.current != null && bgfade2.current != undefined) {
      bgfade2.current.material.repeat = [1, 0]
      // bgfade2.current.material.offset = [0, Math.sin(y * 2 / size.height)]
      bgfade2.current.material.offset[1] += 0.0002
    }

    if (group.current != null || group.current != undefined) {
      group.current.position.y = y
    }

    // console.log(page + ' / ' +state.pages)

    if (moebius.current != null || moebius.current != undefined) {
      moebius.current.position.lerp(moebiusPos.set(viewport.width / 2, -viewport.height / 2 - y, 100 + Math.min(y * 200, 2000)), 0.1)
      //page < state.pages - 1.25
    }

    if (triad.current != null || triad.current != undefined) {
      triad.current.children[0].material.opacity = THREE.MathUtils.lerp(triad.current.children[0].material.opacity, 0.1, 0.025)
    }

    if (aletter1.current != null || aletter1.current != undefined) {
      aletter1.current.children[0].material.repeat = [1, 0]
      aletter1.current.children[0].material.offset[1] += 0.002
    }
    if (aletter2.current != null || aletter2.current != undefined) {
      aletter2.current.children[0].material.repeat = [1, 0]
      aletter2.current.children[0].material.offset[1] -= 0.002
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  const proportionalScale = Math.min(1, viewport.width / 16)

  const [hovered, setHover] = useState(false)
  useEffect(() => void (document.body.style.cursor = hovered ? 'pointer' : 'auto'), [hovered])

  return (
    <>
      <group ref={group}>
        <Flex
          dir="column"
          position={[-viewport.width / 2, viewport.height / 2, 0]}
          size={[viewport.width, viewport.height, 0]}
          onReflow={handleReflow}>
          <Box dir="column" width="100%" height={viewport.height} align="center" justify="center" justifyContent="space-around">
            <group ref={moebius} name="moebius group" position={[0, 0, -500]}>
              <Moebius
                name="moebius function"
                targetOpacity={0.85}
                opacity={0}
                additive={false}
                shaded={true}
                model={content.home.section.main.model}
                textureImage={newImage}
                scale={ratio > 1 ? 240 : 120}
                position={[-0, 0, -1000]}
                rotateY={true}
                rotateZ={true}
                rotationOffset={[0, 0, 0]}
                floatModel={true}
                order={1}
                // touch={touchDevice}
                // browserName={browserName}
              />
            </group>

            <group position={[viewport.width / 2, -viewport.height / 2, 10]} ref={triad}>
              <Text
                bold
                position={[0, 0, 0]}
                anchorX="center"
                anchorY="middle"
                textAlign="center"
                // fontSize={2.5}
                fontSize={(ratio > 1 ? 2.5 : 1.75) * proportionalScale}
                lineHeight={touchDevice ? 3.5 : 1.15}
                letterSpacing={0.175}
                color={foregroundColor}
                curveRadius={12}
                maxWidth={(viewport.width / 5) * 4}>
                <meshBasicMaterial transparent opacity={0} />
                {'around\nbetween\nwithin'}
              </Text>
            </group>

            <group position={[viewport.width / 2, -viewport.height, -5]} rotation={[0, 0, Math.PI]}>
              <Text
                bold
                position={[0, 1, 0]}
                fontSize={4}
                // color={foregroundColor}
                fillOpacity={0.2}
                textAlign={'center'}
                maxWidth={viewport.width * 1.5}
                lineHeight={0.3}
                anchorY={'bottom'}
                anchorX={'center'}>
                <meshBasicMaterial color={'white'} map={newImage} />
                {`.\n.\n.`}
              </Text>
            </group>
          </Box>

          <Spacer />
          <TextTitle
            title={`olá!`}
            // imagesCredit={'Jairo Braga'}
            titleColor={'black'}
            // subTitle={'curious since \'89'}
            text={content.who.cover.text}
            image={content.who.cover.image}
            imageOpacity={0.85}
            left={true}
            fade={false}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}>
            <Box dir="row" mt={0.75} align="center" width="100%" justify={'flex-start'}>
              {/* <Box width="40%">
                <Html zIndexRange={[1, 0]}>
                  <div style={{ whiteSpace: 'nowrap', fontSize: 36 }}>
                    <a href="https://www.linkedin.com/in/andreisperid/" className="icon-bar" target="_blank" rel="noopener noreferrer">
                      <InlineIcon icon={linkedinIcon} /> LinkedIn
                    </a>
                  </div>
                </Html>
              </Box> */}
              <Box>
                {/* <Html zIndexRange={[1, 0]}>
                  <div style={{ whiteSpace: 'nowrap', fontSize: 32, fontWeight: 'bold' }}>
                    <a href="/files/AndreiSperidiao_Resume.pdf" className="icon-bar" target="_blank" rel="noopener noreferrer">
                      <InlineIcon icon={downloadIcon} style={{ color: foregroundColor }} /> RESUME
                    </a>
                  </div>
                </Html> */}
              </Box>
            </Box>
          </TextTitle>
          <ScrollTracker eventName="home bio" />

          <Box mb={ratio > 1 ? 0 : 1.5} />

          {/* columns */}
          <HighlightText text={`I design through different layers and scales to prototype desirable futures`} />
          <Box dir="row" width="100%" minHeight={viewport.height / 2} justifyContent="center" wrap="wrap" alignItems="baseline" mt={0} mb={2}>
            {content.who.timelineVision.map((props, index) => (
              <ColumnItems
                key={index}
                index={index}
                centered={true}
                imageOpacity={1}
                textInside={false}
                titleColor={foregroundColor}
                gradient={false}
                onReflow={(w, h) => {
                  sizesRef.current[index] = h
                }}
                {...props}
              />
            ))}
          </Box>
          <ScrollTracker eventName="home vision" />

          <Spacer />

          <Box dir="row" width="100%" height={viewport.height / 3} justifyContent="center" align="center">
            <Box>
              <group ref={aletter1} position={[0, 0, 5]}>
                <Text bold fontSize={3} color={'white'} fillOpacity={0.5}>
                  <doubleChannelMaterial attach="material" map={textureFadeStrip} transparent alphaMap={textureFadeAlpha} />
                  {`a`}
                </Text>
              </group>
            </Box>
          </Box>

          {/* <Box height="0" width="100%">
            <group  position-y={-viewport.width/2}>
              <Html zIndexRange={[1, 0]} transform >
                <div
                  ref={intersectRef}
                  style={{
                    background: inView? "green" : "red",
                    opacity: 0.5,
                    width: 20,
                    height: viewport.width * 10 * 4
                  }}
                />
              </Html>
            </group>
          </Box> */}

          <Box width="100%" height="auto">
            <mesh position={[viewport.width / 2, 0, -1.5]} ref={bgfade1}>
              <planeBufferGeometry args={[viewport.width * 1.05, ratio > 1 ? viewport.width * 0.8 : viewport.width * 2]} />
              <doubleChannelMaterial attach="material" map={textureFadeStrip} transparent alphaMap={textureFadeAlpha} />
            </mesh>
            <mesh position={[0, 0, -1.5]}>
              <planeBufferGeometry args={[viewport.width * 3, (ratio > 1 ? viewport.width / 10 : viewport.width) + 1]} />
              <meshBasicMaterial attach="material" color="black" transparent opacity={0.9} alphaMap={textureBackgroundAlpha} />
            </mesh>
          </Box>
          <ScrollTracker eventName="home thumb start" />
          <group
            onPointerOver={() => (!touchDevice ? setHover(true) : null)}
            onPointerOut={() => (!touchDevice ? setHover(false) : null)}
            onPointerMissed={() => setHover(false)}>
            <Box dir="row" width="100.01%" justifyContent="center" wrap="wrap" height="auto">
              {content.works.projects.map((props, index) => (
                <ThumbSimple
                  key={index}
                  onReflow={(w, h) => {
                    sizesRef.current = h
                  }}
                  {...props}
                />
              ))}
            </Box>
          </group>
          <Box width="100%" height="auto">
            <mesh position={[viewport.width / 2, 0, -1.5]} ref={bgfade2} rotation={[0, 0, Math.PI]}>
              <planeBufferGeometry args={[viewport.width * 1.05, ratio > 1 ? viewport.width * 0.8 : viewport.width * 2]} />
              <doubleChannelMaterial attach="material" map={textureFadeStrip} transparent alphaMap={textureFadeAlpha} />
            </mesh>
            <mesh position={[0, 0, -1.5]}>
              <planeBufferGeometry args={[viewport.width * 3, (ratio > 1 ? viewport.width / 10 : viewport.width) + 1]} />
              <meshBasicMaterial attach="material" color="black" transparent opacity={0.85} alphaMap={textureBackgroundAlpha} />
            </mesh>
          </Box>
          <ScrollTracker eventName="home thumb end" />

          {/* <Spacer /> */}
          <Box dir="row" width="100%" height={viewport.height / 3} justifyContent="center" align="center">
            <Box>
              <group ref={aletter2} position={[0, 0, 5]}>
                <Text bold fontSize={3} color={'white'} fillOpacity={0.5}>
                  <doubleChannelMaterial attach="material" map={textureFadeStrip} transparent alphaMap={textureFadeAlpha} />
                  {`a`}
                </Text>
              </group>
            </Box>
          </Box>

          {/* <Spacer mt={0} /> */}
          {/* <Box dir="row" width="100%" m={0.5} align={'center'} justify={'center'} mt={1.5} mb={1.5}>
            <Box m={0.35}>
              <Text bold rotation-z={Math.PI / 2} color={foregroundColor} fontSize={1.25} fillOpacity={0.25} textAlign={'center'} anchorX={'right'}>
                {'Skills'.toUpperCase()}
              </Text>
            </Box>
            <Box align="flex-start">
              {content.who.skillsHeader.labels.map((tag, index) => (
                <group key={index}>
                  <Box dir="row" mb={0.2} wrap={ratio > 1 ? 'no-wrap' : 'wrap'}>
                    <Box dir="row" mr={0.15}>
                      <Text color={foregroundColor} fontSize={0.35}>
                        {tag.toUpperCase()}
                      </Text>
                    </Box>
                    <Box dir="row">
                      {ratio > 1
                        ? content.who.skillsHeader.content[index].map((tag2, index2) => (
                            <group key={index2}>
                              <Box dir="row" wrap="no-wrap" mr={0.2}>
                                <Text color={foregroundColor} fontSize={0.35} fillOpacity={0.5}>
                                  {tag2.replace(/(\r\n|\n|\r)/gm, ' ').toLowerCase()}
                                </Text>
                              </Box>
                            </group>
                          ))
                        : null}
                    </Box>
                  </Box>
                </group>
              ))}
            </Box>
          </Box> */}

          <HighlightText text={`I learn by sharing`} />

          <TextImage
            title={'Red Bull Basement Residency'}
            text={content.who.mentoring.text}
            image={content.who.mentoring.image}
            imagesCredit={'Felipe Gabriel - Red Bull'}
            titleColor={'black'}
            imageOpacity={1}
            left={false}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}
          />
          <ScrollTracker eventName="home basement" />
          <ImageMosaic images={content.who.mosaicMentoring2} imagesCredit={content.who.mosaicMentoringCredits2} creditOffset={0.35} opacity={0.99} />

          <TextImage
            title={'Classes, Lectures & Media'}
            text={content.who.speaking.text}
            titleColor={'black'}
            image={content.who.speaking.image}
            imageOpacity={1}
            left={true}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}
          />
          <ScrollTracker eventName="home speaking" />
          <ImageMosaic images={content.who.mosaicMentoring1} imagesCredit={content.who.mosaicMentoringCredits1} opacity={0.99} />

          {/* books */}
          {/* <Box dir="row" width="100%" justifyContent="center" wrap="wrap" alignItems="baseline" mt={0} mb={0}>
            <Box key={index} centerAnchor height={viewport.height} width="100%">
              <Thumbnail
                key={index}
                index={index}
                textureImage={newImage}
                textureThumb={newThumb}
                bgColor={backgroundColor}
                fgColor={foregroundColor}
                hoverColor={content.works.skin.hoverColor}
                touch={touchDevice}
                scale={1.15 * scale}
                {...props}
              />
            </Box>
          </Box> */}

          <Box dir="row" width="100%" justifyContent="center" wrap="wrap" alignItems="baseline" mt={0} mb={0}>
            <group position={[viewport.width / 2, -2, -5]}>
              <Text
                bold
                position={[0, 1, 0]}
                fontSize={4}
                fillOpacity={0.2}
                textAlign={'center'}
                maxWidth={viewport.width * 1.5}
                lineHeight={0.3}
                anchorY={'top'}
                anchorX={'center'}>
                <meshBasicMaterial color={'white'} map={newImage} />
                {`.\n.\n.`}
              </Text>
            </group>
          </Box>

          <Box dir="row" align="center" justify="center" width="100%" marginTop={0} height={viewport.height}>
            <group position={[viewport.width / 2, -viewport.height * (ratio > 1 ? 1.75 : 1.1), 10]} rotation={[0, Math.PI / 3, 0]}>
              <Moebius
                targetOpacity={0.5}
                opacity={0}
                additive={false}
                shaded={true}
                model={content.home.section.main.model}
                textureImage={newImage}
                scale={ratio > 1 ? 28 : 14}
                rotateY={false}
                rotateZ={true}
                rotationOffset={[0, 0, 0]}
                floatModel={true}
                order={1}
              />
            </group>
            <Box dir="column" height="auto" width="auto" align="center" mt={2}>
              <Box align="center" width="auto" justify="center" mb={0.5}>
                <Text
                  bold
                  textAlign="center"
                  anchorX="center"
                  anchorY="bottom"
                  fontSize={Math.max(1 * proportionalScale, 0.4)}
                  color={foregroundColor}
                  maxWidth={(viewport.width / 10) * 8}>
                  {`let's get in touch!`}
                </Text>
              </Box>
              <ScrollTracker eventName="home footer" />
              <Box dir="row" align="center" width="auto">
                <Box marginRight={1} marginLeft={1}>
                  <Html center zIndexRange={[1, 0]}>
                    <a href="https://www.linkedin.com/in/andreisperid/" className="icon-bar" target="_blank" rel="noopener noreferrer">
                      <InlineIcon icon={linkedinIcon} style={{ color: foregroundColor, fontSize: 50 }} />
                    </a>
                  </Html>
                </Box>
                <Box marginRight={1} marginLeft={1}>
                  <Html center zIndexRange={[1, 0]}>
                    <a href="mailto:hi@andrei.cc" className="icon-bar" target="_blank" rel="noopener noreferrer">
                      <InlineIcon icon={emailIcon} style={{ color: foregroundColor, fontSize: 50 }} />
                    </a>
                  </Html>
                </Box>
              </Box>
            </Box>
          </Box>

          <CopyrightFooter background={false} />
        </Flex>
      </group>
    </>
  )
}
function Recruiters({ onReflow }) {
  invertScreen = false

  const texture = useLoader(THREE.TextureLoader, '/images/who/final_strip.jpg')

  if (currentUrl != '/recruiters') {
    topFunction()
    currentUrl = '/recruiters'
  }

  foregroundColor = content.who.skin.foreground
  backgroundColor = content.who.skin.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.backgroundColor = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)
  const ratio = viewport.width / viewport.height

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current != null || group.current != undefined) {
      // group.current.position.lerp(vec.set(0, page < state.threshold ? y : sticky, page < state.threshold ? 0 : page * 1.25), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  const proportionalScale = Math.min(1, viewport.width / 16)

  return (
    <>
      <group ref={group}>
        <Flex
          dir="column"
          position={[-viewport.width / 2, viewport.height / 2, 0]}
          size={[viewport.width, viewport.height, 0]}
          onReflow={handleReflow}>
          <Spacer />
          <TextImage
            title={`Hi!`}
            imagesCredit={'Jairo Braga'}
            titleColor={'black'}
            text={content.who.cover.text}
            image={content.who.cover.image}
            imageOpacity={1}
            left={false}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}>
            <Box dir="row" mt={0.75} align="center" width="100%" justify="flex-start">
              <Box width="45%">
                <Html zIndexRange={[1, 0]}>
                  <div style={{ whiteSpace: 'nowrap', fontSize: 24 }}>
                    <a href="https://www.linkedin.com/in/andreisperid/" className="icon-bar" target="_blank" rel="noopener noreferrer">
                      <InlineIcon icon={linkedinIcon} /> LinkedIn
                    </a>
                  </div>
                </Html>
              </Box>

              <Box width="45%">
                <Html zIndexRange={[1, 0]}>
                  <div style={{ whiteSpace: 'nowrap', fontSize: 24 }}>
                    <a href="/files/AndreiSperidiao_Resume.pdf" className="icon-bar" target="_blank" rel="noopener noreferrer">
                      <InlineIcon icon={downloadIcon} style={{ color: foregroundColor, fontSize: 25 }} /> resume
                    </a>
                  </div>
                </Html>
              </Box>
            </Box>
          </TextImage>

          <Box height="0" width="0">
            <group position={[viewport.width / 2, -viewport.height / 2 - 1.5, 0]}>
              <Moebius
                model={content.home.section.main.model}
                textureImage={texture}
                bgColor={backgroundColor}
                fgColor={foregroundColor}
                scale={35}
                rotateY={false}
                rotateZ={false}
                rotationOffset={[0, Math.PI / 3.5, 0]}
                position={[0, -5, 10]}
                floatModel={false}
                additive={false}
                speed={0.15}
                order={15}
                shaded={false}
              />
            </group>
          </Box>
          <Spacer />

          <Spacer mt={0} />
          <Box dir="row" width="100%" m={0.5} align={'center'} justify={'center'} mt={1.5} mb={1.5}>
            <Box m={0.35}>
              <Text bold rotation-z={Math.PI / 2} color={foregroundColor} fontSize={1.25} fillOpacity={0.25} textAlign={'center'} anchorX={'right'}>
                {'Skills'.toUpperCase()}
              </Text>
            </Box>
            <Box align="flex-start">
              {content.who.skillsHeader.labels.map((tag, index) => (
                <group key={index}>
                  <Box dir="row" mb={0.2} wrap={ratio > 1 ? 'no-wrap' : 'wrap'}>
                    <Box dir="row" mr={0.15}>
                      <Text color={foregroundColor} fontSize={0.35}>
                        {tag.toUpperCase()}
                      </Text>
                    </Box>
                    <Box dir="row">
                      {ratio > 1
                        ? content.who.skillsHeader.content[index].map((tag2, index2) => (
                            <group key={index2}>
                              <Box dir="row" wrap="no-wrap" mr={0.2}>
                                <Text color={foregroundColor} fontSize={0.35} fillOpacity={0.5}>
                                  {tag2.replace(/(\r\n|\n|\r)/gm, ' ').toLowerCase()}
                                </Text>
                              </Box>
                            </group>
                          ))
                        : null}
                    </Box>
                  </Box>
                </group>
              ))}
            </Box>
          </Box>

          <Spacer mt={1} />
          <Box dir="row" width="100%" justifyContent="center" wrap="wrap" alignItems="baseline">
            {content.works.projects.map((props, index) => (
              <ThumbSimple
                key={index}
                onReflow={(w, h) => {
                  sizesRef.current = h
                }}
                {...props}
              />
            ))}
          </Box>

          <Box dir="row" align="center" justify="center" width="100%" marginTop={0} height={viewport.height}>
            <Box dir="column" height="auto" width="auto" align="center" mt={2}>
              <Box align="center" width="auto" justify="center" mb={0.5}>
                <Text
                  textAlign="center"
                  anchorX="center"
                  anchorY="bottom"
                  fontSize={Math.max(1 * proportionalScale, 0.4)}
                  color={foregroundColor}
                  maxWidth={(viewport.width / 10) * 8}>
                  {`let's get in touch!`}
                </Text>
              </Box>
              <Box dir="row" align="center" width="auto">
                <Box marginRight={1} marginLeft={1}>
                  <Html center zIndexRange={[1, 0]}>
                    <a href="https://www.linkedin.com/in/andreisperid/" className="icon-bar" target="_blank" rel="noopener noreferrer">
                      <InlineIcon icon={linkedinIcon} style={{ color: foregroundColor, fontSize: 50 }} />
                    </a>
                  </Html>
                </Box>
                <Box marginRight={1} marginLeft={1}>
                  <Html center zIndexRange={[1, 0]}>
                    <a href="mailto:hi@andrei.cc" className="icon-bar" target="_blank" rel="noopener noreferrer">
                      <InlineIcon icon={emailIcon} style={{ color: foregroundColor, fontSize: 50 }} />
                    </a>
                  </Html>
                </Box>
              </Box>
            </Box>
          </Box>

          <CopyrightFooter background={false} />
        </Flex>
      </group>
    </>
  )
}
function OldHome({ onReflow }) {
  invertScreen = false

  const totalQuestions = content.works.projects.length - 1
  let visible = true

  const [newRotation, setnewRotation] = useState(0)
  const [newTargetOpacity, setnewTargetOpacity] = useState(1)

  if (currentUrl != '/') {
    if (state.homeCounter == 1) {
      state.homeIndex = Math.floor(Math.random() * totalQuestions)
    } else {
      state.homeCounter = 1
    }
    topFunction()
    currentUrl = '/'
  }
  foregroundColor = content.home.skin.foreground
  backgroundColor = content.home.skin.background

  const question = useRef()
  // console.log("home")

  const [hovered, setHover] = useState(false)
  const [location, setLocation] = useLocation()

  const images = content.works.projects.map((x) => x.stripImage)
  const textureStrip = useLoader(THREE.TextureLoader, images)

  const [newName, setnewName] = useState(content.works.projects[state.homeIndex].subtitle)
  const [newUrl, setnewUrl] = useState(content.works.projects[state.homeIndex].url)
  const [newImage, setnewImage] = useState(textureStrip[state.homeIndex])
  const [newLights, setnewLights] = useState([content.works.projects[state.homeIndex].background, content.works.projects[state.homeIndex].foreground])

  colorA = newLights[0]
  colorB = newLights[1]

  const shuffle = useCallback(() => {
    if (visible) {
      state.homeIndex++
      if (state.homeIndex > totalQuestions) {
        state.homeIndex = 0
      }
      state.homeCounter++

      colorA = content.works.projects[state.homeIndex].background
      colorB = content.works.projects[state.homeIndex].foreground

      setnewName(content.works.projects[state.homeIndex].subtitle)
      setnewUrl(content.works.projects[state.homeIndex].url)
      setnewImage(textureStrip[state.homeIndex])
      setnewLights([content.works.projects[state.homeIndex].background, content.works.projects[state.homeIndex].foreground])
    }
  }, [textureStrip, totalQuestions, visible])

  const flip = useCallback(() => {
    if (visible) {
      setnewRotation(Math.PI * 2 * state.homeCounter)
      setnewTargetOpacity(0.25)
      setTimeout(() => {
        shuffle()
        setnewTargetOpacity(1)
      }, 250)
    }
  }, [shuffle, visible])

  document.addEventListener('visibilitychange', function (event) {
    if (document.hidden) {
      visible = false
    } else {
      visible = true
    }
  })

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.backgroundColor = backgroundColor
    document.body.style.background = backgroundColor
    document.body.style.cursor = hovered ? 'pointer' : 'auto'

    if (visible && !hovered) {
      const intervalFlip = setInterval(flip, 6000)
      return () => clearInterval(intervalFlip)
    }
  }, [hovered, visible, flip])

  const [intersectRef, inView] = useInView({
    threshold: 0.9,
  })

  const group = useRef()
  const { viewport, size } = useThree()
  const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)
  const ratio = viewport.width / viewport.height

  const moebius = useRef()
  const moebiusPos = new THREE.Vector3()
  const cursor = useRef()
  const whatIfRef = useRef()

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.075))
    const y = page * viewport.height

    if (moebius.current != null || moebius.current != undefined) {
      moebius.current.position.lerp(moebiusPos.set(0, -y - (!touchDevice ? 1 : 0.5), y * 0.45), 0.04)
    }
    if (group.current != null || group.current != undefined) {
      // group.current.position.lerp(vec.set(0, y, 0), 0.15)
      group.current.position.y = y
    }

    if (question.current != null || question.current != undefined) {
      question.current.position.z = THREE.MathUtils.lerp(question.current.position.z, y, 0.05)
      question.current.rotation.x = THREE.MathUtils.lerp(question.current.rotation.x, newRotation, visible ? 0.02 : 1)
      question.current.children[0].material.opacity = THREE.MathUtils.lerp(
        question.current.children[0].material.opacity,
        hovered ? 1 : 0.5 * (newTargetOpacity - 0.25),
        visible ? 0.1 : 1
      )
    }

    if (whatIfRef.current != null || whatIfRef.current != undefined) {
      whatIfRef.current.children[0].fillOpacity = THREE.MathUtils.lerp(whatIfRef.current.children[0].fillOpacity, 0.12, 0.06)
      whatIfRef.current.children[1].fillOpacity = THREE.MathUtils.lerp(whatIfRef.current.children[1].fillOpacity, 0.02, 0.06)
      whatIfRef.current.children[2].fillOpacity = THREE.MathUtils.lerp(whatIfRef.current.children[2].fillOpacity, 0.02, 0.08)
      whatIfRef.current.children[3].fillOpacity = THREE.MathUtils.lerp(whatIfRef.current.children[3].fillOpacity, 0.01, 0.1)
      whatIfRef.current.children[4].fillOpacity = THREE.MathUtils.lerp(whatIfRef.current.children[4].fillOpacity, 0.005, 0.12)
      whatIfRef.current.children[5].fillOpacity = THREE.MathUtils.lerp(whatIfRef.current.children[5].fillOpacity, 0.0025, 0.14)
    }

    if (cursor.current != null || cursor.current != undefined) {
      cursor.current.position.lerp(
        vec.set(
          (state.mouse[0] * viewport.width) / 2,
          (-state.mouse[1] * viewport.height) / 2,
          (state.mouse[0] == 0 && state.mouse[1] == 0) || !inView ? 50 : 0
        ),
        0.1
      )
    }
  })
  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const scale = Math.min(1, viewport.width / 16)

  const proportionalScale = Math.min(1, viewport.width / 16)
  let freshWork = content.works.projects[0]
  let freshBeside = content.works.projects[1]

  var today = new Date()

  return (
    <>
      <ProjectColorLights lightIntensity={1} />

      <group ref={group}>
        <Flex dir="column" size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
          <group>
            <Html zIndexRange={[0, 1]} center>
              <div
                ref={intersectRef}
                style={{
                  // background: inView ? 'green' : 'red',
                  pointerEvents: 'none',
                  opacity: 0.5,
                  width: 15,
                  height: '100vh',
                }}
              />
            </Html>
          </group>

          <group ref={cursor} position-z={50}>
            <Text
              position={[-0.225, -0.35, 0]}
              fontSize={Math.max(1 * scale, 0.55)}
              anchorY={'bottom'}
              anchorX={'left'}
              color={foregroundColor}
              fillOpacity={0.1}>
              {touchDevice || hovered ? '' : '?'}
            </Text>
          </group>

          <group ref={moebius} position-z={10} name="moebius group">
            <Moebius
              name="moebius function"
              model={content.home.section.main.model}
              textureImage={newImage}
              bgColor={backgroundColor}
              fgColor={foregroundColor}
              targetOpacity={newTargetOpacity * 0.9}
              scale={Math.max(7.5 * scale, 4.5)}
              hovered={hovered}
              rotateY={true}
              rotateZ={true}
              rotationOffset={[0, 0, 0]}
              floatModel={true}
              shaded={true}
              touch={touchDevice}
              browserName={browserName}
            />
          </group>

          <Box dir="column" width="100%" height={viewport.height} align="center" justify="center" justifyContent="space-around">
            <group ref={whatIfRef}>
              <Text
                bold
                position={[0, 0, 5]}
                anchorX="center"
                anchorY="bottom"
                textAlign="center"
                fontSize={2.5 * scale}
                lineHeight={1.35}
                fillOpacity={1}
                letterSpacing={-0.05}
                color={'white'}
                maxWidth={viewport.width / 1.5}>
                {'what iƒ...'}
              </Text>
              <Text
                bold
                position={[0, 0, 4.25]}
                anchorX="center"
                anchorY="bottom"
                textAlign="center"
                fontSize={2.5 * scale}
                lineHeight={1.35}
                fillOpacity={1}
                letterSpacing={-0.05}
                color={'white'}
                maxWidth={viewport.width / 1.5}>
                {'what iƒ...'}
              </Text>
              <Text
                bold
                position={[0, 0, 3.5]}
                anchorX="center"
                anchorY="bottom"
                textAlign="center"
                fontSize={2.5 * scale}
                lineHeight={1.35}
                fillOpacity={1}
                letterSpacing={-0.05}
                color={'white'}
                maxWidth={viewport.width / 1.5}>
                {'what iƒ...'}
              </Text>
              <Text
                bold
                position={[0, 0, 2.75]}
                anchorX="center"
                anchorY="bottom"
                textAlign="center"
                fontSize={2.5 * scale}
                lineHeight={1.35}
                fillOpacity={1}
                letterSpacing={-0.05}
                color={'white'}
                maxWidth={viewport.width / 1.5}>
                {'what iƒ...'}
              </Text>
              <Text
                bold
                position={[0, 0, 2]}
                anchorX="center"
                anchorY="bottom"
                textAlign="center"
                fontSize={2.5 * scale}
                lineHeight={1.35}
                fillOpacity={1}
                letterSpacing={-0.05}
                color={'white'}
                maxWidth={viewport.width / 1.5}>
                {'what iƒ...'}
              </Text>
              <Text
                bold
                position={[0, 0, 0]}
                anchorX="center"
                anchorY="bottom"
                textAlign="center"
                fontSize={2.5 * scale}
                lineHeight={1.35}
                fillOpacity={1}
                letterSpacing={-0.05}
                color={'white'}
                maxWidth={viewport.width / 1.5}>
                {'what iƒ...'}
              </Text>
            </group>
            <group
              position={[0, -1.25, -2]}
              rotation-x={-Math.PI}
              ref={question}
              onPointerOver={() => setHover(true)}
              onPointerOut={() => setHover(false)}
              onClick={() => (setLocation(newUrl), (document.body.style.cursor = 'auto'))}>
              <Text
                centerAnchor
                anchorX="center"
                anchorY="middle"
                textAlign="center"
                // textAlign="left"
                fontSize={Math.max(1 * scale, 0.55)}
                lineHeight={1.25}
                letterSpacing={0}
                color={foregroundColor}
                // fillOpacity={hovered ? 0.75 : 0.3}
                maxWidth={ratio > 1 ? viewport.width / 2 : viewport.width * 0.75}>
                {newName + (hovered ? '?' : '…')}
                <meshBasicMaterial depthTest={false} side={THREE.FrontSide} blending={THREE.AdditiveBlending} />
              </Text>
            </group>
          </Box>

          <group position-y={-viewport.height * 2.025}>
            <Thumbnail
              index={0}
              position={[3 * scale * scale + 0.5, 3.5, scale]}
              model={freshWork.model}
              scale={(1 * scale) / 2}
              caption={freshWork.caption}
              subcaption={freshWork.subcaption}
              subtitle={freshWork.subtitle}
              role={freshWork.role}
              year={freshWork.year}
              bgColor={backgroundColor}
              fgColor={foregroundColor}
              hoverColor={content.home.skin.hoverColor}
              background={freshWork.background}
              foreground={freshWork.foreground}
              url={freshWork.url}
              touch={touchDevice}
              freshHelper={true}
              left={true}
            />
            <Thumbnail
              index={2}
              position={[-3 * scale * scale - 0.5, -3.5, scale]}
              model={freshBeside.model}
              scale={(1 * scale) / 2}
              caption={freshBeside.caption}
              subcaption={freshBeside.subcaption}
              subtitle={freshBeside.subtitle}
              role={freshBeside.role}
              year={freshBeside.year}
              bgColor={backgroundColor}
              fgColor={foregroundColor}
              hoverColor={content.home.skin.hoverColor}
              background={freshWork.background}
              foreground={freshWork.foreground}
              url={freshBeside.url}
              touch={touchDevice}
              freshHelper={true}
              left={false}
            />

            <group position-y={touchDevice ? -0.5 : 0.5}>
              <Text
                bold
                position={[0, 0.1, 0]}
                fontSize={0.8 * scale}
                textAlign="center"
                anchorY={'bottom'}
                anchorX={'center'}
                maxWidth={viewport.width * 0.75}
                letterSpacing={-0.02}
                lineHeight={1.1}
                color={'white'}
                fillOpacity={0.5}>
                {content.home.section.fresh.intro1}
              </Text>
              <Text
                bold
                position={[0, -0.1, 0]}
                fontSize={0.8 * scale}
                textAlign="center"
                anchorY={'top'}
                anchorX={'center'}
                maxWidth={viewport.width * 0.75}
                letterSpacing={-0.02}
                lineHeight={1.1}
                color={'white'}
                fillOpacity={0.5}>
                {content.home.section.fresh.intro2}
              </Text>

              <Text
                bold
                position={[0, 3, -5]}
                fontSize={4 * scale}
                letterSpacing={-0.05}
                color={'#121212'}
                textAlign={'center'}
                maxWidth={viewport.width * 1.5}
                lineHeight={0.8}
                anchorY={'middle'}
                anchorX={'center'}>
                {`what's up what's up what's up what's up what's up what's up what's up what's up what's up what's up what's up what's up what's up what's up what's up what's up what's up what's up`.toUpperCase()}
                <meshBasicMaterial color="white" opacity={0.2} transparent blending={THREE.AdditiveBlending} depthTest={false} />
              </Text>
            </group>
          </group>

          <Box dir="column" width="100%" height={viewport.height * 2} align="center" justify="flex-end">
            <group position={[0, -viewport.height * 1.5 + 0.5, 0]}>
              <Text
                textAlign="center"
                anchorX="center"
                anchorY={'middle'}
                fontSize={Math.max(0.25 * 1 - proportionalScale, 0.225)}
                lineHeight={1.5}
                color={foregroundColor}
                fillOpacity={0.15}
                renderOrder={6}>
                {'© andrei speridião 2006 - ' + today.getFullYear()}
              </Text>
              <mesh position-x={viewport.width / 2} position-z={-0.01} renderOrder={5} rotation={[0, 0, Math.PI]}>
                <planeBufferGeometry args={[viewport.width * 2, 2]} />
                <meshBasicMaterial visible={false} />
              </mesh>
            </group>
          </Box>
        </Flex>
      </group>
    </>
  )
}
function Works({ onReflow }) {
  invertScreen = false
  let sidebar = document.getElementById('scrollDiv')

  if (currentUrl != '/works' && sidebar != undefined) {
    topFunction()
    currentUrl = '/works'
  }

  // console.log('works')

  if (previousPages != state.pages) {
    if (worksScroll !== null) {
      sidebar.scrollTop = worksScroll
      state.top = worksScroll
      previousPages = state.pages

      if (worksScroll > 0) {
        worksExternal = true
      }
    }
  }

  window.addEventListener('mousedown', () => {
    if (currentUrl == '/works') {
      worksScroll = sidebar.scrollTop
    }
  })

  const images = content.works.projects.map((x) => x.stripImage)
  const textureStrip = useLoader(THREE.TextureLoader, images)
  const [newImage, setnewImage] = useState(textureStrip[0])

  const thumbs = content.works.projects.map((x) => x.peekImage)
  const textureThumb = useLoader(THREE.TextureLoader, thumbs)
  const [newThumb, setnewThumb] = useState(textureThumb[0])

  foregroundColor = content.works.skin.foreground
  backgroundColor = content.works.skin.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.backgroundColor = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const tagGroup = useRef()
  const thumb = useRef()
  const credit = useRef()

  const { viewport, size } = useThree()
  const vec = new THREE.Vector3()

  const moebius = useRef()
  const moebiusPos = new THREE.Vector3(0, 0, -2500)

  const [tagOpacity, setnewTagOpacity] = useState(content.works.projects[0].tags)

  useFrame(() => {
    const page = state.top / size.height
    const y = page * viewport.height
    let instant = false

    if (worksExternal) {
      instant = true
      worksExternal = false
    }

    if (moebius.current != null || moebius.current != undefined) {
      moebius.current.position.lerp(moebiusPos.set(0, -y - 1, -2000), instant ? 1 : 0.025)
      const currentProject = Math.round(Math.abs(moebius.current.position.y) / viewport.height)

      const targetProject = Math.round(Math.abs(y) / viewport.height)
      setnewTagOpacity(content.works.projects[targetProject].tags)
      setnewImage(textureStrip[currentProject])
      setnewThumb(textureThumb[currentProject])
      colorA = content.works.projects[currentProject].background
      colorB = content.works.projects[currentProject].foreground
      moebius.current.rotation.z = Math.PI / 2.25
      moebius.current.rotation.y = instant ? 0 : (Math.PI * moebius.current.position.y) / viewport.height
    }

    if (thumb.current != null || thumb.current != undefined) {
      thumb.current.rotation.y = touchDevice
        ? 0
        : THREE.MathUtils.lerp(thumb.current.rotation.y, (-Math.PI * 2 * y) / viewport.height, instant ? 1 : 0.1)
      credit.current.rotation.y = -thumb.current.rotation.y
    }

    if (group.current != null || group.current != undefined) {
      group.current.position.lerp(vec.set(0, y, instant ? 200 : 0), instant ? 1 : 0.15)
      tagGroup.current.position.lerp(vec.set(0, -y, 0), instant ? 1 : 0.15)
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])

  const ratio = viewport.width / viewport.height
  const scale = Math.min(1, viewport.width / 16)

  return (
    <>
      <ProjectColorLights projectColors={['red', 'blue']} lightIntensity={7} />
      <group ref={group} position-z={200}>
        <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]}>
          <group ref={tagGroup} position-y={0} position-z={-200}>
            <Box dir="row" height={0} width="100%" wrap="wrap" justify="center" alignSelf="space-around" align="space-around">
              {ratio > 1 && !touchDevice
                ? content.works.tagStrings.map((tag, index) => (
                    <group key={index} position-z={-4}>
                      <Box centerAnchor>
                        <Text
                          bold
                          fontSize={1.35 * scale}
                          letterSpacing={-0.04}
                          position={[0, 0, 0]}
                          textAlign={'center'}
                          lineHeight={1.25}
                          anchorY={'middle'}
                          anchorX={'center'}>
                          {tag.toUpperCase()}
                          <meshBasicMaterial
                            opacity={0.005 + 0.02 * tagOpacity[index]}
                            transparent
                            blending={THREE.AdditiveBlending}
                            depthTest={false}
                          />
                        </Text>
                      </Box>
                      <Box centerAnchor mr={0.35} ml={0.35} />
                    </group>
                  ))
                : null}
              {ratio > 1 && !touchDevice
                ? content.works.tagStrings.map((tag, index) => (
                    <group key={index} position-z={-4}>
                      <Box centerAnchor>
                        <Text
                          bold
                          fontSize={1.35 * scale}
                          letterSpacing={-0.04}
                          textAlign={'center'}
                          lineHeight={1.25}
                          anchorY={'middle'}
                          anchorX={'center'}>
                          {tag.toUpperCase() + ' '}
                          <meshBasicMaterial
                            opacity={0.005 + 0.02 * tagOpacity[index]}
                            transparent
                            blending={THREE.AdditiveBlending}
                            depthTest={false}
                          />
                        </Text>
                      </Box>
                      <Box centerAnchor mr={0.35} ml={0.35} />
                    </group>
                  ))
                : null}
            </Box>
          </group>
        </Flex>
        <group ref={thumb}>
          <Flex
            dir="column"
            position={[-viewport.width / 2, viewport.height / 2, 0]}
            size={[viewport.width, viewport.height, 0]}
            onReflow={handleReflow}>
            <Box dir="row" width="auto" height="auto" wrap="wrap">
              {content.works.projects.map((props, index) => (
                <Box key={index} centerAnchor height={viewport.height} width="100%">
                  <Thumbnail
                    key={index}
                    index={index}
                    textureImage={newImage}
                    textureThumb={newThumb}
                    bgColor={backgroundColor}
                    fgColor={foregroundColor}
                    hoverColor={content.works.skin.hoverColor}
                    touch={touchDevice}
                    scale={1.15 * scale}
                    {...props}
                  />
                </Box>
              ))}
              <group ref={credit}>
                <Box height={0} width="100%">
                  <group position-y={1} centerAnchor>
                    <CopyrightFooter background={false} />
                  </group>
                </Box>
              </group>
            </Box>
          </Flex>
        </group>

        <group ref={moebius} position-z={2000}>
          <Moebius
            model={content.home.section.main.modelNear}
            bgColor={backgroundColor}
            fgColor={foregroundColor}
            textureImage={newImage}
            opacity={worksExternal ? 0 : 1}
            scale={1500 * scale}
            shaded={false}
          />
        </group>
      </group>
    </>
  )
}
function Who({ onReflow }) {
  invertScreen = false

  const texture = useLoader(THREE.TextureLoader, '/images/who/final_strip.jpg')

  const [hovered, setHover] = useState(-1)

  if (currentUrl != '/who') {
    topFunction()
    currentUrl = '/who'
  }

  foregroundColor = content.who.skin.foreground
  backgroundColor = content.who.skin.background
  const itemExperience = useRef(new Array())

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.backgroundColor = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  const pageLerp = useRef(state.top / size.height)

  const ratio = viewport.width / viewport.height

  const mug = useRef()
  const lightRef = useRef()

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current != null || group.current != undefined) {
      group.current.position.y = y
    }

    if (lightRef.current != null || lightRef.current != undefined) {
      lightRef.current.rotation.z -= 0.005
      lightRef.current.children[2].color.offsetHSL(0.0001, 0, 0)
      lightRef.current.children[0].color.offsetHSL(0.001, 0, 0)
    }

    if (mug.current != null || mug.current != undefined) {
      mug.current.rotation.y = THREE.MathUtils.lerp(mug.current.rotation.y, state.mouse[0] / 10, 0.05)
      mug.current.rotation.x = THREE.MathUtils.lerp(mug.current.rotation.x, state.mouse[1] / 10, 0.05)
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  const itemCompanies = useRef(new Array())

  const proportionalScale = Math.min(1, viewport.width / 16)

  return (
    <>
      <group ref={group}>
        <Flex
          dir="column"
          position={[-viewport.width / 2, viewport.height / 2, 0]}
          size={[viewport.width, viewport.height, 0]}
          onReflow={handleReflow}
          align="center">
          <Spacer />
          <TextImage
            title={`Hi!`}
            imagesCredit={'Jairo Braga'}
            titleColor={'black'}
            text={content.who.cover.text}
            image={content.who.cover.image}
            imageOpacity={1}
            left={true}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}
          />
          <Box height="0" width="0">
            <group position={[0, -viewport.height / 2 - 10, 0]}>
              {touchDevice ? null : (
                <Moebius
                  model={content.home.section.main.model}
                  textureImage={texture}
                  bgColor={backgroundColor}
                  fgColor={foregroundColor}
                  scale={75}
                  rotateY={false}
                  rotateZ={false}
                  rotationOffset={[0, Math.PI / 3.5, 0]}
                  position={[0, -35, 10]}
                  floatModel={false}
                  additive={false}
                  speed={0.15}
                  order={15}
                  shaded={false}
                />
              )}
            </group>
          </Box>
          <Spacer />

          <HighlightText text={`I'm a designer who explores evolving technologies while answering to new contexts.`} />
          <Spacer />

          <Box dir="row" width="100%" justify="center" align="center">
            {ratio > 1 && !touchDevice ? (
              <group>
                <Box dir="column" width="auto" height="auto">
                  <Text
                    bold
                    color={foregroundColor}
                    position-z={5}
                    fontSize={0.5}
                    fillOpacity={0.75}
                    textAlign={'center'}
                    anchorY={'middle'}
                    anchorX={'center'}>
                    {'approach'.toUpperCase()}
                  </Text>

                  <group ref={lightRef}>
                    <pointLight position={[5, 0, 1]} intensity={100} distance={50} color={'cyan'} />
                    <pointLight position={[0, 0, 0]} intensity={5} />
                    <pointLight position={[0, 0, 20]} intensity={10} distance={50} color={'red'} />
                  </group>

                  {content.who.skillsHeader.labels.map((tag, index) => (
                    <group key={index} rotation-z={((Math.PI * 2) / content.who.skillsHeader.labels.length) * index + Math.PI / 2}>
                      {
                        <group position={[3.5, 0, 0]} rotation-z={-(((Math.PI * 2) / content.who.skillsHeader.labels.length) * index + Math.PI / 2)}>
                          <Sphere args={[3.49, 6, 3]}>
                            <meshLambertMaterial
                              flat={true}
                              attach="material"
                              color={foregroundColor}
                              transparent={true}
                              opacity={hovered == index ? 0.15 : 0.075}
                              depthWrite={false}
                            />
                          </Sphere>
                          <Sphere args={[1.75]} visible={false} onPointerOver={() => setHover(index)} onPointerOut={() => setHover(-1)} />
                          <Text
                            bold
                            color={foregroundColor}
                            position-z={1}
                            fontSize={0.4}
                            fillOpacity={0.9}
                            textAlign={'center'}
                            anchorY={'middle'}
                            anchorX={'center'}>
                            {tag}
                          </Text>
                          {content.who.skillsHeader.content[index].map((tag, index2) => (
                            <group key={index2} rotation-z={((Math.PI * 2) / content.who.skillsHeader.content[index].length) * index2 + Math.PI / 2}>
                              <Text
                                rotation-z={-(((Math.PI * 2) / content.who.skillsHeader.content[index].length) * index2 + Math.PI / 2)}
                                position={[1.75, 0, 0]}
                                color={foregroundColor}
                                fontSize={0.2}
                                textAlign={'center'}
                                anchorY={'middle'}
                                anchorX={'center'}>
                                {tag}
                                <meshBasicMaterial
                                  attach="material"
                                  opacity={hovered == index ? 0.75 : 0.01}
                                  visible={hovered == index}
                                  transparent={false}
                                />
                              </Text>
                            </group>
                          ))}
                        </group>
                      }
                    </group>
                  ))}
                </Box>
              </group>
            ) : (
              <group>
                <Box dir="row" width="100%" m={0.5} align={'center'} justify={'center'} mt={1.5} mb={1.5}>
                  <Box m={0.35}>
                    <Text
                      bold
                      rotation-z={Math.PI / 2}
                      color={foregroundColor}
                      fontSize={1.25}
                      fillOpacity={0.25}
                      textAlign={'center'}
                      anchorX={'right'}>
                      {'Skills'.toUpperCase()}
                    </Text>
                  </Box>
                  <Box align="flex-start">
                    {content.who.skillsHeader.labels.map((tag, index) => (
                      <group key={index}>
                        <Box dir="row" mb={0.2} wrap={ratio > 1 ? 'no-wrap' : 'wrap'}>
                          <Box dir="row" mr={0.15}>
                            <Text color={foregroundColor} fontSize={0.35}>
                              {tag.toUpperCase()}
                            </Text>
                          </Box>
                          <Box dir="row">
                            {ratio > 1
                              ? content.who.skillsHeader.content[index].map((tag2, index2) => (
                                  <group key={index2}>
                                    <Box dir="row" wrap="no-wrap" mr={0.2}>
                                      <Text color={foregroundColor} fontSize={0.35} fillOpacity={0.5}>
                                        {tag2.replace(/(\r\n|\n|\r)/gm, ' ').toLowerCase()}
                                      </Text>
                                    </Box>
                                  </group>
                                ))
                              : null}
                          </Box>
                        </Box>
                      </group>
                    ))}
                  </Box>
                </Box>
              </group>
            )}
          </Box>

          <SectionTitle text={'when & where'} />
          <Spacer mt={0} />
          <Box dir="row" width="100%" justifyContent="space-around" wrap="wrap" alignItems="baseline">
            {content.who.timelineExperience.map((props, index) => (
              <Timeline
                key={index}
                index={index}
                item={itemExperience}
                onReflow={(w, h) => {
                  sizesRef.current[index] = h
                }}
                {...props}
              />
            ))}
          </Box>

          <SectionTitle text={`I've already worked with`} />
          <Spacer mt={0} />
          <Box
            dir="row"
            width="75%"
            minHeight={viewport.height / 2}
            justifyContent="center"
            wrap="wrap"
            alignItems="baseline"
            alignSelf="center"
            mt={0}
            mb={1}>
            {content.who.timelineCompanies.map((props, index) => (
              <ColumnItems
                imageOpacity={0.75}
                imageSize={1.5}
                gradient={false}
                key={index}
                index={index}
                item={itemCompanies}
                onReflow={(w, h) => {
                  sizesRef.current[index] = h
                }}
                {...props}
              />
            ))}
          </Box>

          <SectionTitle text={`classes & mentorships`} />
          <Spacer mt={0} />
          <Box
            dir="row"
            width="75%"
            minHeight={viewport.height / 4}
            wrap="wrap"
            justifyContent="center"
            justify="center"
            alignItems="baseline"
            alignSelf="center"
            mt={0}
            mb={0}>
            {content.who.timelineClasses.map((props, index) => (
              <ColumnItems
                imageOpacity={0.75}
                imageSize={1.5}
                gradient={false}
                key={index}
                index={index}
                item={itemCompanies}
                onReflow={(w, h) => {
                  sizesRef.current[index] = h
                }}
                {...props}
              />
            ))}
          </Box>

          <TextImage
            title={`Design & Technology`}
            imagesCredit={'Felipe Gabriel - Red Bull'}
            titleColor="black"
            text={content.who.mentoring.text}
            image={content.who.mentoring.image}
            imageOpacity={0.95}
            left={true}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}
          />

          <ImageMosaic images={content.who.mosaicMentoring1} imagesCredit={content.who.mosaicMentoringCredits1} opacity={0.99} />
          <ImageMosaic images={content.who.mosaicMentoring2} imagesCredit={content.who.mosaicMentoringCredits2} creditOffset={0.35} opacity={0.99} />

          <TextText
            sizeMultiplier={0.6}
            textFirst={content.who.texts[0]}
            textSecond={content.who.texts[1]}
            titleColor="black"
            title={`Design talks & media`}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}
          />

          <Box width="100%" height="0" dir="row" align="center" justify="center">
            <Box width="0" height="0">
              <group position={[0, -viewport.height / 2, ratio > 1 ? -10 : -20]} rotation-y={ratio > 1 ? 0 : -Math.PI / 8}>
                <pointLight position={[0, -2, 20]} distance={40} decay={1} intensity={10} color={'white'} />
                <ModelSimple
                  steadyRot={true}
                  steadyPos={false}
                  model={'/models/coffee.glb'}
                  wireframe={true}
                  scale={12}
                  light={false}
                  opacity={0.2}
                  fgColor={'white'}
                />
                <group ref={mug}>
                  <ModelSimple
                    steadyRot={true}
                    steadyPos={false}
                    model={'/models/mug.glb'}
                    wireframe={true}
                    scale={12}
                    light={false}
                    opacity={0.2}
                    fgColor={'white'}
                  />
                </group>
                <ModelSimple
                  steadyRot={true}
                  steadyPos={false}
                  model={'/models/head.glb'}
                  wireframe={true}
                  scale={12}
                  light={false}
                  opacity={ratio > 1 ? 0.2 : 0}
                  fgColor={'white'}
                />
              </group>
            </Box>
          </Box>

          <Box dir="row" align="center" justify="center" width="100%" marginTop={0} minHeight={viewport.height}>
            <Box dir="column" height="auto" width="auto" align="center" mt={2}>
              <Box align="center" width="auto" justify="center" mb={0.5}>
                <Text
                  textAlign="center"
                  anchorX="center"
                  anchorY="bottom"
                  fontSize={Math.max(1 * proportionalScale, 0.4)}
                  color={foregroundColor}
                  maxWidth={(viewport.width / 10) * 8}>
                  {`let's get in touch!`}
                </Text>
              </Box>
              <Box dir="row" align="center" width="auto">
                <Box marginRight={1} marginLeft={1}>
                  <Html center zIndexRange={[1, 0]}>
                    <a href="https://www.linkedin.com/in/andreisperid/" className="icon-bar" target="_blank" rel="noopener noreferrer">
                      <InlineIcon icon={linkedinIcon} style={{ color: foregroundColor, fontSize: 50 }} />
                    </a>
                  </Html>
                </Box>
                <Box marginRight={1} marginLeft={1}>
                  <Html center zIndexRange={[1, 0]}>
                    <a href="mailto:hi@andrei.cc" className="icon-bar" target="_blank" rel="noopener noreferrer">
                      <InlineIcon icon={emailIcon} style={{ color: foregroundColor, fontSize: 50 }} />
                    </a>
                  </Html>
                </Box>
              </Box>
            </Box>
          </Box>

          <CopyrightFooter background={false} />
        </Flex>
      </group>
    </>
  )
}

// splash screens ------------------------------------------------------------------------

function SplashMoebius({ onReflow }) {
  invertScreen = false

  const totalQuestions = content.works.projects.length - 1
  let visible = true

  const [newTargetOpacity, setnewTargetOpacity] = useState(1)

  if (currentUrl != '/splashmoebius') {
    if (state.homeCounter == 1) {
      state.homeIndex = Math.floor(Math.random() * totalQuestions)
    } else {
      state.homeCounter = 1
    }
    topFunction()
    currentUrl = '/splashmoebius'
  }
  foregroundColor = content.home.skin.foreground
  backgroundColor = content.home.skin.background

  const images = content.works.projects.map((x) => x.stripImage)
  const textureStrip = useLoader(THREE.TextureLoader, images)

  const [newImage, setnewImage] = useState(textureStrip[state.homeIndex])
  const [newLights, setnewLights] = useState([content.works.projects[state.homeIndex].background, content.works.projects[state.homeIndex].foreground])

  colorA = newLights[0]
  colorB = newLights[1]

  const shuffle = useCallback(() => {
    if (visible) {
      state.homeIndex++
      if (state.homeIndex > totalQuestions) {
        state.homeIndex = 0
      }
      state.homeCounter++

      colorA = content.works.projects[state.homeIndex].background
      colorB = content.works.projects[state.homeIndex].foreground

      setnewImage(textureStrip[state.homeIndex])
      setnewLights([content.works.projects[state.homeIndex].background, content.works.projects[state.homeIndex].foreground])
    }
  }, [textureStrip, totalQuestions, visible])

  const flip = useCallback(() => {
    if (visible) {
      setnewTargetOpacity(0.25)
      setTimeout(() => {
        shuffle()
        setnewTargetOpacity(1)
      }, 250)
    }
  }, [shuffle, visible])

  document.addEventListener('visibilitychange', function (event) {
    if (document.hidden) {
      visible = false
    } else {
      visible = true
    }
  })

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.backgroundColor = backgroundColor
    document.body.style.background = backgroundColor
    // document.body.style.cursor = hovered ? 'pointer' : 'auto'

    if (visible) {
      const intervalFlip = setInterval(flip, 6000)
      return () => clearInterval(intervalFlip)
    }
  }, [visible, flip])

  const group = useRef()
  const { viewport, size } = useThree()
  const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  const moebius = useRef()
  const moebiusPos = new THREE.Vector3()

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 1))
    const y = page * viewport.height

    if (moebius.current != null || moebius.current != undefined) {
      moebius.current.position.lerp(moebiusPos.set(0, -y - 0.5, y * 1.75), 0.1)
    }
    if (group.current != null || group.current != undefined) {
      group.current.position.lerp(vec.set(0, y, 0), 0.15)
    }
  })
  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const scale = Math.min(1, viewport.width / 16)

  return (
    <>
      <ProjectColorLights lightIntensity={3} />

      <group ref={group}>
        <Flex dir="column" size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
          <OrbitControls enablePan={true} enableZoom={true} enableRotate={true} />

          <group ref={moebius} position-z={10} name="moebius group">
            <Moebius
              name="moebius function"
              model={content.home.section.main.model}
              textureImage={newImage}
              bgColor={backgroundColor}
              fgColor={foregroundColor}
              targetOpacity={newTargetOpacity}
              scale={Math.max(7.5 * scale, 4.5)}
              hovered={true}
              rotateY={true}
              rotateZ={true}
              rotationOffset={[0, 0, 0]}
              floatModel={true}
              shaded={true}
            />
          </group>

          <Box dir="column" width="100%" height={viewport.height * 2} align="center" justify="flex-end"></Box>
        </Flex>
      </group>
    </>
  )
}
function StripCover({ projectKey, category }) {
  const { viewport } = useThree()
  const project = content[category].projects[projectKey]
  const [textureCoverImage, textureAlpha] = useLoader(THREE.TextureLoader, [project.coverImage, '/images/background_gradient.jpg'])
  const backgroundRef = useRef()

  textureCoverImage.repeat.y = 0
  textureCoverImage.wrapS = THREE.MirroredRepeatWrapping
  textureCoverImage.wrapT = THREE.MirroredRepeatWrapping

  useFrame(({ clock }) => {
    if (textureCoverImage != null) {
      textureCoverImage.offset.set(0, 0.5 + (clock.getElapsedTime() * 0.3) / 50)
    }
    if (backgroundRef.current != null && backgroundRef.current != undefined) {
      backgroundRef.current.material.repeat = [1, 0]
      backgroundRef.current.material.offset = [0, 0.5 + (clock.getElapsedTime() * 0.3) / 50]
    }
  })

  return (
    <>
      <Box dir="row" align={'flex-start'} height="auto" width="auto" margin={0} justifyContent="center">
        <mesh position={[viewport.width / 2, -viewport.height / 2, 0]} ref={backgroundRef}>
          <planeBufferGeometry args={[viewport.width, viewport.height * 2]} />
          <doubleChannelMaterial opacity={0.5} attach="material" map={textureCoverImage} alphaMap={textureAlpha} transparent={true} />
        </mesh>
      </Box>
    </>
  )
}
function SplashStrip({ onReflow }) {
  invertScreen = false

  if (currentUrl != '/splashstrip') {
    topFunction()
    currentUrl = '/splashstrip'
  }

  const [newKey, setNewKey] = useState(0)

  let project = content.works.projects[newKey]
  foregroundColor = project.foreground
  backgroundColor = project.background

  // console.log(document.getElementById('root'))

  useEffect(() => {
    document.getElementById('root').style.color = 'foregroundColor'
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const { viewport } = useThree()
  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])

  return (
    <>
      <StaticLights />
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <group onClick={() => setNewKey(newKey < content.works.projects.length - 1 ? newKey + 1 : 0)}>
          <StripCover projectKey={newKey} category={'works'} />
        </group>
      </Flex>
    </>
  )
}
function Besides({ onReflow }) {
  invertScreen = false

  fnBrowserDetect()

  // console.log("besides")

  let sidebar = document.getElementById('scrollDiv')

  const [inside, setInside] = useState(false)
  const [hovered, setHover] = useState(false)

  if (currentUrl != '/besides' && sidebar != undefined) {
    topFunction()
    currentUrl = '/besides'
  }

  if (previousPages != state.pages) {
    if (besidesScroll !== null) {
      sidebar.scrollTop = besidesScroll
      state.top = besidesScroll
      previousPages = state.pages

      if (besidesScroll > 0) {
        besidesExternal = true
      }
    }
  }

  window.addEventListener('mousedown', () => {
    if (currentUrl == '/besides') {
      besidesScroll = sidebar.scrollTop
    }
  })

  const vecQuat = new THREE.Quaternion()

  // if (touch && browserName != 'safari' && browserName != 'firefox') { // throwing error (firefox) "TypeError: window.AbsoluteOrientationSensor is not a constructor"
  if (touchDevice && browserName == 'chrome') {
    const options = { frequency: 60, referenceFrame: 'device' }
    const sensor = new window.AbsoluteOrientationSensor(options)

    // if (touchDevice) {
    sensor.addEventListener('reading', () => {
      state.imu = sensor.quaternion
    })
    sensor.start()
    // }
  } else {
    //TODO iOS, Safari & Firefox compatibility
  }

  foregroundColor = '#fff9eb'
  backgroundColor = '#000208'

  const universe = useRef()
  const mobile = useRef()
  const offset = useRef()
  const hazeIn = useRef()
  const hazeOut = useRef()
  const phrase = useRef()
  const coords = useRef()
  const zoomLevel = useRef()
  const compass = useRef()

  const vecCoords = new THREE.Vector2(0, 0)
  const vecTargetCoords = new THREE.Vector2(-23.536230209266932, -46.68185474434041)

  const { viewport, size } = useThree()
  const vecPos = new THREE.Vector3()
  const proportionalScale = Math.min(1, viewport.width / 16)

  const textureAlpha = useLoader(THREE.TextureLoader, '/images/bokeh_blur.jpg')
  textureAlpha.center = new THREE.Vector2(0.5, 0.5)

  const [textureSpace, textureStars, textureCompass] = useLoader(THREE.TextureLoader, [
    '/images/besides/eso0932a_low.jpg',
    '/images/besides/stars.jpg',
    '/images/besides/compass.png',
  ])

  textureStars.wrapS = THREE.RepeatWrapping
  textureStars.wrapT = THREE.RepeatWrapping
  textureStars.repeat = new THREE.Vector2(10, 1)

  const ratio = viewport.width / viewport.height

  const [background] = useLoader(XYZLoader, ['/models/besides/background.xyz'])
  const scale = Math.min(1, viewport.width / 16)
  const textureBackgroundAlphaHeader = useLoader(THREE.TextureLoader, '/images/linear_inverted_gradient.jpg')

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.backgroundColor = backgroundColor

    document.getElementById('scrollDiv').style.scroll = 'none'

    document.body.style.background = backgroundColor
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  })

  useFrame(({ clock }) => {
    if (hazeIn.current != null || hazeIn.current != undefined) {
      hazeIn.current.rotation.z += 0.002
      hazeIn.current.rotation.x += 0.001
    }
    if (hazeOut.current != null || hazeOut.current != undefined) {
      hazeOut.current.rotation.y -= 0.001
      hazeOut.current.rotation.x -= 0.002
    }

    if (universe.current != null || universe.current != undefined) {
      const t = (1 + Math.sin(clock.getElapsedTime() * 0.2)) / 2
      // console.log(state.top / size.height)

      if (state.top / size.height <= 0.5) {
        universe.current.rotation.x = Math.cos((t * 10) / 2) / 10
        universe.current.rotation.y += 0.001

        phrase.current.rotation.x = THREE.MathUtils.lerp(phrase.current.rotation.x, inside ? Math.PI / 2 : 0, 0.05)
        coords.current.rotation.x = THREE.MathUtils.lerp(coords.current.rotation.x, !inside ? Math.PI / 2 : 0, 0.05)
        universe.current.position.lerp(vecPos.set(viewport.width / 2, -viewport.height / 2, -30), 0.05)
        universe.current.position.y += Math.cos(t * 10) / 200

        setInside(false)
      } else {
        setInside(true)
        universe.current.rotation.y += !touchDevice ? state.mouse[0] / 75 : 0

        universe.current.rotation.x = THREE.MathUtils.lerp(
          universe.current.rotation.x,
          (!touchDevice ? state.mouse[1] / 3 : 0) + Math.sin((t * 10) / 3) / 30,
          0.025
        )

        universe.current.position.lerp(vecPos.set(viewport.width / 2, -viewport.height / 2, touchDevice && !(ratio > 1) ? 25 : 22), 0.05)
        phrase.current.rotation.x = THREE.MathUtils.lerp(phrase.current.rotation.x, inside ? Math.PI / 2 : 0, 0.15)
        coords.current.rotation.x = THREE.MathUtils.lerp(coords.current.rotation.x, !inside ? Math.PI / 2 : 0, 0.15)

        vecCoords.lerp(vecTargetCoords, 0.25)
        coords.current.children[0].text = 'Rua Cayowaa, São Paulo, Brasil\n' + vecCoords.x + ', ' + vecCoords.y
      }

      zoomLevel.current.children[0].children[0].fillOpacity = inside ? 0.1 : 0.35
      zoomLevel.current.children[1].children[0].fillOpacity = inside ? 0.35 : 0.1

      universe.current.position.z -= Math.sin(t * 8) / 200

      if (touchDevice && inside) {
        mobile.current.quaternion.slerp(vecQuat.set(state.imu[0], state.imu[1], state.imu[2], state.imu[3]).invert(), 0.05)
        offset.current.rotation.x = Math.PI / 2
        offset.current.rotation.y = -Math.PI / 1.5
      } else {
        offset.current.rotation.x = 0
        offset.current.rotation.y = 0
        mobile.current.rotation.x = 0
        mobile.current.rotation.y = 0
        mobile.current.rotation.z = 0

        universe.current.rotation.z = Math.cos((t * 10) / 2) / 30
        besidesRotation = touchDevice ? 0 : universe.current.rotation
        compass.current.rotation.z = besidesRotation.y
      }

      textureAlpha.rotation = THREE.MathUtils.lerp(textureAlpha.rotation, (state.mouse[0] + state.mouse[1]) * 5 + t * 5, 0.01)
    }
  })
  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])

  var today = new Date()

  return (
    <>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        {/* credits  */}
        <group position={[viewport.width / 2, -viewport.height + 0.75, 0]}>
          <Text
            textAlign="center"
            anchorX="center"
            anchorY={'bottom'}
            fontSize={Math.max(0.25 * 1 - proportionalScale, 0.225)}
            lineHeight={1.5}
            color={foregroundColor}
            renderOrder={6}>
            <meshBasicMaterial blending={THREE.AdditiveBlending} depthWrite={false} depthTest={false} transparent opacity={0.1} />
            {/* {'© andrei speridião 2006 - ' + today.getFullYear()} */}
            {'stop making sense, making – 2020...22'}
          </Text>
          <mesh position-x={viewport.width / 2} position-z={-0.01} position-y={-0.5} renderOrder={5} rotation={[0, 0, Math.PI]}>
            <planeBufferGeometry args={[viewport.width * 2, 4]} />
            <meshBasicMaterial color={backgroundColor} alphaMap={textureBackgroundAlphaHeader} transparent opacity={1} />
          </mesh>
        </group>

        {/* phrase */}
        <group
          ref={phrase}
          position={[viewport.width / 2, -viewport.height / 2, 0]}
          rotation={[Math.PI, 0, 0]}
          onPointerOver={(e) => (setHover(!inside), e.stopPropagation())}
          onPointerOut={(e) => (setHover(false), e.stopPropagation())}
          onClick={(e) => (document.getElementById('start').scrollIntoView(), (document.body.style.cursor = 'auto'), e.stopPropagation())}>
          <Text
            textAlign="center"
            anchorX="center"
            anchorY="middle"
            maxWidth={(viewport.width / 8) * 7}
            fontSize={touchDevice && !(ratio > 1) ? 1 : 3}
            // letterSpacing={-0.025}
            // letterSpacing={0.035}
            bold
            // curveRadius={10}
            color={'#394750'}>
            <meshBasicMaterial blending={THREE.AdditiveBlending} depthWrite={false} depthTest={false} transparent opacity={hovered ? 0.2 : 0.5} />
            {/* {('stop making' + (touchDevice && ratio <= 1 ? `\n\n\n\n\n\n` : '') + ' sense, making').toUpperCase()} */}
            {'be–sides'}
          </Text>
        </group>

        {/* coords */}
        <group position={[0.75, -viewport.height + 1, 0]}>
          <group position={[0.35, 0, 0]} ref={coords} rotation={[Math.PI, 0, 0]}>
            <Text
              textAlign="left"
              anchorX="left"
              anchorY="middle"
              fontSize={0.2}
              lineHeight={1.3}
              letterSpacing={0.15}
              color={foregroundColor}
              renderOrder={6}>
              <meshBasicMaterial blending={THREE.AdditiveBlending} depthWrite={false} depthTest={false} transparent opacity={0.3} />
              {/* {"placeholder"} */}
            </Text>
          </group>

          <Line
            points={[
              [0, 0.15, 0],
              [0, 0.2, 0],
            ]} // Array of points
            color="white" // Default
            lineWidth={0.2} // In pixels (default)
            opacity={0.4}
            transparent={true}
            dashed={false} // Default
            renderOrder={6}
            blending={THREE.AdditiveBlending}
            depthWrite={false}
            depthTest={false}
          />
          {/* compass */}
          <group ref={compass}>
            <mesh renderOrder={6} rotation={[0, 0, Math.PI / 1.5]}>
              <planeBufferGeometry args={[0.4, 0.4]} />
              <meshBasicMaterial
                color={foregroundColor}
                blending={THREE.AdditiveBlending}
                depthWrite={false}
                depthTest={false}
                alphaMap={textureCompass}
                transparent
                opacity={0.3}
              />
            </mesh>
          </group>
        </group>

        {/* map */}
        <group ref={zoomLevel} position={[viewport.width - 0.75, -viewport.height * 0.5, 0]}>
          <group position-y={0.1}>
            <Text textAlign="right" anchorX="right" anchorY="bottom" fontSize={0.2} color={'white'}>
              <meshBasicMaterial blending={THREE.AdditiveBlending} depthWrite={false} depthTest={false} transparent />
              {'-\naround'}
            </Text>
          </group>
          <group position-y={-0.1}>
            <Text textAlign="right" anchorX="right" anchorY="top" fontSize={0.2} color={'white'}>
              <meshBasicMaterial blending={THREE.AdditiveBlending} depthWrite={false} depthTest={false} transparent />
              {'within\n+'}
            </Text>
          </group>

          <Line
            points={[
              [-0.8, 0, 0],
              [0.75, 0, 0],
            ]} // Array of points
            color="white" // Default
            lineWidth={0.2} // In pixels (default)
            opacity={0.5}
            transparent={true}
            dashed={false} // Default
            vertexColors={[
              [0, 0, 0],
              [1, 1, 1],
            ]}
            renderOrder={6}
            blending={THREE.AdditiveBlending}
            depthWrite={false}
            depthTest={false}
          />
        </group>

        {/* <axesHelper />
        <gridHelper /> */}

        {/* universe */}
        <group
          position={[viewport.width / 2, -viewport.height / 2, 100]}
          scale={[scale, scale, scale]}
          rotation={touchDevice ? [0, 0, 0] : besidesRotation}
          ref={universe}>
          {/* mobile */}
          <group ref={mobile}>
            {/* offset */}
            <group ref={offset}>
              {/* points */}
              <group position={[1, -12, -8]} rotation={[-Math.PI / 2, 0, 0]}>
                <points geometry={background} renderOrder={2}>
                  <pointsMaterial
                    sizeAttenuation={true}
                    attach="material"
                    vertexColors={true}
                    color={'#272420'}
                    alphaMap={textureAlpha}
                    depthWrite={false}
                    depthTest={false}
                    blending={THREE.AdditiveBlending}
                    size={(touchDevice ? 3 : 4) * (ratio > 1 ? 1 : 0.3)}
                    transparent={true}
                    opacity={inside ? 0.1 : 0.05}
                  />
                </points>

                <group onPointerOver={(e) => e.stopPropagation()} onPointerOut={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
                  <BoxGeo args={[40, 30, 25]} position={[-3, -5, 13]}>
                    <meshBasicMaterial color={'red'} side={THREE.FrontSide} visible={false} />
                  </BoxGeo>
                </group>

                {content.besides.projects.map((props, index) => (
                  <CloudThumb
                    position={[1, 8, 0]}
                    key={index}
                    index={index}
                    titleVisible={inside}
                    inside={inside}
                    touch={!(ratio > 1)}
                    bgColor={backgroundColor}
                    fgColor={foregroundColor}
                    size={!touchDevice && ratio > 1 ? 0.75 : 0.5}
                    {...props}
                  />
                ))}
              </group>

              {/* background */}
              <group scale={[70 * (ratio > 1 ? 1 : 2), 70 * (ratio > 1 ? 1 : 2), 70 * (ratio > 1 ? 1 : 2)]}>
                <group rotation={[-Math.PI / 2, 0, 0]} position={[0, 200, 0]}>
                  <Nebula model={'/models/besides/works.glb'} scale={0.25} />
                  <Constellation
                    cloud={'/models/besides/works_low.xyz'}
                    intensity={0.15 * (!touchDevice ? 1 : 0.5)}
                    background={true}
                    scale={0.0025}
                  />
                  <Constellation cloud={'/models/besides/works_low.xyz'} intensity={0.025} background={false} scale={0.0025} />
                </group>

                <Sphere args={[3]} rotation={[0, -Math.PI / 8, -Math.PI / 20]}>
                  <meshBasicMaterial color={'#726b85'} map={textureSpace} side={THREE.BackSide} blending={THREE.AdditiveBlending} />
                </Sphere>

                <Sphere args={[2.7]} ref={hazeIn}>
                  <meshBasicMaterial color={'#813d2b'} map={textureStars} side={THREE.BackSide} blending={THREE.AdditiveBlending} />
                </Sphere>

                <Sphere args={[2]} rotation={[0.2, 0.1, 0.6]} ref={hazeOut}>
                  <meshBasicMaterial color={'#3b2987'} map={textureStars} side={THREE.BackSide} blending={THREE.AdditiveBlending} />
                </Sphere>

                <Stars radius={0} depth={1} count={15000} factor={3} saturation={1} fade />
              </group>
            </group>
          </group>
        </group>

        <Box dir="row" width="auto" height="auto" wrap="wrap">
          <Box centerAnchor height={viewport.height} width="100%"></Box>
          <Box centerAnchor height={viewport.height} width="100%"></Box>
        </Box>
      </Flex>
    </>
  )
}

// works
function Collab({ onReflow }) {
  invertScreen = false

  let projectCaption = 'collab'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, y, 0), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  const item = useRef(new Array())
  const item2 = useRef(new Array())

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <Cover
          left={true}
          projectKey={projectKey}
          category={'works'}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextTitle
          title={`2020 questioned physical presence`}
          image={project.images[2]}
          text={project.texts[0]}
          imageOpacity={0.5}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic opacity={0.5} images={project.mosaicImages1} />

        <Spacer />
        <HighlightText text={'A remote immersive storytelling tool...'} />
        <VideoFull videoSrc={'/images/works/collab/collab_scene.mp4'} />
        <HighlightText text={'... for designers, artists, writers, engineers and users to meet and co-create.'} />
        <HighlightModel model={project.model} />

        <TextTitle
          title={`challenges`}
          subTitle={'Multidisciplinarity, scalability and flexibility'}
          image={project.images[4]}
          text={project.texts[2]}
          imageOpacity={0.5}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <SectionTitle text={'design process'} />
        <TextImage
          title={'From physical OR digital\nto ...hybrid prototyping'}
          text={project.texts[1]}
          image={project.images[3]}
          imageOpacity={0.5}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Benchmark'}
          text={project.texts[8]}
          image={project.images[8]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextModel
          title={`Chunks of voxels`}
          text={project.texts[3]}
          model={'/models/works/collab_1.glb'}
          modelOpacity={0.75}
          modelScale={7}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'UI foundations'}
          text={project.texts[6]}
          image={project.images[10]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <SectionTitle text={'interaction principles'} />
        <Box dir="row" width="100%" minHeight={viewport.height / 2} justifyContent="center" wrap="wrap" alignItems="baseline" mt={0} mb={2}>
          {project.interaction.map((props, index) => (
            <ColumnItems
              imageSize={4.5}
              textInside={false}
              imageOpacity={0.75}
              key={index}
              index={index}
              item={item}
              onReflow={(w, h) => {
                sizesRef.current[index] = h
              }}
              {...props}
            />
          ))}
        </Box>
        <TextImage
          title={'Real tests and iterations'}
          text={project.texts[4]}
          image={project.images[6]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'6 months total: MVP delivery with advanced features'}
          text={project.texts[5]}
          image={project.images[5]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        {/* columns */}
        <SectionTitle text={'all features'} />
        <Box dir="row" width="100%" minHeight={viewport.height / 2} justifyContent="center" wrap="wrap" alignItems="baseline" mt={0} mb={2}>
          {project.timeline.map((props, index) => (
            <ColumnItems
              key={index}
              index={index}
              item={item2}
              onReflow={(w, h) => {
                sizesRef.current[index] = h
              }}
              {...props}
            />
          ))}
        </Box>

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        {/* tool icons */}
        <SectionTitle text={'hands are the basic tools'} />
        <ImageFull image={project.images[11]} imagesCaptions={''} />

        <HighlightText text={'embed a gem to "specialize" the tool'} />
        <Box dir="row" alignSelf="center" alignContent="center" justifyContent="center" height="auto" width="100%" mt={0} wrap="wrap">
          <pointLight position={[0, 5, 10]} range={25} />
          <Box centerAnchor height={2} m={0.5} mb={0.5}>
            <ModelSimple index={0} rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_experience.glb'} scale={0.5} />
          </Box>
          <Box centerAnchor height={2} m={0.5} mb={0.5}>
            <ModelSimple index={1} rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_interact.glb'} scale={0.5} />
          </Box>
          <Box centerAnchor height={2} m={0.5} mb={0.5}>
            <ModelSimple index={2} rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_animate.glb'} scale={0.5} />
          </Box>
          <Box centerAnchor height={2} m={0.5} mb={0.5}>
            <ModelSimple index={3} rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_camera.glb'} scale={0.5} />
          </Box>
          <Box centerAnchor height={2} m={0.5} mb={0.5}>
            <ModelSimple index={5} rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_sculpt.glb'} scale={0.5} />
          </Box>
          <Box centerAnchor height={2} m={0.5} mb={0.5}>
            <ModelSimple index={4} rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_object.glb'} scale={0.5} />
          </Box>
          <Box centerAnchor height={2} m={0.5} mb={0.5}>
            <ModelSimple index={6} rotationOffset={[1, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_note.glb'} scale={0.5} />
          </Box>
          <Box centerAnchor height={2} m={0.5} mb={0.5}>
            <ModelSimple index={7} rotationOffset={[0.25, 0, 0]} flat={false} steadyRot model={'/models/works/collab_smart.glb'} scale={0.5} />
          </Box>
          <Box centerAnchor height={2} m={0.5} mb={0.5}>
            <ModelSimple index={8} rotationOffset={[0.75, 1, 0]} flat={false} steadyRot model={'/models/works/collab_navigate.glb'} scale={0.5} />
          </Box>
        </Box>

        {/* tool videos */}
        <TextVideo
          title={'SCULPT'}
          text={
            'The voxel-based sculpture system allows users to tell more by doing less, avoiding the need to manipulate primitives and focusing on the storytelling.'
          }
          videoSrc={'/images/works/collab/collab_sculpt_.mp4'}
          videoOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <Box centerAnchor height={2}>
            <ModelSimple rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_sculpt.glb'} scale={1} />
          </Box>
        </TextVideo>
        <TextVideo
          title={'ANIMATE'}
          text={
            'Move, rotate and/or scale any object by either setting keyframes or realtime recording and setting timeline behaviors for looping and more.'
          }
          videoSrc={'/images/works/collab/collab_animation_hybrid.mp4'}
          videoOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <Box centerAnchor height={2}>
            <ModelSimple rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_animate.glb'} scale={1} />
          </Box>
        </TextVideo>
        <TextVideo
          title={'INTERACT'}
          text={
            'Give life to the sculptures by creating Rube Goldberg machines that trigger animations through layered objects collision/dropping or even user controller buttons.'
          }
          videoSrc={'/images/works/collab/collab_interaction_trigger.mp4'}
          videoOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <Box centerAnchor height={2}>
            <ModelSimple rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_interact.glb'} scale={1} />
          </Box>
        </TextVideo>
        <TextVideo
          title={'CAMERA'}
          text={'Create animatable cameras with adjustable zoom, so 2D spectators can keep up with the story from its best angle.'}
          videoSrc={'/images/works/collab/collab_camera_.mp4'}
          videoOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <Box centerAnchor height={2}>
            <ModelSimple rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_camera.glb'} scale={1} />
          </Box>
        </TextVideo>
        <TextVideo
          title={'OBJECT'}
          text={
            'In collab, almost everything is a kind of object that can be cloned, instanced, locked or collected, so users can create real time editable maquettes and more.'
          }
          videoSrc={'/images/works/collab/collab_object_maquette.mp4'}
          videoOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <Box centerAnchor height={2}>
            <ModelSimple rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_object.glb'} scale={1} />
          </Box>
        </TextVideo>
        <TextVideo
          title={'NOTE'}
          text={'Leave colored text notes floating in the air, linked to objects or create more complex boards to discuss with colleagues.'}
          videoSrc={'/images/works/collab/collab_note_.mp4'}
          videoOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <Box centerAnchor height={2}>
            <ModelSimple rotationOffset={[1, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_note.glb'} scale={1} />
          </Box>
        </TextVideo>
        <TextVideo
          title={'EXPERIENCE'}
          text={'This tool is essentially a "play mode", decluttering "edition mode" gizmos and enabling button binding interactions for end users.'}
          videoSrc={'/images/works/collab/collab_experience_clutter.mp4'}
          videoOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <Box centerAnchor height={2}>
            <ModelSimple rotationOffset={[0.5, 0.5, 0]} flat={false} steadyRot model={'/models/works/collab_experience.glb'} scale={1} />
          </Box>
        </TextVideo>
        <TextVideo
          title={'NAVIGATE'}
          text={'Navigate in space by teleporting or through creation history with undo and redo.'}
          videoSrc={'/images/works/collab/collab_navigate_.mp4'}
          videoOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <Box centerAnchor height={2}>
            <ModelSimple rotationOffset={[0.75, 1, 0]} flat={false} steadyRot model={'/models/works/collab_navigate.glb'} scale={1} />
          </Box>
        </TextVideo>
        <TextVideo
          title={'SMART'}
          text={'This "instant-tool" is focused on making things easier in specific cases: angle-snapping objects and sculpting in straight lines.'}
          videoSrc={'/images/works/collab/collab_smart_angle.mp4'}
          videoOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <Box centerAnchor height={2}>
            <ModelSimple rotationOffset={[0.25, 0, 0]} flat={false} steadyRot model={'/models/works/collab_smart.glb'} scale={1} />
          </Box>
        </TextVideo>

        <TextTitle
          title={`pipeline & ecosystem`}
          subTitle={'Focus on quick prototyping'}
          image={project.images[9]}
          text={project.texts[7]}
          imageOpacity={0.5}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <Spacer />
        <VideoFull videoSrc={'/images/works/collab/collab_samemodel.mp4'} />

        <TextModel
          title={'Future: culture and identity'}
          text={project.texts[9]}
          model={'/models/works/collab_2.glb'}
          modelOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <Credits
          textFirst={project.credits}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <Footer
          kind={'extended reality'}
          related={project.related}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function Beleaf({ onReflow }) {
  invertScreen = false

  let projectCaption = 'beleaf'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, y, 0), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  const item = useRef(new Array())

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <Cover
          left={true}
          projectKey={projectKey}
          category={'works'}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <HighlightModel model={'/models/works/beleaf_1.glb'} />

        <TextTitle
          title={`ARVORE\nmeans\ntree`}
          subTitle={'Soul, senses, space'}
          image={project.images[12]}
          text={project.texts[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <SectionTitle text={`... an experience we\ncan't have in "normal" life`} />

        <VideoFull
          videoSrc={'/images/works/beleaf/intro.mp4'}
          imagesCaptions={`Every person instinctively dodges imaginary walls in this exact part of beleaf.`}
        />

        <TextImage
          title={`What can we do only in VR?`}
          text={project.texts[1]}
          image={project.images[6]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={`Prototyping nonsense`}
          text={project.texts[2]}
          image={project.images[7]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <SectionTitle text={`narrative pillars`} />
        <Box dir="row" width="100%" minHeight={viewport.height / 3} justifyContent="center" wrap="wrap" alignItems="baseline" mt={0} mb={0}>
          {project.timeline.map((props, index) => (
            <ColumnItems
              key={index}
              item={item}
              index={index}
              onReflow={(w, h) => {
                sizesRef.current[index] = h
              }}
              {...props}
            />
          ))}
        </Box>

        <TextTitle
          title={`the\nsoul`}
          subTitle={'Leaf'}
          image={project.images[8]}
          text={project.texts[3]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <HighlightModel model={'/models/works/beleaf_1.glb'} />

        <TextTitle
          title={`the\nsenses`}
          subTitle={'Torch'}
          image={project.images[9]}
          text={project.texts[4]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicTorch1} opacity={0.75} />

        <TextModel
          title={'Liminal object'}
          text={project.texts[12]}
          model={'/models/works/beleaf_2.glb'}
          modelOpacity={0.75}
          fixed={false}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={`Embrace nature`}
          text={project.texts[13]}
          image={project.images[11]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicTorch2} imagesCredit={project.mosaicTorch2Credits} opacity={0.75} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <TextTitle
          title={`the\nspace`}
          subTitle={'Four worlds'}
          image={project.images[10]}
          text={project.texts[5]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <HighlightTextPortal projectKey={projectKey} text={`portals transport you to different worlds`} />

        <TextVideo
          title={'act I\nDISCOVER'}
          text={project.texts[6]}
          videoSrc={'/images/works/beleaf/act_1.mp4'}
          videoOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextVideo
          title={'act II\nOBSERVE'}
          text={project.texts[7]}
          videoSrc={'/images/works/beleaf/act_2.mp4'}
          videoOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextVideo
          title={'act III\nSEARCH'}
          text={project.texts[8]}
          videoSrc={'/images/works/beleaf/act_3.mp4'}
          videoOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextVideo
          title={'act IV\nOVERCOME'}
          text={project.texts[9]}
          videoSrc={'/images/works/beleaf/act_4.mp4'}
          videoOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextVideo
          title={'final act\nCOMPLETE'}
          text={project.texts[10]}
          videoSrc={'/images/works/beleaf/act_final.mp4'}
          videoOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageMosaic images={project.mosaicWow} opacity={0.75} />

        <TextModel
          title={'A platform for R&D'}
          text={project.texts[11]}
          model={'/models/works/beleaf_3.glb'}
          modelOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <Credits
          textFirst={project.credits}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <Footer
          kind={'immersive experiences'}
          related={project.related}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function Lab({ onReflow }) {
  invertScreen = false

  let projectCaption = 'arvore lab'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, page < state.threshold ? y : sticky, page < state.threshold ? 0 : page * 1.25), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  const item = useRef(new Array())

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <Cover
          left={true}
          projectKey={projectKey}
          category={'works'}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <HighlightText
          projectKey={projectKey}
          text={`A journal of several years of learnings through experimentation while leading the ARVORE LAB`}
        />

        <ImageMosaic images={project.mosaicImages} opacity={0.5} />
        <TextTitle
          title={`reality is a collective sensorial experience`}
          image={project.images[1]}
          text={project.texts[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <HighlightModel model={project.model} />

        <TextText
          textFirst={project.texts[1]}
          textSecond={project.texts[2]}
          title={`...to create a plurisensorial medium for living stories`}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageFullHover
          image1={project.images[33]}
          image2={project.images[30]}
          imagesCaptions={`VRENA: modules used on VOYAGER with Unity virtual helpers to position sensorial actuators in narrative space.`}
        />

        {/* columns */}
        <SectionTitle text={'the exploration trails'} />
        <Box dir="row" width="100%" minHeight={viewport.height / 3} justifyContent="center" wrap="wrap" alignItems="baseline" mt={0} mb={3}>
          {project.timeline.map((props, index) => (
            <ColumnItems
              key={index}
              imageSize={3}
              item={item}
              index={index}
              onReflow={(w, h) => {
                sizesRef.current[index] = h
              }}
              {...props}
            />
          ))}
        </Box>

        <TextTitle
          title={`touch enables body thinking`}
          image={project.images[2]}
          text={project.texts[3]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Wind: resistance and directionality'}
          text={project.texts[4]}
          image={project.images[4]}
          imageOpacity={0.5}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull
          image={project.images[28]}
          imagesCaptions={
            'Wind curtain machine + Arduino with relays/optocouplers, initially via usb serial connection and later using an ESP8266 over WLAN'
          }
        />

        <TextImage
          title={'Heat: cozy attractiveness'}
          text={project.texts[5]}
          image={project.images[5]}
          imageOpacity={0.5}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Humidity: instant coolness'}
          text={project.texts[6]}
          image={project.images[6]}
          imageOpacity={0.5}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`haptic\nis active touching`}
          image={project.images[7]}
          text={project.texts[7]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Ultrasonic tactility: touching surfaces made of air'}
          text={project.texts[8]}
          image={project.images[8]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Skull diadem: sounds from inside of our head'}
          text={project.texts[9]}
          image={project.images[9]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Phygital devices: hybrid models'}
          text={project.texts[10]}
          image={project.images[10]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull
          image={project.images[29]}
          imagesCaptions={
            'HTC Vive Tracker + custom structure (3D printed and Openbeam Aluminum profiles) + Arduino with IMU and pulsating motor + neodimium magnet'
          }
        />

        <TextImage
          title={'Floor tactility: standing interface'}
          text={project.texts[11]}
          image={project.images[11]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull
          image={project.images[31]}
          imagesCaptions={'Positionable vibration zones (with fade zones) that are triggered when user intersects.'}
        />

        <TextImage
          title={'Seat touch: cars as wearables'}
          text={project.texts[12]}
          image={project.images[12]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <TextTitle
          title={`dynamic spaces`}
          image={project.images[13]}
          subTitle="Travelling 20 meters in 2"
          text={project.texts[13]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Phygitrack: translating atoms, to bits, back to photons'}
          text={project.texts[14]}
          image={project.images[14]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <VideoFull
          videoSrc={'/images/works/lab/phygitrack.mp4'}
          imagesCaptions={'Unity + HTC Vive Tracker + ESP8266 over WLAN (UDP) + servo motors + laser diode'}
        />

        <TextImage
          title={'Octo: robotics for human & architectural scale immersion'}
          text={project.texts[15]}
          image={project.images[15]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <VideoFull
          videoSrc={'/images/works/lab/octo.mp4'}
          imagesCaptions={'Unity + HTC Vive Tracker + ESP32 over WLAN (ESP protocol) + stepper motors + omni wheels'}
        />

        <TextTitle
          title={`smell`}
          imagesCredit={`IFF and NASA/Marshall Space Flight Center`}
          image={project.images[16]}
          subTitle="An immersive experience we are all a part of"
          text={project.texts[16]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicImagesSmell} imagesCredit={project.mosaicImagesSmellCredits} creditOffset={0.25} opacity={0.5} />

        <TextTitle
          title={`biometrics`}
          image={project.images[17]}
          subTitle="Inconscient user interfaces"
          text={project.texts[17]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`extended\nvision`}
          image={project.images[18]}
          subTitle={`Foreshadowing\nfrom another's eyes`}
          text={project.texts[18]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={'Dynamic lighting: light moods follow the narrative'}
          text={project.texts[19]}
          image={project.images[19]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'“Mixed Reality” setups'}
          text={project.texts[20]}
          image={project.images[20]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <VideoFull
          videoSrc={'/images/works/lab/mixed.mp4'}
          imagesCaptions={`LAB Chroma is a solution for attributing DMX light's color to match specific regions of a realtime capture`}
        />
        <TextImage
          title={'Depth cameras'}
          text={project.texts[21]}
          image={project.images[21]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`tools & culture`}
          image={project.images[22]}
          subTitle="using XR to create XR"
          text={project.texts[22]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Facial mocap'}
          text={project.texts[23]}
          image={project.images[23]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Body mocap'}
          text={project.texts[24]}
          image={project.images[24]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Collab'}
          text={project.texts[25]}
          image={project.images[25]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <HtmlLink
            url={'/works/collab'}
            // icon={hackadayIcon}
            text={'COLLAB'}
            self={true}
          />
        </TextImage>
        <TextImage
          title={'wiki'}
          text={project.texts[26]}
          image={project.images[26]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`LAB structure`}
          image={project.images[27]}
          subTitle="Space for collaboration and experimentation"
          text={project.texts[27]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicImagesStructure} opacity={0.5} />

        <TextModel
          title={'A place for serendipity'}
          text={project.texts[28]}
          model={project.model}
          modelOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <Footer
          kind={'extended reality'}
          category={'works'}
          related={project.related}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function ThreeFX({ onReflow }) {
  invertScreen = false

  let projectCaption = '3fx'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, page < state.threshold ? y : sticky, page < state.threshold ? 0 : page * 1.25), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <Cover
          left={true}
          projectKey={projectKey}
          category={'works'}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`immersion\nthrough\nactive\nmovement`}
          image={project.images[1]}
          text={project.texts[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <VideoFull
          videoSrc={'/images/works/threefx/2fx.mp4'}
          imagesCaptions={'2FX was a two degree of freedom simulator prototype already made by BS Motion.'}
        />

        <TextImage
          title={'2FX: starting point'}
          text={project.texts[1]}
          image={project.images[8]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicImagesBs} opacity={0.5} />

        <SectionTitle text={'design process'} />

        <TextTitle
          title={`issues to be tackled`}
          image={project.images[9]}
          imagesCredit={``}
          text={project.texts[2]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          subTitle={`Creative direction`}
          image={project.images[1]}
          title={'essence\nof\nrotation'}
          text={project.texts[3]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextModel
          title={`Rotating nucleus`}
          text={project.texts[4]}
          model={'/models/works/threefx_1.glb'}
          modelOpacity={0.75}
          modelScale={15}
          left={true}
          doubleSide={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull image={project.images[0]} imagesCaptions={``} />
        <TextModel
          title={`Math: beauty of nature`}
          text={project.texts[5]}
          model={'/models/works/threefx_2.glb'}
          modelOpacity={0.75}
          modelScale={12}
          left={false}
          doubleSide={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageFull
          image={project.images[7]}
          imagesCaptions={`A mysterious-looking object, with smoked windows to communicate its purpose underneath.`}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <TextTitle
          title={`first prototype`}
          image={project.images[13]}
          subTitle={'Proofing the concept'}
          text={project.texts[9]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicImages} opacity={0.5} />
        <VideoFull videoSrc={'/images/works/threefx/entering.mp4'} />
        <ImageMosaic images={project.mosaicImages2} opacity={0.5} />

        <ImageFull
          image={project.images[6]}
          imagesCaptions={`The experience of entering a spaceship: external and internal doors that also ensure safety.`}
        />

        <TextImage
          title={'Flexibility'}
          text={project.texts[6]}
          image={project.images[2]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull
          image={project.images[11]}
          imagesCaptions={`The geometric shape is also proper for creating flock patterns, using the simulator itself as scenography in LBEs.`}
        />
        <ImageFull image={project.images[12]} imagesCaptions={`Aproximated external size: 3m x 3m x 2,5m`} />

        <TextImage
          title={'Ergonomics and safety'}
          text={project.texts[7]}
          image={project.images[4]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull
          image={project.images[10]}
          imagesCaptions={`There is enough room for users to move their arms inside the cabin, allowing hand tracking.`}
        />
        <TextImage
          title={'Comfort'}
          text={project.texts[8]}
          image={project.images[5]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <Credits
          textFirst={project.credits}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <Footer
          kind={'immersive experiences'}
          related={project.related}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function Voyager({ onReflow }) {
  invertScreen = false

  let projectCaption = 'voyager'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }
  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, page < state.threshold ? y : sticky, page < state.threshold ? 0 : page * 1.25), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  const item = useRef(new Array())

  const ratio = viewport.width / viewport.height

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <Cover
          left={true}
          projectKey={projectKey}
          category={'works'}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`virtual\nreality\nfor\neveryone`}
          image={project.images[0]}
          text={project.texts[0]}
          imageOpacity={1}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <HighlightModel model={'/models/works/voyager.glb'} />

        <SectionTitle text={`key touchpoints`} />
        <Box dir="row" width="100%" minHeight={viewport.height / 3} justifyContent="center" wrap="wrap" alignItems="baseline" mt={0} mb={3}>
          {project.timeline.map((props, index) => (
            <ColumnItems
              key={index}
              item={item}
              index={index}
              onReflow={(w, h) => {
                sizesRef.current[index] = h
              }}
              {...props}
            />
          ))}
        </Box>

        <HighlightText projectKey={projectKey} text={`...to create unique "brick and mortar" spaces for immersive experiences`} />

        <TextImage
          title={'Previous VR stations issues'}
          text={project.texts[15]}
          image={project.images[17]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`virtual\nreality\nstations`}
          subTitle={'VRENA'}
          image={project.images[1]}
          text={project.texts[1]}
          imageOpacity={1}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <VideoFull videoSrc={'/images/works/voyager/vrena_full.mp4'} />

        <TextImage
          title={'Visual attractiveness'}
          text={project.texts[3]}
          image={project.images[6]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={'Safety & ergonomics'}
          text={project.texts[2]}
          image={project.images[4]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageMosaic images={project.mosaicStores} imagesCredit={project.mosaicStoresCredits} opacity={1} />
        <ImageFull image={project.images[2]} imagesCaptions={``} />
        <ImageMosaic images={project.mosaicResult} opacity={1} />

        <Spacer />
        <TextModel
          title={'Modularity & real state'}
          text={project.texts[4]}
          model={'/models/works/voyager_1.glb'}
          modelScale={12}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <Spacer />
        <SectionTitle text={'composition possibilities'} />
        <Box
          dir="row"
          alignSelf="center"
          alignContent="center"
          justifyContent="center"
          minHeight={viewport.height / 2}
          width="100%"
          mt={0}
          mb={3}
          wrap="wrap">
          <Box centerAnchor height={5} m={1} mb={0.75}>
            <ModelSimple index={0} rotationOffset={[0.5, 0, 0]} flat={false} steadyRot model={'/models/works/voyager_2.glb'} scale={2.5} />
            <Text anchorX="center" textAlign="center" position-y={-2.5} fontSize={0.4} color={'white'}>
              {'ROOM-SCALE ARENA\n4 modules\n1-2 players'}
            </Text>
          </Box>
          <Box centerAnchor height={5} m={1} mb={0.75}>
            <ModelSimple index={1} rotationOffset={[0.5, 0, 0]} flat={false} steadyRot model={'/models/works/voyager_3.glb'} scale={2.5} />
            <Text anchorX="center" textAlign="center" position-y={-2.5} fontSize={0.4} color={'white'}>
              {'STANDING SQUARE\n4 modules\n4 player'}
            </Text>
          </Box>
          <Box centerAnchor height={5} m={1} mb={0.75}>
            <ModelSimple index={2} rotationOffset={[0.5, 0, 0]} flat={false} steadyRot model={'/models/works/voyager_4.glb'} scale={2.5} />
            <Text anchorX="center" textAlign="center" position-y={-2.5} fontSize={0.4} color={'white'}>
              {'STANDING LINE\nN module(s)\nN player(s)'}
            </Text>
          </Box>
          <Box centerAnchor height={5} m={1} mb={0.75}>
            <ModelSimple index={3} rotationOffset={[0.5, 0, 0]} flat={false} steadyRot model={'/models/works/voyager_5.glb'} scale={2.5} />
            <Text anchorX="center" textAlign="center" position-y={-2.5} fontSize={0.4} color={'white'}>
              {'STANDING INDIVIDUAL\n1 module\n1 player'}
            </Text>
          </Box>
        </Box>

        <ImageFull image={project.images[3]} imagesCaptions={``} />

        <TextImage
          title={'Tech infrastructure'}
          text={project.texts[5]}
          image={project.images[12]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={'Color, material & finish'}
          text={project.texts[6]}
          image={project.images[5]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <TextTitle
          title={`design\ndevelop\n& deploy`}
          subTitle={'4 months\nfrom sketch to full operation'}
          image={project.images[7]}
          text={project.texts[7]}
          imageOpacity={1}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={'Optimization and expansion'}
          text={project.texts[8]}
          image={project.images[8]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageMosaic images={project.mosaicProcess} opacity={0.65} />

        {/* VROLLEY */}
        {/* <HighlightText projectKey={projectKey} text={`...or even bring immersive content anywhere`} />

        <TextTitle
          title={`mobile\nvirtual\nreality\ntrolleys`}
          subTitle={'VROLLEY'}
          image={project.images[9]}
          text={project.texts[9]}
          imageOpacity={1}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={'VR can get very messy'}
          text={project.texts[10]}
          image={project.images[11]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicVrolleyProcess} opacity={0.65} />

        <TextImage
          title={'High-end VR, anywhere'}
          text={project.texts[11]}
          image={project.images[9]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageMosaic images={project.mosaicVrolley} opacity={0.65} /> */}

        <Spacer />
        <HighlightText projectKey={projectKey} text={`...and let customers free to have lots of fun.`} />

        <TextTitle
          title={`user\nsession\nmgmt`}
          subTitle={'STATUS (prototype)'}
          image={project.images[10]}
          text={project.texts[12]}
          imageOpacity={1}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={'Device + wristbands'}
          text={project.texts[13]}
          image={project.images[14]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <VideoFull
          videoSrc={'/images/works/voyager/status.mp4'}
          imagesCaptions={`The first prototype used an Arduino Nano with a RFID module to send the card ID via USB serial to a PC.\nThere is low level detection for unintended use (red light feedback).`}
        />

        <Box mt={ratio > 1 ? 0 : 2} />

        <ImageMosaic images={project.mosaicStatus} opacity={0.65} />

        <Spacer />
        <TextImage
          title={'Tablet app'}
          text={project.texts[14]}
          image={project.images[15]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageMosaic images={project.mosaicStatusApp} opacity={0.99} />

        <ImageFull image={project.images[16]} imagesCaptions={``} />

        <HighlightModel model={'/models/works/voyager.glb'} />

        <Credits
          textFirst={project.credits}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <Footer
          kind={'immersive entertainment'}
          category={'works'}
          related={project.related}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function ConnectedDrains({ onReflow }) {
  invertScreen = false

  let projectCaption = 'connected drains'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }
  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, page < state.threshold ? y : sticky, page < state.threshold ? 0 : page * 1.25), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <Cover
          left={true}
          projectKey={projectKey}
          category={'works'}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`undergrad final project`}
          subTitle={`Design degree at Universidade de São Paulo (FAU-USP)`}
          image={project.images[0]}
          text={project.texts[0]}
          imageOpacity={0.9}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextText
          title={'INFO'}
          textFirst={project.texts[15]}
          textSecond={project.texts[16]}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <HighlightText projectKey={projectKey} text={`Drains & IoT:\nconnecting digital and water networks`} />
        <ImageMosaic images={project.mosaicImages} imagesCredit={project.mosaicImagesCredits} opacity={0.75} />
        <TextText
          textFirst={project.texts[3]}
          textSecond={project.texts[4]}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <SectionTitle text={`core issues`} />
        <TextImage
          title={`FLOODS:\nlack of maintenance is costly`}
          image={project.images[1]}
          text={project.texts[1]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={`INJURIES:\nbroken covers are urban traps`}
          image={project.images[2]}
          text={project.texts[2]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`connected drains`}
          subTitle={`The vision`}
          image={project.images[15]}
          text={project.texts[13]}
          imageOpacity={0.9}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Ecosystem'}
          text={project.texts[5]}
          image={project.images[3]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Connected Object'}
          text={project.texts[6]}
          image={project.images[4]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageFull image={project.images[14]} imagesCaptions={''} />

        <ImageMosaic images={project.mosaicObject} opacity={0.75} />

        <TextImage
          title={'Device sensors & features'}
          text={project.texts[7]}
          image={project.images[12]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageMosaic images={project.mosaicObjectProcess} opacity={0.75} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <TextImage
          title={'Web App'}
          text={project.texts[8]}
          image={project.images[6]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageMosaic images={project.mosaicApp} opacity={0.99} />

        <TextImage
          title={'Visual identity'}
          text={project.texts[9]}
          image={project.images[7]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`reflections\n7 years\nlater`}
          subTitle={`The importance of a coherent vision to build futures`}
          image={project.images[13]}
          text={project.texts[14]}
          imageOpacity={0.9}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <SectionTitle text={`recognition`} />
        <TextImage
          title={'Prizes & exhibits'}
          text={project.texts[10]}
          image={project.images[8]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicPrizes} opacity={0.75} />

        <SectionTitle text={`media`} />
        <ImageFull
          image={project.images[10]}
          imagesCaptions={'(August 2014) In an Info magazine issue dedicated to connectivity: "bueiros em rede?" means "networked drains?".'}
        />

        <ImageMosaic images={project.mosaicMedia} opacity={0.75} />
        <TextText
          textFirst={project.texts[11]}
          textSecond={project.texts[12]}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <Footer
          kind={'connected services'}
          category={'works'}
          related={project.related}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function Oasis({ onReflow }) {
  invertScreen = false

  let projectCaption = 'oasis'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, page < state.threshold ? y : sticky, page < state.threshold ? 0 : page * 1.25), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <Cover
          left={true}
          projectKey={projectKey}
          category={'works'}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextTitle
          title={`drink\nas-a-service`}
          image={project.images[6]}
          text={project.texts[0]}
          imageOpacity={0.5}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <HighlightModel model={project.model} />
        <ImageMosaic opacity={0.5} images={project.mosaicImages} />
        <TextTitle
          title={`objectives`}
          imagesCredit="Questto|Nó"
          subTitle={'Throughput, social relations & optimization'}
          image={project.images[5]}
          text={project.texts[4]}
          imageOpacity={0.5}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <HighlightText text={'less queues, more good times with friends'} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <TextImage
          title={'The system'}
          imagesCredit="Questto|Nó"
          text={project.texts[1]}
          image={project.images[3]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'IKEA philosophy'}
          text={project.texts[2]}
          image={project.images[7]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Points of sale implementation'}
          text={project.texts[3]}
          image={project.images[8]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull
          image={project.images[4]}
          imagesCaptions={'After the pilot, an improved version was proposed with better lighting to draw attention, along many other aspects'}
          imagesCredit="Questto|Nó"
        />

        <Credits
          textFirst={project.credits}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <Footer
          kind={'connected services'}
          category={'works'}
          related={project.related}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function ScienceBox({ onReflow }) {
  invertScreen = false

  let projectCaption = 'science box'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, page < state.threshold ? y : sticky, page < state.threshold ? 0 : page * 1.25), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  const item = useRef(new Array())

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <Cover
          left={true}
          projectKey={projectKey}
          category={'works'}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`sync'ed science experiments everywhere`}
          subTitle={'An invention, for an inventions YouTube channel'}
          image={project.images[0]}
          text={project.texts[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <HighlightText projectKey={projectKey} text={`If a box was a living being…`} />

        <VideoFull videoSrc={'/images/works/sciencebox/turntable.mp4'} />

        <HighlightText projectKey={projectKey} text={`…it could invite people nearby to experiment!`} />

        <TextImage
          title={'Imbuing personality on things'}
          text={project.texts[1]}
          image={project.images[1]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <SectionTitle text={`setting up the magic`} />
        <Box dir="row" width="100%" minHeight={viewport.height / 2} justifyContent="center" wrap="wrap" alignItems="baseline" mt={0} mb={1}>
          {project.timeline.map((props, index) => (
            <ColumnItems
              key={index}
              item={item}
              index={index}
              onReflow={(w, h) => {
                sizesRef.current[index] = h
              }}
              {...props}
            />
          ))}
        </Box>

        <TextModel
          title={'The experience in a nutshell'}
          text={project.texts[2]}
          model={'/models/works/sciencebox_1.glb'}
          modelOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={'Borrow the screen'}
          text={project.texts[7]}
          image={project.images[2]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={'Safety is cool'}
          text={project.texts[8]}
          image={project.images[3]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <VideoFull videoSrc={'/images/works/sciencebox/experience.mp4'} />

        <SectionTitle text={`the experiments inside`} />
        <TextVideo
          title={'SLIME'}
          imagesCredit={`Manual do Mundo`}
          text={project.texts[3]}
          videoSrc={'/images/works/sciencebox/experiment_slime.mp4'}
          videoOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextVideo
          title={'INSTANT ICE'}
          imagesCredit={`Manual do Mundo`}
          text={project.texts[4]}
          videoSrc={'/images/works/sciencebox/experiment_ice.mp4'}
          videoOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextVideo
          title={'ELECTRICITY GENERATOR'}
          imagesCredit={`Manual do Mundo`}
          text={project.texts[5]}
          videoSrc={'/images/works/sciencebox/experiment_electric.mp4'}
          videoOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextVideo
          title={'ANAMORPHIC ILLUSION'}
          imagesCredit={`Manual do Mundo`}
          text={project.texts[6]}
          videoSrc={'/images/works/sciencebox/experiment_anamorphic.mp4'}
          videoOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <TextImage
          title={'3…2…1… open!'}
          text={project.texts[9]}
          image={project.images[4]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`making of`}
          imagesCredit={`Wesley Lee`}
          subTitle={'The adventures of making devices in an ad-agency'}
          image={project.images[5]}
          text={project.texts[10]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageFull
          image={project.images[6]}
          imagesCaptions={'There was fun for everyone around, including to our colleagues working with business intelligence and social media.'}
        />

        <TextImage
          title={'Electronics'}
          imagesCredit={`Wesley Lee`}
          text={project.texts[11]}
          image={project.images[7]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <VideoFull
          videoSrc={'/images/works/sciencebox/makingof_soldering.mp4'}
          imagesCredit={`Wesley Lee`}
          imagesCaptions={'The PCB was very dense and fitted tight into one of the box modules together with the batteries.'}
        />

        <TextImage
          title={'Wood works'}
          text={project.texts[12]}
          image={project.images[8]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <VideoFull imagesCredit={`Wesley Lee`} videoSrc={'/images/works/sciencebox/makingof_woodworking.mp4'} />

        <ImageMosaic images={project.mosaicImages} imagesCredit={project.mosaicImagesCredits} opacity={0.75} />
        <Spacer />
        <VideoFull imagesCredit={`Wesley Lee`} videoSrc={'/images/works/sciencebox/grandopening.mp4'} />

        <Credits
          textFirst={project.credits}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <Footer
          kind={'connected experiences'}
          category={'works'}
          related={project.related}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function PlayTags({ onReflow }) {
  invertScreen = false

  let projectCaption = 'play tags'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, page < state.threshold ? y : sticky, page < state.threshold ? 0 : page * 1.25), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <Cover
          left={true}
          projectKey={projectKey}
          category={'works'}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`a fresh video in your pocket`}
          imagesCredit={`Wesley Lee`}
          subTitle={'An interactive ad, for an interactive medium'}
          image={project.images[4]}
          text={project.texts[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <HighlightModel model={'/models/works/playtags_1.glb'} />

        <VideoFull imagesCredit={`Wesley Lee`} videoSrc={'/images/works/playtags/intro.mp4'} />

        <TextImage
          title={'Smart post-its'}
          text={project.texts[1]}
          image={project.images[2]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageFull imagesCredit={`Wesley Lee`} image={project.images[18]} />

        <ImageMosaic images={project.mosaicPixels} imagesCredit={project.mosaicPixelsCredits} opacity={0.75} />

        <TextImage
          title={'Physical pixels'}
          imagesCredit={`Wesley Lee`}
          text={project.texts[2]}
          image={project.images[3]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={'Pick to watch'}
          text={project.texts[3]}
          image={project.images[16]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <VideoFull videoSrc={'/images/works/playtags/experience.mp4'} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <TextImage
          title={'Seven megapixels'}
          imagesCredit={`Wesley Lee`}
          text={project.texts[4]}
          image={project.images[5]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageFullHover image1={project.images[6]} image2={project.images[7]} />
        <ImageFullHover image1={project.images[8]} image2={project.images[9]} />
        <ImageFullHover image1={project.images[10]} image2={project.images[11]} />
        <ImageFullHover image1={project.images[12]} image2={project.images[13]} />
        <ImageFullHover image1={project.images[14]} image2={project.images[15]} />

        <TextTitle
          title={`making of`}
          imagesCredit={`Wesley Lee`}
          subTitle={'Paper, NFC, mobile & cloud'}
          image={project.images[17]}
          text={project.texts[5]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <VideoFull videoSrc={'/images/works/playtags/makingof.mp4'} />

        <ImageMosaic images={project.mosaicProcess} imagesCredit={project.mosaicProcessCredits} opacity={0.75} />

        <Spacer />
        <HighlightModel model={'/models/works/playtags_1.glb'} />

        <Credits
          textFirst={project.credits}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <Footer
          kind={'connected experiences'}
          category={'works'}
          related={project.related}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function EarlyWorks({ onReflow }) {
  invertScreen = false

  let projectCaption = 'early works'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, page < state.threshold ? y : sticky, page < state.threshold ? 0 : page * 1.25), 0.15)
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <Cover
          left={true}
          projectKey={projectKey}
          category={'works'}
          invertedQuestion={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          title={`exploring different mediums`}
          subTitle={`Design & technology`}
          text={project.texts[0]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <HighlightText projectKey={projectKey} text={`1. in the screen\n2. through the screen\n3. as a screen`} />
        <TextTitle
          title={`anything as a screen`}
          imagesCredit={`Wesley Lee`}
          subTitle={`Physical digital: 2011 > 2014`}
          image={project.images[2]}
          text={project.texts[1]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`...what if we could find and drag cool apps to smartphones?`} color={'#0096ff'} />
        <TextImage
          title={'Samsung GALAXY.ME'}
          imagesCredit={`Wesley Lee`}
          text={project.texts[2]}
          image={project.images[3]}
          imageOpacity={0.75}
          color={'#0096ff'}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicGalaxyMe} imagesCredit={project.mosaicGalaxyMeCredits} opacity={0.75} />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`...what if we could eat Doritos boldly and radically?`} color={'#ff6c00'} />
        <TextImage
          title={'Doritos Capsule'}
          text={project.texts[3]}
          image={project.images[4]}
          imageOpacity={0.75}
          color={'#ff6c00'}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicDoritosCapsule} imagesCredit={project.mosaicDoritosCapsuleCredits} opacity={0.75} />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`...what if we could measure body movement while skateboarding?`} color={'#6246f1'} />
        <TextImage
          title={'Rexona Motionsense'}
          imagesCredit={`Wesley Lee`}
          text={project.texts[4]}
          image={project.images[5]}
          imageOpacity={0.75}
          color={'#6246f1'}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageMosaic images={project.mosaicMotionsense} imagesCredit={project.mosaicMotionsenseCredits} opacity={0.75} />
        <VideoFull videoSrc={'/images/works/old/motionsense.mp4'} imagesCaptions={``} />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`...what if a bottle could keep happy moments instead of coke?`} color={'#df3939'} />
        <TextImage
          title={'Rec Bottle'}
          imagesCredit={`Wesley Lee`}
          text={project.texts[5]}
          image={project.images[6]}
          imageOpacity={0.75}
          color={'#df3939'}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <VideoFull
          videoSrc={'/images/works/old/recbottle_clips.mp4'}
          imagesCaptions={`Prototype video, before the final device aspect.`}
          muted={false}
        />
        <TextModel
          title={'Raspberry Pi, camera, tiny screen & speaker'}
          text={project.texts[21]}
          model={'/models/works/recbottle.glb'}
          modelOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicRecBottle} imagesCredit={project.mosaicRecBottleCredits} opacity={0.75} />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`...what if a smart shopping cart contextually taught cooking recipes?`} color={'#f9ce82'} />
        <TextImage
          title={`Hellmann's Recipe Cart`}
          text={project.texts[6]}
          image={project.images[7]}
          imageOpacity={0.75}
          color={'#f9ce82'}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`...what if we could peek anywhere in the world from a single binocular?`} color={'#b0b0b0'} />
        <TextImage
          title={'Google Binoculars'}
          text={project.texts[7]}
          image={project.images[8]}
          imageOpacity={0.75}
          color={'#b0b0b0'}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`...what if weird bots partied hard with us?`} color={'#7646f1'} />
        <TextImage
          title={'Samsung Galaxy X'}
          text={project.texts[8]}
          image={project.images[9]}
          imageOpacity={0.75}
          color={'#7646f1'}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicGalaxyX} opacity={0.75} />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`...what if a fully digital store was physical?`} color={'#e1d9b1'} />
        <TextImage
          title={'Chrome Webstore'}
          text={project.texts[9]}
          image={project.images[10]}
          imageOpacity={0.75}
          color={'#e1d9b1'}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`...what if the marketing team had a smart war room?`} color={'#d65100'} />
        <TextImage
          title={'KDE'}
          text={project.texts[10]}
          image={project.images[11]}
          imageOpacity={0.75}
          color={'#d65100'}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicKde} opacity={0.75} />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`what if crazy worlds and creatures could be interactive?`} />
        <TextTitle
          title={`anything through the screen`}
          subTitle={`Realtime 3D: 2008 > 2011`}
          image={project.images[12]}
          text={project.texts[11]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Desafio Stock Car'}
          text={project.texts[12]}
          image={project.images[13]}
          imageOpacity={0.75}
          color={'#ff9600'}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Rexona Footzombie'}
          text={project.texts[13]}
          image={project.images[14]}
          imageOpacity={0.75}
          color={'#e1cd6f'}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Rexona Tuning'}
          text={project.texts[14]}
          image={project.images[15]}
          imageOpacity={0.75}
          color={'#d3220b'}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Rexona X-Race'}
          text={project.texts[15]}
          image={project.images[16]}
          imageOpacity={0.75}
          color={'#a0c932'}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicXrace} imagesCredit={project.mosaicXraceCredits} opacity={0.75} />

        <TextImage
          title={'Doritos lovers'}
          text={project.texts[16]}
          image={project.images[17]}
          imageOpacity={0.75}
          color={'#ec3838'}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicLovers} opacity={0.75} />

        <TextImage
          title={'Peugeot 207 visualizer'}
          text={project.texts[17]}
          image={project.images[18]}
          imageOpacity={0.75}
          color={'#9d9d9d'}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicPeugeot} opacity={0.75} />

        <Spacer />
        <HighlightText projectKey={projectKey} text={`what if I could create virtual things and places?`} />
        <TextTitle
          title={`anything in the screen`}
          subTitle={`3D stills: 2006 > 2008`}
          image={project.images[19]}
          text={project.texts[18]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Peugeot 307 hot site'}
          text={project.texts[19]}
          image={project.images[20]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Studies'}
          text={project.texts[20]}
          image={project.images[21]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicRenders} opacity={0.75} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <Footer
          kind={'phygital objects and spaces'}
          category={'works'}
          related={project.related}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}

// be-sides

function Chromnos({ onReflow }) {
  invertScreen = false

  let projectCaption = 'chromnos'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  const ratio = viewport.width / viewport.height

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <BesidesCover
          left={true}
          projectKey={projectKey}
          category={'works'}
          invertedQuestion={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <HighlightText projectKey={projectKey} text={`... what if we perceived time \nthrough the colors of everyday?`} />

        <TextTitle
          title={`khrôma\nkrónos`}
          // subTitle={'watch'}
          image={project.imagesColorbar[19]}
          text={project.textsColorbar[5]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <Box
          dir={'row'}
          align="center"
          justify="center"
          width="100%"
          height={ratio > 1 ? (viewport.height / 4) * 3 : viewport.width}
          mt={1.5}
          mb={1.5}
          flexWrap="wrap">
          <Box>
            <Html zIndexRange={[1, 0]} center>
              <iframe
                width={viewport.width * 30}
                height={ratio > 1 ? viewport.height * 30 : viewport.width * 30}
                src="https://www.youtube.com/embed/f3zEaE7I5oA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </Html>
          </Box>
        </Box>

        <TextTitle
          title={`live piece`}
          subTitle={'A continuous real-time experimentation'}
          image={project.imagesColorbar[14]}
          text={'For the actual experience and more details, follow the link below.'}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <HtmlLink url={'https://chromnos.me'} icon={externalIcon} text={'chromnos.me'} />
        </TextTitle>

        {/* <ImageFull image={project.imagesColorbar[12]} imagesCaptions={''} /> */}

        {/* <SectionTitle projectKey={projectKey} text={`... more info`} /> */}

        {/* <TextImage
          title={`Color & time, diffuse`}
          // title={'single\npixel\ncamera'}
          image={project.imagesColorbar[13]}
          text={project.textsColorbar[6]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        /> */}

        {/* <SectionTitle projectKey={projectKey} text={`Life painting`} /> */}
        {/* <ImageFull image={project.imagesColorbar[17]} imagesCaptions={''} /> */}

        {/* <TextTitle
          title={`life painting`}
          subTitle={'Data pigments'}
          image={project.imagesColorbar[18]}
          text={project.textsColorbar[7]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        /> */}

        {/* <TextImage
          title={`Musical chromaticism`}
          // title={'single\npixel\ncamera'}
          image={project.imagesColorbar[15]}
          text={project.textsColorbar[8]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        /> */}

        {/* <VideoFull videoSrc={'/images/chromnos/chromnos_newbar.mp4'} /> */}

        {/* <SectionTitle projectKey={projectKey} text={`ecosystem`} /> */}

        {/* <ImageFull image={project.imagesColorbar[16]} imagesCaptions={'chromnos is based on a wearable IoT ecosystem with different touchpoints, constantly syncing to the web.'} /> */}

        {/*  */}

        {/* <SectionTitle projectKey={projectKey} text={`the creative process`} /> */}

        {/* <HighlightText projectKey={projectKey} text={`2016: color logging`} /> */}

        {/* <TextImage
          title={`First prototype`}
          // title={'single\npixel\ncamera'}
          image={project.imagesColorbar[0]}
          text={project.textsColorbar[0]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        /> */}
        {/* <HighlightText projectKey={projectKey} text={`...what if I could capture raw colors, besides volumes and materials of my surroundings?`} /> */}

        {/* <TextTitle
          subTitle={'Sensor-level data simplification'}
          text={project.textsColorbar[1]}
          image={project.imagesColorbar[3]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        /> */}

        {/* <TextTitle
          title={'singular color codes'}
          subTitle={'Chromatic poetry'}
          text={project.textsColorbar[2]}
          image={project.imagesColorbar[1]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        /> */}

        {/* <ImageFull image={project.imagesColorbar[2]} imagesCaptions={''} /> */}

        {/* <HighlightText projectKey={projectKey} text={`2020: portfolio inspiration`} /> */}

        {/* <ImageFullHover
          image1={project.imagesColorbar[5]}
          image2={project.imagesColorbar[6]}
          imagesCaptions={`The color logging experiment inspired me to extract chromatic signatures with a "scanline" shader, so even in divergent images there was a unity.`}
        /> */}

        {/* <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} /> */}

        {/* <HighlightText projectKey={projectKey} text={`2022: synthesis`} /> */}

        {/* <TextTitle
          title={`khrôma\nkrónos`}
          subTitle={'chromnos'}
          image={project.imagesColorbar[8]}
          text={project.textsColorbar[3]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        /> */}

        {/* <TextImage
          title={`Custom wearable`}
          image={project.imagesColorbar[7]}
          text={project.textsColorbar[4]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        /> */}

        {/* <HighlightText projectKey={projectKey} text={`exploratory pathways`} /> */}

        {/* <SectionTitle text={'creative dimensions'} /> */}
        {/* <Box dir="row" width="100%" minHeight={viewport.height / 2} justifyContent="center" wrap="wrap" alignItems="baseline" mt={0} mb={2}>
          {project.timeline.map((props, index) => (
            <ColumnItems
              imageSize={6}
              textInside={true}
              centered={true}
              imageOpacity={0.5}
              key={index}
              index={index}
              onReflow={(w, h) => {
                sizesRef.current[index] = h
              }}
              {...props}
            />
          ))}
        </Box> */}

        {/* <Box mt={ratio > 1 ? 0 : 3} /> */}

        {/* <ImageFull image={project.imagesColorbar[4]} imagesCaptions={''} /> */}

        {/* <ImageFull image={project.imagesColorbar[10]} imagesCaptions={''} /> */}

        {/* <ImageFull image={project.imagesColorbar[9]} imagesCaptions={''} /> */}

        {/* <ImageFull image={project.imagesColorbar[11]} imagesCaptions={''} /> */}

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <BesidesFooter />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function Etkt({ onReflow }) {
  invertScreen = false

  let projectCaption = 'e-tkt'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  const ratio = viewport.width / viewport.height

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <BesidesCover
          left={true}
          projectKey={projectKey}
          category={'works'}
          invertedQuestion={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextTitle
          subTitle={`A future that didn't happen`}
          title={'classic embossing\nmeets\nIoT'}
          image={project.imagesEtkt[0]}
          text={project.textsEtkt[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <HtmlLink
            url={'https://hackaday.com/2022/08/09/2022-hackaday-prize-congratulations-to-the-winners-of-the-hack-it-back-challenge/'}
            icon={hackadayIcon}
            text={"finalist at the HACKADAY prize '22"}
          />
          <HtmlLink url={'https://www.youtube.com/watch?v=3jpaBhROYGc'} icon={externalIcon} text={'Digi-Key Maker Update'} />
          <HtmlLink
            url={'https://www.hackster.io/news/e-tkt-prints-embossed-labels-with-the-power-of-iot-5ad25299cf22'}
            icon={hacksterIcon}
            text={'Hackster.io'}
          />
          <HtmlLink url={'https://makezine.com/2022/06/22/old-school-label-making-new-school-interface/'} icon={makezineIcon} text={'MAKE:'} />
          <HtmlLink
            url={'https://hackaday.com/2022/06/15/diy-automated-printer-kerchunks-out-classic-embossed-labels/'}
            icon={hackadayIcon}
            text={'HACKADAY'}
          />
        </TextTitle>
        <Box mt={ratio > 1 ? 0 : 3} />

        <ImageFull image={project.imagesEtkt[3]} imagesCaptions={''} />

        <Box
          dir={'row'}
          align="center"
          justify="center"
          width="100%"
          height={ratio > 1 ? (viewport.height / 4) * 3 : viewport.width}
          mt={1.5}
          mb={1.5}
          flexWrap="wrap">
          <Box>
            <Html zIndexRange={[1, 0]} center>
              <iframe
                width={viewport.width * 40}
                height={ratio > 1 ? viewport.height * 50 : viewport.width * 40}
                src="https://www.youtube.com/embed/IKzSupjkA0s"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </Html>
          </Box>
        </Box>

        <ImageFull image={project.imagesEtkt[6]} imagesCaptions={''} />

        <ImageMosaic images={project.mosaicDevice} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <TextTitle
          subTitle={'Do it yourself!'}
          title={'open\nsource'}
          text={project.textsEtkt[2]}
          image={project.imagesEtkt[2]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}></TextTitle>

        <ImageFull image={project.imagesEtkt[4]} imagesCaptions={''} />

        <TextImage
          title={'Dedicated E-TKT page'}
          text={project.textsEtkt[1]}
          image={project.imagesEtkt[5]}
          imageOpacity={0.3}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <HtmlLink url={'https://andreisperid.github.io/E-TKT/about.html'} icon={externalIcon} text={'E-TKT - documentation'} />
        </TextImage>

        <Box mt={ratio > 1 ? 0 : 3} />

        <ImageFull image={project.imagesEtkt[7]} imagesCaptions={''} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <BesidesFooter />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function Hull({ onReflow }) {
  invertScreen = false

  let projectCaption = 'hull'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <BesidesCover
          left={true}
          projectKey={projectKey}
          category={'works'}
          invertedQuestion={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          subTitle={`Simulator, 2020-21`}
          title={'multisensorial drive & fly in VR'}
          image={project.images[0]}
          text={project.texts[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}></TextTitle>

        <VideoFull videoSrc={'/images/drivesim/drive.mp4'} imagesCaptions={``} />

        <SectionTitle text={`haptics enhance the immersion`} />
        <TextImage
          title={'Vibration:\nvehicle becomes body'}
          text={project.texts[1]}
          image={project.images[4]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Wind:\nsurroundings reach out'}
          text={project.texts[2]}
          image={project.images[5]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextImage
          title={'User Interfaces'}
          text={project.texts[3]}
          image={project.images[8]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageMosaic images={project.mosaicImagesSenses} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        <SectionTitle text={`a flexible multipurpose platform`} />
        <ImageFullHover
          image1={project.images[2]}
          image2={project.images[3]}
          imagesCaptions={`Seat adjustment and plug and play yoke makes it easy to switch between modes.`}
        />
        <TextTitle
          subTitle={`Wheel, paddles, pedals, shift and handbrake`}
          title={'driving'}
          image={project.images[6]}
          text={project.texts[4]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}></TextTitle>
        <ImageMosaic images={project.mosaicImagesDrive} />
        <TextTitle
          subTitle={`Yoke, sidestick, rudder pedals and free space`}
          title={'flying'}
          image={project.images[7]}
          text={project.texts[5]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}></TextTitle>
        <ImageMosaic images={project.mosaicImagesFly} />

        <SectionTitle text={`modular development`} />
        <TextText
          textFirst={project.texts[6]}
          textSecond={project.texts[7]}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic opacity={0.5} images={project.mosaicImagesProcess} />
        <ImageFull image={project.images[9]} imagesCaptions={''} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <BesidesFooter />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function Lamps({ onReflow }) {
  invertScreen = false

  let projectCaption = 'lamps'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <BesidesCover
          left={true}
          projectKey={projectKey}
          category={'works'}
          invertedQuestion={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <TextTitle
          subTitle={`Ricochet`}
          title={`lights & surfaces`}
          image={project.images[0]}
          text={project.texts[0]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull image={project.images[14]} />

        {/* pyr */}
        <SectionTitle text={'PYR, 2015-2018'} />
        <TextImage
          title={'A lenghty creation'}
          text={project.texts[4]}
          image={project.images[3]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull image={project.images[1]} />
        <TextImage
          title={'Wooden notes'}
          text={project.texts[5]}
          image={project.images[2]}
          imageOpacity={0.75}
          left={false}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicPyrImages} />

        {/* c lamp */}
        <SectionTitle text={'C, 2015'} />
        <ImageFull image={project.images[4]} />
        <TextImage
          title={'My last light,\neach and every day'}
          text={project.texts[2]}
          image={project.images[5]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull image={project.images[6]} />
        <ImageMosaic images={project.mosaicClampImages} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        {/* ignez */}
        <SectionTitle text={'Ignez, 2016'} />
        <ImageFull image={project.images[7]} />
        <TextImage
          title={'A present for my grandmother'}
          text={project.texts[3]}
          image={project.images[9]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull image={project.images[8]} />
        <ImageFull image={project.images[10]} />
        <ImageMosaic images={project.mosaicIgnezImages} />

        {/* toninha */}
        <SectionTitle text={'Toninha, 2014'} />
        <ImageFull image={project.images[11]} />
        <TextImage
          title={'A present for my girlfriend'}
          text={project.texts[1]}
          image={project.images[12]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull image={project.images[13]} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <BesidesFooter />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function Prototypes({ onReflow }) {
  invertScreen = false

  let projectCaption = 'prototypes'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <BesidesCover
          left={true}
          projectKey={projectKey}
          category={'works'}
          invertedQuestion={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <HighlightText projectKey={projectKey} text={`bridging physical & digital`} />

        {/* tem gente */}
        <TextTitle
          subTitle={`Tem gente? 2015`}
          title={'knock knock knock knock'}
          image={project.imagesTemgente[0]}
          text={project.textsTemgente[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull
          image={project.imagesTemgente[4]}
          imagesCaptions={
            'The device controlled the exhaustors independently, keeping them working until the smell (indicated by Metane - CH4) was at a comfortable level.'
          }
        />
        <TextImage
          title={'Device:\nIoT sensor & actuator'}
          text={project.textsTemgente[1]}
          image={project.imagesTemgente[1]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageFull image={project.imagesTemgente[5]} />
        <TextImage
          title={'Data:\ndevelopment and operation'}
          text={project.textsTemgente[2]}
          image={project.imagesTemgente[2]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'App:\nshould I stay or should I go?'}
          text={project.textsTemgente[3]}
          image={project.imagesTemgente[3]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicTemgente} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        {/* coiso */}
        <TextTitle
          subTitle={`Ana Marini's Final Design Graduation Project, 2017`}
          title={'COISO, by Ana Marini'}
          imagesCredit={'Ana Marini'}
          image={project.imagesCoiso[0]}
          text={project.textsCoiso[0]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <ImageFullHover
          image1={project.imagesCoiso[1]}
          image2={project.imagesCoiso[2]}
          imagesCaptions={`Before and after the kids' friend was born`}
        />
        <ImageMosaic images={project.mosaicCoiso} />
        <TextImage
          title={'An interactive brain for cardboard creatures'}
          text={project.textsCoiso[1]}
          image={project.imagesCoiso[3]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <HtmlLink url={'https://anamarini.com/projetos'} icon={externalIcon} text={'much more at her portfolio!'} />
        </TextImage>

        <Spacer />

        {/* lebraile */}
        <TextTitle
          subTitle={`Design of a portable braile reader, 2018`}
          title={'Lebraile, at Red Bull Basement'}
          image={project.imagesLebraile[0]}
          imagesCredit={'Felipe Gabriel'}
          text={project.textsLebraile[0]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Product design'}
          text={project.textsLebraile[1]}
          image={project.imagesLebraile[1]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <HtmlLink url={'http://www.lebraile.org/'} icon={externalIcon} text={'for more, access Lebraile website'} />
        </TextImage>
        <ImageMosaic images={project.mosaicLebraile} />

        {/* decorato */}
        <TextTitle
          subTitle={`Interactive prototype, 2017`}
          title={'Mueller Decorato Gourmet'}
          image={project.imagesDecorato[0]}
          text={project.textsDecorato[0]}
          imagesCredit={'Questto|Nó'}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Rethinking oven experience'}
          text={project.textsDecorato[1]}
          image={project.imagesDecorato[1]}
          imagesCredit={'Walmor de Paula'}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <HtmlLink
            url={'https://loja.mueller.ind.br/vidro-espelhado/forno-eletrico-digital-de-embutir-decorato-gourmet-inox-44-litros.html'}
            icon={externalIcon}
            text={'you can buy one here'}
          />
        </TextImage>
        <ImageMosaic images={project.mosaicDecorato} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <BesidesFooter />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}
function Material({ onReflow }) {
  invertScreen = false

  let projectCaption = 'material'
  let projectKey = 0
  for (var key in content.works.projects) {
    if (content.works.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.works.projects[key]

  let projectCaptionAnalytics = projectCaption.replaceAll(' ', '')
  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
    analyticsTracker(projectCaptionAnalytics)
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const { viewport, size } = useThree()
  const pageLerp = useRef(state.top / size.height)

  useFrame(() => {
    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      group.current.position.y = y
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  return (
    <group ref={group}>
      <Flex dir="column" position={[-viewport.width / 2, viewport.height / 2, 0]} size={[viewport.width, viewport.height, 0]} onReflow={handleReflow}>
        <BesidesCover
          left={true}
          projectKey={projectKey}
          category={'works'}
          invertedQuestion={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />

        <HighlightText projectKey={projectKey} text={`digital for the purpose of analogical`} />

        {/* mri experiments */}
        <TextTitle
          subTitle={`Skull teardown, 2012`}
          title={'computed tomography scan'}
          image={project.imagesMri[0]}
          text={project.textsMri[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}></TextTitle>
        <VideoFull videoSrc={'/images/material/mri.mp4'} imagesCaptions={`3D printing (ABS) a jaw section in 1:1 scale.`} />
        <TextImage
          title={'Bone > X-ray > Byte > Plastic'}
          text={project.textsMri[1]}
          image={project.imagesMri[2]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicMri} />

        <ImageFull image={project.imagesMri[3]} imagesCaptions={'From then on, if anyone tells me about having done a CT scan...'} />

        {/* polargraph */}
        <TextTitle
          subTitle={`My build of a Polargraph, 2017`}
          title={'drawing robots'}
          image={project.imagesPolargraph[2]}
          text={project.textsPolargraph[0]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <HtmlLink url={'https://www.polargraph.co.uk/'} icon={externalIcon} text={`check Sandy's site and build yours!`} />
        </TextTitle>
        <VideoFull videoSrc={'/images/material/polargraph.mp4'} imagesCaptions={`A servo lifts the pen while hovering.`} />
        <TextImage
          title={'Hybrid robot furniture'}
          text={project.textsPolargraph[1]}
          image={project.imagesPolargraph[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <ImageMosaic images={project.mosaicPolargraph} />
        <ImageFull
          image={project.imagesPolargraph[1]}
          imagesCaptions={'A wireframe from a Ford Escort I have modelled in 3D (photo turned 90 degrees to the left).'}
        />
        <ImageMosaic images={project.mosaicPolargraphTests} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' mid'} />

        {/* holi */}
        <TextTitle
          subTitle={`Holi for Ovni Studios, 2018`}
          title={'low cost AR glasses'}
          image={project.imagesHoli[0]}
          text={project.textsHoli[0]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <HtmlLink url={'https://web.facebook.com/ovnistudios'} icon={externalIcon} text={`check out Ovni's page!`} />
        </TextTitle>
        <TextImage
          title={'An accessible AR headset'}
          text={project.textsHoli[1]}
          image={project.imagesHoli[2]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <VideoFull videoSrc={'/images/material/holi.mp4'} imagesCaptions={`The smartphone image reflects, creating holograms.`} />
        <ImageFull
          image={project.imagesHoli[1]}
          imagesCaptions={'Ovni have developed Ovni Spaces, a proprietary OS that can be accessed with Holi.'}
          imagesCredit={'Ovni Studios'}
        />
        <TextImage
          title={'Everything fitted in A4 size'}
          text={project.textsHoli[2]}
          image={project.imagesHoli[3]}
          imageOpacity={0.75}
          left={true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}
        />
        <TextImage
          title={'Workshop at Red Bull Basement, 2018'}
          text={project.textsHoli[3]}
          imagesCredit={'Red Bull'}
          image={project.imagesHoli[4]}
          imageOpacity={0.75}
          left={!true}
          onReflow={(w, h) => {
            sizesRef.current = h
          }}>
          <HtmlLink
            url={'https://www.redbull.com/br-pt/oculos-realidade-aumentada-baixo-custo'}
            icon={externalIcon}
            text={`article about the workshop (pt_BR)`}
          />
        </TextImage>

        <ImageFull image={project.imagesHoli[5]} imagesCaptions={'Participants built their own Holi and brought it home.'} />

        <ScrollTracker eventName={projectCaptionAnalytics + ' footer'} />

        <BesidesFooter />
        <CopyrightFooter />
      </Flex>
    </group>
  )
}

function Books({ onReflow }) {
  invertScreen = false

  const textureSpace = useLoader(THREE.TextureLoader, '/images/besides/eso0932a_low.jpg')

  let projectCaption = 'books'
  let projectKey = 0
  for (var key in content.besides.projects) {
    if (content.besides.projects[key].caption == projectCaption) {
      projectKey = key
      break
    }
  }
  const project = content.besides.projects[key]

  if (currentUrl != project.url) {
    topFunction()
    currentUrl = project.url
  }

  foregroundColor = project.foreground
  backgroundColor = project.background

  useEffect(() => {
    document.getElementById('root').style.color = foregroundColor
    document.getElementById('root').style.background = backgroundColor
    document.body.style.background = backgroundColor
  })

  const group = useRef()
  const stars = useRef()
  const { viewport, size } = useThree()
  // const vec = new THREE.Vector3()
  const pageLerp = useRef(state.top / size.height)

  useFrame(({ clock }) => {
    const t = (1 + Math.sin(clock.getElapsedTime() * 0.2)) / 2

    const page = (pageLerp.current = THREE.MathUtils.lerp(pageLerp.current, state.top / size.height, 0.15))
    const y = page * viewport.height

    if (group.current) {
      // group.current.position.lerp(vec.set(0, page < state.threshold ? y : sticky, page < state.threshold ? 0 : page * 1.25), 0.15)
      group.current.position.y = y
    }

    if (stars.current) {
      stars.current.rotation.x = THREE.MathUtils.lerp(stars.current.rotation.x, y / 400, 0.01)
      stars.current.rotation.z = Math.sin((t * 10) / 3) / 50
      stars.current.rotation.y = THREE.MathUtils.lerp(stars.current.rotation.y, state.mouse[0] / 50 + Math.sin((t * 10) / 3) / 75, 0.01)
    }
  })

  const handleReflow = useCallback((w, h) => onReflow((state.pages = h / viewport.height)), [onReflow, viewport.height])
  const sizesRef = useRef([])

  return (
    <>
      <group ref={stars}>
        <Sphere args={[3000]} rotation={[Math.PI / 10, -Math.PI / 1.25, Math.PI / 10]}>
          <meshBasicMaterial color={'#726b85'} map={textureSpace} side={THREE.BackSide} blending={THREE.AdditiveBlending} />
        </Sphere>
        <Stars radius={500} depth={1000} count={15000} factor={50} saturation={1} fade />
      </group>
      <group ref={group}>
        <Flex
          dir="column"
          position={[-viewport.width / 2, viewport.height / 2, 0]}
          size={[viewport.width, viewport.height, 0]}
          onReflow={handleReflow}>
          <BesidesCover
            left={true}
            projectKey={projectKey}
            category={'besides'}
            invertedQuestion={true}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}
          />
          <TextBook
            title={'Plant Revolution\nby Stefano Mancuso'}
            text={project.texts[7]}
            image={project.image[7]}
            model={project.model}
            modelOpacity={0.75}
            left={true}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}>
            <Html zIndexRange={[1, 0]} transform>
              <div style={{ whiteSpace: 'nowrap', fontSize: 16 }}>
                <a href={project.storeurl[7]} className="icon-bar" target="_blank" rel="noopener noreferrer">
                  <InlineIcon icon={externalIcon} /> store link
                </a>
              </div>
            </Html>
          </TextBook>
          <TextBook
            title={'How to Speak Machine\nby John Maeda'}
            text={project.texts[6]}
            image={project.image[6]}
            model={project.model}
            modelOpacity={0.75}
            left={false}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}>
            <Html zIndexRange={[1, 0]} transform>
              <div style={{ whiteSpace: 'nowrap', fontSize: 16 }}>
                <a href={project.storeurl[6]} className="icon-bar" target="_blank" rel="noopener noreferrer">
                  <InlineIcon icon={externalIcon} /> store link
                </a>
              </div>
            </Html>
          </TextBook>
          <TextBook
            title={'Dawn of the New Everything\nby Jaron Lanier'}
            text={project.texts[5]}
            image={project.image[5]}
            model={project.model}
            modelOpacity={0.75}
            left={true}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}>
            <Html zIndexRange={[1, 0]} transform>
              <div style={{ whiteSpace: 'nowrap', fontSize: 16 }}>
                <a href={project.storeurl[5]} className="icon-bar" target="_blank" rel="noopener noreferrer">
                  <InlineIcon icon={externalIcon} /> store link
                </a>
              </div>
            </Html>
          </TextBook>
          <TextBook
            title={'Sentient City\nby Mark Shepard'}
            text={project.texts[4]}
            image={project.image[4]}
            model={project.model}
            modelOpacity={0.75}
            left={false}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}>
            <Html zIndexRange={[1, 0]} transform>
              <div style={{ whiteSpace: 'nowrap', fontSize: 16 }}>
                <a href={project.storeurl[4]} className="icon-bar" target="_blank" rel="noopener noreferrer">
                  <InlineIcon icon={externalIcon} /> store link
                </a>
              </div>
            </Html>
          </TextBook>
          <TextBook
            title={'2001 A Space Odyssey\nby Arthur C. Clarke'}
            text={project.texts[3]}
            image={project.image[3]}
            model={project.model}
            modelOpacity={0.75}
            left={true}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}>
            <Html zIndexRange={[1, 0]} transform>
              <div style={{ whiteSpace: 'nowrap', fontSize: 16 }}>
                <a href={project.storeurl[3]} className="icon-bar" target="_blank" rel="noopener noreferrer">
                  <InlineIcon icon={externalIcon} /> store link
                </a>
              </div>
            </Html>
          </TextBook>
          <TextBook
            title={'Living with Complexity\nby Donald A. Norman'}
            text={project.texts[2]}
            image={project.image[2]}
            model={project.model}
            modelOpacity={0.75}
            left={false}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}>
            <Html zIndexRange={[1, 0]} transform>
              <div style={{ whiteSpace: 'nowrap', fontSize: 16 }}>
                <a href={project.storeurl[2]} className="icon-bar" target="_blank" rel="noopener noreferrer">
                  <InlineIcon icon={externalIcon} /> store link
                </a>
              </div>
            </Html>
          </TextBook>
          <TextBook
            title={'Invisible Cities\nby Italo Calvino'}
            text={project.texts[0]}
            image={project.image[0]}
            model={project.model}
            modelOpacity={0.75}
            left={true}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}>
            <Html zIndexRange={[1, 0]} transform>
              <div style={{ whiteSpace: 'nowrap', fontSize: 16 }}>
                <a href={project.storeurl[0]} className="icon-bar" target="_blank" rel="noopener noreferrer">
                  <InlineIcon icon={externalIcon} /> store link
                </a>
              </div>
            </Html>
          </TextBook>
          <TextBook
            title={'The Long Tail\nby Chris Anderson'}
            text={project.texts[1]}
            image={project.image[1]}
            model={project.model}
            modelOpacity={0.75}
            left={false}
            onReflow={(w, h) => {
              sizesRef.current = h
            }}>
            <Html zIndexRange={[1, 0]} transform>
              <div style={{ whiteSpace: 'nowrap', fontSize: 16 }}>
                <a href={project.storeurl[1]} className="icon-bar" target="_blank" rel="noopener noreferrer">
                  <InlineIcon icon={externalIcon} /> store link
                </a>
              </div>
            </Html>
          </TextBook>
          <BesidesFooter />
          <CopyrightFooter />
        </Flex>
      </group>
    </>
  )
}

// --------------------------------------------------------------------------------------
// core App
// --------------------------------------------------------------------------------------
export default function App() {
  const onScroll = (e) => (state.top = e.target.scrollTop)
  const [pages, setPages] = useState(0)

  const scrollRef = useRef()
  const scroll = useRef(0)

  return (
    <>
      {/* <Stats
        showPanel={0} // Start-up panel (default=0)
        className="stats" // Optional className to add to the stats container dom element
        // {...props} // All stats.js props are valid
      /> */}

      <DeviceDetect />
      <Suspense fallback={null}>
        {currentUrl != null && currentUrl != undefined && !currentUrl.includes('/splash') ? <Header /> : null}
        <Canvas
          concurrent
          a
          linear
          dpr={dpr}
          raycaster={{
            computeOffsets: ({ offsetX, offsetY, target }) => ({ offsetX, offsetY: offsetY - target.scrollTop / target.scrollHeight }),
          }}
          camera={{ fov: 30, position: [0, 0, 30], near: 0.1, far: 6000 }}
          gl={{ powerPreference: 'high-performance', antialias: false, alpha: true, stencil: false, depth: false }}
          onCreated={(({ gl }) => gl.setClearColor('#d6d6d6', 0.0), (state) => state.events.connect(scrollRef.current))}>
          {/* {currentUrl != null && currentUrl != undefined && !currentUrl.includes('/splash') ? <HeaderStrip /> : null} */}
          {currentUrl != null && currentUrl != undefined && !currentUrl.includes('/splash') && touchDevice ? <HeaderStrip /> : null}

          <ProgressionBar pages={pages} />

          {/* <MouseLaser /> */}

          <StaticLights />

          {/* <Perf /> */}
          <ScrollContainer scroll={scroll}>
            <Switch>
              <Route exact path="/">
                <Suspense fallback={null}>
                  <Home onReflow={setPages} />
                </Suspense>
              </Route>

              {/* splash screens */}
              <Route path="/splashmoebius">
                <Suspense fallback={null}>
                  <SplashMoebius onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/splashstrip">
                <Suspense fallback={null}>
                  <SplashStrip onReflow={setPages} />
                </Suspense>
              </Route>

              {/* works */}
              <Route path="/works/collab">
                <Suspense fallback={null}>
                  <Collab onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/works/beleaf">
                <Suspense fallback={null}>
                  <Beleaf onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/works/lab">
                <Suspense fallback={null}>
                  <Lab onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/works/threefx">
                <Suspense fallback={null}>
                  <ThreeFX onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/works/voyager">
                <Suspense fallback={null}>
                  <Voyager onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/works/connecteddrains">
                <Suspense fallback={null}>
                  <ConnectedDrains onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/works/oasis">
                <Suspense fallback={null}>
                  <Oasis onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/works/sciencebox">
                <Suspense fallback={null}>
                  <ScienceBox onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/works/playtags">
                <Suspense fallback={null}>
                  <PlayTags onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/works/early">
                <Suspense fallback={null}>
                  <EarlyWorks onReflow={setPages} />
                </Suspense>
              </Route>

              {/* be-sides */}
              <Route path="/chromnos">
                <Suspense fallback={null}>
                  <Chromnos onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/e-tkt">
                <Suspense fallback={null}>
                  <Etkt onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/hull">
                <Suspense fallback={null}>
                  <Hull onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/lamps">
                <Suspense fallback={null}>
                  <Lamps onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/prototypes">
                <Suspense fallback={null}>
                  <Prototypes onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/material">
                <Suspense fallback={null}>
                  <Material onReflow={setPages} />
                </Suspense>
              </Route>

              {/* old  */}
              <Route path="/books">
                <Suspense fallback={null}>
                  <Books onReflow={setPages} />
                </Suspense>
              </Route>
              <Route path="/besides">
                <Suspense fallback={null}>
                  <Besides onReflow={setPages} />
                </Suspense>
              </Route>
            </Switch>
            {!touchDevice ? <MouseLight /> : null}
          </ScrollContainer>
          <Preload all />
          <EffectComposer multisampling={dpr <= 2 ? 2 : 0} disableNormalPass={true}>
            <Vignette eskil={true} offset={0.45} darkness={3.5} blendFunction={BlendFunction.NORMAL} opacity={0.85} />
            <InvertEffect opacity={invertScreen ? 1 : 0} />
          </EffectComposer>
        </Canvas>
      </Suspense>

      <div
        ref={scrollRef}
        id="scrollDiv"
        onScroll={onScroll}
        onPointerMove={(e) => (state.mouse = [(e.clientX / window.innerWidth) * 2 - 1, (e.clientY / window.innerHeight) * 2 - 1])}
        style={{
          position: 'absolute',
          width: '100vw',
          height: 'auto',
          overflowY: 'auto',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          scrollSnapType: 'y mandatory',
          scrollSnapStop: 'always',
        }}>
        <div style={{ height: `${pages * 100}vh`, pointerEvents: 'none' }}>
          <SnapSections />
        </div>
      </div>

      <Loader
        containerStyles={{ position: 'fixed', background: backgroundColor, width: '100%', height: '100%', zIndex: 10000 }}
        innerStyles={{ background: backgroundColor, width: '100vw', height: '1px' }}
        barStyles={{ background: foregroundColor, width: '100vw', height: '1px' }}
        dataStyles={{ opacity: 0 }}
      />
    </>
  )
}
